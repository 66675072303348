import React from 'react';
import { IconButton, Theme, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  createDirectMessage,
  getSubscriptionRoom,
  sendMessageUploadFile
} from 'src/apis/socialChat.apis';
import { urlRocketChat } from 'src/util/config';
import { buttonColor } from 'src/constants/styles';
import {
  closeBoxCreate,
  selectedUserBoxChat
} from 'src/store/action/socialChatAction';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
const useStyles = makeStyles((them: Theme) => ({
  text_input: {
    fontSize: '14px',
    padding: '8px 5px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    marginBottom: 0,

    borderRadius: '50px',
    height: '100%',
    maxHeight: '33px',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  option_text: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  progress_bar: {
    height: '100%',
    maxHeight: '33px',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: '#fafafa30',
    zIndex: '9999',
    borderRadius: '50px'
    // transform: `translateX(${curPercentage}%)`
    // transform: `translateX(${curPercentage}%)`
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '33px',
    position: 'absolute',
    left: '0',
    gap: 4
  },
  icon_button: {
    backgroundColor: 'white',
    width: '26px',
    height: '26px',
    borderRadius: '15px',
    marginBottom: '3px',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: 0,
    zIndex: 10000,
    cursor: 'pointer'
  }
}));
function DialogSendAudio(props) {
  const {
    open,
    setOpen,
    colorIcon,
    stopRecording,
    isRecording,
    audioUrl,
    conversation,
    setAudioUrl,
    setIsRecording,
    chunks,
    setChunks,
    repliedMessage,
    listConversationSelected,
    setRepliedMessage
  } = props;
  const mainTopic = conversation?.topic
    ? conversation?.topic?.colors
    : conversation?.RoomsList?.[0]?.topic?.colors || [
        buttonColor.backgroundColor,
        buttonColor.backgroundColor
      ];
  const match: any = useRouteMatch();

  const classes = useStyles();
  const dispatch = useDispatch();
  const btnSendRef = React.useRef<any>();
  const audioRef = React.useRef<any>();
  const [duration, setDuration] = React.useState<any>();
  const [curTime, setCurTime] = React.useState<any>();
  const [playing, setPlaying] = React.useState(false);
  const [clickedTime, setClickedTime] = React.useState<any>();
  const [time, setTime] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
    setAudioUrl('');
    setChunks([]);
  };
  React.useEffect(() => {
    if (btnSendRef?.current) {
      btnSendRef.current.focus();
    }
  }, [btnSendRef]);
  const handleUploadFileApi = async (
    messageId?: string,
    file?: any,
    time?: any
  ) => {
    try {
      let response;
      let room;
      if (!messageId) {
        if (conversation) {
          response = await sendMessageUploadFile(
            '',
            file,
            conversation.rid,
            time,
            undefined,
            match.params?.key === 'chat' ? 'chatPage' : undefined
          );
        } else {
          let responseCreate = await createDirectMessage(
            listConversationSelected[0]?.username
          );
          room = JSON.parse(responseCreate.data.message).result.rid;
          const res = await getSubscriptionRoom(room._id)
          dispatch(selectedUserBoxChat(res.data.subscription));

          response = await sendMessageUploadFile(
            '',
            file,
            room,
            undefined,
            undefined,
            match.params?.key === 'chat' ? 'chatPage' : undefined
          );
        }
      } else {
        response = await sendMessageUploadFile(
          messageId,
          file,
          conversation.rid,
          undefined,
          undefined,
          match.params?.key === 'chat' ? 'chatPage' : undefined
        );
      }

      if (response?.status === 200) {
        // handle
        dispatch(closeBoxCreate());
      }
    } catch (error) {
      console.log('error upload file message', error);
    }
  };

  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handleSendFileAudio = async () => {
    try {
      let data;
      if (isRecording) {
        data = await stopRecording();
      }

      const blob = new Blob(isRecording ? data : chunks, {
        type: 'audio/mpeg'
      });
      const audioContext = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      const arrayBuffer = await blob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const duration = audioBuffer.duration;
      const file = new File([blob], 'filename.mp3', {
        type: 'audio/mpeg'
      });
      if (!repliedMessage) {
        await handleUploadFileApi(undefined, file, duration);
      } else {
        await handleUploadFileApi(
          `[ ](${urlRocketChat}/messages/${conversation.rid}?msg=${repliedMessage._id}) `,
          file,
          duration
        );
      }
      await setAudioUrl('');
      await setChunks([]);
      handleClose();
      setRepliedMessage(null);
    } catch (error) {
      console.log('error send file message', error);
      handleClose();
      setRepliedMessage(null);
    }
  };

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
    }

    const timer = setTimeout(() => {
      if (isRecording) {
        setIsRecording(false);
      }
    }, 60000);
    return () => clearTimeout(timer);
  }, [audioUrl]);

  React.useEffect(() => {
    const audio = audioRef.current;
    // const audio = document.getElementById('audio');
    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);

    // DOM listeners: update React state on DOM events
    audio.addEventListener('loadeddata', setAudioData);

    audio.addEventListener('timeupdate', setAudioTime);

    audio.addEventListener('loadeddata', () => {
      setDuration(audio.duration);
    });

    // React state listeners: update DOM on React state changes

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }
    playing ? audio.play() : audio.pause();

    // effect cleanup
    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
      audio.removeEventListener('loadedmetadata', setDuration);
    };
  });

  const currentTime = Math.round(curTime);
  const barRef = React.useRef<any>();

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = barRef.current;
    const barStart = bar?.getBoundingClientRect()?.left + window.scrollX;
    const barWidth = bar?.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }
  const onTimeUpdate = time => setClickedTime(time);
  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  }

  React.useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRecording]);
  return (
    <Box className={classes.root}>
      <audio
        id="audio"
        ref={audioRef}
        preload="auto"
        onEnded={() => {
          setPlaying(false);
        }}
      >
        <source src={`${audioUrl}`} type="audio/mpeg" />
      </audio>
      <IconButton
        onClick={handleClose}
        className={classes.icon_button}
        style={{
          background: mainTopic
            ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
            : buttonColor.backgroundColor
        }}
      >
        <i
          className="fa-solid fa-xmark"
          style={{
            color: colorIcon ? '#fafafa' : '#bcc0c4',
            fontSize: 20
          }}
        ></i>
      </IconButton>
      <Box
        className={classes.text_input}
        // ref={barRef}
        // onMouseDown={e => handleTimeDrag(e)}
        sx={{
          background: mainTopic
            ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
            : buttonColor.backgroundColor
        }}
      >
        <Box className={classes.option_text}>
          {isRecording ? (
            <IconButton onClick={stopRecording} className={classes.icon_button}>
              <i
                className="fa-solid fa-pause"
                style={{
                  color: colorIcon ? '#fafafa' : '#bcc0c4',
                  fontSize: 20,
                  zIndex: 1000
                }}
              ></i>
            </IconButton>
          ) : !playing ? (
            <IconButton
              className={classes.icon_button}
              onClick={() => setPlaying(true)}
            >
              <i
                className="fa-solid fa-play"
                style={{
                  color: colorIcon ? '#fafafa' : '#bcc0c4',
                  fontSize: 20
                }}
              ></i>
            </IconButton>
          ) : (
            <IconButton
              className={classes.icon_button}
              onClick={() => setPlaying(false)}
            >
              <i
                className="fa-solid fa-pause"
                style={{
                  color: colorIcon ? '#fafafa' : '#bcc0c4',
                  fontSize: 20
                }}
              ></i>
            </IconButton>
          )}
          <Typography
            style={{
              fontSize: '13px',
              width: '48px',
              height: '24px',
              borderRadius: '15px',
              marginRight: '3px',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: mainTopic ? mainTopic[1] : buttonColor.backgroundColor
            }}
          >
            {formatDuration(playing ? currentTime : time)}
          </Typography>
        </Box>

        <Box className={classes.progress_bar}></Box>
      </Box>
      <IconButton
        ref={btnSendRef}
        className={classes.icon_button}
        onClick={handleSendFileAudio}
      >
        <i
          className="fa-solid fa-paper-plane-top"
          style={{
            color: colorIcon
              ? mainTopic
                ? mainTopic[1]
                : buttonColor.backgroundColor
              : '#bcc0c4',
            fontSize: 30
          }}
        ></i>
      </IconButton>
    </Box>
  );
}

export default DialogSendAudio;
