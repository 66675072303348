import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import {
  fetchAccountRelationship,
  fetchFamilyRelationship
} from 'src/apis/socialUser';
import logoEmso from 'src/assets/images/LogoEmso.svg';
import noti from 'src/assets/images/Noti.svg';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogViolate from 'src/components/Dialog/DialogViolate';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import SocialCutImage from 'src/components/SocialCutImage/SocialCutImage';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import { handlePrice, handleTimeShow } from 'src/helpers/string';
import { getGroupDetailReq } from 'src/store/action/socialGroupAction';
import { updateNotifyVerifyPageName } from 'src/store/action/socialPageAction';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  text: {
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important'
  },
  title: {
    paddingBottom: 0
  },
  content: {
    paddingTop: 0
  },
  itemText: {
    marginTop: 0
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  textContentNoti: {
    fontWeight: '400 !important'
  }
}));

interface Props {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>> | any;
  item?: any;
  type?: String;
  openDialogViolate?: boolean;
  setOpenDialogViolate?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClosePreview?: any;
}

const CommonNoti = (props: Props) => {
  const {
    setOpen,
    item,
    type,
    openDialogViolate,
    setOpenDialogViolate,
    handleClosePreview
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [familyRelaionship, setFamilyRelaionship] =
    React.useState<any>('pending');
  const [isLoadingAccept, setIsLoadingAccept] = React.useState<any>(false);
  const [isLoadingReject, setIsLoadingReject] = React.useState<any>(false);
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const accountSettings =
    useSelector((state: any) => state.userReducer?.accountSetting) || {};
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const subsystem = useCallback(() => {
    switch (item?.type) {
      case 'event':
        return 'sự kiện';
      case 'project':
        return 'dự án';
    }
  }, []);

  const convertText = (data: any, status_tags: any) => {
    if (!status_tags) return data;
    if (!data) return '';
    else {
      return reactStringReplace(data, /(\[\d+\])/gm, (match, i, offset) => {
        let objectMentions = status_tags?.find(
          el => el.entity_id === match.slice(1, match.length - 1)
        );
        return objectMentions?.name;
      }).join('');
    }
  };

  const renderActionText = (post: any, content: string) => {
    if (post?.post_type === 'event_shared') {
      return 'đã tạo một sự kiện mới. ';
    } else if (post?.post_type === 'moment') {
      return 'đã thêm một video vào Khoảnh khắc';
    } else if (post?.post_type === 'watch') {
      return 'đã thêm một video vào Watch';
    } else if (post?.post_type === 'question') {
      return 'đã đặt một câu hỏi';
    } else if (post?.post_type === 'target') {
      return 'đã đặt một mục tiêu';
    } else if (post?.target_account_id) {
      return `đã ${
        post?.reblog_of_id ? ' chia sẻ một liên kết ' : ' đăng '
      } trên dòng thời gian của bạn ${content ? ':' : '.'} `;
    } else if (post?.course_id) {
      return `đã tạo một bài viết mới trong khoá học với nội dung: `;
    } else return `đã tạo một bài viết mới ${content ? ':' : '.'} `;
  };

  const renderDisplayNameObj = (data: any) => {
    if (data?.status?.page) return data.status.page.title;
    else if (data?.status?.group) return data.status.group.title;
    else return data?.account?.display_name;
  };

  const renderTextBold = (textNone: string, textBold: any = null) => {
    return (
      <span>
        {textNone}
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          {textBold}
        </Typography>
      </span>
    );
  };
  const renderNameInfo = item => {
    const infoPageFavourite = item?.favourite?.page;
    const infoPageStatus = item?.status?.page || item?.status?.page_owner;
    if (
      item?.type === 'favourite' &&
      item?.favourite?.status?.favourites_count > 1
    ) {
      return `${
        infoPageFavourite
          ? infoPageFavourite.title
          : item?.account?.display_name
      } và ${item?.favourite?.status?.favourites_count - 1} người khác`;
    } else if (item?.type === 'reblog' && item?.status?.reblogs_count > 1) {
      return `${
        infoPageStatus ? infoPageStatus?.title : item?.account?.display_name
      } và ${item?.status?.reblogs_count - 1} người khác`;
    } else if (item?.type === 'status' || item?.type === 'mention') {
      return item?.account?.display_name;
    } else {
      return infoPageFavourite
        ? infoPageFavourite?.title
        : infoPageStatus
        ? infoPageStatus?.title
        : item?.account?.display_name;
    }
  };
  const renderPrimary = item => {
    let text: any = '';
    let user: string = renderNameInfo(item);
    // notiContent not use anywhere
    // let notiContent = '';
    // if (item?.status?.content && item?.status?.page) {
    //   notiContent = convertText(
    //     item?.status?.content,
    //     item?.status?.status_tags
    //   );
    // } else {
    //   notiContent = item?.status?.page
    //     ? item?.status?.page?.title
    //     : item?.status?.content;
    // }
    // if (!item.status?.content?.length && !item.status?.page) {
    //   if (
    //     item?.type === 'favourite' &&
    //     item?.favourite?.status?.favourites_count > 1
    //   ) {
    //     notiContent = `${item?.account?.display_name} và ${item?.favourite?.status?.favourites_count} người khác`;
    //   } else {
    //     notiContent = item?.status?.account?.display_name;
    //   }
    // }

    if (item?.type === 'favourite') {
      text =
        `đã bày tỏ cảm xúc về ${
          item?.favourite?.status?.in_reply_to_parent_id ||
          item?.favourite?.status?.in_reply_to_id
            ? 'bình luận'
            : 'bài viết'
        } ${
          !item?.favourite?.status?.page_owner &&
          item?.favourite?.status?.account?.id === meInfo?.id
            ? `của bạn ${
                item?.favourite?.status?.group
                  ? `trong nhóm ${item?.favourite?.status?.group?.title}`
                  : ''
              } ${item?.favourite?.status?.content ? ': ' : ''}`
            : !item?.favourite?.status?.content && item?.favourite?.status?.page
            ? `của trang ${item?.favourite?.status?.content ? ': ' : ''}`
            : ''
        }` +
        convertText(
          item?.favourite?.status?.page?.title
            ? item?.favourite?.status?.page?.title
            : item?.favourite?.status?.content,
          item?.favourite?.status?.status_tags
        );
    } else if (item?.type === 'follow') {
      text = 'đã theo dõi bạn';
    } else if (item?.type === 'reblog') {
      text = 'đã chia sẻ bài viết của bạn';
    } else if (item?.type === 'mention') {
      text = (
        <span>
          {` đã gắn thẻ bạn trong một bài viết`}
          {item?.status?.group?.title && (
            <span>
              &nbsp;trong nhóm
              <Typography
                className={classes.text}
                component="span"
                sx={{
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.group?.title}`}
              </Typography>
            </span>
          )}
        </span>
      );
    } else if (item?.type === 'poll') {
      text = 'đã bầu chọn trong cuộc thăm dò của bạn';
    } else if (item?.type === 'rejected_project') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Dự án của bạn đã bị từ chối
        </Typography>
      );
    } else if (item?.type === 'response_reported_status') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Bài viết của bạn đã bị xoá do vi phạm chính sách cộng đồng của Emso
        </Typography>
      );
    } else if (item?.type === 'approved_project') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Dự án của bạn đã được phê duyệt
        </Typography>
      );
    } else if (item?.type === 'status') {
      if (item?.status?.reblog) {
        text = (
          <span>
            đã chia sẻ một bài viết
            {item?.status?.reblog?.group || item?.status?.reblog?.page
              ? ' trong'
              : ''}{' '}
            {item?.status?.reblog?.group || item?.status?.reblog?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.reblog?.group
                  ? item?.status?.reblog?.group?.title
                  : item?.status?.reblog?.page?.title}
              </Typography>
            ) : (
              ''
            )}
            {': '}
            {item?.status?.reblog?.content}
          </span>
        );
      } else if (item?.status?.shared_project) {
        text = (
          <span>
            đã chia sẻ dự án
            {item?.status?.group || item?.status?.page ? ' trong ' : ' '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_project.title} `}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else if (item?.status?.shared_recruit) {
        text = (
          <span>
            đã chia sẻ một bài viết tuyển dụng
            {item?.status?.group
              ? ' trong nhóm '
              : item?.status?.page
              ? 'trong page'
              : ' '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_recruit?.title}`}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else if (item?.status?.shared_course) {
        text = (
          <span>
            {'đã chia sẻ một khoá học '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_course?.title}`}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else if (item?.status?.shared_event) {
        text = (
          <span>
            đã chia sẻ một sự kiện
            {item?.status?.group
              ? ' trong nhóm '
              : item?.status?.page
              ? 'trong page'
              : ' '}
            {item?.status?.group || item?.status?.page ? (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {item?.status?.group
                  ? item?.status?.group?.title
                  : item?.status?.page?.title}
              </Typography>
            ) : (
              <Typography
                className={classes.text}
                sx={{
                  display: 'inline',
                  color: !item?.read ? 'text.primary' : 'text.secondary'
                }}
              >
                {` ${item?.status?.shared_event?.title}`}
              </Typography>
            )}
            {item?.status?.target_account_id === meInfo.id
              ? ' lên trang cá nhân của bạn'
              : null}
          </span>
        );
      } else {
        if (item?.status?.page_owner) {
          user = item?.status?.page_owner?.title;

          text =
            item?.status?.post_type === 'event_shared'
              ? 'đã tạo một sự kiện '
              : 'đã có một bài viết mới: ' + item?.status?.content;
        } else if (item?.status?.group || item?.status?.page) {
          if (
            item.account?.relationships?.friendship_status === 'ARE_FRIENDS'
          ) {
            const newContent = convertText(
              item?.status?.content,
              item?.status?.status_tags
            );
            user = item?.account?.display_name;
            text = (
              <span>
                {item?.status?.post_type === 'event_shared'
                  ? 'đã tạo sự kiện trong'
                  : 'đã tạo bài viết trong'}{' '}
                {item?.status?.group ? 'nhóm' : 'trang'}{' '}
                <span
                  className={classes.text}
                  style={{
                    color: !item?.read ? 'text.primary' : 'text.secondary'
                  }}
                >
                  {item?.status?.group
                    ? item?.status?.group?.title
                    : item?.status?.page?.title}
                </span>
                <span>: {newContent}</span>
              </span>
            );
          } else {
            const newContent = convertText(
              item?.status?.content,
              item?.status?.status_tags
            );
            user = item?.status?.group
              ? item?.status?.group?.title
              : item?.status?.page?.title;
            text =
              item?.status?.post_type === 'event_shared'
                ? 'có sự kiện mới: '
                : 'có bài viết mới: ' + newContent;
          }
        }
        // hiện tại đăng bài trong event, thông báo trả về mang status event
        // else if (item?.status?.event) {
        //   text = renderTextBold('đã đăng trong:', item.status.event?.title);
        // }
        else if (item?.status?.course) {
          text = renderTextBold(
            'đã đăng 1 bài viết mới trong khoá học bạn quan tâm'
          );
        } else {
          text =
            renderActionText(item?.status, item?.status?.content) +
            (item?.status?.content
              ? convertText(item?.status?.content, item?.status?.status_tags)
              : '');
        }
      }
    } else if (item?.type === 'event' || item?.type === 'project') {
      user = item?.account?.display_name;
      text = (
        <Typography
          sx={{
            display: 'inline'
          }}
        >
          {`đã đăng trong ${subsystem()}`}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {' '}
            {item?.status?.event?.title || item?.status?.project?.title}
          </Typography>
        </Typography>
      );
    } else if (item?.type === 'project_donate') {
      text = (
        <span>
          {` đã ủng hộ dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'music_invitation_author') {
      text = (
        <span>
          {` đã mời bạn làm tác giả bài hát: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.music?.name}
          </Typography>
        </span>
      );
    } else if (item?.type === 'accept_course_invitation_host') {
      text = ' đã chấp nhận làm giáo viên trong khoá học của bạn';
    } else if (item?.type === 'accept_course_invitation') {
      text = ` đã quan tâm khóa học `;
    } else if (item?.type === 'course_invitation_host') {
      text = 'đã mời bạn làm giáo viên của khoá học';
    } else if (item?.type === 'project_invitation_host') {
      text = (
        <span>
          {` đã mời bạn làm người đồng tổ chức dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'project_invitation') {
      text = (
        <span>
          {` đã mời bạn quan tâm dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'accept_project_invitation_host') {
      text = (
        <span>
          {` đã xác nhận làm đồng tổ chức cho dự án:  `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'accept_project_invitation') {
      text = (
        <span>
          {` đã xác nhận quan tâm dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'cancel_project') {
      text = (
        <span>
          {` đã hủy dự án: `}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.project?.title}
          </Typography>
        </span>
      );
    } else if (item?.type === 'friendship_request') {
      text = 'đã gửi cho bạn lời mời kết bạn.';
    } else if (item?.type === 'event_invitation') {
      text = 'đã gửi cho bạn lời mời tham gia sự kiện.';
    } else if (item?.type === 'event_invitation_host') {
      user = item?.event?.page
        ? item?.event?.page?.title
        : item?.account?.display_name;
      text = renderTextBold(
        'đã mời bạn đồng tổ chức sự kiện: ',
        item?.event?.title
      );
    } else if (item?.type === 'course_invitation') {
      text = renderTextBold('đã mời bạn quan tâm khóa học ');
    } else if (item?.type === 'rejected_course') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Khoá học của bạn đã bị từ chối
        </Typography>
      );
    } else if (item?.type === 'approved_course') {
      user = '';
      text = (
        <Typography
          className={classes.text}
          sx={{
            display: 'inline',
            color: !item?.read ? 'text.primary' : 'text.secondary'
          }}
        >
          Khoá học của bạn đã được phê duyệt
        </Typography>
      );
    } else if (item?.type === 'product_invitation') {
      text = renderTextBold('đã mời bạn quan tâm đến sản phẩm');
    } else if (item?.type === 'page_follow') {
      text = renderTextBold('đã thích trang ', item?.page?.title);
    } else if (item?.type === 'admin_page_invitation') {
      text = renderTextBold('đã mời bạn làm quản trị viên ', item?.page?.title);
    } else if (item?.type === 'moderator_page_invitation') {
      text = renderTextBold(
        'đã mời bạn làm kiểm duyệt viên ',
        item?.page?.title
      );
    } else if (item?.type === 'page_invitation_follow') {
      text = renderTextBold('đã mời bạn thích trang ', item?.page?.title);
    } else if (item?.type === 'group_invitation') {
      text = renderTextBold('đã mời bạn tham gia nhóm ', item?.group?.title);
    } else if (item?.type === 'group_invitation_host') {
      text = renderTextBold(
        'đã mời bạn làm quản trị viên của nhóm ',
        item?.group?.title
      );
    } else if (item?.type === 'moderator_group_invitation_host') {
      text = renderTextBold(
        'đã mời bạn làm người kiểm duyệt của nhóm ',
        item?.group?.title
      );
    } else if (item?.type === 'accept_moderator_group_invitation_host') {
      text = renderTextBold(
        'đã đồng làm người kiểm duyệt của nhóm ',
        item?.event?.title
      );
    } else if (item?.type === 'accept_event_invitation') {
      text = renderTextBold(
        'đã trả lời là Sẽ tham gia sự kiện ',
        item?.event?.title
      );
    } else if (item?.type === 'accept_event_invitation_host') {
      text = renderTextBold(
        'đã đồng ý đồng tổ chức sự kiện ',
        item?.event?.title
      );
    } else if (item?.type === 'accept_follow_event_invitation') {
      text = renderTextBold(
        'đã trả lời là Quan tâm sự kiện ',
        item?.event.title
      );
    } else if (item?.type === 'recruit_invitation') {
      text = renderTextBold(
        'đã mời bạn tham gia tuyển dụng ',
        item?.recruit?.title
      );
    } else if (item?.type === 'recruit_apply') {
      text = renderTextBold('đã nộp CV tuyển dụng ', item?.recruit?.title);
    } else if (item?.type === 'accept_admin_page_invitation') {
      text = renderTextBold(
        'đã đồng ý làm quản trị viên trang ',
        item?.page?.title
      );
    } else if (item?.type === 'accept_moderator_page_invitation') {
      text = renderTextBold(
        'đã đồng ý làm kiểm duyệt viên trang ',
        item?.page?.title
      );
    } else if (item?.type === 'accept_page_invitation_follow') {
      text = renderTextBold(
        'đã chấp nhận lời mời thích trang ',
        item?.page?.title
      );
    } else if (item?.type === 'approved_page_identity_verification') {
      user = `Trang ${item?.page_identity_verification?.page?.title}`;
      text = ` của bạn đã được xác minh thành công.`;
    } else if (item?.type === 'approved_report_violation') {
      user = '';
      text = item.report_violation?.group
        ? 'Nhóm của bạn đã bị khóa bởi vi phạm chính sách cộng đồng EMSO.'
        : 'Trang của bạn đã bị khóa bởi vi phạm chính sách cộng đồng EMSO.';
    } else if (item?.type === 'approved_report_course') {
      user = '';
      text = 'Khoá học của bạn đã bị khoá.';
    } else if (item?.type === 'approved_change_title') {
      user = '';
      text = `Yêu cầu đổi tên trang của bạn đã được phê duyệt`;
    } else if (item?.type === 'rejected_change_title') {
      user = '';
      text = `Yêu cầu đổi tên trang của bạn đã bị từ chối`;
    } else if (item?.type === 'rejected_page_identity_verification') {
      user = `Trang ${item?.page_identity_verification?.page?.title}`;
      text = ` của bạn xác minh thất bại.`;
    } else if (item?.type === 'approved_identity_verification') {
      user = '';
      text = 'Tài khoản của bạn xác minh thành công.';
    } else if (item?.type === 'rejected_identity_verification') {
      user = '';
      text = 'Tài khoản bị từ chối xác minh.Vui lòng thử lại sau!';
    } else if (item?.type === 'rejected_earn_money' && item?.earn_money?.page) {
      user = `Trang ${item?.earn_money?.page?.title}`;
      text = ' của bạn bật kiếm tiền thất bại.Vui lòng thử lại sau!';
    } else if (item?.type === 'approved_earn_money' && item?.earn_money?.page) {
      user = `Trang ${item?.earn_money?.page?.title}`;
      text = ' của bạn bật kiếm tiền thành công.';
    } else if (item?.type === 'approved_earn_money') {
      user = '';
      text = 'Tài khoản của bạn bật kiếm tiền thành công.';
    } else if (item?.type === 'rejected_earn_money') {
      user = '';
      text = 'Tài khoản của bạn bật kiếm tiền thất bại.Vui lòng thử lại sau!';
    } else if (item?.type === 'accept_group_invitation') {
      text = renderTextBold(
        'đã chấp nhận lời mời tham gia nhóm',
        item?.group?.title
      );
    } else if (item?.type === 'accept_friendship_request') {
      text = 'đã chấp nhận lời mời kết bạn.';
    } else if (item?.type === 'group_join_request') {
      text = renderTextBold('đã yêu cầu tham gia ', item?.group?.title);
    } else if (item?.type === 'approved_group_join_request') {
      user = '';
      text = (
        <span>
          Chào mừng bạn đến với{' '}
          <span
            className={classes.text}
            style={{
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.group?.title}
          </span>
          <span>. Quản trị viên đã phê duyệt yêu cầu tham gia của bạn.</span>
        </span>
      );
    } else if (item?.type === 'created_status') {
      text = renderTextBold(
        'Video của bạn đã sẵn sàng.Bây giờ bạn có thể mở xem.'
      );
      user = '';
    } else if (item?.type === 'status_tag') {
      user = '';
      if (item?.status?.in_reply_to_parent_id || item?.status?.in_reply_to_id) {
        user = item?.account?.display_name;
        text = (
          <span>
            &nbsp;đã nhắc đến bạn ở một bình luận trong&nbsp;
            <Typography
              className={classes.text}
              sx={{
                display: 'inline',
                color: !item?.read ? 'text.primary' : 'text.secondary'
              }}
            >
              {item?.status?.group?.title}
            </Typography>
          </span>
        );
      } else {
        user = item?.account?.display_name;
        text = renderTextBold(
          ` đã nhắc đến bạn ở một bài viết trong ${
            item?.status?.page ? 'trang' : item?.status?.group ? 'nhóm ' : ''
          }`,
          renderDisplayNameObj(item)
        );
      }
    } else if (item?.type === 'family_member') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;đã liệt kê bạn là&nbsp;
          {item?.family_member?.family_relationship_category?.name}.
        </span>
      );
    } else if (item?.type === 'rejected_family_member') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;đã từ chối mối quan hệ&nbsp;
          {item?.family_member?.family_relationship_category?.name}
          &nbsp;của bạn.
        </span>
      );
    } else if (item?.type === 'approved_family_member') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;đã đồng ý mối quan hệ là&nbsp;
          {item?.family_member?.family_relationship_category?.name}
          &nbsp;của bạn.
        </span>
      );
    } else if (item?.type === 'account_relationship') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;đã liệt kê bạn là&nbsp;
          {item?.account_relationship?.relationship_category?.name}.
        </span>
      );
    } else if (item?.type === 'approved_group_status') {
      user = '';
      if (item?.group || item?.page) {
        text = (
          <>
            Bài viết của bạn tại {item?.group ? 'nhóm' : 'trang'}:
            <Typography
              className={classes.text}
              sx={{
                display: 'inline',
                color: !item?.read ? 'text.primary' : 'text.secondary'
              }}
            >
              {' '}
              {item?.group?.title ?? item?.page?.title}
            </Typography>{' '}
            đã được quản trị viên phê duyệt
          </>
        );
      }
    } else if (item?.type === 'group_status_alert') {
      user = '';
      text = (
        <span>
          Bình luận của&nbsp;
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;trong bài viết nhóm&nbsp;
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.group.title}
          </Typography>
          &nbsp;đã vi phạm từ ngữ kiểm duyệt.&nbsp;
        </span>
      );
    } else if (item?.type === 'page_status_alert') {
      user = '';
      text = (
        <span>
          Bình luận của&nbsp;
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>
          &nbsp;trong bài viết trang&nbsp;
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.page_owner.title}
          </Typography>
          &nbsp;đã vi phạm từ ngữ kiểm duyệt.&nbsp;
        </span>
      );
    } else if (item?.type === 'comment') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.status?.page_owner
              ? item?.status?.page_owner?.title
              : item.account.display_name}
          </Typography>{' '}
          {item?.status?.event
            ? 'đã bình luận bài viết của bạn trong '
            : 'đã bình luận bài viết '}
          {item?.status?.group
            ? 'trong nhóm: '
            : item?.status?.group
            ? 'trong trang: '
            : ''}
          <Typography
            className={classes.text}
            sx={{
              display: 'contents',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.status?.group?.title ||
              item?.status?.page?.title ||
              item?.status?.event?.title}
          </Typography>
          {item?.status?.event
            ? null
            : `với nội dung: ${convertText(
                item?.status?.content,
                item?.status?.status_tags
              )}`}
        </span>
      );
    } else if (item?.type === 'group_pending_status') {
      user = '';
      text = (
        <span>
          Bài viết của{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          trong{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.status?.group?.title}
          </Typography>{' '}
          cần được phê duyệt:{' '}
          {convertText(item?.status?.content, item?.status?.status_tags)}
        </span>
      );
    } else if (item.type === 'rejected_group_status') {
      user = '';
      text = (
        <span>
          Bài viết của bạn trong nhóm{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.group.title}
          </Typography>{' '}
          đã bị quản trị viên từ chối, vui lòng đăng những nội dung phù hợp hơn.
        </span>
      );
    } else if (item?.type === 'group_reported_status') {
      user = '';
      text = (
        <span>
          <span style={{ fontWeight: '500' }}>
            {item.account.display_name + ' '}
          </span>
          <span
            style={{
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            <span className={classes.textContentNoti}>
              đã báo cáo vi phạm bài viết trong{' '}
            </span>
            <span style={{ fontWeight: '500' }}>
              {item?.status?.group?.title}
            </span>
          </span>
        </span>
      );
    } else if (item?.type === 'accept_group_invitation_host') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          đã đồng ý làm quản trị viên của nhóm{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.group?.title}
          </Typography>{' '}
        </span>
      );
    } else if (item.type === 'accept_moderator_group_invitation_host') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item.account.display_name}
          </Typography>{' '}
          đã đồng ý làm người kiểm duyệt của nhóm{' '}
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.group?.title}
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'approved_identity_verification') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            Tài khoản của bạn xác minh thành công.
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'rejected_identity_verification') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            Tài khoản của bạn bị từ chối xác minh. Vui lòng thử lại sau!
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'response_account_request') {
      user = '';
      const textContent =
        item.account_request_certification.status === 'rejected'
          ? 'Yêu cầu xác minh huy hiệu của bạn đã bị từ chối.'
          : 'Chúc mừng tài khoản của bạn đã được EMSO phê duyệt xác minh huy hiệu';

      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {textContent}
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'order') {
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item.read ? 'text.primary' : 'text.secondary'
            }}
          >
            đã tạo một đơn hàng mới
          </Typography>{' '}
        </span>
      );
    } else if (item?.type === 'approved_music') {
      user = '';
      text = 'Bài hát của bạn đã được phê duyệt';
    } else if (item?.type === 'rejected_music') {
      user = '';
      text = 'Bài hát của bạn đã bị từ chối';
    } else if (item?.type === 'approved_playlist') {
      user = '';
      text = 'Album của bạn đã được phê duyệt';
    } else if (item?.type === 'rejected_playlist') {
      user = '';
      text = 'Album của bạn đã bị từ chối';
    } else if (item?.type === 'friend_birthday') {
      user = '';
      text = (
        <Typography component={'span'}>
          Hôm nay là sinh nhật{' '}
          <Typography fontWeight={500} component={'span'}>
            {item?.account?.display_name}
          </Typography>
          . Hãy cho họ biết bạn đang nghĩ về họ!
        </Typography>
      );
    } else if (item?.type === 'update_delivery_address') {
      user = '';
      text = (
        <Typography>
          Tài khoản của bạn đã được cập nhật liên kết với địa chỉ và số điện
          thoại mới. Nếu bạn không thực hiện thay đổi này, vui lòng liên hệ bộ
          phận hỗ trợ của EMSO 123456789;
        </Typography>
      );
    } else if (item?.type === 'accept_music_invitation_author') {
      text = (
        <Typography
          component={'span'}
          sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
        >
          {`đã đồng ý làm tác giả bài hát: ${item?.music?.name}`}
        </Typography>
      );
    } else if (item?.type === 'verification_blood_donation') {
      user = '';
      text = (
        <Typography component={'span'}>
          Trang{' '}
          <Typography fontWeight={500} component={'span'}>
            {item?.page_blood_donation?.page?.title}
          </Typography>
          {` của bạn đã xác minh trang hiến máu ${
            item?.page_blood_donation?.status === 'approved'
              ? 'thành công'
              : 'thất bại'
          }`}
        </Typography>
      );
    } else if (item?.type === 'accept_recruit_invitation') {
      text = (
        <Typography
          component={'span'}
          sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
        >
          {`đã đồng ý quan tâm tin tuyển dụng ${item?.recruit?.title}`}
        </Typography>
      );
    } else if (item?.type === 'accept_product_invitation') {
      text = (
        <Typography
          component={'span'}
          sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
        >
          {`đã đồng ý quan tâm sản phẩm`}
        </Typography>
      );
    } else if (item?.type === 'approved_merchant_payment_request') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            Xử lý yêu cầu thanh toán thành công
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            {`Xử lý yêu cầu thanh toán ${
              item?.activity_id
            } thành công. số tiền ${handlePrice(
              item?.data_template?.amount
            )} đã được chuyển về tài khoản ngân hàng của bạn.`}
          </Typography>
        </Typography>
      );
    } else if (item?.transfer?.source_transaction === 'donate_music') {
      user = '';
      text = (
        <span>
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.account?.display_name}
          </Typography>
          &nbsp;đã donate cho bài hát:&nbsp;
          <Typography
            className={classes.text}
            sx={{
              display: 'inline',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {item?.transfer?.music?.name}
          </Typography>
        </span>
      );
    }

    //For market
    //!page_owner dành cho phía người mua. Thông báo đó page cũng sẽ nhận được nhưng sẽ có filed page_owner

    if (item?.type === 'update_order_status') {
      if (item?.data_template?.order_status === 'pending') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Đơn hàng mới
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`${item?.account.display_name} đã đặt một đơn hàng ${item?.data_template?.shipping_method_id} ${item?.activity_id}. Vui lòng không giao hàng cho đến khi Mã vận đơn được cập nhật trong chi tiết đơn hàng.`}
            </Typography>
          </Typography>
        );
      }
      if (item?.data_template?.order_status === 'order_confirmed') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              {`Đã xác nhận đơn hàng thanh toán ${item?.data_template?.shipping_method_id}`}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Đơn hàng ${item?.activity_id} đã được người bán xác nhận. Vui lòng kiểm tra thời gian nhận hàng dự kiến trong Chi tiết đơn hàng và tin nhắn (nếu có) từ người bán tại EMSO Chat - Kênh liên hệ duy nhất dành cho người bán nhé.`}
            </Typography>
          </Typography>
        );
      }
      if (item?.data_template?.order_child_status === 'buyer_cancelled') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Đơn hàng đã bị huỷ bởi người mua
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Đơn hàng ${item?.activity_id} đã được hủy bởi Người mua ${item?.account.display_name}. Vui lòng không gửi hàng cho đơn vị vận chuyển.`}
            </Typography>
          </Typography>
        );
      }
      if (item?.data_template?.order_child_status === 'shop_cancelled') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Đơn hàng đã bị huỷ bởi người bán
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Đơn hàng ${item?.activity_id} đã được hủy bởi Người bán ${item?.data_template?.page_title}.`}
            </Typography>
          </Typography>
        );
      }
      if (item?.data_template?.order_child_status === 'emso_cancelled') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Đơn hàng đã bị huỷ bởi EMSO
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Rất tiếc, đơn hàng ${item?.activity_id} đã bị huỷ do người bán ${item?.data_template?.page_title} không gửi hàng đúng hẹn. Mong bạn thông cảm và đặt lại đơn khác`}
            </Typography>
          </Typography>
        );
      }
      if (item?.data_template?.order_status === 'shipping') {
        if (!item?.data_template?.order_child_status) {
          user = '';
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Bạn có đơn hàng đang trên đường giao
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Đơn hàng ${item?.activity_id} đang trên đường giao tới bạn và dự kiến được giao trong 1-2 ngày tới. Vui lòng bỏ qua thông báo này nếu bạn đã nhận được hàng nhé.`}
              </Typography>
            </Typography>
          );
        }
        if (
          item?.data_template?.order_child_status === 'waiting_to_return' &&
          !item?.page_owner
        ) {
          user = '';
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Bạn đã bỏ lỡ một đơn hàng
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Đơn hàng ${item?.activity_id} đã không được nhận`}
              </Typography>
            </Typography>
          );
        }
        if (
          item?.data_template?.order_child_status === 'waiting_to_return' &&
          item?.page_owner
        ) {
          user = '';
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Giao hàng thất bại
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Đơn hàng ${item?.activity_id} giao thất bại do người mua không nhận hàng. Đơn vị vận chuyển đang tiến hành quy trình giao lại.`}
              </Typography>
            </Typography>
          );
        }
      }
      if (item?.data_template?.order_status === 'delivery_failed') {
        if (
          item?.data_template?.order_child_status === 'returning' &&
          !item?.page_owner
        ) {
          user = '';
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Giao hàng thất bại
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Đơn hàng ${item?.activity_id} giao thất bại do người mua không nhận hàng. Đơn hàng của bạn sẽ bị huỷ và trả hàng cho người bán.`}
              </Typography>
            </Typography>
          );
        }
        if (
          item?.data_template?.order_child_status === 'returning' &&
          item?.page_owner
        ) {
          user = '';
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Giao hàng thất bại
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Đơn hàng ${item?.activity_id} giao thất bại người mua không nhận hàng. Đơn vị vận chuyển đang tiến hành quy trình trả hàng cho người bán.`}
              </Typography>
            </Typography>
          );
        }
        if (
          item?.data_template?.order_child_status === 'lost' &&
          !item?.page_owner
        ) {
          user = '';
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Giao hàng thất bại
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {item?.data_template?.shipping_method_id === 'COD'
                  ? `Đơn hàng ${item?.activity_id} giao thất bại lần do đơn vị vận chuyển làm thất lạc sản phẩm. Người bán sẽ sớm liên hệ lại với bạn để gửi lại sản phẩm, xin lỗi bạn vì sự bất tiện này`
                  : `Đơn hàng ${item?.activity_id} giao thất bại lần do đơn vị vận chuyển làm thất lạc sản phẩm. Số tiền thanh toán bồi thường đang được hoàn lại vào tài khoản của bạn`}
              </Typography>
            </Typography>
          );
        }
        if (
          item?.data_template?.order_child_status === 'lost' &&
          item?.page_owner
        ) {
          user = '';
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Giao hàng thất bại
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Đơn hàng ${item?.activity_id} giao thất bại lần do đơn vị vận chuyển làm thất lạc sản phẩm. Số tiền thanh toán bồi thường đang được hoàn lại vào tài khoản của bạn.`}
              </Typography>
            </Typography>
          );
        }
      }
      if (item?.data_template?.order_status === 'finish') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Đã giao hàng thành công
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Đơn hàng ${item?.activity_id} đã được giao thành công cho người mua. Số tiền thanh toán đã được chuyển vào tài khoản EMSO của bạn.`}
            </Typography>
          </Typography>
        );
      }
    }
    if (item?.type === 'status_refund_request') {
      if (
        item?.data_template?.refund_request_status === 'create_refund_request'
      ) {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Yêu cầu trả hàng hoàn tiền mới
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Bạn có 1 yêu cầu trả hàng hoàn tiền mới ${item?.activity_id}`}
            </Typography>
          </Typography>
        );
      }
      if (
        item?.data_template?.refund_request_status ===
        'new_comment_refund_request'
      ) {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Thông tin trao đổi của người mua đã được cập nhật
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Người mua của bạn đã cập nhật phản hồi tại đơn trả hàng ${item?.activity_id}, vui lòng chọn xem chi tiết để xem thông tin.`}
            </Typography>
          </Typography>
        );
      }
      if (
        item?.data_template?.refund_request_status === 'emso_approved_refund'
      ) {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Yêu cầu từ chối trả hàng hoàn tiền của bạn đã được EMSO xét duyệt.
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              Yêu cầu từ chối trả hàng hoàn tiền của bạn đã được EMSO xét duyệt.
              Số dư từ đơn hàng của bạn sẽ được giữ nguyên.
            </Typography>
          </Typography>
        );
      }
      if (
        item?.data_template?.refund_request_status === 'emso_rejected_refund'
      ) {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Yêu cầu từ chối trả hàng hoàn tiền của bạn bị từ chối
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Yêu cầu từ chối trả hàng hoàn tiền của bạn đã bị EMSO từ chối với lý do: ${item?.data_template?.note_admin}`}
            </Typography>
          </Typography>
        );
      }
    }
    if (item?.type === 'new_comment_refund_request') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            Thông tin trao đổi của người bán đã được cập nhật
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            {`Người bán đã cập nhật phản hồi tại đơn trả hàng ${item?.activity_id}, vui lòng chọn xem chi tiết để xem thông tin.`}
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'request_return_product') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            Yêu cầu trả hàng hoàn tiền được chấp nhận, vui lòng trả hàng cho
            người bán
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            Yêu cầu trả hàng hoàn tiền đã được người bán đồng ý. Vui lòng trả
            hàng cho người bán tối đa trong 4 ngày kể từ thời điểm người bán
            chấp nhận yêu cầu của bạn.
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'accepted_refund') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            Đã hoàn tiền thành công
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            Yêu cầu trả hàng hoàn tiền đã được người bán đồng ý. Tiền bồi thường
            đã được hoàn vào tài khoản thanh toán của bạn, vui lòng kiểm tra lại
            tài khoản.
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'emso_rejected_refund') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            Khiếu nại trả hàng hoàn tiền của bạn bị từ chối
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            {`Khiếu nại trả hàng hoàn tiền của bạn đã bị EMSO từ chối với lý do: ${item?.data_template?.note_admin}. Số tiền của bạn sẽ không được hoàn lại.`}
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'emso_approved_refund') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            Khiếu nại trả hàng hoàn tiền của bạn đã được EMSO xét duyệt.
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            Yêu cầu trả hàng hoàn tiền đã được EMSO đồng ý. Tiền bồi thường đã
            được hoàn vào tài khoản của bạn, vui lòng kiểm tra lại tài khoản.
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'notification_template') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            {`${item?.data_template?.title}`}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            {`${item?.data_template?.description}`}
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'new_campaign') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
          >
            Chương trình khuyến mãi mới đang diễn ra, cùng tham gia với EMSO
            ngay nào.
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            Chương trình khuyến mãi đang trong thời gian diễn ra, cùng đăng ký
            với EMSO ngay để quảng bá sản phẩm và tăng cao doanh thu hơn cho cửa
            hàng của bạn nào.
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'approved_custom_brand') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            Thương hiệu của bạn đã được phê duyệt thành công
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'rejected_custom_brand') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            Thương hiệu của bạn đã được phê duyệt thất bại
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'new_rating') {
      user = '';
      text = (
        <Typography>
          <Typography
            sx={{
              color: !item?.read ? 'text.primary' : 'text.secondary',
              wordBreak: 'break-word'
            }}
          >
            Bạn nhận được 1 đánh giá mới, vui lòng click vào thông báo để xem
            chi tiết
          </Typography>
        </Typography>
      );
    }
    if (item?.type === 'history_finance') {
      if (item?.data_template?.reason === 'completed_order') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Bạn có biến động số dư mới
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Số dư tài khoản EMSO + ${handlePrice(
                item?.data_template?.total
              )} từ thanh toán COD/chuyển khoản đơn hàng ${item?.activity_id}`}
            </Typography>
          </Typography>
        );
      }

      if (item?.data_template?.reason === 'refund') {
        user = '';
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Bạn có biến động số dư mới
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`Số dư tài khoản EMSO + ${handlePrice(
                item?.data_template?.total
              )} từ bồi thường đơn hàng thất lạc ${item?.activity_id}`}
            </Typography>
          </Typography>
        );
      }
    }
    if (item?.type === 'product_status') {
      user = '';
      // nếu data_template có product name thì là thông báo hết hàng
      if (item?.data_template?.product_name) {
        text = (
          <Typography>
            <Typography
              sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
            >
              Sản phẩm đã hết hàng
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: !item?.read ? 'text.primary' : 'text.secondary',
                wordBreak: 'break-word'
              }}
            >
              {`${item?.data_template?.product_name} đã được bán hết. Nhấn để chỉnh sửa số lượng sản phẩm trong kho`}
            </Typography>
          </Typography>
        );
      } else {
        // Chỉ có phê duyệt sản phẩm là không có lý do
        if (item?.data_template?.product_status === 'approved') {
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Sản phẩm đã được duyệt
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                Sản phẩm của bạn đã được EMSO duyệt, từ giờ bạn có thể bán sản
                phẩm trên gian hàng của mình
              </Typography>
            </Typography>
          );
        }
        if (item?.data_template?.product_status === 'rejected') {
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Sản phẩm đã bị từ chối
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Sản phẩm của bạn đã bị từ chối phê duyệt vì lý do: ${item?.data_template?.note_admin}`}
              </Typography>
            </Typography>
          );
        }
        if (item?.data_template?.product_status === 'warning') {
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Sản phẩm của bạn đã bị hạn chế hiển thị
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Sản phẩm của bạn đã bị hạn chế hiển thị vì lý do sau: ${item?.data_template?.note_admin}`}
              </Typography>
            </Typography>
          );
        }
        if (item?.data_template?.product_status === 'violated') {
          text = (
            <Typography>
              <Typography
                sx={{ color: !item?.read ? 'text.primary' : 'text.secondary' }}
              >
                Sản phẩm đã bị khoá
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  color: !item?.read ? 'text.primary' : 'text.secondary',
                  wordBreak: 'break-word'
                }}
              >
                {`Sản phẩm của bạn đã bị khoá vì lý do sau: ${item?.data_template?.note_admin}`}
              </Typography>
            </Typography>
          );
        }
      }
    }

    return (
      <span
        className={match.path === PATHS.NOTI ? undefined : classes.lineClamp}
      >
        {user && (
          <Typography
            className={classes.text}
            sx={{
              display: 'inline-block',
              color: !item?.read ? 'text.primary' : 'text.secondary'
            }}
          >
            {user}
          </Typography>
        )}
        <span style={{ fontSize: 14 }}> {text}</span>
      </span>
    );
  };
  const renderIdOrNameObj = (item: any) => {
    if (item?.page_owner) return item.page_owner.username ?? item.page_owner.id;
    else if (item?.page) return item.page.username ?? item.page.id;
    else if (item?.group) return item.group.username ?? item.group.id;
    else return item?.account.username ?? item?.account.id;
  };

  const renderLink = item => {
    if (!item) return '/';
    if (
      ['favourite', 'status_tag', 'created_status', 'reblog'].includes(
        item?.type
      ) ||
      (item?.type === 'status' &&
        !item?.status?.group &&
        !item?.status?.page &&
        !item?.status?.event &&
        !item?.status?.course)
    ) {
      if (item?.status?.course_id) {
        return `/course/${item?.status?.course_id}/discussion?post=${item?.status?.id}`;
      } else if (item?.status?.group?.id) {
        return `/group/${item.status.group.id}/post/${
          item?.status?.in_reply_to_id || item?.status?.id
        }`;
      } else if (item?.favourite) {
        if (
          item?.favourite?.status?.in_reply_to_parent_id ||
          item?.favourite?.status?.in_reply_to_id
        ) {
          return `/posts/${
            item?.favourite?.status?.in_reply_to_parent_id
              ? item?.favourite?.status?.in_reply_to_parent_id
              : item?.favourite?.status?.in_reply_to_id
              ? item?.favourite?.status?.in_reply_to_id
              : item?.favourite?.status?.id
          }`;
        } else if (item?.favourite?.status?.group?.id) {
          return `/group/${item.favourite.status.group.id}/post/${item?.favourite.status?.id}`;
        } else {
          return `/${renderIdOrNameObj(item?.favourite?.status)}/posts/${
            item?.favourite?.status?.in_reply_to_parent_id
              ? item?.favourite?.status?.in_reply_to_parent_id
              : item?.favourite?.status?.in_reply_to_id
              ? item?.favourite?.status?.in_reply_to_id
              : item?.favourite?.status?.id
          }`;
        }
      } else if (
        item?.type === 'status_tag' &&
        !(item?.status?.in_reply_to_parent_id || item?.status?.in_reply_to_id)
      ) {
        return '/diary/tags';
      } else if (
        item?.type === 'status_tag' &&
        (item?.status?.in_reply_to_parent_id || item?.status?.in_reply_to_id)
      ) {
        return `/posts/${
          item?.status?.in_reply_to_parent_id
            ? item?.status?.in_reply_to_parent_id
            : item?.status?.in_reply_to_id
            ? item?.status?.in_reply_to_id
            : item?.status?.id
        }?commentId=${item?.status.id}`;
      } else {
        return `/${renderIdOrNameObj(item?.status)}/posts/${
          item?.status?.in_reply_to_parent_id
            ? item?.status?.in_reply_to_parent_id
            : item?.status?.in_reply_to_id
            ? item?.status?.in_reply_to_id
            : item?.status?.id
        }`;
      }
    } else if (
      ['rejected_change_title', 'approved_change_title'].includes(item?.type)
    ) {
      return `/marketplace/shop/edit_page_info?page_id=${item?.request_change_title_page?.page?.id}`;
    } else if (
      item?.project &&
      [
        'approved_project',
        'project_donate',
        'project_invitation_host',
        'project_invitation',
        'accept_project_invitation',
        'accept_project_invitation_host'
      ].includes(item?.type)
    ) {
      return `/grow/${item.project.id}/about`;
    } else if (item?.type === 'cancel_project') {
      return '/grows/home';
    } else if (item?.type === 'music_invitation_author') {
      return `/music_space/invites`;
    } else if (
      item?.status?.event &&
      (item?.type === 'status' || item?.type === 'event')
    ) {
      return `/event/${item.status.event?.id}/discussion?post=${item?.status?.id}`;
    } else if (
      item?.status?.project &&
      (item?.type === 'status' || item?.type === 'project')
    ) {
      return `/grow/${item.status.project?.id}/discussion?post=${item?.status?.id}`;
    } else if (item?.status?.group && item?.type === 'status') {
      return `/group/${
        item?.status?.group?.username ?? item?.status?.group?.id
      }/post/${item?.status?.id}`;
    } else if (item?.status?.page && item?.type === 'status') {
      return `/${
        item?.status?.page?.username ?? item?.status?.page?.id
      }/posts/${item?.status?.id}`;
    } else if (item?.status?.event && item?.type === 'status') {
      return `/${
        item?.status?.event?.username ?? item?.status?.event?.id
      }/posts/${item?.status?.id}`;
    } else if (item?.type === 'friendship_request') {
      return `/friends/requests/${item?.account?.id}`;
    } else if (
      [
        'course_invitation',
        'rejected_course',
        'approved_course',
        'approved_report_course',
        'course_invitation_host',
        'accept_course_invitation',
        'accept_course_invitation_host'
      ].includes(item?.type)
    ) {
      return `/course/${item?.activity_id}/about`;
    } else if (item?.type === 'product_invitation') {
      return `/products/invites?id=${item?.activity_id}`;
    } else if (item?.type === 'mention') {
      if (accountSettings?.review_tag_on_profile) {
        return `/diary/tags`;
      } else if (item?.status?.group?.id) {
        return `/group/${item.status.group.id}/post/${item?.status?.id}`;
      } else {
        return `/${
          item?.status?.account?.username ?? item?.status?.account?.id
        }/posts/${item?.status?.id}`;
      }
    } else if (
      [
        'event_invitation',
        'event_invitation_host',
        'accept_event_invitation',
        'accept_event_invitation_host',
        'accept_follow_event_invitation'
      ].includes(item?.type)
    ) {
      return `/event/${item?.event?.id}/discussion`;
    } else if (
      [
        'page_follow',
        'accept_admin_page_invitation',
        'accept_moderator_page_invitation',
        'accept_page_invitation_follow'
      ].includes(item?.type)
    ) {
      return `/page/${item?.page?.username ?? item?.page?.id}`;
    } else if (item?.type === 'approved_report_violation') {
      return item.report_violation?.group
        ? `/group/${item?.report_violation?.group?.id}`
        : `/page/${item?.report_violation?.page?.id}/about`;
    } else if (
      [
        'rejected_project',
        'rejected_page_identity_verification',
        'rejected_identity_verification'
      ].includes(item?.type)
    ) {
      return `/grows/not/approve/${item.id}`;
    } else if (
      item?.type === 'response_account_request' ||
      item?.type === 'follow'
    ) {
      if (item?.account_request_certification?.status === 'rejected') {
        return `/grows/not/approve/${item.id}`;
      }
      return `/user/${meInfo.id}`;
    } else if (
      [
        'page_invitation_follow',
        'admin_page_invitation',
        'moderator_page_invitation'
      ].includes(item?.type)
    ) {
      return `/pages/invites`;
    } else if (
      [
        'group_invitation',
        'group_invitation_host',
        'accept_group_invitation',
        'approved_group_join_request',
        'rejected_group_status'
      ].includes(item?.type)
    ) {
      return `/group/${item?.group?.username ?? item?.group?.id}`;
    } else if (item?.type === 'group_join_request') {
      return `/group/${
        item?.group_join_request?.group?.username ??
        item?.group_join_request?.group?.id
      }/request_member`;
    } else if (
      [
        'recruit_invitation',
        'recruit_apply',
        'accept_recruit_invitation'
      ].includes(item?.type)
    ) {
      return `/recruit/${item?.recruit?.id}/about`;
    } else if (
      [
        'accept_friendship_request',
        'family_member',
        'rejected_family_member',
        'approved_family_member',
        'friend_birthday'
      ].includes(item?.type)
    ) {
      return `/user/${item?.account?.username ?? item?.account?.id}`;
    } else if (item?.type === 'group_status_alert' && item?.status?.group?.id) {
      return `/group/${item?.status?.group.id}/report_censorship`;
    } else if (item?.type === 'page_status_alert' && item?.status?.page.id) {
      return `/page/${item?.status?.page.id}/notifications/moderation_activity_log`;
    } else if (item?.type === 'comment') {
      return `/posts/${
        item?.status?.in_reply_to_parent_id ?? item?.status?.in_reply_to_id
      }`;
    } else if (item?.type === 'approved_group_status') {
      return `/group/${item?.group?.id}`;
    } else if (item?.status?.in_reply_to_id) {
      return `/posts/${
        item?.status?.in_reply_to_parent_id ?? item?.status?.in_reply_to_id
      }`;
    } else if (item?.type === 'group_pending_status') {
      return `/group/${item.status.group?.id}/waiting_post`;
    } else if (item?.type === 'group_reported_status') {
      return `/group/${item.status.group.id}/content_report`;
    } else if (item?.type === 'moderator_group_invitation_host') {
      return `/group/${item.group.id}`;
    } else if (
      [
        'approved_earn_money',
        'rejected_earn_money',
        'approved_identity_verification',
        'rejected_page_identity_verification',
        'approved_page_identity_verification'
      ].includes(item?.type)
    ) {
      if (
        [
          'rejected_page_identity_verification',
          'approved_page_identity_verification'
        ].includes(item?.type)
      ) {
        dispatch(
          updateNotifyVerifyPageName(
            item?.page_identity_verification.page.title
          )
        );
      }
      return '/settings/money';
    } else if (
      ['status_donate', 'music_donate', 'donate'].includes(item?.type)
    ) {
      return '/emso_payment/home';
    } else if (item?.type === 'accept_group_invitation_host') {
      return `/group/${item.group.id}`;
    } else if (item?.type === 'account_relationship') {
      return `/user/${
        item?.account_relationship?.partner?.username ??
        item?.account_relationship?.partner?.id
      }`;
    } else if (item?.type === 'order') {
      return `/marketplace/shop/product_order/${item?.order_id}`;
    } else if (
      item?.type === 'approved_music' ||
      item?.type === 'rejected_music' ||
      item?.type === 'accept_music_invitation_author'
    ) {
      return `/music_space/music/song`;
    } else if (
      item?.type === 'approved_playlist' ||
      item?.type === 'rejected_playlist'
    ) {
      return `/music_space/music/album`;
    } else if (item?.type === 'update_delivery_address') {
      return '/products/my_addresses';
    } else if (item?.type === 'verification_blood_donation') {
      return `/page/${item.page_blood_donation?.page?.id}`;
    } else if (item?.type === 'accept_product_invitation') {
      return `/product/${item?.activity_id}/about`;
    } else if (item?.type === 'approved_merchant_payment_request') {
      return `/emso_payment/payment_history?status=all`;
    } else if (
      ['approved_custom_brand', 'rejected_custom_brand'].includes(item?.type)
    ) {
      return `/marketplace/shop/brand?page_id=${item?.page_owner?.id}`;
    } else if (item?.type === 'new_rating') {
      return `/marketplace/shop/shop_reviews?page_id=${item?.page_owner?.id}&tab=all_reviews&rating_point=all_rating_point`;
    }

    //for notification market
    //!page_owner dành cho phía người mua. Thông báo đó page cũng sẽ nhận được nhưng sẽ có filed page_owner

    // for clear code
    // const type = item?.type
    // const order_status = item?.data_template?.order_status
    // const order_child_status = item?.data_template?.order_child_status

    if (item?.type === 'update_order_status') {
      if (item?.data_template?.order_status === 'pending') {
        return `/marketplace/shop/product_order/${item?.activity_id}?page_id=${item?.page_owner?.id}`;
      }
      if (item?.data_template?.order_status === 'order_confirmed') {
        return `/order/${item?.activity_id}`;
      }
      if (item?.data_template?.order_child_status === 'buyer_cancelled') {
        return `/marketplace/shop/product_order/${item?.activity_id}?page_id=${item?.page_owner?.id}`;
      }
      if (item?.data_template?.order_child_status === 'shop_cancelled') {
        return `/order/${item?.activity_id}`;
      }
      if (item?.data_template?.order_child_status === 'emso_cancelled') {
        if (item?.page_owner)
          return `/marketplace/shop/product_order/${item?.activity_id}?page_id=${item?.page_owner?.id}`;
        else return `/order/${item?.activity_id}`;
      }
      if (item?.data_template?.order_status === 'shipping') {
        if (item?.page_owner)
          return `/marketplace/shop/product_order/${item?.activity_id}?page_id=${item?.page_owner?.id}`;
        else return `/order/${item?.activity_id}`;
      }
      if (item?.data_template?.order_status === 'delivery_failed') {
        if (item?.page_owner)
          return `/marketplace/shop/product_order/${item?.activity_id}?page_id=${item?.page_owner?.id}`;
        else return `/order/${item?.activity_id}`;
      }
      if (item?.data_template?.order_status === 'finish') {
        return `/marketplace/shop/product_order/${item?.activity_id}?page_id=${item?.page_owner?.id}`;
      }
    }
    if (item?.type === 'status_refund_request') {
      // for seller
      if (
        item?.data_template?.refund_request_status === 'create_refund_request'
      )
        return `/products/return_for_refund/${item?.activity_id}`;
      if (
        item?.data_template?.refund_request_status ===
        'new_comment_refund_request'
      )
        return `/products/return_for_refund/${item?.activity_id}`;
      if (item?.data_template?.refund_request_status === 'emso_approved_refund')
        return `/products/return_for_refund/${item?.activity_id}`;
      if (item?.data_template?.refund_request_status === 'emso_rejected_refund')
        return `/products/return_for_refund/${item?.activity_id}`;
    }
    if (item?.type === 'new_comment_refund_request') {
      return `/products/return_for_refund/${item?.activity_id}`;
    }
    if (item?.type === 'request_return_product') {
      return `/products/return_for_refund/${item?.activity_id}`;
    }
    if (item?.type === 'accepted_refund') {
      return `/products/return_for_refund/${item?.activity_id}`;
    }
    if (item?.type === 'emso_rejected_refund') {
      return `/products/return_for_refund/${item?.activity_id}`;
    }
    if (item?.type === 'emso_approved_refund') {
      return `/products/return_for_refund/${item?.activity_id}`;
    }
    if (item?.type === 'poll') {
      if (item?.status?.group?.id)
        return `/group/${item?.status?.group?.id}?post=${item?.status?.id}`;
      else return '/';
    }
    if (item?.type === 'history_finance') {
      if (item?.data_template?.reason === 'completed_order') {
        return `/marketplace/shop/product_order/${item?.activity_id}?page_id=${item?.page_owner?.id}`;
      }

      if (item?.data_template?.reason === 'refund') {
        return `/marketplace/shop/product_order/${item?.order_id}`;
      }
    }
    if (item?.type === 'product_status') {
      if (item?.data_template?.product_name) {
        return `/marketplace/shop/edit_product?page_id=${item?.page_owner?.id}&id_product=${item?.activity_id}`;
      } else {
        if (item?.data_template?.product_status === 'approved') {
          return `/marketplace/shop/product_hosting?page_id=${item?.page_owner?.id}&page=1&type=is_active`;
        }
        if (item?.data_template?.product_status === 'rejected') {
          return `/marketplace/shop/product_hosting?page_id=${item?.page_owner?.id}&page=1&type=violet_product`;
        }
        if (item?.data_template?.product_status === 'warning') {
          return `/marketplace/shop/product_hosting?page_id=${item?.page_owner?.id}&page=1&type=violet_product`;
        }
        if (item?.data_template?.product_status === 'violated') {
          return `/marketplace/shop/product_hosting?page_id=${item?.page_owner?.id}&page=1&type=violet_product`;
        }
      }
    }

    return '/';
  };

  const isNotificationMarket = (item: any): boolean => {
    //!page_owner dành cho phía người mua. Thông báo đó page cũng sẽ nhận được nhưng sẽ có filed page_owner

    // for clear code
    // const type = item?.type
    // const order_status = item?.data_template?.order_status
    // const order_child_status = item?.data_template?.order_child_status

    if (item?.type === 'update_order_status') {
      if (item?.data_template?.order_status === 'order_confirmed') {
        return true;
      }
      if (item?.data_template?.order_child_status === 'buyer_cancelled') {
        return true;
      }
      if (item?.data_template?.order_child_status === 'shop_cancelled') {
        return true;
      }
      if (item?.data_template?.order_child_status === 'emso_cancelled') {
        return true;
      }
      if (item?.data_template?.order_status === 'shipping') {
        return true;
      }
      if (item?.data_template?.order_status === 'delivery_failed') {
        return true;
      }
      if (item?.data_template?.order_status === 'finish') {
        return true;
      }
    }
    if (item?.type === 'status_refund_request') {
      // for seller
      if (item?.data_template?.refund_request_status === 'emso_approved_refund')
        return true;
      if (item?.data_template?.refund_request_status === 'emso_rejected_refund')
        return true;
    }
    if (item?.type === 'new_comment_refund_request') {
      return true;
    }
    if (item?.type === 'request_return_product') {
      return true;
    }
    if (item?.type === 'accepted_refund') {
      return true;
    }
    if (item?.type === 'emso_rejected_refund') {
      return true;
    }
    if (item?.type === 'emso_approved_refund') {
      return true;
    }
    if (item?.type === 'history_finance') {
      return true;
    }
    if (item?.type === 'product_status') {
      return true;
    }

    return false;
  };

  const renderImage = item => {
    switch (item?.type) {
      case 'follow':
      case 'reblog':
      case 'favourite':
      case 'poll':
      case 'project':
      case 'project_invitation_host':
      case 'project_donate':
      case 'accept_project_invitation':
      case 'project_invitation':
      case 'approved_project':
      case 'rejected_project':
      case 'product_invitation':
      case 'rejected_course':
      case 'approved_course':
      case 'rejected_group_status':
        return { positionY: -461 };
      case 'status':
        if (item?.status?.group) return { positionY: -337 };
        else if (item?.status?.page) return { positionY: -420 };
        else if (item?.status?.post_type === 'event_shared')
          return { positionY: -127 };
        else if (item?.status?.post_type === 'watch')
          return { positionY: -502 };
        else if (item?.status?.post_type === 'moment')
          return { positionY: -251 };
        return { positionY: -291 };
      case 'friendship_request':
      case 'accept_friendship_request':
      case 'family_member':
      case 'mention':
      case 'account_relationship':
        return { positionY: -542 };
      case 'event_invitation':
      case 'event_invitation_host':
      case 'accept_event_invitation':
      case 'accept_event_invitation_host':
      case 'accept_follow_event_invitation':
      case 'event':
        return { positionY: -127 };
      case 'page_follow':
      case 'admin_page_invitation':
      case 'moderator_page_invitation':
      case 'page_invitation_follow':
      case 'accept_admin_page_invitation':
      case 'accept_moderator_page_invitation':
      case 'accept_page_invitation_follow':
      case 'page_status_alert':
        return { positionY: -420 };
      case 'group_invitation':
      case 'group_invitation_host':
      case 'group_join_request':
      case 'accept_group_invitation':
      case 'approved_group_join_request':
      case 'group_pending_status':
      case 'group_reported_status':
      case 'group_status_alert':
        return { positionY: -337 };
      case 'comment':
        return { positionY: -168 };
      case 'status_tag':
        return { positionY: -291 };
      case 'created_status':
        return { positionY: -502 };
      case 'music_invitation_author':
        return { positionY: -625 };
      case 'order':
        return { positionY: -587 };
      default:
        break;
    }
  };
  const renderAvatarObj = (data: any) => {
    if (data?.type === 'created_status') {
      return data.account.avatar_media
        ? data.account.avatar_media.preview_url
        : data.account.avatar_static;
    } else if (!data?.status?.page_owner) {
      return data?.account?.avatar_media?.preview_url;
    } else if (data?.status?.page_owner) {
      return data.status.page_owner?.avatar_media
        ? data.status.page_owner?.avatar_media?.preview_url
        : avatarDefault;
    } else if (data?.type === 'favourite' && data?.favourite?.page) {
      return data?.favourite?.page?.avatar_media?.preview_url ?? avatarDefault;
    } else if (data?.status?.page) {
      return data.status.page.avatar_media;
    } else if (data?.status?.group) {
      if (data?.type === 'group_pending_status') {
        return data.account.avatar_media;
      } else {
        return data.status.group.avatar_media;
      }
    } else if (data?.event?.page?.avatar_media) {
      return data.item.event.page.avatar_media;
    } else if (
      data?.group?.banner &&
      ['approved_group_status', 'rejected_group_status'].includes(data?.type)
    ) {
      return data?.group?.banner;
    } else if (
      [
        'approved_identity_verification',
        'rejected_identity_verification',
        'rejected_page_identity_verification',
        'approved_page_identity_verification',
        'rejected_earn_money',
        'approved_earn_money',
        'rejected_course',
        'approved_course',
        'response_account_request',
        'approved_report_violation'
      ].includes(data?.type)
    ) {
      return logoEmso;
    } else {
      return data?.account?.avatar_media;
    }
  };

  const handleAction = async type => {
    let response;
    const checkCallApi = () => {
      if (item?.type === 'account_relationship') {
        return fetchAccountRelationship(item?.account_relationship?.id, {
          status: type
        });
      } else {
        return fetchFamilyRelationship(item?.family_member?.id, {
          status: type
        });
      }
    };
    response = await checkCallApi();
    if (response.status === 200) {
      if (type === 'approved') {
        setIsLoadingAccept(false);
      } else if (type === 'rejected') {
        setIsLoadingReject(false);
      }
      setFamilyRelaionship(response.data.status);
    }
  };
  const renderButton = () => {
    return (
      <>
        {familyRelaionship === 'pending' ? (
          <Box sx={{ position: 'absolute', bottom: '7px', left: '84px' }}>
            <ButtonInherit
              style={{
                height: '30px',
                width: '100px'
              }}
              color={buttonColor.backgroundColor}
              label="Đồng ý"
              action={() => handleAction('approved')}
              loading={isLoadingAccept}
            />
            <ButtonInherit
              style={{
                height: '30px',
                width: '100px'
              }}
              label="Từ chối"
              action={() => handleAction('rejected')}
              loading={isLoadingReject}
            />
          </Box>
        ) : familyRelaionship === 'approved' ? (
          <Box sx={{ position: 'absolute', bottom: '18px', left: '84px' }}>
            <Typography sx={{ fontSize: '14px', color: 'text.primary' }}>
              Đã chấp nhận lời mời
            </Typography>
          </Box>
        ) : (
          <Box sx={{ position: 'absolute', bottom: '18px', left: '84px' }}>
            <Typography sx={{ fontSize: '14px', color: 'text.primary' }}>
              Đã từ chối lời mời
            </Typography>
          </Box>
        )}
      </>
    );
  };
  const renderImgAvatar = item => {
    if (item?.type === 'notification_template')
      return item?.data_template?.url_banner;

    if (item.status?.page_owner) {
      return (
        item.status?.page_owner?.avatar_media?.preview_url ?? avatarDefault
      );
    } else if (item?.type === 'favourite' && item?.favourite?.page) {
      return item?.favourite?.page?.avatar_media?.preview_url ?? avatarDefault;
    } else if (
      ['rejected_group_status', 'approved_group_status'].includes(item?.type)
    ) {
      return (
        item?.group?.banner?.preview_url ?? item?.group?.banner?.preview_url
      );
    } else if (item.account.avatar_media) {
      return item.account.avatar_media.preview_url;
    } else if (item.account.avatar_static) {
      return item.account.avatar_static;
    }
  };

  const renderBlock = item => {
    return (
      <Box sx={{ position: 'relative' }}>
        <NavLink
          to={renderLink(item)}
          style={{ textDecoration: 'none', width: '100%' }}
          onClick={e => {
            if (
              item?.type === 'approved_group_join_request' &&
              match.path === PATHS.GROUP_DETAIL
            ) {
              dispatch(getGroupDetailReq(item.group.id, ''));
            }
            if (item?.type === 'notification_template') {
              window.open(item?.data_template?.url, '_blank');
              e.preventDefault();
            }
          }}
        >
          <ListItem
            className={classes.listItem}
            button
            onClick={() => {
              handleClosePreview && handleClosePreview();
              setOpen(false);
            }}
            sx={
              item?.type === 'family_member' ||
              item?.type === 'account_relationship'
                ? { padding: '6px 6px 35px 6px !important' }
                : { padding: '6px !important' }
            }
          >
            <ListItemAvatar
              sx={
                type === 'notiSoket'
                  ? { position: 'relative', marginTop: 1 }
                  : { position: 'relative' }
              }
            >
              <AvatarSocial
                type="feed"
                style={{ width: 60, height: 60, marginRight: 8 }}
                src={
                  [
                    'approved_identity_verification',
                    'rejected_identity_verification',
                    'approved_page_identity_verification',
                    'rejected_page_identity_verification',
                    'response_account_request',
                    'approved_project',
                    'rejected_project',
                    'approved_earn_money',
                    'rejected_earn_money',
                    'rejected_course',
                    'approved_course',
                    'approved_report_violation',
                    'approved_report_course',
                    'approved_music',
                    'rejected_music',
                    'approved_playlist',
                    'rejected_playlist',
                    'rejected_change_title',
                    'approved_change_title',
                    'verification_blood_donation',
                    'approved_merchant_payment_request',
                    'approved_custom_brand',
                    'rejected_custom_brand',
                    'new_campaign',
                    ''
                  ].includes(item.type) || isNotificationMarket(item)
                    ? logoEmso
                    : renderImgAvatar(item)
                }
                avatarObj={renderAvatarObj(item)}
              />
              <SocialCutImage
                image={noti}
                item={renderImage(item)}
                style={{ position: 'absolute', top: 52, right: 2 }}
                zoom={0.68}
              />
            </ListItemAvatar>
            <ListItemText
              style={
                !item?.read
                  ? { marginTop: 0, marginLeft: 10 }
                  : {
                      color: '#65676B',
                      marginTop: 0,
                      marginLeft: 10
                    }
              }
              primaryTypographyProps={{
                style: {
                  color: theme.palette.mode === 'dark' ? '#fff' : '#050505'
                }
              }}
              primary={renderPrimary(item)}
              secondary={
                <>
                  <Typography
                    className={classes.subText}
                    style={
                      !item?.read
                        ? {
                            fontWeight: 500,
                            color: buttonColor.backgroundColor
                          }
                        : { color: '#65676B' }
                    }
                  >
                    {handleTimeShow(item.created_at)}
                  </Typography>
                </>
              }
            />
            {!item?.read ? (
              <ListItemIcon
                style={{
                  paddingTop: 35,
                  paddingRight: 12,
                  minWidth: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    backgroundColor: buttonColor.backgroundColor,
                    width: 12,
                    height: 12,
                    borderRadius: 6
                  }}
                ></div>
              </ListItemIcon>
            ) : null}
          </ListItem>
        </NavLink>
        {item?.type === 'family_member' || item?.type === 'account_relationship'
          ? renderButton()
          : null}
      </Box>
    );
  };

  const renderDialog = item => {
    return (
      <DialogViolate
        item={item}
        open={openDialogViolate}
        setOpenDialogViolate={setOpenDialogViolate}
      />
    );
  };

  return (
    <>
      {item?.type === 'bad_status' && openDialogViolate
        ? renderDialog(item)
        : renderBlock(item)}
    </>
  );
};

export default CommonNoti;

//comment bài viết láy id bài viết
