import produce from 'immer';
import { cloneDeep, orderBy } from 'lodash';
import * as types from 'src/constants/store/socialPost';
const _ = require('lodash');

const initialState = {
  activities: [] as Array<any>,
  hasMore: true,
  page: 1,
  isLoad: null,
  isLoadActivitiesFirst: false,
  flagPath: null,
  error: {},
  scheduledPost: [] as Array<any>,
  loadingSchedule: false,
  pinnedPost: [] as Array<any>,
  listPostTags: [],
  collectionSaved: [],
  checkLoadMore: false,
  notiPost: null,
  notiGroup: false,
  notiScheduledPostGroup: false,
  isUpdatingPost: false,
  pageFollow: null,
  notiSharePost: {},
  listIdsWatchHistory: [],
  processing: '',
  inViewPosts: [],
  idRemoveTagMention: ''
};
export const socialPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_POST_SUCCESS: {
      return produce(state, draftState => {
        let newActivity = action.payload.activities;
        if (newActivity.length > 0) {
          if (action.payload.type === 'banner_group') {
            draftState.activities.unshift(newActivity[0]);
          } else {
            draftState.activities.push(...newActivity);
          }
          draftState.hasMore = true;
        } else {
          draftState.isLoadActivitiesFirst = true;
          draftState.hasMore = false;
        }

        draftState.activities = _.uniqBy(draftState.activities, 'id');
        draftState.checkLoadMore = !state.checkLoadMore;
      });
    }

    case types.UPDATE_PATH: {
      return {
        ...state,
        flagPath: action.payload.path
      };
    }

    case types.CREATE_POST_SUCCESS: {
      let data = action.payload.data;
      let newState = _.cloneDeep(state);
      let post = data.post;
      newState.isLoad = 'success';
      newState.notiGroup = true;

      // Hiện tại nhiều chỗ đang dùng media_attachments[0].
      // Nhưng key media_attachments không tồn tại nên không thể check media_attachments[0]
      if (!post?.media_attachments) {
        post = { ...post, media_attachments: [] };
      }
      if (!post.status) {
        if (post?.media_attachments[0]?.remote_url === 'pending') {
          post.status_video_create = 'pending';
        }

        if (post.visibility !== 'pending' && post?.post_type !== 'moment') {
          newState.activities.unshift(post);
        }
      }

      if (post?.scheduled_at) {
        return {
          ...newState,
          scheduledPost: [post].concat(newState?.scheduledPost),
          notiScheduledPostGroup: true
        };
      }
      if (post) {
        newState.notiPost = 'Chia sẻ dự án thành công';
      } else {
        newState.notiPost = 'Chia sẻ dự án thất bại';
      }

      return newState;
    }

    case types.UPDATE_POST_SUCCESS: {
      let data = action.payload.data;
      let hiddenPost = action.payload?.hiddenPost;
      let hiddenTag = action.payload?.hiddenPost?.hiddenTag;
      let pinnedUpdate = action.payload?.hiddenPost?.pinned;
      let newState = _.cloneDeep(state);
      let index = newState.activities.findIndex((el: any) => el.id === data.id);
      let indexPin = newState.pinnedPost.findIndex(
        (item: any) => item.id === data.id
      );
      let indexScheduled = newState.scheduledPost.findIndex(
        item => item.status.id === data.id
      );

      return {
        ...newState,
        isLoad: 'success',
        activities:
          hiddenPost || hiddenTag
            ? [
                ...newState.activities.slice(0, index),
                ...newState.activities.slice(index + 1)
              ]
            : produce(state, draftState => {
                if (index !== -1) draftState.activities[index] = data;
              }).activities,
        pinnedPost: pinnedUpdate
          ? [
              ...newState.pinnedPost.slice(0, indexPin),
              data,
              ...newState.pinnedPost.slice(indexPin + 1)
            ]
          : [
              ...newState.pinnedPost.slice(0, indexPin),
              ...newState.pinnedPost.slice(indexPin + 1)
            ],
        scheduledPost: produce(newState.scheduledPost, draftState => {
          if (indexScheduled !== -1) {
            draftState[indexScheduled].status = data;
          }
        })
      };
    }

    case types.UPDATE_FOLLOW_SUCCESS: {
      let id = action.payload.data.page.id;
      const post = action.payload.data;
      const type = action.payload.type;
      let newState = _.cloneDeep(state);

      let updateFollowing;
      if (newState.activities?.length > 0) {
        updateFollowing = newState.activities.map(e => {
          if (e?.page?.id === id) {
            newState.pageFollow = {
              ...e,
              page: {
                ...e.page,
                page_relationship: {
                  ...e.page.page_relationship,
                  following: type === 'unfollows' ? false : true,
                  like: type === 'unfollows' ? false : true
                }
              }
            };
            return {
              ...e,
              page: {
                ...e.page,
                page_relationship: {
                  ...e.page.page_relationship,
                  following: type === 'unfollows' ? false : true,
                  like: type === 'unfollows' ? false : true
                }
              }
            };
          } else {
            return e;
          }
        });
      } else {
        newState.pageFollow = {
          ...post,
          page: {
            ...post.page,
            page_relationship: {
              ...post.page.page_relationship,
              following: type === 'unfollows' ? false : true,
              like: type === 'unfollows' ? false : true
            }
          }
        };
      }

      return {
        ...newState,
        activities: updateFollowing
      };
    }

    case types.COMMENT_TOTAL: {
      let data = action.payload.data;
      let idPost = action.payload.idPost;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        activities: state.activities.map((el: any) =>
          el?.id === idPost
            ? {
                ...el,
                replies_total: data.replies_total
              }
            : el
        )
      };
    }

    case types.DELETE_OLD_POST_SUCCESS: {
      let { id } = action.payload;
      return produce(state, draftState => {
        const post_index = state.activities.findIndex(
          (el: any) => el?.id === id
        );
        if (post_index !== -1) draftState.activities.splice(post_index, 1);
      });
    }

    case types.DELETE_POST_SUCCESS: {
      let postId = action.payload.data.postId;
      let newState = _.cloneDeep(state);
      let dataFilter = newState.activities.filter(item => item.id !== postId);
      let pinFilter = newState.pinnedPost.filter(item => item.id !== postId);

      return {
        ...socialPostReducer,
        activities: dataFilter,
        pinnedPost: pinFilter
      };
    }
    case types.GET_PIN_POST_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        pinnedPost: data
      };
    }
    case types.PIN_POST_SUCCESS:
    case types.UNPIN_POST_SUCCESS: {
      let newState = _.cloneDeep(state);
      let id = action.payload.id;
      let dataPost = action.payload.data;
      let index = newState.activities?.findIndex((el: any) => el.id === id);
      let indexPinned = newState.pinnedPost?.findIndex(
        (el: any) => el.id === id
      );

      return index < 0
        ? {
            ...newState,
            activities: [dataPost]?.concat(newState.activities),
            pinnedPost:
              indexPinned < 0
                ? [dataPost]?.concat(newState.pinnedPost)
                : newState.pinnedPost?.filter((el: any) => el.id !== id)
          }
        : {
            ...newState,
            activities: [
              ...newState.activities.splice(0, index),
              ...newState.activities.splice(index + 1)
            ],
            pinnedPost:
              indexPinned < 0
                ? [dataPost]?.concat(newState.pinnedPost)
                : newState.pinnedPost?.filter((el: any) => el.id !== id)
          };
    }
    case types.SAVE_POST_SUCCESS:
    case types.UNSAVE_POST_SUCCESS:
    case types.MUTE_POST_SUCCESS:
    case types.UNMUTE_POST_SUCCESS: {
      let id = action.payload.id;
      let dataPost = action.payload.data;
      let index = state.activities?.findIndex((el: any) => el.id === id);

      //save score for moment after create or delete bookmark
      if (index >= 0 && dataPost?.post_type === 'moment') {
        dataPost = {
          ...dataPost,
          score: state.activities[index].score
        };
      }

      return index < 0
        ? state
        : {
            ...state,
            activities: produce(state.activities, draftState => {
              draftState[index] = dataPost;
            })
          };
    }

    case types.RESET_ACTIVITIES: {
      let newState;
      if (action.payload === 'activity') {
        newState = {
          ...state,
          activities: []
        };
      } else {
        newState = {
          ...initialState
        };
      }
      return newState;
    }

    case types.RESET_PINNED_POST: {
      let newState;
      if (action.payload === 'pinned_post') {
        newState = {
          ...state,
          pinnedPost: []
        };
      } else {
        newState = { ...initialState };
      }
      return newState;
    }

    case types.RESET_POST_LOAD: {
      let newState = {
        ...state,
        isLoad: 'loading'
      };
      return newState;
    }

    case types.RESET_FALSE_POST_LOAD: {
      let newState = {
        ...state,
        isLoad: 'success'
      };
      return newState;
    }
    case types.ERROR_POST: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, error: newState };
    }
    case types.RESET_ERROR_POST: {
      let newState = {
        ...state,
        error: {}
      };
      return newState;
    }
    case types.GET_SCHEDULED_POST_SUCCESS: {
      let { data } = action.payload;
      return { ...state, scheduledPost: data, loadingSchedule: false };
    }
    case types.SCHEDULED_POST_SUCCESS: {
      let { id } = action.payload;
      return {
        ...state,
        scheduledPost: state.scheduledPost.filter(
          (el: any) => el?.status?.id !== id
        )
      };
    }
    case types.SCHEDULED_POST_UPDATE_SUCCESS: {
      let { id, data_update } = action.payload;
      const post_update_id = state.scheduledPost.findIndex(el => el.id === id);
      if (post_update_id !== -1)
        state.scheduledPost[post_update_id] = data_update;
      return {
        ...state
      };
    }
    case types.LOADING_POST_SCHEDULED: {
      let { type } = action.payload;
      return {
        ...state,
        loadingSchedule: type === 'loading' ? true : false
      };
    }

    case types.REACTIONS_POST: {
      let { id, favourites, viewer_reaction, reactions } = action.payload;
      return {
        ...state,
        activities: state.activities.map((el: any) =>
          el?.id === id
            ? {
                ...el,
                favourites: favourites,
                viewer_reaction: viewer_reaction,
                reactions: reactions,
                favourites_count: viewer_reaction
                  ? el?.favourites_count + 1
                  : el?.favourites_count - 1
              }
            : el?.reblog?.id === id
            ? {
                ...el,
                reblog: {
                  ...el.reblog,
                  favourites: favourites,
                  favourites_count: favourites.length,
                  reactions: reactions
                }
              }
            : el
        )
      };
    }

    case types.UPDATE_REACTIONS_POST: {
      let { data } = action.payload;
      const newState = cloneDeep(state);
      const dataPost: any = newState.activities;

      return {
        ...newState,
        activities: dataPost.map((el: any) =>
          el?.id === data?.status.id
            ? {
                ...el,
                favourites: [
                  {
                    id: data.id,
                    custom_vote_type: data.custom_vote_type,
                    account: data.account,
                    page: data.page,
                    status_id: data.status.id
                  },
                  ...el.favourites
                ],
                favourites_count: data.status.favourites_count,
                reactions: data.status.reactions
              }
            : el
        )
      };
    }

    case types.UPDATE_REBLOG_COUNT: {
      let { data } = action.payload;
      return produce(state, draftState => {
        const post_id = draftState.activities.findIndex(
          el => el?.id === data.id
        );
        if (post_id !== -1) ++draftState.activities[post_id].reblogs_count;
      });
    }

    case types.GET_LIST_BOOKMARK_COLLECTION_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, collectionSaved: newState };
    }

    case types.LIST_POST_TAGS: {
      return {
        ...state,
        listPostTags: action.payload
      };
    }

    case types.UPDATE_POST_TAGS: {
      let id = action.payload;
      return {
        ...state,
        listPostTags: state.listPostTags.filter((el: any) => el?.id !== id)
      };
    }

    case types.UPDATE_IS_LOAD: {
      let value = action.payload;
      return {
        ...state,
        isLoad: value
      };
    }
    case types.NOTI_POST: {
      return {
        ...state,
        notiPost: action.payload
      };
    }
    case types.RESET_NOTI_GROUP: {
      return {
        ...state,
        notiGroup: false
      };
    }
    case types.RESET_NOTI_POST: {
      return {
        ...state,
        notiPost: null
      };
    }
    case types.RESET_NOTI_SCHEDULED_POST_GROUP: {
      return {
        ...state,
        notiScheduledPostGroup: false
      };
    }

    case types.IS_UPDATING_POST: {
      return {
        ...state,
        isUpdatingPost: action.payload
      };
    }
    case types.NOTI_SHARE_POST: {
      return {
        ...state,
        notiSharePost: {
          code: action.payload.code,
          msg: action.payload.msg
        }
      };
    }
    case types.RESET_NOTI_SHARE_POST: {
      return {
        ...state,
        notiSharePost: {}
      };
    }
    case types.UPDATE_ID_WATCH_HISTORY: {
      let id = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...state,
        listIdsWatchHistory: [...newState.listIdsWatchHistory, ...id]
      };
    }

    case types.STATUS_PROCESSING_POST: {
      let type = action.payload;
      let newState = _.cloneDeep(state);
      return {
        ...newState,
        processing: type
      };
    }
    case types.ADD_POST_TO_VIEW: {
      let newState = _.cloneDeep(state);
      const data = action.payload;
      const index = newState.inViewPosts?.findIndex(
        (item: any) => item.id === data.id
      );
      if (index !== -1) {
        newState.inViewPosts[index] = action.payload;
      } else {
        newState.inViewPosts?.push(action.payload);
      }

      return newState;
    }

    case types.REMOVE_POST_TO_VIEW: {
      let newState = _.cloneDeep(state);
      const data = action.payload;

      let newInViewPosts = newState.inViewPosts?.filter(
        el => el?.id !== data.id
      );
      return { ...newState, inViewPosts: newInViewPosts };
    }
    case types.UN_TAG_MENTION: {
      return {
        ...state,
        idRemoveTagMention: action.payload,
        activities: state.activities.filter(
          (el: any) => el?.id !== action.payload
        )
      };
    }
    case types.ADD_POST_FROM_DIARY: {
      let post: any = action.payload;
      return {
        ...state,
        activities: [post, ...state.activities]
      };
    }

    case types.PIN_OR_UNPIN_POST: {
      let post = action.payload;
      let updatedActivities;
      let updatedPinnedPost;

      if (post.pinned) {
        // Thêm bài viết vào danh sách ghim
        updatedPinnedPost = [...state.pinnedPost];
        if (!updatedPinnedPost.find(item => item.id === post.id)) {
          // Lưu index của bài viết khi pin
          const originalIndex = state.activities.findIndex(
            item => item.id === post.id
          );
          const postWithIndex = { ...post, originalIndex };
          updatedPinnedPost.unshift(postWithIndex);
        }
        // Loại bỏ bài viết khỏi activities nếu được ghim
        updatedActivities = state.activities.filter(
          item => item.id !== post.id
        );
      } else {
        // Loại bỏ bài viết khi bỏ ghim
        updatedPinnedPost = state.pinnedPost.filter(
          item => item.id !== post.id
        );
        const postToUnpin = state.pinnedPost.find(item => item.id === post.id);
        if (postToUnpin) {
          updatedActivities = [...state.activities];
          // Chèn bài viết trở lại vào vị trí index đã lưu khi pin
          updatedActivities.splice(postToUnpin.originalIndex, 0, {
            ...post,
            pinned: false
          });
        } else {
          updatedActivities = [...state.activities];
        }
      }

      return {
        ...state,
        activities: updatedActivities,
        pinnedPost: updatedPinnedPost
      };
    }

    default:
      return state;
  }
};
