import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { BASE_ROOT_MARKET, USER_TOKEN } from 'src/util/config';
import { apiGet, apiPost } from './apis';
import {
  apiDeleteMarket,
  apiGetMarket,
  apiPatchMarket,
  apiPostMarket
} from './apisMarket';
import { apiGetV2 } from './apisv2';

interface ListProductApproval {
  product_ids: Array<String | Number>;
}

const fetchApiRequestV2 = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/v2/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiRequestAdmin = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT_MARKET}/api/admin/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getListBanks = async () => {
  return apiGetMarket('bank_lists', null, null);
};

export const getCalendarMarketplaceApi = async (params: any) => {
  return apiGetMarket('product_search', params, null);
};

export const hiddenProductApi = async (pageId, productId, data) => {
  return apiPatchMarket(
    `sellers/${pageId}/product_managements/${productId}/hidden_products`,
    null,
    data
  );
};

export const fastUpdate = async (idProduct, idVariant, data) => {
  return apiPatchMarket(
    `/products/${idProduct}/product_variants/${idVariant}`,
    null,
    data
  );
};

export const getSuggestionsProductApi = async params => {
  return apiGetMarket('suggestions/product', params, null);
};

export const createUpdateProductShopApi = async (
  data: any,
  type: any,
  idPage: string
) => {
  if (type === 'create') {
    return apiPostMarket(`sellers/${idPage}/product_managements`, null, data);
  }

  return apiPatchMarket(
    `sellers/${idPage}/product_managements/${type}`,
    null,
    data
  );
};

export const sendApprovalProduct = async (
  data: ListProductApproval,
  idPage: string,
  idProduct: any
) => {
  return apiPostMarket(
    `sellers/${idPage}/product_managements/${idProduct}/send_approval_products`,
    null,
    data
  );
};

export const checkPhoneNumberVerification = async params => {
  return apiPostMarket('check_phone_number_verification', null, params);
};

export const getListPagesApi = (params: any) => {
  return apiGetMarket('pages', params, null);
};

export const getListShopMallApi = (params: any) => {
  return apiGet('shop_malls', params, null);
};

//danh sách sản phẩm của người bán
export const getProductManageShop = async (params, pageId) => {
  return apiGetMarket(
    `sellers/${pageId}/product_managements`,

    params,
    null
  );
};

//danh sách sản phẩm của người mua (có trả total)
export const getListProductApi = async params => {
  return apiGetMarket(`list_products`, params, null);
};

//danh sách sản phẩm của người mua (không trả total)
export const getProductApi = async params => {
  return apiGetMarket('products', params, null);
};

// danh sách sản phẩm flash sale của shop
export const getProductFlashSaleShopApi = async params => {
  return apiGetMarket('product_shop_flash_sale_now', params, null);
};

export const confirmPhoneNumber = async params => {
  return apiPostMarket('confirm_phone_number', null, params);
};

//chi tiết sản phẩm của người bán
export const getDetailProductShopApi = async (idProduct: any, pageId) => {
  return apiGetMarket(
    `sellers/${pageId}/product_managements/${idProduct}`,

    null,
    null
  );
};

//fetch landing pages
export const fetchLandingPages = async params => {
  return apiGetMarket('landing_pages', params, null);
};

export const getShopMallBanners = async params => {
  return apiGetMarket('banner_shop_malls', params, null);
};

// chi tiết sản phẩm của người mua
export const getDetailProductApi = async (idProduct: any) => {
  return apiGetMarket(`products/${idProduct}`, null, null);
};

export const getSuggestionProductsApi = async (idProduct: any, prams: any) => {
  return apiGetMarket(
    `products/${idProduct}/suggestion_products`,

    prams,
    null
  );
};

export const getProductCategories = async () => {
  return apiGetMarket('product_categories', null, null);
};

export const deleteProductApi = async (id: any, idPage: string) => {
  return apiDeleteMarket(
    `sellers/${idPage}/product_managements/${id}`,

    null,
    null
  );
};

export const deleteMultiProductApi = async (payload: any, idPage: string) => {
  return apiDeleteMarket(
    `sellers/${idPage}/product_managements/destroy_products`,
    null,
    payload
  );
};

export const createInviteProduct = async (idProduct: any, idInvite: any) => {
  return apiPostMarket(`products/${idProduct}/invitations`, null, {
    target_account_ids: idInvite
  });
};

export const interesteProductApi = async (idProduct: any) => {
  return apiPostMarket(`products/${idProduct}/product_followers`, null, null);
};
export const noInteresteProductApi = async (
  idProduct: any,
  idFollowers: any
) => {
  return apiDeleteMarket(
    `products/${idProduct}/product_followers/${idFollowers}`,

    null,
    null
  );
};

export const getListInvitation = async (params: any) => {
  return apiGetMarket('product_invitations', params, null);
};

export const respondInvitationProduct = async (idProduct: any, params: any) => {
  return apiPostMarket(
    `products/${idProduct}/invitations/invitations_respond`,
    null,
    params
  );
};

export const getListHostOfProduct = async (idProduct: any, params: any) => {
  return apiGetMarket(
    `/products/${idProduct}/invitation_hosts`,

    params,
    null
  );
};

export const CRUDCart = async (type, params: any, data) => {
  if (type === 'patch') {
    return apiPatchMarket(`shopping_carts/${params}`, null, data);
  }

  if (type === 'post') {
    return apiPostMarket(`shopping_carts`, null, data);
  }

  return apiDeleteMarket(`shopping_carts/${params}`, null, data);
};
export const getListCartApi = async (params: any) => {
  return apiGetMarket('shopping_carts', params, null);
};

export const getTotalProductInCarts = async () => {
  return apiGetMarket('count_product_in_shopping_carts', null, null);
};

export const paymentProduct = async (idProduct: any) => {
  return apiPostMarket(
    `products/${idProduct}/payment_product`,

    null,
    {
      detail_type: 'payment_product'
    }
  );
};

export const sendApproval = async (idProduct: any) => {
  return apiPostMarket(
    `products/${idProduct}/send_approval`,

    null,
    null
  );
};

export const getBalance = async () => {
  return apiGetMarket(`transactions`, null, null);
};

export const rechargeEcoin = async (data: any) => {
  return apiPostMarket(`transfer`, null, data);
};

export const ratingProduct = async (idProduct: any, data: any, type) => {
  if (type === 'create') {
    return apiPostMarket(`orders/${idProduct}/product_rating`, null, data);
  }

  return apiPatchMarket(
    `orders/${idProduct}/product_rating/${type}`,
    null,
    data
  );
};

export const getListRating = async (idProduct: any, params: any) => {
  return apiGetMarket(`products/${idProduct}/list_rating`, params, null);
};

export const getListPaid = async idProduct => {
  return apiGetMarket(
    `products/${idProduct}/list_participants`,

    null,
    null
  );
};

export const CRUDAddress = async (type, idAddress: any, params: any, data) => {
  if (type === 'patch') {
    return apiPatchMarket(`delivery_addresses/${idAddress}`, params, data);
  }

  if (type === 'delete') {
    return apiDeleteMarket(`delivery_addresses/${idAddress}`, params, data);
  }

  if (type === 'get') {
    return apiGetMarket('delivery_addresses', params, data);
  }

  return apiPostMarket('delivery_addresses', params, data);
};

export const paymentOrder = async data => {
  return apiPostMarket('orders', null, data);
};

export const listOrders = async params => {
  return apiGetMarket('orders', params, null);
};

export const getOrderPage = async (idPage: any, params: any) => {
  return apiGetMarket(
    `sellers/${idPage}/order_managements`,

    params,
    null
  );
};

// chi tiết order của người bán
export const detailOrder = async (idPage, idOrder: any) => {
  return apiGetMarket(
    `sellers/${idPage}/order_managements/${idOrder}`,

    null,
    null
  );
};

//chi tiết order của người mua
export const detailOrderBuyer = async (idOrder: any) => {
  return apiGetMarket(`orders/${idOrder}`, null, null);
};

export const deleteOrderSeller = async (idPage, idOrder: any, data) => {
  return apiDeleteMarket(
    `sellers/${idPage}/order_managements/${idOrder}`,

    null,
    data
  );
};

export const deleteOrdersBuyer = async (idOrder: any, data) => {
  return apiDeleteMarket(`orders/${idOrder}`, null, data);
};

export const shopListCancelReasonsApi = async (pageId, orderId) => {
  return apiGetMarket(
    `sellers/${pageId}/order_managements/${orderId}/shop_list_cancel_reasons`,

    null,
    null
  );
};

export const patchOrder = async (pageId, idOrder: any, data) => {
  return apiPatchMarket(
    `sellers/${pageId}/order_managements/${idOrder}`,

    null,
    data
  );
};

export const patchOrderBuyer = async (idOrder: any, data) => {
  return apiPatchMarket(`orders/${idOrder}`, null, data);
};

export const shopOrderStatusLogsApi = async (pageId, idOrder) => {
  return apiGetMarket(
    `sellers/${pageId}/order_managements/${idOrder}/shop_order_status_logs`,

    null,
    null
  );
};

export const buyerOrderStatusLogApi = async idOrder => {
  return apiGetMarket(`orders/${idOrder}/order_status_logs`, null, null);
};

export const adminOrderStatusLogsApi = async (orderId, params) => {
  return fetchApiRequestAdmin(
    `admin_order_status_logs?order_id=${orderId}`,
    'GET',
    params,
    null
  );
};

export const paymentConfirm = async (idOrder: any) => {
  return apiPostMarket(`orders/${idOrder}/payment_order`, null, null);
};

export const paymentMethods = async (params: any, type) => {
  return apiGetMarket(
    `${type === 'vtcpay' ? 'create_vtcpay_url' : 'create_payment_url'}`,

    params,
    null
  );
};

export const updateStatusOrder = async (pageId, idOrder: any, data: any) => {
  return apiPatchMarket(
    `sellers/${pageId}/order_managements/${idOrder}`,

    null,
    data
  );
};

export const searchProduct = async (params: any) => {
  return apiGetMarket(`product_search`, params, null);
};

export const searchNameShop = async (params: any) => {
  return fetchApiRequestV2(`search`, 'GET', params, null);
};

export const searchShopRelatedProducts = async (params: any) => {
  return apiGetMarket(`search_pages`, params, null);
};

export const searchShopRelatedProductsV2 = async (params: any) => {
  return apiGetV2(`search`, params, null);
};

export const verifyDelivered = async (idOrder: any) => {
  return apiPostMarket(`orders/${idOrder}/verify_delivered`, null, {
    status: 'finish'
  });
};

export const countOrders = async () => {
  return apiGetMarket(`count_orders`, null, null);
};

export const productCategories = async (id: any, params) => {
  return apiGetMarket(
    `product_categories${id ? '/' + id : ''}`,

    params,
    null
  );
};

export const listCategories = async (
  idParent: string | number,
  levelChildren: number
) => {
  return apiGetMarket(
    `list_categories/${idParent}`,

    { level_children: levelChildren },
    null
  );
};

export const productSubCategories = async idCategory => {
  return apiGetMarket(`product_categories/${idCategory}`, null, null);
};

export const getListProductInterested = async (params: any) => {
  return apiGetMarket(`products`, params, null);
};

export const getListReportCategories = async (params: any) => {
  return apiGetMarket('report_categories', params, null);
};

export const sendReportCategories = async (data: any) => {
  return apiPost('report_violations', null, data);
};

export const reportProductApi = async (data: any) => {
  return apiPostMarket('report_violations', null, data);
};

export const searchHistory = async (params: any) => {
  return apiGetMarket('search_histories', params, null);
};

export const saveSearchHistory = async (data: any) => {
  return apiPostMarket('search_histories', null, data);
};

export const getListCampaignsApi = async (params: any = {}) => {
  return apiGetMarket('campaigns', params, null);
};

export const getDetailCampaignsApi = async (id: any) => {
  return apiGetMarket(`campaigns/${id}`, null, null);
};

export const getCampaignProductsApi = async (id: any, params) => {
  return apiGetMarket(`campaigns/${id}/products`, params, null);
};

export const getProductsFlashSale = async (params: any) => {
  return apiGetMarket('campaigns', params, null);
};

export const getDetailsFlashSales = async (idCampain: any) => {
  return apiGetMarket(`campaigns/${idCampain}`, null, null);
};

export const getListCancelReasonApi = async params => {
  return apiGetMarket('list_cancel_reasons', params, null);
};

export const getTopSeachApi = async () => {
  return apiGetMarket('top_search_products', null, null);
};

export const getSuggestCategoriesApi = async params => {
  return apiGetMarket('product_keywords', params, null);
};

export const getSearchCategoriesApi = async params => {
  return apiGetMarket('product_categories', params, null);
};

export const suggestCategoriesApi = async params => {
  return apiGetMarket('product_category_search', params, null);
};

export const productCategoryParentSearchApi = async params => {
  return apiGetMarket('product_category_parent_search', params, null);
};

export const getAllAttributeApi = async () => {
  return apiGetMarket('product_attributes', null, null);
};

export const addValueAttributeApi = async (id, data) => {
  return apiPostMarket(
    `product_attributes/${id}/custom_attribute_value`,

    null,
    data
  );
};

export const removeValueAttributeApi = async (id, data) => {
  return apiDeleteMarket(
    `product_attributes/${id}/delete_attribute_value`,

    null,
    data
  );
};

export const updateValueAttributeApi = async (id, data) => {
  return apiPatchMarket(
    `product_attributes/${id}/update_attribute_value`,

    null,
    data
  );
};

export const createTransport = async data => {
  return apiPostMarket(`shippings`, null, data);
};

export const createServices = async data => {
  return apiPostMarket(`available_services`, null, data);
};

export const leadTime = async data => {
  return apiPostMarket(`leadtime`, null, data);
};

export const cancelShipping = async data => {
  return apiPostMarket(`cancel_shipping`, null, data);
};

export const returnShipping = async data => {
  return apiPostMarket(`return_shipping`, null, data);
};

export const printShippingOrder = async data => {
  return apiPostMarket(`gen_token`, null, data);
};

export const getDetailShipping = async data => {
  return apiPostMarket(`detail_shipping`, null, data);
};

export const printOrderTransport = async data => {
  return apiPostMarket(`gen_token`, null, data);
};

export const getCategoryGuideApi = async params => {
  return apiGetMarket(`product_category_guide`, params, null);
};

export const getBrandApi = async params => {
  return apiGetMarket(`list_brand_by_product_category`, params, null);
};

export const getShippingFeeApi = async data => {
  return apiPostMarket(`shipping_fee`, null, data);
};

export const createVoucherShopApi = async (data, pageId) => {
  return apiPostMarket(
    `sellers/${pageId}/voucher_managements`,

    null,
    data
  );
};

export const endVoucherShopApi = async (id, pageId) => {
  return apiPatchMarket(
    `sellers/${pageId}/voucher_managements/${id}`,

    null,
    {
      end_time: moment().format()
    }
  );
};

export const deleteVoucherShopApi = async (id, pageId) => {
  return apiDeleteMarket(
    `sellers/${pageId}/voucher_managements/${id}`,

    null,
    null
  );
};

// danh sách voucher cho người bán
export const getListVoucherShopApi = async (pageId, params) => {
  return apiGetMarket(
    `sellers/${pageId}/voucher_managements`,

    params,
    null
  );
};
// danh sách voucher cho người mua
export const getListVoucherApi = async params => {
  return apiGetMarket(`vouchers`, params, null);
};

export const getListValidEmsoVoucherApi = async data => {
  return apiPostMarket(`valid_emso_vouchers`, null, data);
};

export const getListVoucherValidApi = async data => {
  return apiPostMarket(`valid_page_vouchers`, null, data);
};

export const getListVoucherApiEmso = async params => {
  return fetchApiRequestAdmin(`vouchers`, 'GET', params, null);
};

export const saveVoucherApi = async data => {
  return apiPostMarket(`my_vouchers`, null, data);
};

export const deleteMyVoucherApi = async id => {
  return apiDeleteMarket(`my_vouchers/${id}`, null, null);
};

export const listVoucherApi = async params => {
  return apiGetMarket(`my_vouchers`, params, null);
};

export const listProductReportReasons = async params => {
  return apiGetMarket(`report_reasons`, params, null);
};

export const reportProductRating = async data => {
  return apiPostMarket(`product_rating_reports`, null, data);
};

export const getAndUpdateDetailVoucherShopApi = async (
  id,
  method,
  data,
  pageId
) => {
  if (method === 'PATCH') {
    return apiPatchMarket(
      `sellers/${pageId}/voucher_managements/${id}`,
      null,
      data
    );
  }

  if (method === 'DELETE')
    return apiDeleteMarket(
      `sellers/${pageId}/voucher_managements/${id}`,
      null,
      data
    );

  if (method === 'GET') {
    return apiGetMarket(
      `sellers/${pageId}/voucher_managements/${id}`,
      null,
      null
    );
  }

  return apiPostMarket(
    `sellers/${pageId}/voucher_managements/${id}`,
    null,
    data
  );
};

export const getDetailVoucherShopApi = async (id, params, pageId) => {
  return apiGetMarket(
    `sellers/${pageId}/voucher_managements/${id}`,
    params,
    null
  );
};

export const registerVoucherEMSOShopApi = async (id, data, pageId) => {
  return apiPostMarket(
    `sellers/${pageId}/voucher_managements/${id}/register_voucher`,
    null,
    data
  );
};

export const getListFlashSaleApi = async (params: any) => {
  return apiGetMarket('flash_sales', params, null);
};

export const getShopReportListReasons = async () => {
  return apiGetMarket('list_shop_report_categories', null, null);
};

export const reportShop = async (payload: any) => {
  return apiPostMarket('shop_reports', null, payload);
};

export const getListSizeChartCategoryApi = async () => {
  return apiGetMarket('size_chart_category', null, null);
};

export const getMeasurementsDetailAdminApi = async (params: any) => {
  return fetchApiRequestAdmin('measurements', 'GET', params, null);
};

export const getListSizeChartOfPageApi = async params => {
  return apiGetMarket('size_charts', params, null);
};

export const createFlashSaleApi = async (data: any) => {
  return apiPostMarket('flash_sales', null, data);
};

export const getDetailFlashSaleApi = async (id: any) => {
  return apiGetMarket(`flash_sales/${id}`, null, null);
};

export const getListProdutcsOfFlashSaleApi = async (id: any) => {
  return apiGetMarket(`flash_sales/${id}/products`, null, null);
};

export const updateFlashSaleApi = async (id: any, data: any) => {
  return apiPatchMarket(`flash_sales/${id}`, null, data);
};
export const deleteFlashSaleApi = async (id: any) => {
  return apiDeleteMarket(`flash_sales/${id}`, null, null);
};

export const getListProductFlashSaleNow = async (params: any = {}) => {
  return apiGetMarket(`products_flash_sale_now`, params, null);
};

export const followProductListApi = async data => {
  return apiPostMarket('follow_products', null, data);
};

export const getCategoriesOfPage = async params => {
  return apiGetMarket('list_page_categories', params, null);
};

export const getPageDetailProductCategories = async params => {
  return apiGetMarket('page_detail_product_categories', params, null);
};

export const createSizeChartsApi = async data => {
  return apiPostMarket('size_charts', null, data);
};

export const deleteSizeChartApi = async id => {
  return apiDeleteMarket(`size_charts/${id}`, null, null);
};

export const updateSizeChartApi = async (id, data) => {
  return apiPatchMarket(`size_charts/${id}`, null, data);
};

export const getMyOrderRating = async id => {
  return apiGetMarket(`orders/${id}/product_rating`, null, null);
};

export const getDetailSizeChartApi = async id => {
  return apiGetMarket(`size_charts/${id}`, null, null);
};

export const getListCombosPromotionApi = async (params: any) => {
  return apiGetMarket('bundle_deals', params, null);
};

export const createCombosPromotionApi = async (data: any) => {
  return apiPostMarket('bundle_deals', null, data);
};

export const getDetailCombosPromotionApi = async (id: any) => {
  return apiGetMarket(`bundle_deals/${id}`, null, null);
};

export const updateCombosPromotionApi = async (id: any, data: any) => {
  return apiPatchMarket(`bundle_deals/${id}`, null, data);
};

export const deleteCombosPromotionApi = async (id: any) => {
  return apiDeleteMarket(`bundle_deals/${id}`, null, null);
};

export const createShopProgramApi = async (data: any) => {
  return apiPostMarket(`campaigns`, null, data);
};

export const getListShopProgramApi = async (params: any) => {
  return apiGetMarket(`campaigns`, params, null);
};

export const getDetailShopProgramApi = async (id: any) => {
  return apiGetMarket(`campaigns/${id}`, null, null);
};

export const updateShopProgramApi = async (id: any, data: any) => {
  return apiPatchMarket(`campaigns/${id}`, null, data);
};

export const deleteShopProgramApi = async (id: any) => {
  return apiDeleteMarket(`campaigns/${id}`, null, null);
};

export const totalPriceInCheckoutApi = async (data: any) => {
  return apiPostMarket(`total_payments`, null, data);
};

export const totalPriceInCartApi = async (data: any) => {
  return apiPostMarket(`total_payments_in_shopping_cart`, null, data);
};

export const getListCampaignEmsoApi = async (param: any) => {
  return apiGetMarket(`campaigns`, param, null);
};

export const getDetailCampaignEmsoApi = async (id: any) => {
  return apiGetMarket(`campaigns/${id}`, null, null);
};

export const getChildCampaignsShopFlashSaleApi = async (param: any) => {
  return apiGetMarket(`child_campaigns_shop_flash_sale`, param, null);
};

export const getTotalFramesTimeFlashSale = async (param: any) => {
  return apiGetMarket(`total_frames_time_shop_flash_sale`, param, null);
};

export const registerProductCampFlashSaleEmsoApi = async (data: any) => {
  return apiPostMarket(`flash_sales`, null, data);
};

export const getListSaleProductVariantsApi = async (params: any) => {
  return apiGetMarket(`list_sale_product_variants`, params, null);
};

export const updateFlashSaleEmsoApi = async (id, data) => {
  return apiPatchMarket(`flash_sales/${id}`, null, data);
};

export const deleteSaleProductVariantApi = async params => {
  return apiDeleteMarket(`destroy_sale_product_variant`, params, null);
};

export const updateSaleProductVariantApi = async params => {
  return apiPatchMarket(`update_sale_product_variant`, params, null);
};

export const getSaleProductVariantsShopFlashSaleApi = async params => {
  return apiGetMarket(`sale_product_variants_shop_flash_sale`, params, null);
};

export const getShopFlashSales = async params => {
  return apiGetMarket(`shop_flash_sales`, params, null);
};

export const getShopTransactionHistory = async (id,params) => {
  return apiGetMarket(`sellers/${id}/shop_revenue_logs`, params, null);
};

export const getShopBalance = async id => {
  return apiGetMarket(`sellers/${id}/shop_balance`, null, null);
};

export const getComplainReasonsApi = async (params: Object) => {
  return apiGetMarket(`complain_reasons`, params, null);
};

export const createOrderComplaintApi = async data => {
  return apiPostMarket(`complains`, null, data);
};

export const getDetailRefundRequestApi = async id => {
  return apiGetMarket(`refund_requests/${id}`, null, null);
};

export const approveOrRejectRefundRequestApi = async (
  id,
  status,
  params,
  data
) => {
  return apiPostMarket(`refund_requests/${id}/${status}`, params, data);
};

export const createCommentRefundApi = async data => {
  return apiPostMarket(`statuses`, null, data);
};

export const getCommentListApi = async (id, params) => {
  return apiGetMarket(`refund_requests/${id}/statuses`, params, null);
};

export const getRatingListApi = async (idPage, params) => {
  return apiGetMarket(`sellers/${idPage}/product_ratings`, params, null);
};

export const responseRatingApi = async (idPage, idRating, data) => {
  return apiPostMarket(
    `sellers/${idPage}/product_ratings/${idRating}/response_rating`,

    null,
    data
  );
};

export const getDetailPageMarketApi = async (pageId: any) => {
  return apiGetMarket(`pages/${pageId}`, null, null);
};

export const updateRefundRequestAdmin = (id, params) => {
  return fetchApiRequestAdmin(`refund_requests/${id}`, 'PATCH', params, null);
};

export const createSellerBrandApi = (pageId, data) => {
  return apiPostMarket(`sellers/${pageId}/brands`, null, data);
};
export const updateSellerBrandApi = (pageId, idBrand, data) => {
  return apiPatchMarket(`sellers/${pageId}/brands/${idBrand}`, null, data);
};
export const getListSellerBrandApi = (pageId, params) => {
  return apiGetMarket(`sellers/${pageId}/brands`, params, null);
};
export const getBrandDetailApi = (pageId, brandId) => {
  return apiGetMarket(`sellers/${pageId}/brands/${brandId}`, null, null);
};
export const deleteBranDetailApi = (pageId, brandId) => {
  return apiDeleteMarket(`sellers/${pageId}/brands/${brandId}`, null, null);
};
