import React from 'react';
import { makeStyles } from '@mui/styles';
import { buttonColor, scrollStyle } from 'src/constants/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClickOutHandler from 'react-onclickout';

import CommonNoti from 'src/Notification/commonNoti';
import { postReadNotiApi } from 'src/apis/socialNoti';
import { Theme, Typography, Box, Skeleton, Avatar, Grid } from '@mui/material';
import ButtonCustom from 'src/components/Button/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 75px)',
    borderRadius: 10,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    borderTop: '1px solid rgba(0,0,0,0.10)',
    width: 400,
    position: 'absolute',
    top: 50,
    right: 20,
    padding: '12px',
    overflowY: 'scroll',
    zIndex: '1000',
    ...scrollStyle
  },
  rootNoti: {
    display: 'flex',
    maxWidth: '100%',
    flexWrap: 'wrap'
  },
  listItem: {
    padding: '6px !important',
    alignItems: 'flex-start !important',
    '&:hover': {
      borderRadius: '10px !important'
    }
  },
  text: {
    fontSize: '15px',

    fontWeight: '500 !important'
  },
  subText: {
    color: '#65676b',
    fontSize: '13px !important'
  },
  avatar: {
    width: '28px !important',
    height: '28px !important',
    position: 'absolute',
    top: 33,
    right: 2
  }
}));

enum TypeNoti {
  all = 'all',
  emso = 'emso',
  market = 'market'
}

interface Props {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>> | any;
  fetchNotification?: any;
  notifications?: any;
  setNotifications?: any;
  hasMore?: boolean | any;
  newNotis?: any;
  oldNotis?: any;
  handleClosePreview?: any;
}

const NotificationHeader = (props: Props) => {
  const classes = useStyles();

  const {
    setOpen,
    fetchNotification,
    notifications,
    setNotifications,
    hasMore,
    newNotis,
    oldNotis,
    handleClosePreview
  } = props;
  const [typeNoti, setTypeNoti] = React.useState<TypeNoti>(TypeNoti.all);
  const loaderRef = React.useRef();
  const match: any = useRouteMatch();
  const handleReadNoti = async (id: any) => {
    let res = await postReadNotiApi(id);
    if (res.status === 200) {
      setOpen(false);
    }
  };
  const funcLoad = () => {
    let postLast = [...notifications].pop();
    let maxId = postLast?.id;
    fetchNotification({
      max_id: maxId,
      limit: 20,
      notification_type: typeNoti
    });
  };

  React.useEffect(() => {
    setNotifications([]);
    fetchNotification({ limit: 20, notification_type: typeNoti });
  }, [typeNoti]);

  React.useEffect(() => {
    if (loaderRef.current) {
      const element: any = loaderRef.current;
      if (element?.offsetWidth > 0 && element?.offsetHeight > 0) {
        funcLoad();
      }
    }
  }, []);

  const loaderSkeleton = () =>
    Array.from(Array(2).keys()).map(item => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
        ref={loaderRef}
      >
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <Skeleton width="100%" style={{ marginLeft: '12px' }}>
          <Typography>.</Typography>
        </Skeleton>
      </Box>
    ));
  const history = useHistory();
  const arrConditionNoti = [
    'follow',
    'friendship_request',
    'accept_friendship_request',
    'product_invitation',
    'status_donate',
    'donate',
    'music_donate',
    'rejected_page_identity_verification',
    'approved_page_identity_verification',
    'rejected_identity_verification',
    'approved_identity_verification',
    'approved_earn_money',
    'rejected_earn_money',
    'favourite',
    'order',
    'response_account_request',
    'approved_report_violation',
    'approved_report_course',
    'course_invitation',
    'response_reported_status',
    'approved_music',
    'rejected_music',
    'approved_playlist',
    'rejected_playlist',
    'cancel_order',
    'friend_birthday',
    'update_delivery_address',
    'accept_course_invitation',
    'verification_blood_donation',
    'accept_product_invitation',
    'approved_merchant_payment_request',
    'course_invitation_host'
  ];

  const arrNotificationMarket = (el: any): boolean => {
    //Hiển thị thông báo đã hoàn thiện từ phía BE. Không liên quan đến BA
    //!page_owner dành cho phía người mua. Thông báo đó page cũng sẽ nhận được nhưng sẽ có filed page_owner

    // for clear code
    // const type = el?.type
    // const order_status = el?.data_template?.order_status
    // const order_child_status = el?.data_template?.order_child_status

    if (el?.type === 'notification_template') return true;

    if (el?.type === 'update_order_status') {
      if (el?.data_template?.order_status === 'pending') return true;

      if (el?.data_template?.order_status === 'order_confirmed') return true;

      if (el?.data_template?.order_child_status === 'buyer_cancelled')
        return true;

      if (el?.data_template?.order_child_status === 'shop_cancelled')
        return true;

      if (el?.data_template?.order_child_status === 'emso_cancelled')
        return true;

      if (el?.data_template?.order_status === 'shipping') return true;

      if (el?.data_template?.order_status === 'delivery_failed') return true;

      if (el?.data_template?.order_status === 'finish') return true;
    }

    if (el?.type === 'status_refund_request') {
      if (el?.data_template?.refund_request_status === 'create_refund_request')
        return true;
      if (
        el?.data_template?.refund_request_status ===
        'new_comment_refund_request'
      )
        return true;
      if (el?.data_template?.refund_request_status === 'emso_approved_refund')
        return true;
      if (el?.data_template?.refund_request_status === 'emso_rejected_refund')
        return true;
    }

    if (el?.type === 'new_comment_refund_request') return true;

    if (el?.type === 'request_return_product') return true;

    if (el?.type === 'accepted_refund') return true;

    if (el?.type === 'emso_rejected_refund') return true;

    if (el?.type === 'emso_approved_refund') return true;

    if (el?.type === 'new_campaign') return true;

    if (el?.type === 'new_campaign') return true;

    if (el?.type === 'approved_custom_brand') return true;

    if (el?.type === 'rejected_custom_brand') return true;

    if (el?.type === 'history_finance') return true;

    if (el?.type === 'product_status') return true;

    return false;
  };

  const checkShowNotification = el => {
    if (
      el?.status ||
      el?.event ||
      el?.course ||
      el?.page ||
      el?.group ||
      el?.recruit ||
      el?.project ||
      el?.music ||
      el?.request_change_title_page ||
      arrConditionNoti.includes(el?.type) ||
      arrNotificationMarket(el) ||
      !!el?.family_member
    )
      return true;
  };
  return (
    <ClickOutHandler onClickOut={() => setOpen(false)}>
      <Box
        id="scrollableDivNotification"
        className={classes.root}
        sx={
          match.path === '/notifications'
            ? { display: 'none', backgroundColor: 'background.primary' }
            : { display: 'block', backgroundColor: 'background.primary' }
        }
      >
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 700,
            padding: '0px 8px 8px 8px'
          }}
        >
          Thông báo
        </Typography>
        <InfiniteScroll
          dataLength={notifications.length}
          next={funcLoad}
          hasMore={hasMore}
          loader={loaderSkeleton()}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDivNotification"
          scrollThreshold={0.8}
        >
          <div>
            <div className={classes.rootNoti}>
              <Grid container columnSpacing={1}>
                {Object.values(TypeNoti).map((type: TypeNoti) => {
                  return (
                    <Grid item>
                      <ButtonCustom
                        style={{
                          margin: '3px 5px',
                          borderRadius: '20px',
                          fontSize: '15px',
                          backgroundColor:
                            type === typeNoti
                              ? '#e7f3ff'
                              : 'background.secondary',
                          color:
                            type === typeNoti
                              ? buttonColor.backgroundColor
                              : 'text.primary'
                        }}
                        label={(() => {
                          switch (type) {
                            case TypeNoti.all:
                              return 'Tất cả';
                            case TypeNoti.emso:
                              return 'Thông báo EMSO';
                            case TypeNoti.market:
                              return 'Thông báo mua sắm';
                          }
                        })()}
                        action={() => setTypeNoti(type)}
                      ></ButtonCustom>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            {newNotis?.length > 0 && (
              <div>
                <div
                  className={classes.rootNoti}
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '12px'
                  }}
                >
                  <Typography style={{ fontWeight: 600 }}>Mới</Typography>
                  <ButtonCustom
                    style={{
                      margin: '3px 5px',
                      maxWidth: '100px',
                      borderRadius: '5px',
                      fontSize: '15px',
                      fontWeight: 'normal',
                      backgroundColor: 'transparent',
                      color: buttonColor.backgroundColor
                    }}
                    label="Xem tất cả"
                    action={() => {
                      handleClosePreview && handleClosePreview();
                      history.push('/notifications');
                    }}
                  ></ButtonCustom>
                </div>
                {newNotis?.map((el: any, index) =>
                  checkShowNotification(el) ||
                  (el?.type === 'account_relationship' &&
                    el?.account_relationship) ? (
                    <React.Fragment key={index}>
                      <CommonNoti
                        handleClosePreview={handleClosePreview}
                        item={el}
                        setOpen={() => handleReadNoti(el?.id)}
                      />
                    </React.Fragment>
                  ) : null
                )}
              </div>
            )}
            {oldNotis?.length > 0 && (
              <div>
                <div
                  className={classes.rootNoti}
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '12px'
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: buttonColor.backgroundColor
                    }}
                  >
                    Trước đó{' '}
                  </Typography>
                  {newNotis?.length === 0 && (
                    <ButtonCustom
                      style={{
                        margin: '3px 5px',
                        maxWidth: '100px',
                        borderRadius: '5px',
                        fontSize: '15px',
                        fontWeight: 'normal',
                        backgroundColor: 'transparent',
                        color: buttonColor.backgroundColor
                      }}
                      label="Xem tất cả"
                      action={() => history.push('/notifications')}
                    ></ButtonCustom>
                  )}
                </div>
                {oldNotis?.map((el: any, index) =>
                  checkShowNotification(el) ? (
                    <React.Fragment key={index}>
                      <CommonNoti
                        handleClosePreview={handleClosePreview}
                        item={el}
                        setOpen={() => handleReadNoti(el?.id)}
                      />
                    </React.Fragment>
                  ) : null
                )}
              </div>
            )}
          </div>
        </InfiniteScroll>
      </Box>
    </ClickOutHandler>
  );
};

export default NotificationHeader;
