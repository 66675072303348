import {
  Avatar,
  Button,
  Divider,
  ListItem,
  Skeleton,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getReactionById,
  reactionPostApi,
  unReactionPostApi
} from 'src/apis/socialPost.api';
import ReactEmoji from 'src/components/ReactEmoji/Index';
import EmojiIconShow from 'src/components/ReactEmojiItem/Index';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';

import _, { cloneDeep } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import logoUser from 'src/assets/images/user.jpg';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import CUPost from 'src/components/CUPost';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import DialogShareToGroup from 'src/components/Dialog/DialogShare/DialogShareToGroup';
import DialogShareToMessenger from 'src/components/Dialog/DialogShare/DialogShareToMessenger';
import ListButton from 'src/components/List/ListButton';
import { buttonColor } from 'src/constants/styles';
import {
  getListMyPageReq,
  roleEditPreviewDetailPage,
  roleEditPreviewDetailUser
} from 'src/store/action/socialPageAction';
import {
  reactionPost,
  updateReblogsCount
} from 'src/store/action/socialPostAction';
import { changeReaction } from 'src/store/action/socialWatchAction';
import { ResponseApi } from 'src/@types/api';
import Vector from 'src/assets/images/Vector.png';
import comment from 'src/assets/images/comment.png';
import share from 'src/assets/images/share.png';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 43,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  text: {
    fontSize: '15px !important',
    marginLeft: 5,
    fontWeight: '600 !important',
    color: theme.palette.text.secondary
  },
  button: {
    height: '80%',
    borderRadius: '5px',
    width: '100%',
    padding: 0,
    margin: '2px 1px 2px 1px',
    display: 'flex',
    alignItems: 'center'
  },
  boxLoader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    width: '100%',
    marginLeft: '-4px'
  }
}));

const setEmojiReactShow = (id: number, classes, type, post) => {
  switch (id) {
    case EnumEmojiReactType.like:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: buttonColor.backgroundColor, fontWeight: 'bold' }}
          >
            Thích
          </Typography>
        </div>
      );
    case EnumEmojiReactType.angry:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#e9710f', fontWeight: 'bold' }}
          >
            Phẫn nộ
          </Typography>
        </div>
      );
    case EnumEmojiReactType.love:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Tooltip
            title={
              type === 'previewMedia' && !post.in_reply_to_parent_id
                ? 'Yêu thích'
                : ''
            }
          >
            <Typography
              variant="subtitle1"
              style={{ color: '#f33e58', fontWeight: 'bold' }}
            >
              {type === 'previewMedia' || post.post_type === 'watch'
                ? 'Yêu th...'
                : 'Yêu thích'}
            </Typography>
          </Tooltip>
        </div>
      );
    case EnumEmojiReactType.haha:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#f7b125', fontWeight: 'bold' }}
          >
            Haha
          </Typography>
        </div>
      );
    case EnumEmojiReactType.sad:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#f7b125', fontWeight: 'bold' }}
          >
            Buồn
          </Typography>
        </div>
      );
    case EnumEmojiReactType.wow:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EmojiIconShow style={{ marginRight: 5 }} id={id} />
          <Typography
            variant="subtitle1"
            style={{ color: '#f7b125', fontWeight: 'bold' }}
          >
            Bất ngờ
          </Typography>
        </div>
      );
    case EnumEmojiReactType.yay:
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <EmojiIconShow
            style={{ marginRight: '5px', marginBottom: '3px' }}
            id={id}
          />
          <Tooltip title={type === 'previewMedia' ? 'Tự hào' : ''}>
            <Typography
              variant="subtitle1"
              style={{ color: '#f7b125', fontWeight: 'bold' }}
            >
              {(type === 'previewMedia' && !post.in_reply_to_parent_id) ||
              post.post_type === 'watch'
                ? 'Tự hào'
                : 'Tự hào'}
            </Typography>
          </Tooltip>
        </div>
      );
    default:
      return (
        <>
          {/* <EmojiIconShow style={{ marginRight: 5 }} id={id} /> */}
          <img
            src={Vector}
            alt="image"
            style={{
              width: '20px',
              objectFit: 'contain'
            }}
          />
          <Typography sx={{ marginLeft: '10px' }} className={classes.text}>
            Thích
          </Typography>
        </>
      );
  }
};

interface PostActionButton {
  post?: any;
  type?: String;
  setShowComment?: React.Dispatch<React.SetStateAction<any>> | any;
  setReactionCount?: React.Dispatch<React.SetStateAction<any>> | any;
  setPostData?: React.Dispatch<React.SetStateAction<any>> | any;
  setMediaData?: React.Dispatch<React.SetStateAction<any>> | any;
  handleGetComment?: any;
  listComment?: any;
  setListComment?: React.Dispatch<React.SetStateAction<any>> | any;
  setFocus?: React.Dispatch<React.SetStateAction<any>> | any;
  roleInteract?: RolePreviewEdit;
  listReaction?: any;
  setListReaction?: React.Dispatch<React.SetStateAction<any>> | any;
  setMeReaction?: React.Dispatch<React.SetStateAction<any>> | any;
  setButtonSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  typeLive?: String | any;
  setChooseFocus?: React.Dispatch<React.SetStateAction<any>> | any;
  typePost?: String | any;
  pageInfo?: any;
  setHasMore?: any;
}

const PostActionButton = React.memo(
  forwardRef((props: PostActionButton, ref: any) => {
    const classes = useStyles();
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const {
      post,
      type,
      setShowComment,
      setReactionCount,
      setPostData,
      setMediaData,
      handleGetComment,
      listComment,
      setListComment,
      setFocus,
      roleInteract,
      listReaction,
      setListReaction,
      setMeReaction,
      setButtonSelected,
      typeLive,
      setChooseFocus,
      typePost,
      setHasMore,
      pageInfo
    } = props;

    const history = useHistory();
    const [emojiReactId, setEmojiReactId] = useState(-1);
    const emojiReactShow = setEmojiReactShow(emojiReactId, classes, type, post);
    const [openCUPost, setOpenCUPost] = useState(false);
    const [sharePostNow, setSharePostNow] = useState(false);
    const [sharePostInNewFeed, setSharePostInNewFeed] = useState(false);
    const [openDialogShareToGroup, setOpenDialogShareToGroup] = useState(false);
    const [openDialogShareToMessenger, setOpenDialogShareToMessenger] =
      useState(false);
    const [shareTo, setShareTo] = useState('');
    const [openDialogMoreRole, setOpenDialogMoreRole] = useState(false);
    const [closePopper, setClosePopper] = useState(false);
    const postWatchSelected = useSelector(
      (state: any) => state.watchReducer.postSelected
    );
    const eventInfo = useSelector((state: any) => state.eventReducer.info);
    const growInfo = useSelector((state: any) => state.growReducer.info);

    const groupSelected = useSelector(
      (state: any) => state.groupReducer.groupSelected
    );
    const meInfo = useSelector((state: any) => state.meReducer.info);
    const [idReaction, setIdReaction] = React.useState<number | null>(null);
    const hasMore = useSelector((state: any) => state.pageReducer.hasMore);

    React.useEffect(() => {
      let reaction;
      switch (idReaction) {
        case -1:
          reaction = undefined;
          break;
        case 0:
          reaction = 'like';
          break;
        case 1:
          reaction = 'love';
          break;
        case 3:
          reaction = 'yay';
          break;
        case 2:
          reaction = 'haha';
          break;
        case 4:
          reaction = 'wow';
          break;
        case 5:
          reaction = 'sad';
          break;
        case 6:
          reaction = 'angry';
          break;
      }
      if (typeof idReaction === 'number') {
        dispatch(
          changeReaction({
            reaction: reaction,
            postSelected: post,
            listReaction: listReaction
          })
        );
      }
    }, [JSON.stringify(listReaction)]);

    React.useEffect(() => {
      //get react of page when open dialog preview post by role page (<DialogPreviewPost/>)
      if (roleInteract?.page_owner_id && type === 'previewMedia')
        handleGetReactionById(roleInteract?.page_owner_id, post?.id);
    }, [type]);

    React.useEffect(() => {
      let idReactEmojiStr = !post.viewer_reaction
        ? 'default'
        : post.viewer_reaction;
      let idReactEmoji = parseInt(EnumEmojiReactType[idReactEmojiStr]);
      setEmojiReactId(idReactEmoji);
    }, [post.viewer_reaction]);

    const debounceClickPost = useCallback(
      _.debounce((idReaction, post = null) => {
        handleCallAPIClickVote(idReaction, roleInteract?.page_owner_id, post);
      }, 300),
      [roleInteract?.page_owner_id]
    );
    const renderAvatar = (info: any) => {
      if (info?.avatar_media) {
        return info?.avatar_media.show_url
          ? info?.avatar_media.show_url
          : info?.avatar_media.preview_url;
      } else {
        return meInfo?.avatar_static;
      }
    };

    const checkVisibility = () => {
      //Không hiện role đối với các trường hợp:

      // TH bài viết bình thường có quyền riêng tư khác công khai
      if (post?.visibility !== 'public') return false;

      // TH bài viết trong group riêng tư
      if (post?.group?.is_private) return false;

      // TH bài viết trong nhóm có quyền riêng tư khác công khai
      if (post?.event && post?.event?.visibility !== 'public') return false;

      return true;
    };

    const emojiReactionList = [
      'like',
      'love',
      'haha',
      'yay',
      'wow',
      'sad',
      'angry'
    ];
    const pageList =
      useSelector((state: any) => state.pageReducer.my_pages)?.map(
        (el: any) => ({
          id: el.id,
          label: el.title,
          avatar_icon: renderAvatar(el),
          checkbox: true,
          action: () => {
            handleGetReactionById(el?.id, post?.id);
            dispatch(
              roleEditPreviewDetailPage({
                role:
                  el?.id === pageInfo?.id
                    ? pageInfo?.page_relationship?.role
                    : '',
                page_owner_id: el?.id,
                avatar_media: el?.avatar_media,
                title: el?.title
              })
            );
            setOpenDialogMoreRole(false);
          }
        })
      ) || [];

    const info_page_target = useSelector(
      (state: any) => state.pageReducer.info_page_target
    );

    const set_avatar = (): string => {
      const find_page_from_my_pages = pageList?.find(
        (el: any) => el.id === roleInteract?.page_owner_id
      );

      //Lựa chọn từ my_pages
      if (roleInteract?.page_owner_id && find_page_from_my_pages?.avatar_icon)
        return find_page_from_my_pages?.avatar_icon;

      //Lựa chọn từ info page target
      if (
        roleInteract?.page_owner_id &&
        info_page_target.id === roleInteract?.page_owner_id
      )
        return (
          info_page_target?.avatar_media?.show_url ??
          info_page_target?.avatar_media?.preview_url ??
          info_page_target?.avatar_media?.url
        );

      //Lựa chọn từ me
      if (meInfo?.avatar_media)
        return (
          meInfo?.avatar_media?.show_url ??
          meInfo?.avatar_media?.preview_url ??
          meInfo?.avatar_media?.url
        );

      return logoUser;
    };

    const listOptionRole = [
      {
        ...meInfo,
        avatar_icon:
          meInfo?.avatar_media?.preview_url ??
          meInfo?.avatar_media?.url ??
          logoUser,
        checkbox: true,
        action: () => {
          handleGetReactionById(null, post?.id);
          dispatch(
            roleEditPreviewDetailUser({
              role: '',
              page_owner_id: null,
              avatar_media: meInfo?.avatar_media,
              title: null
            })
          );
          setOpenDialogMoreRole(false);
        }
      },
      ...pageList
    ];

    let optionsShare: any = [
      [
        {
          id: 0,
          key: 'share_now',
          label: 'Chia sẻ ngay',
          icon: 'fal fa-share',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setSharePostNow(true);
          }
        },
        {
          id: 1,
          key: 'share',
          label: 'Chia sẻ lên bảng tin',
          icon: 'fal fa-edit',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setSharePostInNewFeed(true);
            setOpenCUPost(true);
          }
        },
        !roleInteract?.page_owner_id && {
          id: 2,
          key: 'sendMessenger',
          label: 'Gửi bằng Emso chat',
          icon: 'fal fa-comment',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setOpenDialogShareToMessenger(true);
          }
        },
        !roleInteract?.page_owner_id && {
          id: 3,
          key: 'shareToGroup',
          label: 'Chia sẻ lên cộng đồng',
          icon: 'fal fa-users',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setShareTo('group');
            setOpenDialogShareToGroup(true);
          }
        },
        !roleInteract?.page_owner_id && {
          id: 4,
          key: 'shareToPage',
          label: 'Chia sẻ lên Trang',
          icon: 'fa-light fa-flag',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setOpenDialogShareToGroup(true);
            setShareTo('page');
          }
        },
        !roleInteract?.page_owner_id && {
          id: 5,
          key: 'shareToFriend',
          label: 'Chia sẻ lên trang cá nhân của bạn bè',
          icon: 'fal fa-user-friends',
          styleIcon: { fontSize: '20px' },
          action: () => {
            setShareTo('friend');
            setOpenDialogShareToGroup(true);
          }
        }
      ]
    ];
    const hiddenButtonShare = ['pinned', 'rating'];
    const increment_reblog_count = () => {
      //increment count for detail post. Data from API and save in useState
      const new_data = { ...post, reblogs_count: post.reblogs_count + 1 };
      //Phân hệ bên hastag dùng local state
      if (setPostData) setPostData(new_data);
      //Phân hệ moment dùng global state
      else dispatch(updateReblogsCount(post));
    };

    let listActionPost = [
      {
        key: 'comment',
        label: 'Bình luận',
        icon: comment,
        action: () => {
          ref.current?.firstChild.focus();
          if (typePost === 'pinned') {
            history.push(
              `/${post?.account?.username ?? post?.account?.id}/posts/${
                post.id
              }`
            );
          } else {
            if (
              match.path.includes('watch') &&
              type !== 'watch-selected' &&
              postWatchSelected.id === post.id
            ) {
              setFocus(true);
              setChooseFocus && setChooseFocus(true);
              return;
            }
            if (!listComment.length) {
              handleGetComment(
                post.id,
                {
                  sort_by: 'newest'
                },
                setListComment,
                setHasMore
              );
            }
            if (typeLive) {
              setButtonSelected('chat');
            }
            setShowComment(true);
            setFocus(true);
            ref?.current?.focus();
          }
        },
        visible: post?.off_comment || !!groupSelected.inactivate_reason
      },
      {
        key: 'share',
        label: 'Chia sẻ',
        icon: share,
        options: optionsShare,
        action: id => {
          // if (roleInteract.role === 'admin') {
          //   setOpenDialogShareToGroup(true);
          // }
        },
        visible:
          !!groupSelected.inactivate_reason ||
          hiddenButtonShare.includes(typePost) ||
          (eventInfo?.visibility !== 'public' && eventInfo?.visibility) ||
          (Object.keys(growInfo).length &&
            growInfo?.status &&
            growInfo?.status !== 'approved') ||
          post?.group?.is_private ||
          (meInfo?.id === post?.account?.id &&
            post?.visibility === 'private' &&
            !post?.reblog)
            ? true
            : false
      },
      {
        key: 'send',
        label: 'Gửi',
        icon: 'fa-regular fa-paper-plane',
        action: () => {
          setOpenDialogShareToMessenger(true);
        },
        visible:
          (typePost === 'pinned' ? true : false) ||
          (post?.group?.is_private ? false : true)
      }
    ];

    const handleChangeReaction = (idReaction, viewerReaction) => {
      setListReaction(prev =>
        prev.map(item =>
          viewerReaction &&
          post?.viewer_reaction === viewerReaction &&
          item.type === viewerReaction
            ? {
                ...item,
                [`${viewerReaction}s_count`]:
                  item[`${viewerReaction}s_count`] - 1
              }
            : item.type === idReaction
            ? {
                ...item,
                [`${idReaction}s_count`]: item[`${idReaction}s_count`] + 1
              }
            : item
        )
      );
      setPostData(prev => {
        return {
          ...prev,
          reactions: prev.reactions.map(item =>
            viewerReaction &&
            post?.viewer_reaction === viewerReaction &&
            item.type === viewerReaction
              ? {
                  ...item,
                  [`${viewerReaction}s_count`]:
                    item[`${viewerReaction}s_count`] - 1
                }
              : item.type === idReaction
              ? {
                  ...item,
                  [`${idReaction}s_count`]: item[`${idReaction}s_count`] + 1
                }
              : item
          ),
          viewer_reaction: idReaction
        };
      });

      setMediaData &&
        setMediaData(prev => {
          if (!Array.isArray(prev)) {
            return {
              ...prev,
              reactions: prev.reactions?.map(item =>
                viewerReaction &&
                post?.viewer_reaction === viewerReaction &&
                item.type === viewerReaction
                  ? {
                      ...item,
                      [`${viewerReaction}s_count`]:
                        item[`${viewerReaction}s_count`] - 1
                    }
                  : item.type === idReaction
                  ? {
                      ...item,
                      [`${idReaction}s_count`]: item[`${idReaction}s_count`] + 1
                    }
                  : item
              ),
              viewer_reaction: idReaction
            };
          }

          return prev;
        });
    };

    const handleUnfavourte = idReaction => {
      setListReaction(prev =>
        prev.map(item =>
          item.type === idReaction
            ? {
                ...item,
                [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
              }
            : item
        )
      );
      setPostData(prev => ({
        ...prev,
        reactions: prev?.reactions?.map(item =>
          item.type === idReaction
            ? {
                ...item,
                [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
              }
            : item
        ),
        favourites: prev?.favourites?.filter(
          el => el.account?.id !== meInfo?.id
        ),
        viewer_reaction: null
      }));
      dispatch(
        reactionPost(
          post.id,
          post?.favourites?.filter(el => el.account?.id !== meInfo?.id),
          null,
          post?.reactions?.map(item =>
            item.type === idReaction
              ? {
                  ...item,
                  [`${idReaction}s_count`]: item[`${idReaction}s_count`] - 1
                }
              : item
          )
        )
      );
    };

    const handleChangeReactionAfterCallAPI = response => {
      if (post.post_type === 'moment') {
        setPostData && setPostData(prev => ({ ...prev, ...response }));
        dispatch(
          reactionPost(
            post.id,
            response.favourites,
            response.viewer_reaction,
            response.reactions
          )
        );
      } else {
        setListReaction(response.reactions);
        setPostData(prev => ({ ...prev, response }));
        setMeReaction(response.favourites);
        dispatch(
          reactionPost(
            post.id,
            response.favourites,
            response.viewer_reaction,
            response.reactions
          )
        );
      }
    };

    const handleCallAPIClickVote = async (
      idReaction: number,
      idPage: string | null | undefined, //string cho có page, null cho cá nhân(không page), undefiend cho obj không được truyền vào
      postPayload
    ) => {
      let response;
      let data = {
        custom_vote_type: EnumEmojiReactType[idReaction],
        page_id: idPage
      };

      // Nếu không dùng local state để set thay đổi reaction luôn thì khi gọi global state sẽ không cần đợi api
      // Phải truyển thêm postPayload nếu không khi reaction sau đó bỏ reaction thì count không đúng
      if (!setPostData && postPayload) {
        let reactions = cloneDeep(postPayload.reactions);
        let reaction_love = reactions.find(el => el.type == 'love');
        if (idReaction < 0) {
          unReactionPostApi(postPayload.id, { page_id: idPage });
          reaction_love.loves_count -= 1;
          dispatch(reactionPost(postPayload.id, [], null, reactions));
        } else {
          reaction_love.loves_count += 1;
          dispatch(reactionPost(postPayload.id, [], idReaction, reactions));
          reactionPostApi(postPayload.id, data);
        }
      } else {
        if (idReaction < 0) {
          response = await unReactionPostApi(post.id, { page_id: idPage });
          if (response.status === 200) {
            if (post.post_type === 'moment') {
              dispatch(
                reactionPost(
                  post.id,
                  response.data.favourites,
                  response.data.viewer_reaction,
                  response.data.reactions
                )
              );
            }
          }
        } else {
          response = await reactionPostApi(post.id, data);
          if (response.status === 200) {
            handleChangeReactionAfterCallAPI(response.data);
          }
        }
      }
    };

    const handleGetReactionById = async (idPage: any, idPost: string) => {
      let data = {
        page_id: idPage
      };
      try {
        let response: ResponseApi = await getReactionById(idPost, data);
        if (response.status === 200) {
          let custom_vote_type: string = response.data?.custom_vote_type;
          setEmojiReactId(
            emojiReactionList.findIndex(item => item === custom_vote_type)
          );
        }
      } catch (err: any) {
        if (err.response.status === 500) {
          setEmojiReactId(-1);
        }
      }
    };

    const handleClickPost = async (idReaction: number) => {
      setIdReaction(idReaction);
      if (emojiReactId < 0) {
        setEmojiReactId(idReaction);
        setReactionCount(prev => prev + 1);
      } else if (idReaction === emojiReactId) {
        setEmojiReactId(idReaction);
      } else {
        setEmojiReactId(idReaction);
        if (idReaction < 0) {
          setReactionCount(prev => prev - 1);
        }
      }
      switch (idReaction) {
        case 0:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'love':
                handleChangeReaction('like', 'love');
                break;
              case 'haha':
                handleChangeReaction('like', 'haha');
                break;
              case 'yay':
                handleChangeReaction('like', 'yay');
                break;
              case 'wow':
                handleChangeReaction('like', 'wow');
                break;
              case 'sad':
                handleChangeReaction('like', 'sad');
                break;
              case 'angry':
                handleChangeReaction('like', 'angry');
                break;
            }
          } else {
            handleChangeReaction('like', '');
          }
          break;
        case 1:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('love', 'like');
                break;
              case 'haha':
                handleChangeReaction('love', 'haha');
                break;
              case 'yay':
                handleChangeReaction('love', 'yay');
                break;
              case 'wow':
                handleChangeReaction('love', 'wow');
                break;
              case 'sad':
                handleChangeReaction('love', 'sad');
                break;
              case 'angry':
                handleChangeReaction('love', 'angry');
                break;
            }
          } else {
            handleChangeReaction('love', '');
          }
          break;
        case 2:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('haha', 'like');
                break;
              case 'love':
                handleChangeReaction('haha', 'love');
                break;
              case 'yay':
                handleChangeReaction('haha', 'yay');
                break;
              case 'wow':
                handleChangeReaction('haha', 'wow');
                break;
              case 'sad':
                handleChangeReaction('haha', 'sad');
                break;
              case 'angry':
                handleChangeReaction('haha', 'angry');
                break;
            }
          } else {
            handleChangeReaction('haha', '');
          }
          break;
        case 3:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('yay', 'like');
                break;
              case 'love':
                handleChangeReaction('yay', 'love');
                break;
              case 'haha':
                handleChangeReaction('yay', 'haha');
                break;
              case 'wow':
                handleChangeReaction('yay', 'wow');
                break;
              case 'sad':
                handleChangeReaction('yay', 'sad');
                break;
              case 'angry':
                handleChangeReaction('yay', 'angry');
                break;
            }
          } else {
            handleChangeReaction('yay', '');
          }
          break;
        case 4:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('wow', 'like');
                break;
              case 'love':
                handleChangeReaction('wow', 'love');
                break;
              case 'haha':
                handleChangeReaction('wow', 'haha');
                break;
              case 'yay':
                handleChangeReaction('wow', 'yay');
                break;
              case 'sad':
                handleChangeReaction('wow', 'sad');
                break;
              case 'angry':
                handleChangeReaction('wow', 'angry');
                break;
            }
          } else {
            handleChangeReaction('wow', '');
          }
          break;
        case 5:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('sad', 'like');
                break;
              case 'love':
                handleChangeReaction('sad', 'love');
                break;
              case 'haha':
                handleChangeReaction('sad', 'haha');
                break;
              case 'yay':
                handleChangeReaction('sad', 'yay');
                break;
              case 'wow':
                handleChangeReaction('sad', 'wow');
                break;
              case 'angry':
                handleChangeReaction('sad', 'angry');
                break;
            }
          } else {
            handleChangeReaction('sad', '');
          }
          break;
        case 6:
          if (post?.viewer_reaction) {
            switch (post?.viewer_reaction) {
              case 'like':
                handleChangeReaction('angry', 'like');
                break;
              case 'love':
                handleChangeReaction('angry', 'love');
                break;
              case 'haha':
                handleChangeReaction('angry', 'haha');
                break;
              case 'yay':
                handleChangeReaction('angry', 'yay');
                break;
              case 'wow':
                handleChangeReaction('angry', 'wow');
                break;
              case 'sad':
                handleChangeReaction('angry', 'sad');
                break;
            }
          } else {
            handleChangeReaction('angry', '');
          }
          break;
        case -1:
          switch (post?.viewer_reaction) {
            case 'like':
              handleUnfavourte('like');
              break;
            case 'love':
              handleUnfavourte('love');
              break;
            case 'haha':
              handleUnfavourte('haha');
              break;
            case 'yay':
              handleUnfavourte('yay');
              break;
            case 'wow':
              handleUnfavourte('wow');
              break;
            case 'sad':
              handleUnfavourte('sad');
              break;
            case 'angry':
              handleUnfavourte('angry');
              break;
          }
          break;
      }
      debounceClickPost(idReaction);
    };

    const handleReactPost = async (idReaction: number, post: any = null) => {
      debounceClickPost(idReaction, post);
      setPostData &&
        setPostData(prev => ({
          ...prev,
          reactions: [
            ...post.reactions.slice(0, 3),

            {
              type: 'love',
              loves_count: post?.viewer_reaction
                ? post?.reactions?.[3]?.loves_count - 1
                : post?.reactions?.[3]?.loves_count + 1
            },
            ...post.reactions.slice(4)
          ],
          viewer_reaction: post?.viewer_reaction ? null : 'love'
        }));
    };
    const loadActivity = maxId => {
      dispatch(
        getListMyPageReq({
          max_id: maxId,
          limit: 10
        })
      );
    };

    const funcLoad = () => {
      let pageLast = [...pageList].pop();
      let maxId = pageLast?.id;
      loadActivity(maxId);
    };

    return (
      <div>
        {post.card ||
        post.media_attachments?.length ||
        post.replies_total ||
        post.reblogs_count ||
        post.reblog ||
        post.card ||
        post.status_background ||
        post.place ? null : (
          <Divider />
        )}

        <div
          style={match.path.includes('watch') ? { width: 380 } : {}}
          className={classes.root}
          id={`post-${post?.id}`}
        >
          {post.post_type === 'moment' ? (
            <Button
              key="love"
              className={classes.button}
              startIcon={
                post?.viewer_reaction ? (
                  <i
                    style={{ color: 'rgb(254, 44, 85)' }}
                    className="fa-solid fa-heart"
                  ></i>
                ) : (
                  <i className="fa-light fa-heart"></i>
                )
              }
              color="inherit"
              style={{ textTransform: 'none' }}
              onClick={() => {
                if (post?.viewer_reaction) {
                  handleReactPost(EnumEmojiReactType.default, post);
                } else {
                  handleReactPost(EnumEmojiReactType.love, post);
                }
              }}
            >
              <Typography
                style={
                  post?.viewer_reaction ? { color: 'rgb(254, 44, 85)' } : {}
                }
                className={classes.text}
              >
                Thích
              </Typography>
            </Button>
          ) : groupSelected.inactivate_reason ? null : (
            <ReactEmoji
              onChange={id => {
                handleClickPost(id);
              }}
              closePopper={closePopper}
            >
              {(popupState, bindHover, clearOpen) => {
                // setClosePopper(false);
                return (
                  <Button
                    {...bindHover(popupState)}
                    className={classes.button}
                    aria-label="add an alarm"
                    sx={{
                      textTransform: 'none',
                      padding: '5px 0px 5px 0px',
                      width: post.in_reply_to_parent_id ? '100%' : 'undefined',
                      margin: '2px 0px 0px',
                      color: 'text.secondary',
                      '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'button.secondary.background'
                      }
                    }}
                    onClick={() => {
                      clearOpen();
                      setClosePopper(true);
                      if (emojiReactId < 0) {
                        handleClickPost(EnumEmojiReactType.like);
                      } else {
                        handleClickPost(EnumEmojiReactType.default);
                      }
                    }}
                  >
                    {emojiReactShow}
                  </Button>
                );
              }}
            </ReactEmoji>
          )}
          {post.in_reply_to_parent_id
            ? null
            : listActionPost
                .filter((item: any) => item?.visible !== true)
                ?.map((item: any) => {
                  return (
                    <div
                      style={{ width: '100%', height: '80%' }}
                      key={item?.key}
                    >
                      <IconButtonOptions
                        startIcon={item.icon}
                        name={item.label}
                        startIconStyle={{
                          marginRight: '6px',
                          // color: 'rgb(101, 103, 107)',
                          color: 'text.secondary',
                          fontSize: '18px',
                          height: '20px',
                          width: '20px'
                        }}
                        style={{
                          textTransform: 'none',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'button.primary.secondary'
                          },
                          width: '100%',
                          margin: 0,
                          flexWrap: 'nowrap',
                          padding: '6px'
                        }}
                        styleNameButton={{
                          color: 'text.secondary',
                          display: 'inline',
                          fontSize: 15
                        }}
                        styleListMenu={{
                          maxWidth: '320px',
                          padding: '6px 0px'
                        }}
                        stylePopup={{ padding: '0px', zIndex: 1500 }}
                        options={item.options ? item.options : null}
                        openPopup={item.options ? true : false}
                        disablePortal={false}
                        action={() => item.action(post.id)}
                      />
                    </div>
                  );
                })}
          {typePost !== 'pinned' && checkVisibility() && (
            <IconButtonOptions
              icon={
                <>
                  <Avatar
                    sx={{
                      marginRight: '3px',
                      height: '25px',
                      width: '25px'
                    }}
                    src={set_avatar()}
                  ></Avatar>
                  <i
                    style={{ fontSize: 14 }}
                    className="fa-solid fa-caret-down"
                    aria-hidden="true"
                  ></i>
                </>
              }
              options={[
                [
                  {
                    id: 'interact',
                    title: 'Chọn cách tương tác',
                    description:
                      'Bình luận và bày tỏ cảm xúc dưới tên trang cá nhân hoặc Trang của bạn.'
                  }
                ],
                [
                  {
                    id: meInfo?.id,
                    label: meInfo?.display_name,
                    avatar_icon:
                      meInfo?.avatar_media?.preview_url ??
                      meInfo?.avatar_media?.url ??
                      meInfo?.avatar_media?.show_url ??
                      logoUser,
                    checkbox: true,
                    action: () => {
                      handleGetReactionById(null, post?.id);
                      dispatch(
                        roleEditPreviewDetailUser({
                          role: '',
                          page_owner_id: null,
                          avatar_media: meInfo?.avatar_media,
                          title: null
                        })
                      );
                    }
                  }
                ]?.concat(
                  pageList
                    ?.filter((el: any, index: any) => index < 3)
                    ?.concat(
                      pageList?.length > 3
                        ? [
                            {
                              id: 'more',
                              label: 'Xem thêm',
                              styleLabel: { padding: '0px 5px' },
                              endIcon: 'fa-light fa-ellipsis',
                              styleEndIcon: { fontWeight: 600 },
                              action: () => setOpenDialogMoreRole(true)
                            }
                          ]
                        : []
                    )
                )
              ]}
              openPopup={true}
              styleListMenu={{ maxWidth: '298px' }}
              style={{
                // zoom: match.path.includes('watch') ? '118%' : '100%',
                width: '60px',
                backgroundColor: 'transparent',
                margin: 0,
                '&:hover': {
                  backgroundColor: 'button.primary.secondary'
                },
                padding: '6px'
              }}
              stylePopup={{ padding: '0px', zIndex: 1500 }}
              optionCheckDefault={meInfo?.id}
              pageChecked={roleInteract}
              pageCurrentId={pageInfo?.id}
              disablePortal={false}
            />
          )}
        </div>

        <CUPost
          type={type}
          post={post}
          openCUPost={openCUPost}
          setOpenCUPost={setOpenCUPost}
          shareNow={sharePostNow}
          setSharePostNow={setSharePostNow}
          sharePostInNewFeed={sharePostInNewFeed}
          setSharePostInNewFeed={setSharePostInNewFeed}
          increment_reblog_count={increment_reblog_count}
        />
        {openDialogShareToGroup && (
          <DialogShareToGroup
            shareTo={shareTo}
            post={post}
            open={openDialogShareToGroup}
            handleClose={() => {
              setOpenDialogShareToGroup(false);
            }}
            pageInfo={pageInfo}
            increment_reblog_count={increment_reblog_count}
            isSharePost={true}
          />
        )}

        {openDialogShareToMessenger && (
          <DialogShareToMessenger
            open={openDialogShareToMessenger}
            handleClose={() => setOpenDialogShareToMessenger(false)}
            post={post}
            meInfo={meInfo}
            type={type}
            page={pageInfo}
          />
        )}
        {openDialogMoreRole && (
          <DialogConfirmDelete
            open={openDialogMoreRole}
            handleClose={() => setOpenDialogMoreRole(false)}
            title="Cách tương tác"
            text="Bình luận và bày tỏ cảm xúc dưới tên trang cá nhân hoặc Trang của bạn."
            alignText="center"
            contentDialog={
              <>
                <InfiniteScroll
                  dataLength={listOptionRole?.length}
                  next={funcLoad}
                  hasMore={hasMore}
                  scrollThreshold={0.8}
                  loader={
                    <div className={classes.boxLoader}>
                      <ListItem>
                        <Skeleton
                          variant="circular"
                          animation="wave"
                          width="34.4px"
                          height="34.4px"
                          sx={{ marginRight: 2 }}
                        />
                        <Skeleton
                          animation="wave"
                          width={'70%'}
                          variant="text"
                          sx={{ fontSize: '1.2rem', marginTop: 1 }}
                        />
                      </ListItem>
                    </div>
                  }
                  style={{ overflow: 'hidden' }}
                  scrollableTarget="scroll-dialog-content"
                >
                  <ListButton
                    item={listOptionRole?.map((el: any) => ({
                      ...el,
                      label: null,
                      title: el?.label ?? el?.display_name,
                      checkbox: false
                    }))}
                    selectedItem={
                      roleInteract?.page_owner_id
                        ? roleInteract?.page_owner_id
                        : meInfo?.id
                    }
                    checkedIcon={true}
                    typeAction={'action'}
                    line={1}
                    styleTitle={{
                      display: 'block',
                      textOverflow: 'ellipsis'
                    }}
                    styleListItemText={{
                      paddingRight: '40px'
                    }}
                  />
                </InfiniteScroll>
              </>
            }
            styleDialogContent={{ height: '560px' }}
            hiddenButton={true}
          />
        )}
      </div>
    );
  })
);

export default PostActionButton;
