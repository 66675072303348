import { constant } from 'lodash';

export const SELECT_USER_CHAT = 'SELECT_USER_CHAT';
export const REMOVE_USER_CHAT = 'REMOVE_USER_CHAT';
export const HIDDEN_USER_CHAT = 'HIDDEN_USER_CHAT';
export const GET_LIST_MESSAGE_REQ = 'GET_LIST_MESSAGE_REQ';
export const GET_LIST_MESSAGE_SUCCESS = 'GET_LIST_MESSAGE_SUCCESS';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const RECEIVE_MESSAGE_SUCCESS = 'RECEIVE_MESSAGE_SUCCESS';
export const RECEIVE_ACTION_BOX_SUCCESS = 'RECEIVE_ACTION_BOX_SUCCESS';
export const LOAD_MESSAGE_SUCCESS = 'LOAD_MESSAGE_SUCCESS';
export const GET_LIST_CONVERSATION_IDS = 'GET_LIST_CONVERSATION_IDS';
export const GET_LIST_CONVERSATION_SUCCESS = 'GET_LIST_CONVERSATION_SUCCESS';
export const OPEN_BOX_CREATE = 'OPEN_BOX_CREATE';
export const CLOSE_BOX_CREATE = 'CLOSE_BOX_CREATE';
export const CREATE_GROUP_CHAT_WITH = 'CREATE_GROUP_CHAT_WITH';
export const EVENT_NEW_MESSAGE = 'EVENT_NEW_MESSAGE';

export const EVENT_NEW_SUBSCRIPTION = 'EVENT_NEW_SUBSCRIPTION';
export const EVENT_UPDATE_SUBSCRIPTION = 'EVENT_UPDATE_SUBSCRIPTION';
export const EVENT_ROOM_CHANGE = 'EVENT_ROOM_CHANGE'
export const SUB_ROOM_SUCCESS = 'SUB_ROOM_SUCCESS';
export const EVENT_DELETE_MESSAGE = 'EVENT_DELETE_MESSAGE';
export const SETTING_CHAT = 'SETTING_CHAT';
export const CHANGE_EMOTICON = 'CHANGE_EMOTICON';
export const CHANGE_TOPIC = 'CHANGE_TOPIC';
export const CHANGE_ROLES_ADD_MEMBER_GROUP = 'CHANGE_ROLES_ADD_MEMBER_GROUP';

export const IS_BLOCKER = 'IS_BLOCKER';
export const IS_BLOCKED = 'IS_BLOCKED';

export const GET_LIST_CONVERSATION_REQ = 'GET_LIST_CONVERSATION_REQ';
export const GET_INFO_ME_CHAT_SUCCESS = 'GET_INFO_ME_CHAT_SUCCESS';
export const FOCUS_CONVERSATION = 'FOCUS_CONVERSATION';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export const LEAVE_CONVERSATION = 'LEAVE_CONVERSATION';
export const GET_MESSAGE_REQ = 'GET_MESSAGE_REQ';
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const RESET_LIST_POPUP_CHAT = 'RESET_LIST_POPUP_CHAT';

export const UPDATE_ADD_MEMBERS_GROUP = 'UPDATE_ADD_MEMBERS_GROUP';
export const GET_CREATE_VOTE_MESSAGE = 'GET_CREATE_VOTE_MESSAGE';

export const PINNED_CONVERSATION = 'PINNED_CONVERSATION';
export const SOUND_NOTIFICATION = 'SOUND_NOTIFICATION';
export const DISABLE_NOTIFICATION = 'DISABLE_NOTIFICATION';
export const USER_STATUS_REAL_TIME = 'USER_STATUS_REAL_TIME';
export const READ_UNREAD_MESSAGE = 'READ_UNREAD_MESSAGE';
export const READ_UNREAD_MESSAGE_PAGE = 'READ_UNREAD_MESSAGE_PAGE';
export const DELETE_QUEUE_POPUP_CHAT = 'DELETE_QUEUE_POPUP_CHAT';
export const CHANGE_NAME_POPUP_CHAT = 'CHANGE_NAME_POPUP_CHAT';
export const CHANGE_AVATAR_POPUP_CHAT = 'CHANGE_AVATAR_POPUP_CHAT';
export const CHANGE_ROLES_GROUP_CHAT = 'CHANGE_ROLES_GROUP_CHAT';
export const GET_LIST_CONVERSATION_CHAT_PAGE_REQ =
  'GET_LIST_CONVERSATION_CHAT_PAGE_REQ';
export const GET_LIST_CONVERSATION_CHAT_PAGE_SUCCESS =
  'GET_LIST_CONVERSATION_CHAT_PAGE_SUCCESS';
export const EVENT_MESSAGE_FROM_CHAT_PAGE = 'EVENT_MESSAGE_FROM_CHAT_PAGE';

export const ADD_LABEL_CHAT_PAGE = 'UPDATE_LABEL_CHAT_PAGE';

export const REMOVE_LABEL_CHAT_PAGE = 'REMOVE_LABEL_CHAT_PAGE';

export const REMOVE_LABEL_TOTAL_PAGE = 'REMOVE_LABEL_TOTAL_PAGE';

export const BLOCKER_USER = 'BLOCKER_USER';
export const BLOCKED_USER = 'BLOCKED_USER';
export const UN_BLOCKER_USER = 'UN_BLOCKER_USER';
export const BLOCKER_PAGE = 'BLOCKER_PAGE';
export const UN_BLOCKER_PAGE = 'UN_BLOCKER_PAGE';

export const GET_MESSAGE_AUTOMATIC = 'GET_MESSAGE_AUTOMATIC';
export const NETWORK_RESTORED = 'NETWORK_RESTORED ';

export const SYNCHRONIZE_LIST_POPUP_CHAT = 'SYNCHRONIZE_LIST_POPUP_CHAT';
export const SYNCHRONIZE_LIST_CONVERSATIONS_CHAT_PAGE =
  'SYNCHRONIZE_LIST_CONVERSATIONS_CHAT_PAGE';
