import React, { useEffect } from 'react';
import { orderBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Typography, Box, Link, Divider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import VerifiedIcon from '@mui/icons-material/Verified';
import { removeNoteChatPage, setNoteChatPage } from 'src/apis/socialChat.apis';

import {
  buttonColor,
  buttonColorHover,
  colorBlue,
  scrollStyle
} from 'src/constants/styles';
import {
  getAboutUser,
  getInforUser,
  getLifeEventUser
} from 'src/apis/socialUser';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { urlWebEmso } from 'src/util/config';
import Button from 'src/components/Button/Button';

import { styled } from '@mui/material/styles';

import { alpha } from '@material-ui/core';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import DialogBlockMember from '../BoxChat/DialogFunctionChat/DialogBlockUser';
import { useDispatch } from 'react-redux';
import {
  synchronizeListConversationsChatPage,
  synchronizeListPopupChat
} from 'src/store/action/socialChatAction';

interface ChipData {
  color: string;
  title: string;
}

export const subArray = (array1: Array<ChipData>, array2: Array<ChipData>) => {
  let data: Array<ChipData> = [];
  if (array1.length > 0) {
    array1.map((label: ChipData) => {
      const index = array2.findIndex(el => el.title === label.title);
      if (index === -1) {
        data.push(label);
      }
    });
  }
  return data;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    borderTop: '1px solid rgba(0,0,0,0.10)',
    width: '100%',
    maxWidth: '360px',
    minWidth: '300px',
    margin: '0 auto',
    padding: '12px',
    overflow: 'hidden auto',
    backgroundColor: theme.palette.background.primary,
    ...scrollStyle,
    boxSizing: 'border-box'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '80px'
  },
  avatarBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    gap: '10px'
  },
  boxNameHeader: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  boxText: {
    display: 'flex',
    width: '100%',
    gap: 4,
    alignItems: 'center',
    padding: '4px 0'
  },
  container: {
    width: '100%',
    minHeight: '120px',
    padding: '12px'
  },
  boxInformation: {
    width: '100%',
    padding: '20px 12px',
    height: '100%',
    opacity: 0.85,
    display: 'flex',
    // alignItems: 'center',
    gap: 4,
    flexDirection: 'column-reverse'
  },
  textInformation: {
    width: '100%',
    display: 'flex',
    gap: 6,
    justifyContent: 'start',
    alignItems: 'center',
    padding: '4px 0'
  },
  styleAddLabel: {
    display: 'flex',
    gap: 2,
    width: '100%',
    justifyContent: 'center',
    height: '100%',
    '& .MuiOutlinedInput-root': {
      height: '-webkit-fill-available'
    }
  },
  styleBoxAddLabel: {
    padding: '10px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  styleRootOptionQA: {
    position: 'relative',
    width: '100%',
    maxHeight: '300px',
    overflow: 'hidden',
    height: '100%'
  },
  styleBorderRadiusQA: {
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    backgroundColor: theme.palette.background.primary,
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    color: '#000'
  },
  styleFormOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px'
  },
  buttonOption: {
    width: 27,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#333',

    '&:hover': {
      backgroundColor: '#665d5d',
      cursor: 'pointer'
    }
  },
  styleHeaderOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  styleInputOption: {
    fontSize: '14px !important',
    fontWeight: 500,
    padding: '4px 12px !important',
    width: '100%'
  },
  styleTextOptionQA: {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    padding: '8px 0'
  },
  customScrollbar: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      // backgroundColor: 'transparent',
      borderRadius: '20px',
      background: theme.palette.mode === 'dark' ? '#fafafa20' : '#1c1e2120'
    }
  },
  styleButtonAddOptions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 200,
    position: 'relative'
  },
  styleButtonQuestion: {
    padding: '0px 12px',
    borderRadius: '8px',
    fontSize: ' 14px',
    textAlign: 'center',
    color: theme.palette.mode === 'light' ? colorBlue.color : '#37353690',
    maxWidth: '80%',
    height: '100%',
    // display: 'inline',
    // wordWrap: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'normal'
  },
  styleTextQuestion: {
    fontSize: ' 14px',
    fontWeight: 400,
    padding: '4px 0',
    backgroundColor: 'transparent',
    textAlign: 'start',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: '10',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  styleOptionQuestion: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
    borderRadius: '4px',
    backgroundColor: theme.palette.mode === 'light' ? '#f0f2f550' : '#ddd10'
  },
  styleButtonSaveOptions: {
    padding: '4px 12px',
    borderRadius: '8px',
    fontSize: ' 14px',
    border: 'none',
    textAlign: 'center',
    color: colorBlue.color,
    textDecoration: 'none',
    width: 'fit-content',
    textTransform: 'none'
  },
  styleBoxButtonSave: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 20,
    backgroundColor: 'transparent',
    padding: '4px 12px',
    gap: 3
  },
  styleTextField: {
    color: '#000'
  },
  selectColor: {}
}));
export const colorLabelOptions = [
  {
    color: '#63BE09',
    title: 'Xanh lá'
  },
  {
    color: '#fb9240',
    title: 'Cam'
  },
  {
    color: '#5291ff',
    title: 'Xanh da trời'
  },
  {
    color: '#f65569',
    title: 'Đỏ'
  },
  {
    color: '#8a73c9',
    title: 'Tím'
  },
  {
    color: '#90949c',
    title: 'Xám'
  }
];

const RedditTextField = styled((props: any) => {
  const {
    handleChangeNote,
    index,
    listOptions,
    note,
    handleRemoveOption,
    conversation,
    listOptionNote,
    setListOptionNote
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isShowOption, setIsShowOption] = React.useState<boolean>(true);
  const handleToggleShowQAOption = () => {
    setIsShowOption(!isShowOption);
  };

  React.useEffect(() => {
    if (!note.note) {
      setIsShowOption(false);
    }
  }, []);

  const date = new Date(note.time);
  const localeDateString = date.toLocaleDateString('vn-VN');
  const localeTimeString = date.toLocaleTimeString('vn-VN');

  const handleOnKeyDown = event => {
    if (event.keyCode === 13) {
      handleAddNoteChatPage();
    }
  };

  const handleSynchronizeRedux = (roomId, newDataNote) => {
    const newData = {
      ...conversation,
      notes: newDataNote
    };
    dispatch(synchronizeListPopupChat(roomId, newData));
    dispatch(synchronizeListConversationsChatPage(roomId, newData));
  };

  const handleAddNoteChatPage = async () => {
    try {
      const roomId = conversation.rid || conversation._id;
      if (listOptions[index].note.length > 0) {
        listOptions[index].time = Date.now();
        const response = await setNoteChatPage(roomId, listOptions[index]);
        if (response.status === 200) {
          handleSynchronizeRedux(roomId, response.data.notes);
          if (
            listOptionNote.findIndex(el => el.id === listOptions[index].id) ===
            -1
          ) {
            setListOptionNote([...listOptionNote, listOptions[index]]);
          } else {
            listOptionNote[index].note = listOptions[index].note;
          }
          setIsShowOption(true);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <Box className={classes.styleRootOptionQA}>
      {isShowOption ? (
        <Box className={classes.styleOptionQuestion}>
          {note && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                opacity: '0.75'
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px'
                }}
              >{`${localeDateString} - ${localeTimeString}`}</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={handleToggleShowQAOption}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    Chỉnh sửa
                  </Typography>
                </Box>
                <Box sx={{ cursor: 'pointer' }} onClick={handleRemoveOption}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    Xóa
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              height: '100%'
            }}
          >
            <Typography className={classes.styleTextQuestion} variant="body1">
              {note.note}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className={classes.styleBorderRadiusQA}>
          <TextField
            id="standard-basic"
            variant="outlined"
            onChange={handleChangeNote}
            autoFocus
            onKeyDown={e => handleOnKeyDown(e)}
            color={
              listOptions &&
              !listOptions[index].note &&
              listOptions[index].note.length > 200
                ? 'error'
                : 'info'
            }
            InputProps={{
              inputProps: {
                style: {
                  color: theme.palette.mode === 'light' ? '#000' : '#fff'
                }
              }
            }}
            helperText={
              listOptions &&
              !listOptions[index].note &&
              listOptions[index].note.length > 200
                ? 'Số kí tự không vượt quá 200'
                : ''
            }
            FormHelperTextProps={{
              style: { color: 'red' }
            }}
            sx={{ color: '#000 !important' }}
            label={` ${
              listOptions &&
              listOptions[index] &&
              listOptions[index].note.length
            }/200`}
            value={note.note}
          />
          <Box className={classes.styleHeaderOption}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 1,
                padding: '4px 0',
                width: '100%'
              }}
            >
              <Button
                style={{
                  color: theme.palette.mode === 'light' ? '#000' : '#fff',
                  padding: '4px 16px',
                  borderRadius: '4px',
                  width: 'fit-content',
                  backgroundColor:
                    theme.palette.mode === 'light' ? '#fafafa' : '#ddd30',
                  border:
                    theme.palette.mode === 'light'
                      ? '0.25px  solid #ddd'
                      : 'unset',
                  cursor: 'pointer',
                  '&:hover': {}
                }}
                action={() => {
                  setListOptionNote([]);
                  setIsShowOption(true);
                }}
                label={'Hủy'}
              />

              <Box
                sx={{
                  cursor:
                    listOptions[index].note.length > 0
                      ? 'pointer'
                      : 'not-allowed'
                }}
              >
                <Button
                  style={{
                    color: theme.palette.mode === 'light' ? '#fafafa' : '#fff',
                    padding: '4px 16px',
                    borderRadius: '4px',
                    width: 'fit-content',

                    backgroundColor:
                      theme.palette.mode === 'light'
                        ? colorBlue.color
                        : '#179df7',
                    '&:hover': {
                      backgroundColor:
                        theme.palette.mode === 'light' ? '#0864bb' : '#0864bb80'
                    }
                  }}
                  action={handleAddNoteChatPage}
                  label={'Lưu'}
                  disabled={
                    listOptions[index].note.trim().length > 0 ? false : true
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
})(({ theme }) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    padding: '0px !important',
    borderRadius: 8,
    color: '#000',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  }
}));

interface Note {
  id: string;
  note: string;
  time: number;
}

function InfoUserChatPage(props) {
  const { conversation, setOpenRLeftConversation } = props;
  const [userInfoChatPage, setUserInfoChatPage] = React.useState<any>({});
  const [userAboutChatPage, setUserAboutChatPage] = React.useState<any>({});
  const [userAboutLifeEventChatPage, setUserAboutLifeEventChatPage] =
    React.useState<any>([]);
  // const [titleLabel, setTitleLabel] = React.useState<string>('');
  // const [colorLabel, setColorLabel] = React.useState<string>('#63BE09');
  // const [labelPage, setLabelPage] = React.useState<any>([]);
  // const [labelConversation, setLabelConversation] = React.useState<any>([]);
  const { u, notes } = conversation;
  const [openDialogBlockUser, setOpenDialogBlockUser] =
    React.useState<boolean>(false);

  const [listOptionNote, setListOptionNote] = React.useState<Array<Note>>(
    notes || []
  );

  useEffect(() => {
    setListOptionNote(notes || []);
  }, [notes?.length]);

  useEffect(() => {
    if (listOptionNote.length === 0) {
      setListOptionNote(notes || []);
    }
  }, [listOptionNote.length]);

  const classes = useStyles();
  const theme = useTheme();

  const fetchUserAboutChatPage = async () => {
    try {
      const userId = u._id;
      const response = await getAboutUser(userId, null);
      if (response.status === 200) {
        setUserAboutChatPage(response.data);
      }
    } catch (error) {}
  };
  const fetchLifeEventUserAboutChatPage = async () => {
    try {
      const userId = u._id;
      const response = await getLifeEventUser(userId);
      if (response.status === 200) {
        setUserAboutLifeEventChatPage(response.data);
      }
    } catch (error) {}
  };

  const fetchUserInfoChatPage = async () => {
    try {
      const userId = u._id;
      const response = await getInforUser(userId, null);
      if (response.status === 200) {
        setUserInfoChatPage(response.data);
      }
    } catch (error) {}
  };

  // const fetchLabelPage = async () => {
  //   try {
  //     const response = await getTeamInfoById(prid);
  //     if (response.status === 200) {
  //       setLabelPage(response.data.teamInfo?.label || []);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  React.useEffect(() => {
    fetchUserAboutChatPage();
    fetchUserInfoChatPage();
    fetchLifeEventUserAboutChatPage();
    // fetchLabelPage();
    // if (conversation?.label) {
    //   setLabelConversation(conversation.label);
    // }
  }, []);

  const listActionsConversation = [
    [
      {
        label: 'Dừng liên lạc',
        id: 'unRead',
        icon: 'fa-light fa-user',
        styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
        action: () => {
          setOpenDialogBlockUser(true);
        }
      }
    ]
  ];
  const listInfoUser = [
    {
      id: 'phone',
      icon: <i className="fa-solid fa-phone" style={{ color: '#7165e0' }}></i>,
      title: userAboutChatPage.general_information?.phone_number || 'Chưa rõ'
    },
    {
      id: 'mail',
      icon: <i className="fa-solid fa-envelope"></i>,
      title: userAboutChatPage?.email || 'Chưa rõ'
    },
    {
      id: 'date',
      icon: <i className="fa-solid fa-cake-candles"></i>,
      title:
        `${userAboutChatPage.general_information?.birth_date || '__'}/${
          userAboutChatPage.general_information?.birth_month || '__'
        }/${userAboutChatPage.general_information?.birth_year || '__'}` ||
        'Chưa rõ'
    }
  ];

  const listAboutUser = [
    {
      id: 'graduate',
      icon: <i className="fa-solid fa-graduation-cap"></i>,
      link: ``,
      titleLink: `Đã tốt nghiệp : ${
        userAboutLifeEventChatPage.find(
          item => item?.life_event?.life_event_category?.code === 'hoc_van'
        )?.life_event?.company || 'chưa rõ'
      }`
    },
    {
      id: 'job',
      icon: <i className="fa-solid fa-briefcase"></i>,
      link: ``,
      titleLink: `Làm việc tại ${
        userAboutLifeEventChatPage.find(
          item => item?.life_event?.life_event_category?.code === 'work'
        )?.life_event?.company || 'chưa rõ'
      }`
    },
    {
      id: 'town',
      icon: <i className="fa-solid fa-location-dot"></i>,
      link: ``,
      titleLink: `Quê quán : ${
        userAboutChatPage.general_information?.hometown?.title || 'chưa rõ'
      }`
    },
    {
      id: 'house',
      icon: <i className="fa-solid fa-house-user"></i>,
      link: ``,
      titleLink: `Sống tại: ${
        userAboutChatPage.general_information?.place_live?.title || 'Chưa rõ'
      }`
    }
  ];
  const isCertified = userInfoChatPage && userInfoChatPage.certified;

  const removeOption = async (index, el) => {
    try {
      const roomId = conversation.rid || conversation._id;
      if (listOptionNote.length > 0) {
        const response = await removeNoteChatPage(roomId, el);
        if (response.status === 200) {
          setListOptionNote(listOptionNote.filter(item => item.id !== el.id));
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const now = Date.now();
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.avatarBox}>
          <Box>
            <AvatarSocial
              type="feed"
              style={{
                width: 60,
                height: 60,
                marginLeft: 0
              }}
              avatarObj={userInfoChatPage.avatar_media}
            />
          </Box>
          <Box className={classes.boxNameHeader}>
            <Box className={classes.boxText}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
                {userInfoChatPage?.display_name}
              </Typography>
              {isCertified && (
                <VerifiedIcon
                  titleAccess="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                  fontSize="small"
                  viewBox="0 -2 24 24"
                  sx={{
                    color: buttonColor.backgroundColor,
                    fontSize: '14px',
                    '&:hover': {
                      color: buttonColorHover.backgroundColor
                    },
                    marginRight: '8px'
                  }}
                />
              )}
            </Box>
            <Link
              href={`${urlWebEmso}/user/${userInfoChatPage.username}`}
              target="_blank"
              variant="body2"
              sx={{ color: '#7165e0', textDecoration: 'none' }}
            >
              Xem trang cá nhân
            </Link>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButtonOptions
            icon={
              <i
                style={{
                  fontSize: '18px',
                  color: theme.palette.mode === 'dark' ? '#f9f9f9' : '#606770'
                }}
                className="fa-solid fa-ellipsis"
              />
            }
            openPopup={true}
            typePopup="popover"
            options={listActionsConversation}
            horizontalAnchor="right"
            horizontalTransform="right"
            style={{
              width: '35px',
              height: '35px',
              marginRight: '5px',
              // position: 'absolute',
              zIndex: 100,
              // right: '60px',
              borderRadius: '50%',
              border: theme.palette.mode === 'dark' ? '' : '1px solid #DADADA',
              backgroundColor:
                theme.palette.mode === 'dark' ? '#3E4042' : '#fff',
              boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
            }}
          />
          <Button
            icon={<CloseIcon />}
            style={{
              width: '35px',
              height: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              animation: '0.2s linear',
              borderRadius: '4px'
            }}
            action={() => setOpenRLeftConversation(false)}
          />
        </Box>
      </Box>
      <Divider />
      {/* information */}
      <Box className={classes.container}>
        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
          Giới thiệu
        </Typography>
        <Box className={classes.boxInformation}>
          <Typography sx={{ fontSize: '14px' }}>Chi tiết đã thêm</Typography>
          {listInfoUser.map((el: any, index) => (
            <Box key={el.id} id={el.id} className={classes.textInformation}>
              <Box sx={{ width: '20px', textAlign: 'center' }}>{el.icon}</Box>
              <Typography sx={{ fontSize: '14px' }}>{el.title}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />

      <Box className={classes.container}>
        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
          Trang cá nhân trên Emso
        </Typography>
        <Box className={classes.boxInformation}>
          {listAboutUser.map((el: any, index) => (
            <Box key={el.id} id={el.id} className={classes.textInformation}>
              <Box sx={{ width: '20px', textAlign: 'center' }}>{el.icon}</Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  width: '100%',
                  wordWrap: 'break-word'
                }}
              >
                {el.titleLink}
              </Typography>
              <Link href="#" variant="body2">
                {el.link}
              </Link>
            </Box>
          ))}
        </Box>
      </Box>

      <Divider />
      {/* note */}
      <Box className={classes.container}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
            Ghi chú
          </Typography>
          <Box
            onClick={() => {
              if (!listOptionNote.some(el => el.note === '')) {
                setListOptionNote([
                  ...listOptionNote,
                  {
                    id: uuidv4(),
                    note: '',
                    time: now
                  }
                ]);
              }
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              sx={{
                color: '#7165e0',
                fontSize: '16px',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Thêm ghi chú
            </Typography>
          </Box>
        </Box>
        <Box className={classes.boxInformation} sx={{ padding: '10px 0' }}>
          {listOptionNote &&
            listOptionNote.length > 0 &&
            listOptionNote.map((el: Note, index: number) => (
              <Box
                key={index}
                className={classes.styleFormOption}
                sx={{ width: '100%' }}
              >
                <RedditTextField
                  key={index}
                  note={el}
                  conversation={conversation}
                  index={index}
                  listOptions={listOptionNote}
                  handleChangeNote={e => {
                    if (e.target.value.length <= 200) {
                      setListOptionNote([
                        ...listOptionNote.slice(0, index),
                        {
                          ...el,
                          note: e.target.value.length <= 200 && e.target.value
                        },
                        ...listOptionNote.slice(index + 1)
                      ]);
                    }
                  }}
                  handleRemoveOption={() => removeOption(index, el)}
                  listOptionNote={listOptionNote}
                  setListOptionNote={setListOptionNote}
                  // handleAddNoteChatPage={() => handleAddNoteChatPage(index, el)}
                />
              </Box>
            ))}
        </Box>
      </Box>
      {openDialogBlockUser && (
        <DialogBlockMember
          open={openDialogBlockUser}
          setOpen={setOpenDialogBlockUser}
          conversation={conversation}
          // userSelected={userSelected}
          // handleBlockRoom={handleBlockRoom}
        />
      )}
    </Box>
  );
}

export default InfoUserChatPage;
