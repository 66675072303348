import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Theme,
  Tooltip,
  Typography,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { PATHS } from 'src/constants/paths';

import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  deleteMessage,
  setReactMessage,
  pinMessage,
  unPinMessage
} from 'src/apis/socialChat.apis';
import { getAboutUser, getInforUser } from 'src/apis/socialUser';
import { getDetailPageApi } from 'src/apis/socialPages.api';

import { collapseString } from 'src/common/string';

import { urlify } from 'src/helpers/common';
import { handleTimeShow } from 'src/helpers/string';
import { checkMentions } from 'src/pages/Chat/BoxChat/Footer';
import { urlRocketChat, urlWebEmso } from 'src/util/config';
import { classifyTypeMessage } from 'src/pages/Chat/BoxChat/chatCommon';

import DetailMessageAudio from 'src/pages/Chat/BoxChat/DetailMessage/DetailMessageAudio';
import DetailMessageFile from 'src/pages/Chat/BoxChat/DetailMessage/DetailMessageFiles';
import DetailMessageImage from 'src/pages/Chat/BoxChat/DetailMessage/DetailMessageImage';
import DetailMessageReply from 'src/pages/Chat/BoxChat/DetailMessage/DetailMessageReply';
import DetailMessageVideo from 'src/pages/Chat/BoxChat/DetailMessage/DetailMessageVideo';
import DialogUserChatGroup from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogUserGroupChat';
import { DetailMessageVoted } from 'src/pages/Chat/BoxChat/DetailMessage/DetailMessageVoted';

import DialogForwardMessage from 'src/components/Dialog/DialogForward/DialogForwardMessage';
import DialogViewPinMessage from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogViewPinMessage';

import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import ReactEmoji from 'src/components/ReactEmoji/Index';
import RenderReactionImg from 'src/components/ReactEmoji/RenderReactionImg';
import { buttonColor } from 'src/constants/styles';
import { renderAllAvatar } from '../../functionChat';

const senderMessageStyle: any = {
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 'auto',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    padding: '2px 0px 0px 8px'
  },
  box: {
    marginRight: '5px !important',
    marginLeft: '0px',
    fontSize: 15,
    width: 'fit-content',
    backgroundColor: '#e4e6eb',
    borderRadius: '16px 16px 16px 16px',
    color: '#040404',
    padding: '7px 12px 8px 12px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'inherit',
    position: 'relative'
  },
  hideText: {
    maxWidth: 220,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  forwardMessage: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',

    gap: 1,
    fontSize: 10
  }
};

const myMessageStyle: any = {
  item: {
    float: 'right',
    justifyContent: 'flex-end',
    display: 'flex',
    width: 'fit-content !100%',
    height: '100%',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    padding: '10px 0px 10px 0px',
    border: 'none',
    marginRight: '10px'
  },
  box: {
    // backgroundColor: buttonColor.backgroundColor,
    borderRadius: '16px 16px 16px 16px',
    color: 'white',

    fontSize: 15,
    width: 'fit-content',
    padding: '7px 12px 8px 12px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'inherit',
    position: 'relative'
  },
  forwardMessage: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    fontSize: 10
  }
};

const reactConvert = [
  {
    id: 0, // like
    type: 'like',
    name: ':thumbsup:'
  },
  {
    id: 1, // tim
    type: 'love',
    name: ':heart:'
  },
  {
    id: 2, // haha
    type: 'haha',
    name: ':laughing:'
  },
  {
    id: 3, // thuong thuong
    type: 'yay',
    name: ':relaxed:'
  },
  {
    id: 4, // wow
    type: 'wow',
    name: ':scream:'
  },
  {
    id: 5, // buon
    type: 'sad',
    name: ':cry:'
  },
  {
    id: 6, // phan no
    type: 'angry',
    name: ':rage:'
  }
];
const AvatarRenderMessage = props => {
  const {
    message,
    conversation,
    setUserSelectedData,
    setOpenDialogUserChatGroup,
    setUserAboutData,
    match
  } = props;
  const userSelected = message?.u;
  const handleGetInfoUser = async () => {
    try {
      if (
        conversation &&
        match.params.key !== 'chat' &&
        conversation.t === 'cp'
      ) {
        const response = await getDetailPageApi(conversation?.prid);
        if (response.status === 200) {
          setUserSelectedData(response.data);
        }
      } else {
        const response = await getInforUser(userSelected._id, null);
        if (response.status === 200) {
          setUserSelectedData(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAboutUser = async () => {
    try {
      if (
        (conversation && conversation.t !== 'cp') ||
        (match.params.key === 'chat' && conversation.t === 'cp')
      ) {
        const response = await getAboutUser(userSelected._id, null);
        if (response.status === 200) {
          setUserAboutData(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenDialogUserChatGroup = () => {
    handleGetInfoUser();
    handleGetAboutUser();
    setOpenDialogUserChatGroup(true);
  };

  return (
    <Box onClick={handleOpenDialogUserChatGroup} key={message?._id}>
      <AvatarSocial
        type="feed"
        style={{ width: 30, height: 30, marginRight: '4px' }}
        avatarObj={renderAllAvatar(
          conversation,
          match,
          'avatarMessageGroup',
          message
        )}
      />
    </Box>
  );
};
const checkForward = message => {
  if (typeof message === 'string' && message.includes('@[all](Mọi người)')) {
    return message.replace(/@\[all\]\(Mọi người\)/g, '@Mọi người');
  } else {
    const regexp = /@\[(\d+)\]\((.*?)\)/g;
    const textForward = message.replace(regexp, '@$2');
    return textForward;
  }
};
const checkMentionsDetail = message => {
  if (message && message.mentions && message.mentions.length > 0) {
    const regexpRemoveText = /@\[\d+\]\([^)]*\)/g;
    const matches = message.msg.match(regexpRemoveText);
    const resultMatches = matches ? matches.join(' ') : '';
    let isReplyMessage = message.msg.startsWith(`[ ](${urlRocketChat}/`)
      ? message.msg.split(') ')[1]
      : resultMatches;
    const regexp = /@\[([\w\s]+)\]/g;
    const text = isReplyMessage.replace(regexp, username => {
      const objectMentions = message.mentions.find(
        el => `@[${el._id}]` === username
      );

      if (objectMentions) {
        if (objectMentions.username !== 'all') {
          return `<a href="${urlWebEmso}/user/${objectMentions.username}" target="_blank" style="color:inherit;text-decoration:none; font-weight:'400px'"><b>@${objectMentions.name}</b></a>`;
        } else return `<b>@mọi người</b>`;
      } else {
        return message.msg;
      }
    });
    let newText = text.replace(/\([^)]*\)/g, '');
    let result = '';
    if (newText) {
      result = newText?.replace(/\n/g, '<br/>');
    }
    return parse(`<p>${result}</p>`);
  }
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      reactionCount: {
        position: 'absolute',
        bottom: '-15px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 10,
        '&:hover': {
          cursor: 'pointer'
        },
        visibility: 'visible',
        color: '#000',
        paddingRight: '2px',
        width: 'fit-content',
        height: 'fit-content',
        padding: '0 4px',
        gap: 1
      },
      styleDivLink: {
        width: '100%',
        height: 'auto',
        padding: '12px',
        backgroundColor: theme.palette.mode === 'light' ? '#f0f2f5' : '#3a3b3c',
        borderRadius: '0px 0px 8px 8px',
        cursor: 'pointer'
      },
      styleDivShowAction: {
        width: '60px !important',
        marginBottom: '2px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        border: 'none'
      },
      styleDivShowAction_item: {
        width: '60px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      styleDivAvatar: {
        width: '20px',
        display: 'flex',
        flexDirection: 'column-reverse',
        border: 'none'
      },
      styleDivIconCircle: { width: '20px', height: '30px' },
      styleShowAvatarSender: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        border: 'none'
      },
      styleDivConversation: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'relative'
      },
      styleDefaultSender: {
        width: '60px !important',
        // height: '100%',
        marginRight: '5px',
        marginBottom: '2px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        border: 'none'
      },
      styleForwardMessage: {
        color: theme.palette.mode === 'light' ? '#20262E' : '#E9E8E8',
        fontSize: '10px',
        display: 'flex',
        gap: 2
      },
      styleButtonActionMessage: {
        width: '100%',
        height: '36px',
        padding: 0
      },
      styleTitleActionMessage: {
        fontSize: '14px',
        fontWeight: '700'
      }
    }),
  { index: 1 }
);
interface MessageProps {
  message: any;
  messageNext?: any;
  showAvatar?: Boolean;
  conversation?: any;
  isFirstIndex?: boolean;
  handleReplyMessage?: any;
  setUpdatedMessage: React.Dispatch<any>;
  showNameMemberGrChat;
  borderTopMessage?: any;
  borderBottomMessage?: any;
  showTimeSend?: any;
  nextMessageShowTimeSend?: any;
  typeBoxChat?: string | undefined;
  listMemberGroupChat?: any;
  repliedMessage?: any;
  searchMessage?: any;
  setIsToastMessage: React.Dispatch<any>;
  setTitleToastMessage: React.Dispatch<any>;
  setCodeNoti?: any;

  // isChatPage?: string | undefined;
}

const Index: React.FC<MessageProps> = React.memo(
  ({
    message,
    messageNext,
    showAvatar,
    conversation,
    isFirstIndex,
    handleReplyMessage,
    setUpdatedMessage,
    showNameMemberGrChat,
    borderTopMessage,
    borderBottomMessage,
    showTimeSend,
    nextMessageShowTimeSend,
    typeBoxChat,
    listMemberGroupChat,
    repliedMessage,
    searchMessage,
    setIsToastMessage,
    setTitleToastMessage,
    setCodeNoti
  }) => {
    const listMemberGrChat = JSON.parse(listMemberGroupChat);
    // const mainTopic = conversation?.RoomsList?.[0]?.topic;
    const [userSelectedData, setUserSelectedData] = React.useState<any>({});
    const [userAboutData, setUserAboutData] = React.useState<any>({});
    const [contentTooltip, setContentTooltip] =
      React.useState<any>('Bày tỏ cảm xúc');
    const match: any = useRouteMatch();
    const theme = useTheme();
    const classes = useStyles();
    const [showAction, setShowAction] = React.useState(false);

    const [anchorElMore, setAnchorElMore] =
      React.useState<HTMLButtonElement | null>(null);
    const [anchorElReact, setAnchorElReact] =
      React.useState<HTMLButtonElement | null>(null);
    const [openDialogShareToMessenger, setOpenDialogShareToMessenger] =
      React.useState<boolean>(false);
    const [openDialogViewPinMessage, setOpenDialogViewPinMessage] =
      React.useState<boolean>(false);
    const [openDialogUserChatGroup, setOpenDialogUserChatGroup] =
      React.useState<boolean>(false);

    const [isShowIframe, setIsShowIframe] = React.useState(false);
    const meInfo = useSelector((state: any) => state.meReducer.info) || {};
    const rocketId = localStorage.getItem('userId') || meInfo.id;
    const isForward = message?.type === 'forward';
    const isMessageChanged = message?.editedBy?.username;
    const type = classifyTypeMessage(message);
    const openPopoverElMore = Boolean(anchorElMore);

    const mainTopic = conversation?.topic
      ? conversation?.topic?.colors
      : conversation?.RoomsList?.[0]?.topic?.colors || [
          buttonColor.backgroundColor,
          buttonColor.backgroundColor
        ];

    const idPopoverElMore = 'more';

    let reactions;
    let totalReactions = 0;
    if (!!message?.reactions) {
      reactions = [
        {
          type: 'like',
          likes_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'like')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'like')?.name}`
            ]?.names?.map((item: any) => item.name) ?? []
        },
        {
          type: 'haha',
          hahas_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'haha')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'haha')?.name}`
            ]?.names?.map((item: any) => item.name) ?? []
        },
        {
          type: 'angry',
          angrys_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'angry')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'angry')?.name}`
            ]?.names?.map((item: any) => item.name) ?? []
        },
        {
          type: 'love',
          loves_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'love')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'love')?.name}`
            ]?.names?.map((item: any) => item.name) ?? []
        },
        {
          type: 'sad',
          sads_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'sad')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'sad')?.name}`
            ]?.names?.map((item: any) => item.name) ?? []
        },
        {
          type: 'wow',
          wows_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'wow')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'wow')?.name}`
            ]?.names?.map((item: any) => item.name) ?? []
        },
        {
          type: 'yay',
          yays_count:
            message.reactions[
              `${reactConvert.find(el => el.type === 'yay')?.name}`
            ]?.usernames?.length ?? 0,
          username:
            message.reactions[
              `${reactConvert.find(el => el.type === 'yay')?.name}`
            ]?.names?.map((item: any) => item.name) ?? []
        }
      ];
      Object.values(message.reactions).map((el: any) => {
        totalReactions += el.usernames.length;
      });
    }

    function getSubjectName() {
      if (message?.u?.username === meInfo?.username) {
        return 'Bạn';
      } else if (message?.u?.name) {
        return message?.u?.name.split(' ').slice(-1);
      } else {
        return '';
      }
    }
    const subjectName = getSubjectName();
    const listActionMessages = [
      {
        title: 'Xem thêm',
        id: 'more',
        icon: 'fa-solid fa-ellipsis-vertical',
        display:
          conversation.t === 'cp' &&
          message &&
          message?.u?._id === conversation?.u?._id
            ? 'none'
            : 'block'
      },
      {
        title: 'Trả lời',
        id: 'reply',
        icon: 'fa-solid fa-reply'
      }
    ];

    const handlePinMessage = async () => {
      try {
        const response = await pinMessage(message?._id, conversation?.rid);
        if (response.status === 200) {
          // console.log(response.data);
        }
      } catch (error) {
        setIsToastMessage(true);
        setCodeNoti(400);
        setTitleToastMessage('Ghim tin nhắn thất bại.');
      }
    };
    const handleUnPinMessage = async () => {
      try {
        const response = await unPinMessage(message?._id, conversation.rid);
        if (response.status === 200) {
          // console.log(response.data);
        }
      } catch (error) {
        setIsToastMessage(true);
        setCodeNoti(400);

        setTitleToastMessage('Xoá ghim tin nhắn thất bại.');
      }
    };

    const actionMessage = [
      conversation && !conversation?.is_user_chat
        ? message?.u?._id === rocketId
          ? {
              title: 'Xóa, gỡ bỏ',
              action: () => handleDeleteMessage()
            }
          : {
              styleButton: {
                display: 'none'
              }
            }
        : (message && message?.u?._id !== conversation?.u?._id) ||
          (message &&
            message?.u?._id === conversation?.u?._id &&
            match.params.key === 'chat')
        ? {
            title: 'Xóa, gỡ bỏ',
            action: () => handleDeleteMessage()
          }
        : {
            styleButton: {
              display: 'none'
            }
          },
      conversation && !conversation?.is_user_chat
        ? message?.u?._id === rocketId &&
          (type === 'message' || type === 'reply')
          ? {
              title: 'Chỉnh sửa',
              action: () => handleUpdateMessage()
            }
          : {
              styleButton: {
                display: 'none'
              }
            }
        : (message && message?.u?._id !== conversation?.u?._id) ||
          (message &&
            message?.u?._id === conversation?.u?._id &&
            match.params.key === 'chat')
        ? {
            title: 'Chỉnh sửa',
            action: () => handleUpdateMessage()
          }
        : {
            styleButton: {
              display: 'none'
            }
          },

      !message?.alias && conversation.t !== 'cp'
        ? {
            title: 'Chuyển tiếp',
            action: () => {
              setOpenDialogShareToMessenger(true);
              setAnchorElMore(null);
            }
          }
        : {
            styleButton: {
              display: 'none'
            }
          },
      conversation.t !== 'cp'
        ? !message?.pinned
          ? {
              title: 'Ghim',
              action: () => {
                handlePinMessage();
                setAnchorElMore(null);
              }
            }
          : {
              title: 'Bỏ ghim',
              action: () => {
                handleUnPinMessage();
                setAnchorElMore(null);
              }
            }
        : {
            styleButton: {
              display: 'none'
            }
          }
    ];
    const renderLinkMess = (urls: any, message: any) => {
      let newText = message.msg.replace(/@\[([\w\s]+)\]|\([^)]*\)/g, '');
      urls.forEach((url: any) => {
        newText = newText.replace(
          new RegExp(url.url, 'g'),
          `<a href="${url.url}" target="_blank" style="text-decoration: underline; font-weight: 500; cursor: pointer; color:unset ">${url.url}</a>`
        );
      });

      return parse(newText);
    };

    const renderIconLike = (fontSize: any, msg: any) => {
      return (
        <div style={{ position: 'relative' }}>
          {msg !== ':like_small: ' &&
          msg !== ':like_big: ' &&
          msg !== ':like_largest: ' ? (
            <Box>
              <Typography style={{ fontSize: fontSize }}>
                {msg.split(' ').slice(-1)}
              </Typography>
              {message?.reactions &&
              !['image', 'video', 'gif', 'reply', 'link', 'audio'].includes(
                classifyTypeMessage(message)
              )
                ? renderPostReactEmojis()
                : null}
            </Box>
          ) : (
            <Box>
              <i
                className="fa-sharp fa-solid fa-thumbs-up"
                style={{
                  color: mainTopic
                    ? mainTopic?.[1]
                    : buttonColor.backgroundColor,
                  fontSize: fontSize
                }}
              ></i>
              {message?.reactions &&
              !['image', 'video', 'gif', 'reply', 'link', 'audio'].includes(
                classifyTypeMessage(message)
              )
                ? renderPostReactEmojis()
                : null}
            </Box>
          )}
        </div>
      );
    };
    const renderPostReactEmojis = () => {
      return (
        <div
          className={classes.reactionCount}
          style={
            message.u?._id === rocketId ? { right: '0px' } : { right: '3px' }
          }
        >
          <span>
            <RenderReactionImg listReaction={reactions} isMessage />
          </span>
          {totalReactions > 1 && (
            <span
              style={{
                fontSize: '11px',
                color: buttonColor.backgroundColor,
                textAlign: 'center',
                fontWeight: 'bold',
                paddingRight: '4px'
              }}
            >
              {totalReactions}
            </span>
          )}
        </div>
      );
    };
    let isPinnedMessage = false;
    if (message?.pinned) {
      isPinnedMessage = true;
    }

    const getBorderRadius = (
      conversation,
      message,
      rocketId,
      borderTopMessage,
      borderBottomMessage,
      nextMessageShowTimeSend,
      showTimeSend
    ) => {
      const isCpConversation = conversation && conversation?.t !== 'cp';

      const isMyMessage =
        conversation && conversation.t !== 'cp'
          ? message?.u?._id === rocketId &&
            message &&
            message?.type !== 'automatic_reply'
          : (message && message.type === 'automatic_reply') ||
            (message?.u?._id === rocketId && conversation.t !== 'cp') ||
            (message &&
              conversation.t === 'cp' &&
              message.isPage === false &&
              match?.params?.key !== 'chat') ||
            (message &&
              conversation.t === 'cp' &&
              message.isPage &&
              match?.params?.key === 'chat');

      const isMessageFromOtherUser =
        (message && message.type === 'automatic_reply') ||
        (conversation &&
          !conversation?.is_user_chat &&
          message?.u?._id === rocketId);
      const isGrConversation =
        conversation?.t !== 'p' || conversation.t !== 'c';
      const isUserConversation = conversation?.t === 'd';

      if (isCpConversation && isGrConversation && isUserConversation) {
        return isMessageFromOtherUser
          ? getMyMessageBorderRadius(
              borderTopMessage,
              borderBottomMessage,
              nextMessageShowTimeSend,
              showTimeSend
            )
          : getOtherUserMessageBorderRadius(
              borderTopMessage,
              borderBottomMessage,
              nextMessageShowTimeSend,
              showTimeSend
            );
      }
      return isMyMessage
        ? getMyMessageBorderRadius(
            borderTopMessage,
            borderBottomMessage,
            nextMessageShowTimeSend,
            showTimeSend
          )
        : getOtherUserMessageBorderRadius(
            borderTopMessage,
            borderBottomMessage,
            nextMessageShowTimeSend,
            showTimeSend
          );
    };

    const getMyMessageBorderRadius = (
      borderTopMessage,
      borderBottomMessage,
      nextMessageShowTimeSend,
      showTimeSend
    ) => {
      if (borderTopMessage || showTimeSend) {
        if (borderBottomMessage || nextMessageShowTimeSend) {
          return '16px'; // my message border top + bottom
        }

        return '16px 16px 4px 16px'; // my message border only top
      }

      if (borderBottomMessage || nextMessageShowTimeSend) {
        return '16px 4px 16px 16px'; // my message border only bottom
      }

      return '16px 4px 4px 16px'; // my message not border
    };

    const getOtherUserMessageBorderRadius = (
      borderTopMessage,
      borderBottomMessage,
      nextMessageShowTimeSend,
      showTimeSend
    ) => {
      if (borderTopMessage || showTimeSend) {
        if (borderBottomMessage || nextMessageShowTimeSend) {
          return '16px'; // sender message border top + bottom
        }

        return '16px 16px 16px 4px'; // sender message border only top
      }

      if (borderBottomMessage || nextMessageShowTimeSend) {
        return '4px 16px 16px 16px'; // sender message border only bottom
      }

      return '4px 16px 16px 4px'; // sender message not border
    };

    const renderContentMessage = message => {
      const typeMessage = classifyTypeMessage(message);
      let isHighlightedMessage;
      if (searchMessage && message && message.msg) {
        if (message?.msg.startsWith('[ ](https')) {
          isHighlightedMessage = message.msg.endsWith(searchMessage?.msg);
        } else {
          isHighlightedMessage = message.msg === searchMessage?.msg;
        }
      }
      const emojiRegex = /[\u{1F300}-\u{1F9FF}]/gu;
      const hasEmojiOnly = !message?.msg.replace(emojiRegex, '').trim();

      switch (typeMessage) {
        case 'message': {
          if (
            typeof message?.msg === 'string' &&
            message?.msg.includes(':like_small: ')
          ) {
            return renderIconLike(30, message?.msg);
          } else if (
            typeof message?.msg === 'string' &&
            message?.msg.includes(':like_big: ')
          ) {
            return renderIconLike(60, message?.msg);
          } else if (
            typeof message?.msg === 'string' &&
            message?.msg.includes(':like_largest: ')
          ) {
            console.log('check messae', message);

            return renderIconLike(100, message?.msg);
          } else
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems:
                    conversation && !conversation?.is_user_chat
                      ? message?.type !== 'automatic_reply' &&
                        message?.u?._id === rocketId &&
                        match.params.key !== 'chat'
                        ? 'flex-end'
                        : 'flex-start'
                      : message?.u?._id !== conversation?.u?._id
                      ? 'flex-end'
                      : 'flex-start',
                  flexDirection: 'column',
                  position: 'relative',
                  // width: typeBoxChat === 'fullScreen' ? '60%' : '40%'
                  width: 'fit-content',
                  maxWidth: typeBoxChat === 'fullScreen' ? '560px' : 'unset'
                }}
              >
                <Box
                  id={message?._id}
                  sx={
                    conversation && !conversation?.is_user_chat
                      ? message?.type !== 'automatic_reply' &&
                        message?.u?._id === rocketId
                        ? myMessageStyle.box
                        : senderMessageStyle.box
                      : (message && message.type === 'automatic_reply') ||
                        message?.u?._id !== conversation?.u?._id
                      ? myMessageStyle.box
                      : senderMessageStyle.box
                  }
                  style={{
                    position: 'relative',
                    width: 'fit-content',
                    background:
                      conversation && !conversation?.is_user_chat
                        ? (message?.type !== 'automatic_reply' &&
                            message?.u?._id === rocketId &&
                            conversation.t !== 'cp') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage === false &&
                            match?.params?.key !== 'chat') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage &&
                            match?.params?.key === 'chat')
                          ? hasEmojiOnly
                            ? 'unset'
                            : mainTopic
                            ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                            : buttonColor.backgroundColor
                          : theme.palette.mode === 'light'
                          ? '#e4e6eb'
                          : '#3e4042'
                        : (message && message.type === 'automatic_reply') ||
                          (message?.u?._id === rocketId &&
                            conversation.t !== 'cp') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage === false &&
                            match?.params?.key !== 'chat') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage &&
                            match?.params?.key === 'chat')
                        ? mainTopic
                          ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                          : buttonColor.backgroundColor
                        : theme.palette.mode === 'light'
                        ? '#e4e6eb'
                        : '#3e4042',
                    color:
                      conversation && !conversation?.is_user_chat
                        ? message?.type !== 'automatic_reply' &&
                          message?.u?._id === rocketId &&
                          message?.isPage &&
                          match?.params?.key === 'chatpage'
                          ? '#050505'
                          : message?.type !== 'automatic_reply' &&
                            message?.u?._id === rocketId &&
                            message?.isPage === false &&
                            match?.params?.key !== 'chatpage'
                          ? '#FFF'
                          : theme.palette.mode === 'light'
                          ? '#050505'
                          : '#fff'
                        : (message && message.type === 'automatic_reply') ||
                          (message?.u?._id === rocketId &&
                            conversation.t !== 'cp') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage === false &&
                            match?.params?.key !== 'chat') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage &&
                            match?.params?.key === 'chat')
                        ? '#fff'
                        : theme.palette.mode === 'light'
                        ? '#050505'
                        : '#fff',
                    borderRadius: getBorderRadius(
                      conversation,
                      message,
                      rocketId,
                      borderTopMessage,
                      borderBottomMessage,
                      nextMessageShowTimeSend,
                      showTimeSend
                    ),
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    alignItems: 'center',
                    opacity: isForward ? 0.9 : 1
                  }}
                >
                  {isForward ? <i className="fa-solid fa-tally-1"></i> : null}

                  {isMessageChanged ? (
                    <Tooltip
                      title={`Đã chỉnh sửa`}
                      arrow
                      placement="top"
                      sx={{
                        maxWidth: '120px'
                      }}
                    >
                      <Box>
                        <i
                          style={{
                            fontSize: '12px',
                            opacity: 0.75,
                            padding: '0 4px'
                          }}
                          className="fa-light fa-pen"
                        ></i>
                      </Box>
                    </Tooltip>
                  ) : null}
                  <div>
                    <div
                      style={{
                        background: isHighlightedMessage ? '#fafafa' : '',
                        color: isHighlightedMessage ? '#373536' : '',
                        whiteSpace: 'pre-line',
                        fontSize: hasEmojiOnly ? '1.4rem' : '0.89rem'
                      }}
                    >
                      {conversation &&
                      conversation.type === 'group' &&
                      message &&
                      message?.mentions &&
                      message?.mentions.length > 0
                        ? checkMentions(message)
                        : isForward
                        ? checkForward(message?.msg)
                        : message?.msg}
                    </div>

                    {message?.reactions &&
                    ![
                      'image',
                      'video',
                      'gif',
                      'reply',
                      'link',
                      'audio'
                    ].includes(classifyTypeMessage(message))
                      ? renderPostReactEmojis()
                      : null}
                  </div>
                </Box>
              </div>
            );
        }

        case 'image': {
          return (
            <DetailMessageImage
              message={message}
              borderTopMessage={borderTopMessage}
              borderBottomMessage={borderBottomMessage}
              showTimeSend={showTimeSend}
              nextMessageShowTimeSend={nextMessageShowTimeSend}
              renderPostReactEmojis={renderPostReactEmojis}
              meInfo={meInfo}
              conversation={conversation}
              getBorderRadius={getBorderRadius}
            />
          );
        }

        case 'video': {
          return (
            <DetailMessageVideo
              message={message}
              conversation={conversation}
              renderPostReactEmojis={renderPostReactEmojis}
              type={typeBoxChat}
            />
          );
        }

        case 'text':
        case 'application': {
          return (
            <DetailMessageFile
              message={message}
              classes={classes}
              borderTopMessage={borderTopMessage}
              borderBottomMessage={borderBottomMessage}
              showTimeSend={showTimeSend}
              nextMessageShowTimeSend={nextMessageShowTimeSend}
              renderPostReactEmojis={renderPostReactEmojis}
              conversation={conversation}
              mainTopic={mainTopic}
              getBorderRadius={getBorderRadius}
              theme={theme}
            />
          );
        }

        case 'audio': {
          return (
            <DetailMessageAudio
              message={message}
              reactions={reactions}
              totalReactions={totalReactions}
              classes={classes}
              renderPostReactEmojis={renderPostReactEmojis}
              mainTopic={mainTopic}
              theme={theme}
              conversation={conversation}
            />
          );
        }

        case 'link': {
          const imageUrl =
            message?.metaEmso && Object.keys(message?.metaEmso).length > 0
              ? message?.metaEmso?.response?.image_url
              : message.urls[0]?.meta?.ogImage ??
                message.urls[0]?.meta?.oembedThumbnailUrl;

          const displayStyle = imageUrl ? {} : { display: 'none' };
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                id={message?._id}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  // borderRadius: '8px 8px 8px 8px',
                  width: match?.path === PATHS.CHAT ? 'inherit' : 'unset',
                  maxWidth: '300px',
                  position: 'relative',
                  borderRadius: getBorderRadius(
                    conversation,
                    message,
                    rocketId,
                    borderTopMessage,
                    borderBottomMessage,
                    nextMessageShowTimeSend,
                    showTimeSend
                  )
                }}
              >
                {/* header */}
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    background:
                      conversation && !conversation?.is_user_chat
                        ? message?.type !== 'automatic_reply' &&
                          message?.u?._id === rocketId
                          ? mainTopic
                            ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                            : buttonColor.backgroundColor
                          : theme.palette.mode === 'light'
                          ? '#e4e6eb'
                          : '#3e4042'
                        : (message && message.type === 'automatic_reply') ||
                          (message?.u?._id === rocketId &&
                            conversation.t !== 'cp') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage === false &&
                            match?.params?.key !== 'chat') ||
                          (message &&
                            conversation.t === 'cp' &&
                            message.isPage &&
                            match?.params?.key === 'chat')
                        ? mainTopic
                          ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                          : buttonColor.backgroundColor
                        : theme.palette.mode === 'light'
                        ? '#e4e6eb'
                        : '#3e4042',
                    padding: '8px 12px',
                    color: conversation
                      ? message?.type !== 'automatic_reply' &&
                        message?.u?._id === rocketId
                        ? '#fff'
                        : theme.palette.mode === 'light'
                        ? '#050505'
                        : '#fff'
                      : (message && message.type === 'automatic_reply') ||
                        message?.u?._id === rocketId
                      ? '#fff'
                      : theme.palette.mode === 'light'
                      ? '#050505'
                      : '#fff',
                    borderRadius: '8px 8px 0px 0px',
                    fontWeight: '400'
                  }}
                >
                  <Typography fontSize="15px" fontWeight="400" textAlign="left">
                    {checkMentionsDetail(message)}
                    {renderLinkMess(message?.urls, message)}
                  </Typography>
                </div>
                {/* image body */}
                <Box
                  onClick={() => {
                    window.open(message.urls[0].url, '_blank');
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: 'auto',
                      ...displayStyle
                    }}
                    src={imageUrl}
                    alt={message.urls[0]?.meta?.ogImage}
                  />
                  {/* link footer */}
                  <div className={classes.styleDivLink}>
                    <Typography
                      textAlign="left"
                      fontSize="14px"
                      fontWeight="600"
                      sx={{
                        lineHeight: '1.333333',
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                      {collapseString(
                        message?.metaEmso?.response?.title ??
                          message?.urls[0]?.meta?.ogTitle ??
                          message?.urls[0]?.meta?.pageTitle,
                        70
                      )}
                    </Typography>
                  </div>
                </Box>
                {message?.reactions ? renderPostReactEmojis() : null}
              </Box>
            </div>
          );
        }

        case 'reply': {
          return (
            <DetailMessageReply
              message={message}
              conversation={conversation}
              reactions={reactions}
              totalReactions={totalReactions}
              classes={classes}
              checkMentions={checkMentions}
              borderTopMessage={borderTopMessage}
              borderBottomMessage={borderBottomMessage}
              showTimeSend={showTimeSend}
              nextMessageShowTimeSend={nextMessageShowTimeSend}
              renderPostReactEmojis={renderPostReactEmojis}
              listMemberGrChat={listMemberGrChat}
              handleClickActionMessage={handleClickActionMessage}
              showAction={showAction}
              renderToolTipEmoji={renderToolTipEmoji}
              styleDivShowAction={classes.styleDivShowAction}
              styleDefaultSender={classes.styleDefaultSender}
              styleDivShowActionItem={classes.styleDivShowAction_item}
              listActionMessages={listActionMessages}
              setShowAction={setShowAction}
              anchorElMore={anchorElMore}
              anchorElReact={anchorElReact}
              typeBoxChat={typeBoxChat}
              mainTopic={mainTopic}
              repliedMessage={repliedMessage}
              getBorderRadius={getBorderRadius}
              isHighlightedMessage={isHighlightedMessage}
            />
          );
        }

        case 'gif': {
          let style;
          if (message.msg.startsWith('https://sdk.mojitok.im')) {
            style = {
              width: '64px',
              height: '64px'
            };
          } else {
            style = {
              width: '160px',
              height: 'auto'
            };
          }
          return (
            <Box
              id={message?._id}
              sx={{
                padding: 0,
                marginTop: '2px',
                position: 'relative'
              }}
              style={style}
            >
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  borderRadius: getBorderRadius(
                    conversation,
                    message,
                    rocketId,
                    borderTopMessage,
                    borderBottomMessage,
                    nextMessageShowTimeSend,
                    showTimeSend
                  )
                }}
                src={message.msg}
                alt={message.msg}
              />
              {message.reactions ? renderPostReactEmojis() : null}
            </Box>
          );
        }
        case 'vote': {
          return (
            <DetailMessageVoted
              message={message}
              conversation={conversation}
              myMessageStyle={myMessageStyle}
              senderMessageStyle={senderMessageStyle}
              mainTopic={mainTopic}
              typeBoxChat={typeBoxChat}
              renderPostReactEmojis={renderPostReactEmojis}
            />
          );
        }

        default: {
          return (
            <Box
              style={{
                display: 'flex',
                alignItems:
                  message.u?._id === rocketId ? 'flex-end' : 'flex-start',
                flexDirection: 'column',
                position: 'relative',
                // width: typeBoxChat === 'fullScreen' ? '60%' : '40%'
                width: '100%'
              }}
            >
              <Box
                sx={
                  message.u?._id === rocketId
                    ? myMessageStyle.box
                    : senderMessageStyle.box
                }
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  background:
                    message.u?._id === rocketId
                      ? mainTopic
                        ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                        : buttonColor.backgroundColor
                      : theme.palette.mode === 'light'
                      ? '#e4e6eb'
                      : '#3e4042',
                  color:
                    message.u?._id === rocketId
                      ? '#fff'
                      : theme.palette.mode === 'light'
                      ? '#050505'
                      : '#fff',
                  borderRadius: getBorderRadius(
                    conversation,
                    message,
                    rocketId,
                    borderTopMessage,
                    borderBottomMessage,
                    nextMessageShowTimeSend,
                    showTimeSend
                  ),
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  alignItems: 'center',
                  opacity: isForward ? 0.9 : 1
                }}
              >
                <a
                  href={message?.msg}
                  style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color:
                      message.u?._id === rocketId
                        ? '#fff'
                        : theme.palette.mode === 'light'
                        ? '#050505'
                        : '#fff'
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {message?.msg}
                </a>
                {/* </a> */}
                {isShowIframe && (
                  <iframe
                    src={`${urlify(message?.msg)}`}
                    width="300px"
                    height="300px"
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 10
                    }}
                    title={message?.msg}
                  />
                )}
              </Box>
              {message?.reactions ? renderPostReactEmojis() : null}
            </Box>
          );
        }
      }
    };
    const renderMessageTypeEvent = message => {
      const messageContent = message?.msg;

      switch (message?.t) {
        case 'r': {
          return `${subjectName} đã đặt tên nhóm là ${messageContent}.`;
        }

        case 'ul':
        case 'ult': {
          return `${messageContent} đã rời khỏi nhóm.`;
        }

        case 'uj':
        case 'ujt': {
          return `${messageContent} đã tham gia vào nhóm.`;
        }

        case 'rm': {
          return 'tin nhắn bị gỡ bỏ';
        }

        case 'ut': {
          return 'tin nhan ut';
        }

        case 'au':
        case 'added-user-to-team': {
          return `${subjectName} đã thêm ${messageContent} vào nhóm.`;
        }

        case 'ru':
        case 'removed-user-from-team': {
          return `${subjectName} đã xóa ${messageContent} khỏi nhóm.`;
        }

        case 'user-muted': {
          return 'tin nhan user-muted';
        }

        case 'subscription-role-removed': {
          return `${subjectName} đã gỡ quyền quản trị viên của ${messageContent}.`;
        }

        case 'subscription-role-added': {
          return `${subjectName} đã thêm ${messageContent} làm quản trị viên.`;
        }

        case 'room_changed_avatar': {
          return `${subjectName} đã đổi ảnh đại diện nhóm.`;
        }
        case 'room_changed_topic': {
          return `${subjectName} đã đổi chủ đề thành ${messageContent?.title}.`;
        }
        case 'room_e2e_enabled': {
          if (messageContent.icon) {
            return `${subjectName} đã đặt cảm xúc nhanh thành ${messageContent?.icon}.`;
          }
          if (!messageContent.icon) {
            return `${subjectName} đã gỡ biểu tượng cảm xúc nhanh.`;
          }
        }
        case 'message_pinned': {
          return `${subjectName} đã ghim một tin nhắn.`;
        }
        case 'message_unpinned': {
          return `${subjectName} đã bỏ ghim một tin nhắn.`;
        }
        case 'room_changed_privacy': {
          return `${subjectName} đã ${
            message.msg === 'public' ? 'tắt' : 'bật'
          } tính năng phê duyệt thành viên ${
            message.msg === 'public'
              ? 'và bất cứ ai đều có thể thêm người mới vào nhóm.'
              : 'và chỉ có quản trị viên có thể thêm người mới vào nhóm.'
          }`;
        }
        case 'room_changed_description': {
          return message.msg === 'admin'
            ? `${subjectName} chỉ cho phép quản trị viên gửi tin nhắn.`
            : `${subjectName} đã cho phép tất cả mọi người gửi tin nhắn.`;
        }
        case 'room-set-read-only': {
          return `${subjectName} chỉ cho phép quản trị viên gửi tin nhắn.`;
        }
        case 'room-removed-read-only': {
          return `${subjectName} cho phép tất cả mọi người gửi tin nhắn.`;
        }
        default: {
          return '';
        }
      }
    };
    const handleClickActionMessage = (
      id,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (id === 'more') {
        setAnchorElMore(event.currentTarget);
      } else if (id === 'react') {
        setAnchorElReact(event.currentTarget);
      } else {
        // handleReply message
        handleReplyMessage(message);
      }
    };
    const handleDeleteMessage = async () => {
      try {
        const response = await deleteMessage(message._id);
        setAnchorElMore(null);

        if (response.status === 200) {
          // console.log('[data]', response.data);
          setIsToastMessage(true);
          setCodeNoti(200);

          setTitleToastMessage('Đã xóa tin nhắn thành công.');
        }
      } catch (error) {
        // console.log(error);
        setIsToastMessage(true);
        setCodeNoti(400);

        setTitleToastMessage('Xóa tin nhắn thất bại.');
      }
    };

    const handleUpdateMessage = () => {
      setUpdatedMessage(message);
      setAnchorElMore(null);
    };

    const handleReactMessage = async id => {
      try {
        const messageId: string = message?._id;
        const react: string =
          reactConvert.find(el => el?.id === id)?.name ?? ':thumbsup:';
        await setReactMessage(messageId, react);
      } catch (error) {}
    };
    // const statusMessageSend = message?.
    const renderToolTipEmoji = () => {
      return (
        <Tooltip
          title={contentTooltip}
          placement="top"
          key={'reaction' + message?._id}
          sx={{ maxWidth: '200px' }}
        >
          <Box>
            <ReactEmoji
              style={{
                width: '14px !important',
                height: '14px !important'
              }}
              onChange={id => {
                handleReactMessage(id);
              }}
              setContentTooltip={setContentTooltip}
            >
              {(popupState, bindHover) => {
                return (
                  <Typography
                    {...bindHover(popupState)}
                    component="span"
                    variant="body2"
                    // className={classes.inline}
                    style={{
                      paddingLeft: 35,
                      display: 'inline',
                      padding: 3.5,
                      fontWeight: '600 !important',
                      color: '#65676b',
                      fontSize: '12px !important',
                      '&:hover': {
                        textDecoration: 'underline'
                      },
                      cursor: 'pointer'
                    }}
                    color="textPrimary"
                    onClick={() => {}}
                  >
                    <i
                      style={{ fontSize: '15px' }}
                      className="fa-light fa-face-smile"
                    />
                  </Typography>
                );
              }}
            </ReactEmoji>
          </Box>
        </Tooltip>
      );
    };
    const timeShowMessage = ` ${
      message?.editedAt
        ? handleTimeShow(message?.editedAt?.$date)
        : handleTimeShow(message?.ts?.$date) ?? ' '
    }`;
    const timeSendBoundary = date => {
      let day = `0 + ${new Date(date).getDate()}`.slice(-2);
      let month = `0 ${new Date(date).getMonth() + 1}`.slice(-2);
      let year = new Date(date).getFullYear();
      return `${day} / ${month} / ${year}`;
    };

    const PinnedMessageComponents = props => {
      return isPinnedMessage ? (
        <Box
          sx={
            conversation?.t !== 'cp'
              ? message.type !== 'automatic_reply' &&
                message?.u?._id === rocketId
                ? myMessageStyle.forwardMessage
                : senderMessageStyle.forwardMessage
              : (message && message.type === 'automatic_reply') ||
                message?.u?._id === rocketId
              ? myMessageStyle.forwardMessage
              : senderMessageStyle.forwardMessage
          }
          style={{
            fontSize: '10px',
            color: theme.palette.mode === 'light' ? '#20262E60' : '#E9E8E860',
            width: 'full',
            position: 'relative',
            marginLeft: '40px'
          }}
        >
          <Typography fontSize={12}>Đã ghim</Typography>
          <i
            className="fa-solid fa-map-pin"
            style={{ color: '#f8466a', transform: 'rotate(45deg)' }}
          ></i>
        </Box>
      ) : null;
    };

    return (
      <>
        {/* {console.log('render message')} */}
        {showTimeSend ? (
          <div
            // id="showTimeSend"
            style={{
              width: '100%',
              marginTop: '16px',
              marginBottom: '16px',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: '500',
              color: '#8a8d91'
            }}
          >
            {timeSendBoundary(message?.ts?.$date)}
          </div>
        ) : null}
        {message?.t ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '10px 20px'
            }}
          >
            <div
              style={{
                maxWidth: '80%',
                height: '100%',
                textAlign: 'center',
                lineHeight: 1.3
              }}
            >
              <Typography
                style={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  color: '#8a8d91'
                }}
              >
                {renderMessageTypeEvent(message)}
                {message?.t === 'message_pinned' ||
                message?.t === 'message_unpinned' ? (
                  <span
                    style={{
                      color: mainTopic
                        ? mainTopic?.[0]
                        : buttonColor.backgroundColor,
                      cursor: 'pointer',
                      fontWeight: 600
                    }}
                    onClick={() => setOpenDialogViewPinMessage(true)}
                  >
                    {' '}
                    Xem tất cả
                  </span>
                ) : null}
              </Typography>
            </div>
          </div>
        ) : conversation && conversation.t !== 'cp' ? (
          message &&
          message?.u?._id === rocketId &&
          message.type !== 'automatic_reply' ? (
            // my message
            <Box
              style={{
                width: '90%',
                height: '100%',
                float: 'right',
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '0px',
                marginBottom: message?.reactions ? '15px' : undefined
              }}
              onMouseEnter={(event: any) => {
                event.preventDefault();
                setShowAction(true);
              }}
              onMouseLeave={() => {
                if (Boolean(anchorElReact) || openPopoverElMore) {
                  return;
                } else {
                  setShowAction(false);
                }
              }}
              // ref={refTxt}
              // id="BoxMessage"
            >
              <ListItem
                sx={myMessageStyle.item}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                  padding:
                    conversation?.t !== 'cp'
                      ? messageNext?.u?._id === rocketId
                        ? '2px 0px 0px'
                        : '10px 0px 0px 0px'
                      : messageNext?.u?._id !== conversation?.u?._id
                      ? '2px 0px 0px'
                      : '10px 0px 0px 0px'
                }}
              >
                {isForward ? (
                  <Box
                    sx={
                      conversation?.t !== 'cp'
                        ? message.type !== 'automatic_reply' &&
                          message?.u?._id === rocketId
                          ? myMessageStyle.forwardMessage
                          : senderMessageStyle.forwardMessage
                        : (message && message.type === 'automatic_reply') ||
                          message?.u?._id === rocketId
                        ? myMessageStyle.forwardMessage
                        : senderMessageStyle.forwardMessage
                    }
                    style={{
                      fontSize: '10px',
                      color:
                        theme.palette.mode === 'light'
                          ? '#20262E60'
                          : '#E9E8E860',
                      width: 'full',
                      position: 'relative',
                      right: 0
                      //marginLeft: '40px'
                    }}
                  >
                    <i className="fa-solid fa-right"></i>
                    <Typography fontSize={12}>
                      {' '}
                      {subjectName} đã chuyển tiếp 1 tin nhắn
                    </Typography>
                  </Box>
                ) : null}
                <PinnedMessageComponents />
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'flex-end',
                    position: 'relative'
                  }}
                >
                  <div className={classes.styleDivShowAction}>
                    {showAction && classifyTypeMessage(message) !== 'reply' ? (
                      <div className={classes.styleDivShowAction_item}>
                        {listActionMessages.map((el, index) => {
                          return (
                            <Tooltip
                              title={el.title}
                              placement="top"
                              key={index}
                            >
                              <Box>
                                <IconButton
                                  aria-describedby={idPopoverElMore}
                                  sx={{ width: '14px', height: '14px' }}
                                  onClick={e =>
                                    handleClickActionMessage(el.id, e)
                                  }
                                >
                                  <i
                                    style={{
                                      fontSize: '15px',
                                      color: '#8f9296'
                                    }}
                                    className={el.icon}
                                  />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        })}
                        {renderToolTipEmoji()}
                      </div>
                    ) : classifyTypeMessage(message) !== 'reply' ? (
                      <div style={{ width: '60px', height: '30px' }}></div>
                    ) : null}
                  </div>
                  <Tooltip
                    title={timeShowMessage}
                    arrow
                    sx={{
                      maxWidth: ['video', 'image', 'link', 'reply'].includes(
                        classifyTypeMessage(message)
                      )
                        ? 'calc(100% - 80px)'
                        : 'unset'
                    }}
                  >
                    <Box>{renderContentMessage(message)}</Box>
                    {/* render tin nhắn bên phải (tin nhắn mình gửi) */}
                  </Tooltip>
                </div>
              </ListItem>
            </Box>
          ) : // sender message

          !message?.color && !message?.title && !message?.emoticon ? (
            <Box
              sx={{
                width: '90%',
                height: '100%',
                float: 'left',
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '0px',
                marginBottom: message?.reactions ? '15px' : undefined
              }}
              onMouseEnter={(event: any) => {
                event.preventDefault();
                setShowAction(true);
              }}
              onMouseLeave={() => {
                if (Boolean(anchorElReact) || openPopoverElMore) {
                  return;
                } else {
                  setShowAction(false);
                }
              }}
              // ref={refTxt}
              // id="BoxMessage"
            >
              <ListItem
                sx={senderMessageStyle.item}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  width: '100%',
                  padding:
                    messageNext?.u?._id === rocketId
                      ? '10px 0px 0px 8px'
                      : '2px 0px 0px 8px'
                }}
              >
                {isForward ? (
                  <Box
                    sx={
                      conversation?.t !== 'cp'
                        ? message.type !== 'automatic_reply' &&
                          message?.u?._id === rocketId
                          ? myMessageStyle.forwardMessage
                          : senderMessageStyle.forwardMessage
                        : (message && message.type === 'automatic_reply') ||
                          message?.u?._id === rocketId
                        ? myMessageStyle.forwardMessage
                        : senderMessageStyle.forwardMessage
                    }
                    style={{
                      fontSize: '10px',
                      color:
                        theme.palette.mode === 'light'
                          ? '#20262E60'
                          : '#E9E8E860',
                      width: 'full',
                      position: 'relative',
                      right: 0,
                      marginLeft: '40px'
                    }}
                  >
                    <i className="fa-solid fa-right"></i>
                    <Typography fontSize={12}>
                      {' '}
                      {subjectName} đã chuyển tiếp 1 tin nhắn
                    </Typography>
                  </Box>
                ) : null}
                <PinnedMessageComponents />
                <Box
                  style={{
                    display: 'flex',
                    margin: '0.5px 0',
                    width: 'inherit'
                  }}
                >
                  <Box className={classes.styleShowAvatarSender}>
                    {showAvatar ? (
                      <Tooltip
                        title={
                          conversation.fname
                        }
                        arrow
                        placement="left"
                        sx={{ maxWidth: '227px' }}
                      >
                        <Box>
                          <AvatarRenderMessage
                            message={message}
                            conversation={conversation}
                            setUserSelectedData={setUserSelectedData}
                            setOpenDialogUserChatGroup={
                              setOpenDialogUserChatGroup
                            }
                            setUserAboutData={setUserAboutData}
                            match={match}
                            urlRocketChat={urlRocketChat}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <div
                        style={{
                          width: '30px',
                          height: '30px',
                          marginRight: '4px'
                        }}
                      ></div>
                    )}
                  </Box>

                  <Box className={classes.styleDivConversation}>
                    <Tooltip
                      title={timeShowMessage}
                      arrow
                      placement="left"
                      sx={{
                        maxWidth: ['video', 'image', 'link', 'reply'].includes(
                          classifyTypeMessage(message)
                        )
                          ? 'calc(100% - 80px)'
                          : 'unset'
                      }}
                    >
                      <Box>{renderContentMessage(message)}</Box>
                      {/* render tin nhắn bên trái (tin nhắn người khác gửi đến) */}
                    </Tooltip>

                    <Box className={classes.styleDefaultSender}>
                      {showAction &&
                      classifyTypeMessage(message) !== 'reply' ? (
                        <div className={classes.styleDivShowAction_item}>
                          {renderToolTipEmoji()}
                          {listActionMessages.reverse().map((el, index) => {
                            return (
                              <Tooltip
                                title={el.title}
                                placement="top"
                                key={index}
                              >
                                <Box>
                                  <IconButton
                                    aria-describedby={idPopoverElMore}
                                    sx={{ width: '14px', height: '14px' }}
                                    onClick={e =>
                                      handleClickActionMessage(el.id, e)
                                    }
                                  >
                                    <i
                                      style={{
                                        fontSize: '15px',
                                        color: '#8f9296'
                                      }}
                                      className={el.icon}
                                    />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            );
                          })}
                        </div>
                      ) : classifyTypeMessage(message) !== 'reply' ? (
                        <div style={{ width: '60px', height: '30px' }}></div>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </ListItem>
            </Box>
          ) : null
        ) : (message && message.type === 'automatic_reply') ||
          (message &&
            conversation.t === 'cp' &&
            message.isPage === false &&
            match?.params?.key !== 'chat') ||
          (message &&
            conversation.t === 'cp' &&
            message.isPage &&
            match?.params?.key === 'chat') ? (
          // người gửi
          <Box
            style={{
              width: match?.path === PATHS.CHAT ? '60%' : '90%',
              height: '100%',
              float: 'right',
              justifyContent: 'space-between',
              display: 'flex',
              marginTop: '0px',
              marginBottom: message?.reactions ? '15px' : undefined
            }}
            onMouseEnter={(event: any) => {
              event.preventDefault();
              setShowAction(true);
            }}
            onMouseLeave={() => {
              if (Boolean(anchorElReact) || openPopoverElMore) {
                return;
              } else {
                setShowAction(false);
              }
            }}
            // ref={refTxt}
            // id="BoxMessage"
          >
            <ListItem
              sx={myMessageStyle.item}
              style={{
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'flex-start',
                alignItems: 'flex-end',
                padding:
                  conversation?.t !== 'cp'
                    ? messageNext?.u?._id === rocketId
                      ? '2px 0px 0px'
                      : '10px 0px 0px 0px'
                    : messageNext?.u?._id !== conversation?.u?._id
                    ? '2px 0px 0px'
                    : '10px 0px 0px 0px'
              }}
            >
              {isForward ? (
                <Box
                  sx={
                    conversation?.t !== 'cp'
                      ? message.type !== 'automatic_reply' &&
                        message?.u?._id === rocketId
                        ? myMessageStyle.forwardMessage
                        : senderMessageStyle.forwardMessage
                      : (message && message.type === 'automatic_reply') ||
                        message?.u?._id === rocketId
                      ? myMessageStyle.forwardMessage
                      : senderMessageStyle.forwardMessage
                  }
                  style={{
                    fontSize: '10px',
                    color:
                      theme.palette.mode === 'light'
                        ? '#20262E60'
                        : '#E9E8E860',
                    width: 'full',
                    position: 'relative',
                    right: 0,
                    marginLeft: '40px'
                  }}
                >
                  <i className="fa-solid fa-right"></i>
                  <Typography fontSize={12}>
                    {' '}
                    {subjectName} đã chuyển tiếp 1 tin nhắn
                  </Typography>
                </Box>
              ) : null}
              <PinnedMessageComponents />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'flex-end',
                  position: 'relative'
                }}
              >
                <div className={classes.styleDivShowAction}>
                  {showAction && classifyTypeMessage(message) !== 'reply' ? (
                    <div className={classes.styleDivShowAction_item}>
                      {listActionMessages.map((el, index) => {
                        return (
                          <Tooltip title={el.title} placement="top" key={index}>
                            <Box>
                              <IconButton
                                aria-describedby={idPopoverElMore}
                                sx={{ width: '14px', height: '14px' }}
                                onClick={e =>
                                  handleClickActionMessage(el.id, e)
                                }
                              >
                                <i
                                  style={{ fontSize: '15px', color: '#8f9296' }}
                                  className={el.icon}
                                />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        );
                      })}
                      {renderToolTipEmoji()}
                    </div>
                  ) : classifyTypeMessage(message) !== 'reply' ? (
                    <div style={{ width: '60px', height: '30px' }}></div>
                  ) : null}
                </div>

                <Tooltip
                  title={timeShowMessage}
                  arrow
                  sx={{
                    maxWidth: '227px'
                  }}
                >
                  <Box>{renderContentMessage(message)}</Box>
                </Tooltip>
              </div>
            </ListItem>
          </Box>
        ) : (
          <Box
            style={{
              width: match?.path === PATHS.CHAT ? '60%' : '90%',
              height: '100%',
              float: 'left',
              justifyContent: 'space-between',
              display: 'flex',
              marginTop: '0px',
              marginBottom: message?.reactions ? '15px' : undefined
            }}
            onMouseEnter={(event: any) => {
              event.preventDefault();
              setShowAction(true);
            }}
            onMouseLeave={() => {
              if (Boolean(anchorElReact) || openPopoverElMore) {
                return;
              } else {
                setShowAction(false);
              }
            }}
            // ref={refTxt}
            // id="BoxMessage"
          >
            <ListItem
              sx={senderMessageStyle.item}
              style={{
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '100%',
                padding:
                  messageNext?.u?._id === rocketId
                    ? '10px 0px 0px 8px'
                    : '2px 0px 0px 8px'
              }}
            >
              {isForward ? (
                <Box
                  sx={
                    conversation?.t !== 'cp'
                      ? message.type !== 'automatic_reply' &&
                        message?.u?._id === rocketId
                        ? myMessageStyle.forwardMessage
                        : senderMessageStyle.forwardMessage
                      : message?.type === 'automatic_reply' ||
                        message?.u?._id !== conversation?.u?._id
                      ? myMessageStyle.forwardMessage
                      : senderMessageStyle.forwardMessage
                  }
                  style={{
                    fontSize: '10px',
                    color:
                      theme.palette.mode === 'light'
                        ? '#20262E60'
                        : '#E9E8E860',
                    width: 'full',
                    position: 'relative',
                    right: 0,
                    marginLeft: '40px'
                  }}
                >
                  <i className="fa-solid fa-right"></i>
                  <Typography fontSize={12}>
                    {' '}
                    {subjectName} đã chuyển tiếp 1 tin nhắn
                  </Typography>
                </Box>
              ) : null}
              <PinnedMessageComponents />
              <Box
                style={{ display: 'flex', margin: '0.5px 0', width: 'inherit' }}
              >
                <Box className={classes.styleShowAvatarSender}>
                  {showAvatar ? (
                    <Tooltip
                      title={message ? message?.u?.name : null}
                      arrow
                      placement="left"
                      sx={{ maxWidth: '227px' }}
                    >
                      <Box>
                        <AvatarRenderMessage
                          message={message}
                          conversation={conversation}
                          setUserSelectedData={setUserSelectedData}
                          setOpenDialogUserChatGroup={
                            setOpenDialogUserChatGroup
                          }
                          setUserAboutData={setUserAboutData}
                          match={match}
                          urlRocketChat={urlRocketChat}
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '4px'
                      }}
                    ></div>
                  )}
                </Box>

                <Box className={classes.styleDivConversation}>
                  <Tooltip
                    title={timeShowMessage}
                    arrow
                    placement="left"
                    sx={{ maxWidth: '227px' }}
                  >
                    <Box>{renderContentMessage(message)}</Box>
                  </Tooltip>

                  <Box className={classes.styleDefaultSender}>
                    {showAction && classifyTypeMessage(message) !== 'reply' ? (
                      <div className={classes.styleDivShowAction_item}>
                        {renderToolTipEmoji()}
                        {listActionMessages
                          .filter(el => el?.display !== 'none')
                          .reverse()
                          .map((el, index) => {
                            return (
                              <Tooltip
                                title={el.title}
                                placement="top"
                                key={index}
                              >
                                <IconButton
                                  aria-describedby={idPopoverElMore}
                                  sx={{ width: '14px', height: '14px' }}
                                  onClick={e =>
                                    handleClickActionMessage(el.id, e)
                                  }
                                >
                                  <i
                                    style={{
                                      fontSize: '15px',
                                      color: '#8f9296'
                                    }}
                                    className={el.icon}
                                  />
                                </IconButton>
                              </Tooltip>
                            );
                          })}
                      </div>
                    ) : classifyTypeMessage(message) !== 'reply' ? (
                      <div style={{ width: '60px', height: '30px' }}></div>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </ListItem>
          </Box>
        )}
        {openPopoverElMore ? (
          <Popover
            id={idPopoverElMore}
            open={openPopoverElMore}
            anchorEl={anchorElMore}
            onClose={() => {
              setAnchorElMore(null);
            }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
            style={{
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            <List sx={{ width: '100%', height: '100%' }}>
              {actionMessage.map((el: any, index: any) => (
                <ListItemButton
                  className={classes.styleButtonActionMessage}
                  style={{ ...el.styleButton }}
                  key={index}
                >
                  <ListItem onClick={el?.action}>
                    <span className={classes.styleTitleActionMessage}>
                      {el?.title}
                    </span>
                  </ListItem>
                </ListItemButton>
              ))}
            </List>
          </Popover>
        ) : null}

        {Boolean(anchorElReact) ? (
          <Popover
            open={Boolean(anchorElReact)}
            anchorEl={anchorElReact}
            onClose={() => {
              setAnchorElReact(null);
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
          >
            reaction
          </Popover>
        ) : null}

        {openDialogShareToMessenger ? (
          <DialogForwardMessage
            open={openDialogShareToMessenger}
            handleClose={() => setOpenDialogShareToMessenger(false)}
            message={message}
            meInfo={meInfo}
          />
        ) : null}

        {openDialogViewPinMessage ? (
          <DialogViewPinMessage
            open={openDialogViewPinMessage}
            setOpen={setOpenDialogViewPinMessage}
            conversation={conversation}
          />
        ) : null}

        {openDialogUserChatGroup && (
          <DialogUserChatGroup
            open={openDialogUserChatGroup}
            setOpen={setOpenDialogUserChatGroup}
            userSelectedData={userSelectedData}
            conversation={conversation}
            userAboutData={userAboutData}
          />
        )}
      </>
    );
  }
);

export default Index;
