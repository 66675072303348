import * as React from 'react';
import _ from 'lodash';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  InputAdornment,
  TextField,
  DialogActions,
  ListItemText,
  ListItemAvatar,
  ListItem,
  ListItemButton,
  Checkbox,
  Stack,
  Skeleton,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { addMemberGroupChat } from 'src/apis/socialChat.apis';
import { searchUserGlobal } from 'src/apis/socialSearch.api';

import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { buttonColor } from 'src/constants/styles';

export default function DialogAddMemberGroupChat(props) {
  const { open, setOpen, conversation, fetchListMember, members } = props;
  const theme = useTheme();
  const [keyword, setKeyword] = React.useState('');
  const [listUser, setListUser] = React.useState<Object[]>([]);
  const [listUserSelected, setListUserSelected] = React.useState<Object[]>([]);
  const [isLoadingMember, setIsLoadingMember] = React.useState<boolean>(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddMemberGroupChat = async () => {
    const listUsers = listUserSelected.map(
      (el: any) => el.username ?? el.userName
    );
    const roomId = conversation.rid;
    try {
      const response = await addMemberGroupChat(roomId, listUsers);
      if (response.status === 200) {
        setKeyword('');
        setListUser([]);
        setOpen(false);
        fetchListMember();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  function filterMembersSearch(
    currentMembersGroup: any[],
    resultSearch: any[]
  ) {
    let hashTable: any = {};
    const result: any[] = [];
    for (let i = 0; i < currentMembersGroup.length; i++) {
      hashTable[currentMembersGroup[i]._id] = true;
    }

    for (let j = 0; j < resultSearch.length; j++) {
      if (!hashTable[resultSearch[j].userId]) {
        result.push(resultSearch[j]);
      }
    }
    return result;
  }
  const debounceSearchChat = React.useCallback(
    _.debounce(async function (q) {
      let params = {
        type: 'accounts',
        q,
        offset: 1,
        limits: 20
      };
      let response = await searchUserGlobal(params);
      if (response.status === 200) {
        let listConversations = _.cloneDeep(response.data.accounts).map(el => {
          el.displayName = el.display_name;
          el.userName = el.username;
          el.userId = el.id;
          return el;
        });
        // get member username in current group and filter if user is already in group
        const membersOfGroup = conversation?.RoomsList?.find(
          item => item._id === conversation.rid
        )?.members;
        const listUserNotInGroup = listConversations.filter(
          item => !membersOfGroup.includes(item.username)
        );
        setListUser(listUserNotInGroup);
        setIsLoadingMember(false);
      }
    }, 500),
    []
  );

  const handleSearch = keyword => {
    if (keyword) {
      debounceSearchChat(keyword);
    }
  };

  React.useEffect(() => {
    if (!keyword) {
      setListUser([]);
      setIsLoadingMember(true);
    }
    handleSearch(keyword);
  }, [keyword]);

  const handleSelectUser = async user => {
    if (
      listUserSelected.map((el: any) => el.username).includes(user.username)
    ) {
      setListUserSelected(
        listUserSelected.filter((el: any) => el.username !== user.username)
      );
    } else {
      setListUserSelected([...listUserSelected, user]);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            height: 'auto',
            width: '500px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              Thêm người
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: theme.palette.mode === 'light' ? '#505256' : '#fff'
                  }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 8px'
          }}
        >
          <TextField
            fullWidth
            size="small"
            value={keyword}
            onChange={(event: any) => {
              setKeyword(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i
                    style={{ fontSize: 14, fontWeight: 500, color: 'black' }}
                    className="fa-light fa-magnifying-glass"
                    aria-hidden="true"
                  ></i>
                </InputAdornment>
              ),
              sx: {
                '&::placeholder': {}
              },
              disableUnderline: true,
              onKeyDown: e => {
                if (e.key === 'Enter') {
                  handleSearch(keyword);
                }
              }
            }}
            InputLabelProps={{
              style: { fontSize: '19px' }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: '20px',
                  maxHeight: '40px'
                },
                color: '#050505'
              },
              backgroundColor: '#f1f2f5',
              borderRadius: '17px',
              maxHeight: '35px',
              border: 'none !important',
              margin: '10px',
              width: '95%'
            }}
            placeholder="Tìm kiếm"
            inputProps={{
              classes: {
                input: {
                  '&::placeholder': {}
                }
              }
            }}
          />
          <Box
            style={{
              width: '100%',
              height: '400px',
              overflowY: 'auto',
              marginTop: '10px'
            }}
          >
            {isLoadingMember && keyword ? (
              <Stack
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                <Skeleton variant="circular" width={36} height={32} />

                <Skeleton
                  variant="rectangular"
                  sx={{
                    fontSize: '16px',
                    width: '100%',
                    borderRadius: '50px'
                  }}
                />
              </Stack>
            ) : (
              listUser.map((el: any, index) => {
                return (
                  <ListItem
                    key={index}
                    sx={{
                      height: '48px',
                      width: '100%',
                      borderRadius: '10px',
                      padding: '5px'
                    }}
                    secondaryAction={
                      <Checkbox
                        checked={listUserSelected
                          .map((el: any) => el.username)
                          .includes(el.username)}
                        onChange={event => {
                          handleSelectUser(el);
                        }}
                        sx={{
                          color: '#1871ed',
                          '&.Mui-checked': {
                            color: '#1871ed'
                          }
                        }}
                        inputProps={{
                          'aria-label': 'checked'
                        }}
                      />
                    }
                  >
                    <ListItemButton onClick={() => handleSelectUser(el)}>
                      <ListItemAvatar>
                        <AvatarSocial
                          avatarObj={el.avatar_media}
                          style={{
                            width: 36,
                            height: 36,
                            marginRight: '10px',
                            marginLeft: '10px'
                          }}
                          type="feed"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="body2" fontWeight="500">
                            {el.fname}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={'Hủy'}
            style={{
              width: '40px',
              height: '30px',
              // color: buttonColor.backgroundColor,
              // backgroundColor: '#e4e6eb',
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleClose}
          />
          <ButtonInherit
            label={'Thêm'}
            style={{
              width: '40px',
              height: '30px',
              color: '#fff',
              backgroundColor: buttonColor.backgroundColor,
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleAddMemberGroupChat}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
