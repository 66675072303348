import {
  Checkbox,
  ClickAwayListener,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Theme,
  Typography
} from '@mui/material';
import React from 'react';
import {
  createUpdatePostApi,
  crudBookmarkApi,
  deletePostApi,
  hiddenPostDetailApi,
  hideTagUser,
  pinPostApi,
  unPinPostApi
} from 'src/apis/socialPost.api';

import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  blockAndRemovePostGroup,
  removeAccountGroupApi
} from 'src/apis/socialGroup';
import { likeFollowPage } from 'src/apis/socialPages.api';
import {
  completeTargetUser,
  followUser,
  turnOnNotification,
  unFollowUser
} from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import CUPost from 'src/components/CUPost';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import DialogCreateCollectionSaved from 'src/components/Dialog/DialogCreateCollectionSaved';
import DialogEmbed from 'src/components/Dialog/DialogEmbed';
import DialogVisibility from 'src/components/Dialog/DialogPostVisibility';
import DialogReport from 'src/components/Dialog/DialogReport';
import DialogReportAdminGroup from 'src/components/Dialog/DialogReportAdminGroup';
import DialogUpdatePage from 'src/components/Dialog/DialogUpdatePage';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import { updateCachePage } from 'src/store/action/saveCacheAction';
import {
  getRemoveAccountReq,
  updateSchedulePost
} from 'src/store/action/socialGroupAction';
import {
  deleteOldPostSuccess,
  getListPinPostReq,
  notiSharePost,
  pinOrUnpinPostAction,
  schedulePostSuccess,
  unTagMention,
  unbookmarkPostSuccess,
  updateFollowPage,
  updatePostSuccessAction
} from 'src/store/action/socialPostAction';
import { delRatingCourse } from 'src/apis/socialCourse.api';
import { ResponseApi } from 'src/@types/api';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '15px 0px 0px 15px',
    backgroundColor: '#fff',
    marginBottom: 10,
    // borderRadius: 10,
    width: '100%',
    border: '1px solid rgba(0,0,0,0.10)',
    boxShadow: '0 1px 2px rgb(0 0 0 / 20%) !important',
    marginTop: 10,
    minWidth: 550
  },
  listItem: {
    '&.MuiListItemButton-root': {
      cursor: 'text',
      '&:hover': {
        backgroundColor: '#fff'
      },
      '&:focus': {
        backgroundColor: '#fff'
      },
      '&.Mui-focusVisible': {
        '&:focus': {
          backgroundColor: '#fff'
        }
      }
    }
  },
  list: {
    '&.MuiListItem-root': {
      display: 'flex !important',
      padding: '0px 12px 4px 0px'
    }
  },
  firstListItem: {
    marginLeft: 15,
    '&.MuiListItemText-root': {
      fontWeight: 'bold'
    }
  }
}));

interface MenuActionProps {
  roleGroupMember?: any;
  post?: any;
  anchorEl?: any;
  openPopper?: boolean;
  setOpenPopper?: React.Dispatch<React.SetStateAction<boolean>> | any;
  handleCloseMenu?: any;
  type?: String | any;
  setPostData?: React.Dispatch<React.SetStateAction<any>> | any;
  setMediaData?: React.Dispatch<React.SetStateAction<any>> | any;
  listReport?: any;
  typeAction?: String;
  setOpenDialogRating?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setUpdateRating?: React.Dispatch<React.SetStateAction<any>> | any;
  setCreateRating?: React.Dispatch<React.SetStateAction<any>> | any;
  setRatingSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  itemRating?: any;
  ratingSelected?: any;
  idPost?: String;
  setValue?: React.Dispatch<React.SetStateAction<any>> | any;
  setFiles?: React.Dispatch<React.SetStateAction<any>> | any;
  valueRating?: any;
  setPostDetail?: React.Dispatch<React.SetStateAction<any>> | any;
  setListPostRating?: any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  pageInfo?: any;
  eventInfo?: any;
  courseInfo?: any;
  projectInfo?: any;
}

const MenuAction: React.FC<MenuActionProps> = React.memo(props => {
  const {
    roleGroupMember,
    post,
    anchorEl,
    openPopper,
    setOpenPopper,
    handleCloseMenu,
    type,
    setPostData,
    setMediaData,
    listReport,
    typeAction,
    setOpenDialogRating,
    setUpdateRating,
    setCreateRating,
    itemRating,
    ratingSelected,
    setRatingSelected,
    setValue,
    setFiles,
    valueRating,
    setPostDetail,
    setListPostRating,
    setNoti,
    setOpenSnackbar,
    pageInfo,
    eventInfo,
    courseInfo,
    projectInfo
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match: any = useRouteMatch();
  const [openDialogReport, setOpenDialogReport] = React.useState(false);
  const [updateTags, setUpdateTags] = React.useState<any>(false);
  const [openDialogVisibility, setOpenDialogVisibility] = React.useState(false);
  const [openDialogEmbed, setOpenDialogEmbed] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openCUPost, setOpenCUPost] = React.useState(false);
  // const [openSnackbar, setOpenSnackbar] = React.useState(false);
  // const [openDialogUpdateTime, setOpenDialogUpdateTime] =
  //   React.useState<any>(false);
  const [openDialogRoleComment, setOpenDialogRoleComment] =
    React.useState(false);
  const [isLoadingRole, setIsLoadingRole] = React.useState(false);
  const [roleComment, setRoleComment] = React.useState<any>(
    post?.comment_moderation || null
  );
  const [dataEmbed, setDataEmbed] = React.useState<any>(null);
  const [isRemovePost, setIsRemovePost] = React.useState(false);
  const [isRemovePostAndBlock, setIsRemovePostAndBlock] = React.useState(false);
  const [openDialogAdminReport, setOpenDialogAdminReport] =
    React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [checkedBlockAccount, setCheckedBlockAccount] = React.useState(false);
  const [isGetNoti, setIsGetNoti] = React.useState(post?.notify);
  const [loading, setLoading] = React.useState(false);
  const [typeEdit, setEdit] = React.useState<any>(type);
  // const [noti, setNoti] = React.useState<any>({
  //   code: 200,
  //   message: ''
  // });
  const [isFollow, setIsFollow] = React.useState<any>(false);
  const [openDialogCreateCollection, setOpenDialogCreateCollection] =
    React.useState<any>(null);

  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};
  const adminsGroup =
    useSelector((state: any) => state.groupReducer.adminsGroup.admins) || {};
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const listAccountRemove =
    useSelector((state: any) => state.groupReducer.accountsRemove) || [];
  const blockedUserList: string[] = listAccountRemove.map(item => item.id);
  let activitiesCache =
    useSelector((state: any) => {
      return state.saveCacheReducer.activitiesCache;
    }) || [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleChangeCheckedBlock = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedBlockAccount(event.target.checked);
  };

  React.useEffect(() => {
    if (type === 'watch') {
      if (post?.page) {
        if (post?.page?.page_relationship?.following) setIsFollow(true);
        else setIsFollow(false);
      } else if (post?.group) {
        if (post?.account?.relationships?.following) setIsFollow(true);
        else setIsFollow(false);
      }
    }
  }, [post, type]);

  const handleBlockAndRemovePost = async () => {
    if (checkedBlockAccount && !checked) {
      let response = await removeAccountGroupApi(
        groupSelected.id,
        {
          account_id: post?.account.id
        },
        'post'
      );
      if (response.status === 200) {
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Đã chặn tác giả thành công'
        });
        setOpenDelete(false);
        dispatch(getRemoveAccountReq(groupSelected.id));
      }
    } else {
      let response = await blockAndRemovePostGroup(post?.group?.id, {
        status_id: post?.id,
        delete_recent_activity: checked,
        delete_account: checkedBlockAccount
      });
      if (response.status === 200) {
        dispatch(
          notiSharePost({
            code: 200,
            msg:
              checked && !checkedBlockAccount
                ? 'Đã gỡ bài viết trong 7 ngày gần đây thành công.'
                : 'Đã gỡ bài viết và chặn tác giả thành công'
          })
        );
        setOpenDelete(false);
        dispatch(deleteOldPostSuccess(post.id));
        if (checked && checkedBlockAccount) {
          setIsRemovePostAndBlock(true);
          dispatch(getRemoveAccountReq(groupSelected.id));
        }
      }
    }
  };

  const handleLikeFollowPage = async (idPage: any, action: any) => {
    let response: any = await likeFollowPage(idPage, action);
    if (response?.status === 200) {
      setIsFollow(prev => !prev);
      dispatch(updateFollowPage(post, action));
    }
  };

  const handleFollowUnfollowUser = async (idUser: any, action: any) => {
    let res: any;
    if (action === 'follow') {
      res = await followUser(idUser);
    } else if (action === 'unfollow') {
      res = await unFollowUser(idUser);
    }
    if (res?.status === 200) {
      setIsFollow(prev => !prev);
    }
  };

  const displayName: string = post?.account?.display_name
    ? post?.account?.display_name
    : post?.account?.username;

  let postId = post?.id ?? '';
  const checkAdminGroup = () => {
    let result = adminsGroup.find((el: any) => el.account.id === meInfo.id);
    return !!result;
  };

  const handleActionTurnOffNotifications = async () => {
    let response = await turnOnNotification({
      status_id: post?.id,
      allow: false
    });
    if (response.status === 200) {
      setIsGetNoti(false);
      dispatch(
        notiSharePost({
          code: 200,
          msg: 'Đã tắt thông báo'
        })
      );
    }
  };

  const handleActionTurnOnNotifications = async () => {
    let response = await turnOnNotification({
      status_id: post?.id,
      allow: true
    });
    if (response.status === 200) {
      setIsGetNoti(true);
      dispatch(
        notiSharePost({
          code: 200,
          msg: 'Đã bật thông báo'
        })
      );
    }
  };

  const handleCheckRoleComment = async roleComment => {
    let response = await createUpdatePostApi(
      {
        comment_moderation: roleComment
      },
      postId
    );
    if (response.status === 200) {
      setOpenDialogRoleComment(false);
      setIsLoadingRole(false);
    }
  };

  const handleEmbedpost = async () => {
    let urlIframe = `<iframe src="${window.location.origin}/${
      post?.account?.username ?? post?.account?.id
    }/posts/${post?.id}" height="500px" width="500px"></iframe>`;

    setDataEmbed(urlIframe);
    setOpenDialogEmbed(true);
  };

  // const handleUpdateTime = async (typeAction: any, created_update: any) => {
  //   try {
  //     let res = await createUpdatePostApi(
  //       { backdated_time: created_update?.backdated_time },
  //       post?.id
  //     );
  //     if (res.status === 200) {
  //       dispatch(updatePostSuccessAction(res.data));
  //       setOpenSnackbar(true);
  //       setNoti({
  //         code: res.status,
  //         message: 'Chỉnh sửa ngày thành công.'
  //       });
  //     }
  //   } catch (err: any) {
  //     setOpenSnackbar(true);
  //     setNoti({
  //       code: err.response.status,
  //       message: 'Có lỗi trong quá trình xử lý.Vui lòng thử lại sau!'
  //     });
  //   }
  // };

  const handleUnbookmark = async (data: any) => {
    let res: ResponseApi = await crudBookmarkApi(
      'DELETE',
      null,
      data,
      '1',
      null
    );
    if (res.status === 200) {
      dispatch(unbookmarkPostSuccess(data.bookmark_id, res.data));
      setOpenSnackbar(true);
      setNoti({
        code: 200,
        message: 'Bỏ lưu bài viết thành công!'
      });
      setPostData &&
        setPostData(prev => ({ ...prev, bookmarked: res.data.bookmarked }));
      if (match.params?.key === 'schedule_post') {
        dispatch(updateSchedulePost(res.data));
      }
    }
  };

  const pinOrUnpin = async () => {
    if (post?.pinned) {
      const res: ResponseApi = await unPinPostApi(post?.id);
      if (res) {
        dispatch(
          notiSharePost({
            code: 200,
            msg: 'Bỏ ghim bài viết thành công'
          })
        );
        dispatch(pinOrUnpinPostAction(res.data));
      }
    } else {
      const res: ResponseApi = await pinPostApi(post?.id);
      if (res) {
        dispatch(
          notiSharePost({
            code: 200,
            msg: 'Ghim bài viết thành công'
          })
        );
        dispatch(pinOrUnpinPostAction(res.data));
      }
    }
  };

  let listMoreActionTop = [
    {
      key: 'pinPost',
      icon: 'fal fa-thumbtack',
      label: post?.pinned ? 'Bỏ ghim bài viết' : 'Ghim bài viết',
      action: () => {
        handleCloseMenu();
        pinOrUnpin();
      },
      visible:
        (post.target_account && meInfo?.id !== post.target_account.id) ||
        !(
          (meInfo?.id === post.account?.id &&
            (type === 'stream_profile' ||
              type === 'post-detail' ||
              type === 'stream_home')) ||
          (type === 'page' &&
            (rolePage.role === 'admin' || rolePage.role === 'moderator')) ||
          (type === 'stream_group' && groupSelected.group_relationship?.admin)
        )
    },
    {
      key: 'savePost',
      icon: post?.bookmarked ? 'fa-light fa-bookmark-slash' : 'fal fa-bookmark',
      label:
        type === 'watch'
          ? post?.bookmarked
            ? 'Bỏ lưu video'
            : 'Lưu video'
          : post?.bookmarked
          ? 'Bỏ lưu bài viết'
          : 'Lưu bài viết',
      description:
        type === 'watch'
          ? post?.bookmarked
            ? 'Xóa video khỏi danh sách mục đã lưu'
            : 'Thêm vào danh sách mục đã lưu'
          : post?.bookmarked
          ? 'Xóa bài viết khỏi danh sách mục đã lưu'
          : 'Thêm vào danh sách mục đã lưu',
      action: () => {
        if (!post?.bookmarked) {
          setOpenDialogCreateCollection(2);
        } else {
          handleUnbookmark({ bookmark_id: postId });
        }
        handleCloseMenu();
      },
      visible: type === 'rating' || typeAction === 'scheduled_action'
    },
    {
      key: 'follow',
      icon: !isFollow
        ? 'fa-light fa-square-plus fa-lg'
        : 'fa-light fa-square-xmark fa-lg',
      label:
        type === 'watch'
          ? post?.page
            ? isFollow
              ? `Bỏ theo dõi trang ${post?.page?.title}`
              : `Theo dõi trang ${post?.page?.title}`
            : post?.group
            ? isFollow
              ? `Bỏ theo dõi ${displayName}`
              : `Theo dõi ${displayName}`
            : ''
          : '',
      description:
        type === 'watch'
          ? 'Bắt đầu xem video trong danh sách xem và bài viết trên Bảng feed'
          : '',
      action: () => {
        if (post?.page) {
          handleLikeFollowPage(
            post?.page?.id,
            isFollow ? 'unfollows' : 'follows'
          );
        } else if (post?.group) {
          handleFollowUnfollowUser(
            post?.account?.id,
            isFollow ? 'unfollow' : 'follow'
          );
        }

        handleCloseMenu();
      },
      visible: !(type === 'watch')
    }
  ];

  const checkVisibleRemoveTag = post => {
    if (
      post?.account?.id === meInfo.id ||
      (post?.mentions?.findIndex((el: any) => el.id === meInfo.id) === -1 &&
        post?.status_tags?.find((el: any) => el.entity_id === meInfo.id)
          ?.silent)
    ) {
      return true;
    } else if (
      post?.status_tags?.find((el: any) => el.entity_id === meInfo.id)
        ?.silent === false ||
      post?.mentions?.find((el: any) => el?.id === meInfo.id)
        ?.mentions_status === 'pending' ||
      post?.mentions?.find((el: any) => el?.id === meInfo.id)
        ?.mentions_status === 'approved'
    ) {
      return false;
    } else {
      return true;
    }
  };

  let listMoreActionMid = [
    {
      key: 'complete',
      icon: 'fal  fa-bullseye-pointer',
      label: 'Hoàn thành mục tiêu',
      action: () => {
        handleCompleteTarget();
        handleCloseMenu();
      },
      visible: !(
        meInfo?.id === post.account?.id &&
        post.post_type === 'target' &&
        post?.status_target?.target_status === null
      )
    },
    {
      key: 'editPost',
      icon: 'fal fa-edit',
      label: 'Chỉnh sửa bài viết',
      action: () => {
        if (type === 'rating') {
          setOpenDialogRating(true);
          setCreateRating(false);
          setUpdateRating(true);
          setRatingSelected(itemRating);
          setValue(valueRating);
          setFiles(post?.media_attachments);
        } else {
          setOpenCUPost(true);
          setUpdateTags(false);
          setEdit('update');
          setPostData && setPostData(post);
        }
        handleCloseMenu();
      },
      visible:
        type === 'feed' ||
        type === 'all_group' ||
        type === 'watch-search' ||
        (type === 'page'
          ? rolePage.role === '' || post.poll
          : meInfo?.id !== post.account?.id ||
            post.post_type === 'moment' ||
            post.post_type === 'event_banner' ||
            post.post_type === 'event_shared' ||
            post.post_type === 'group_banner' ||
            post.post_type === 'account_avatar' ||
            post.post_type === 'account_banner' ||
            post.post_type === 'page_avatar' ||
            post.post_type === 'page_banner' ||
            post.poll) ||
        type === 'previewMedia'
    },
    {
      key: 'roleComment',
      icon: 'fa-light fa-message',
      label: 'Ai có thể bình luận về bài viết này?',
      action: () => {
        handleCloseMenu();
        setOpenDialogRoleComment(true);
      },
      visible:
        type === 'page'
          ? rolePage.role === ''
          : !(
              meInfo?.id === post.account?.id &&
              (type === 'page' ||
                type === 'stream_profile' ||
                type === 'stream_home' ||
                type === 'post-detail')
            ) ||
            post?.event ||
            post?.group
    },
    {
      key: 'editObject',
      icon: 'fal fa-globe-americas',
      label: 'Chỉnh sửa đối tượng',
      description: 'Chỉnh sửa đối tượng theo dõi bài viết của bạn',
      action: () => {
        handleCloseMenu();
        setOpenDialogVisibility(true);
      },
      visible:
        type === 'scheduled_action' ||
        type === 'page' ||
        // meInfo?.banner?.id === post?.media_attachments?.[0]?.id ||
        // post?.group?.banner?.id === post?.media_attachments?.[0]?.id ||
        // post?.page?.banner?.id === post?.media_attachments?.[0]?.id ||
        // post?.event?.banner?.id === post?.media_attachments?.[0]?.id ||
        post?.event ||
        post?.post_type === 'project_banner' ||
        meInfo?.id !== post?.account?.id ||
        type === 'rating' ||
        type === 'stream_group' ||
        post?.course ||
        post?.project ||
        (post?.visibility === 'scheduled' && post?.group) ||
        post?.group
      // post?.page
    },
    {
      key: 'removeTag',
      label: 'Gỡ thẻ',
      icon: 'fa-light fa-tag',
      action: () => {
        handleCloseMenu();
        removeTagUser();
        dispatch(unTagMention(post?.id));
      },
      visible: checkVisibleRemoveTag(post)
    },
    {
      key: 'report',
      label: 'Báo cáo',
      icon: 'fa-light fa-flag',
      action: () => {
        // setTypeReport('');
        handleCloseMenu();
        setOpenDialogReport(true);
      },
      visible:
        type === 'page'
          ? rolePage.role === 'admin' || rolePage.role === 'moderator'
          : !(meInfo?.id !== post.account?.id)
    },
    {
      key: 'report',
      label: 'Báo cáo với quản trị viên',
      icon: 'fa-light fa-shield-cross',
      action: () => {
        // setTypeReport('adminGroup');
        handleCloseMenu();
        setOpenDialogAdminReport(true);
      },
      visible: !(
        ['all_group', 'stream_group'].includes(type) &&
        meInfo?.id !== post.account?.id &&
        !roleGroupMember?.admin
      )
    },
    // {
    //   key: 'seeEditHistory',
    //   icon: 'fal fa-history',
    //   label: 'Xem lịch sử chỉnh sửa',
    //   action: () => {
    //     handleCloseMenu();
    //   }
    // },
    // {
    //   key: 'turnOnlNotification',
    //   icon: 'fas fa-bell',
    //   label: 'Bật thông báo về bài viết này',
    //   action: () => {}
    // },
    {
      key: 'turnOffNotification',
      icon: !isGetNoti ? 'fal fa-bell' : 'fal fa-bell-slash',
      label:
        isGetNoti === false
          ? 'Bật thông báo về bài viết này'
          : 'Tắt thông báo về bài viết này',
      action: () => {
        handleCloseMenu();
        isGetNoti === false
          ? handleActionTurnOnNotifications()
          : handleActionTurnOffNotifications();
      },
      visible: type === 'rating'
    },
    {
      key: 'divCode',
      icon: 'fal fa-code',
      label: 'Nhúng',
      action: () => {
        handleCloseMenu();
        handleEmbedpost();
        // setOpenDialogEmbed(true);
      },
      visible: type === 'rating'
    }
    // {
    //   key: 'editTime',
    //   icon: 'fa-light fa-calendar-days',
    //   label: 'Chỉnh sửa ngày',
    //   action: () => {
    //     handleCloseMenu();
    //     setOpenDialogUpdateTime(true);
    //   },
    //   visible: !(
    //     (!!post.page?.id && type === 'page') ||
    //     ((type === 'stream_profile' ||
    //       type === 'post-detail' ||
    //       type === 'stream_home') &&
    //       meInfo?.id === post.account?.id)
    //   )
    // }
    // {
    //   key: 'care',
    //   icon: post?.muted ? 'fal fa-times-square' : 'fal fa-plus-square',
    //   label: post?.muted ? 'Không quan tâm' : 'Quan tâm',
    //   description: post?.muted
    //     ? 'Không hiển thị bài viết này lên tường của bạn'
    //     : 'Hiển thị bài viết lên tường của bạn',
    //   action: () => {
    //     handleCloseMenu();
    //     handleActionMenu(
    //       post.id,
    //       post?.muted ? unmutePostApi : mutePostApi,
    //       post?.muted ? unmutePostSuccess : mutePostSuccess
    //     );
    //   },
    //   visible: type === 'rating'
    // }
  ];

  let listMoreActionBot = [
    {
      key: 'removePost',
      icon: 'fal fa-xmark',
      label: 'Gỡ bài viết ',
      action: () => {
        handleCloseMenu();
        setIsRemovePost(true);
        setOpenDelete(true);
      },
      visible: !(
        type === 'stream_group' &&
        meInfo?.id !== post.account?.id &&
        checkAdminGroup()
      )
    },
    {
      key: 'removePostandBlock',
      icon: 'fal fa-trash-can',
      label: 'Gỡ bài viết và chặn tác giả ',
      action: () => {
        handleCloseMenu();
        setIsRemovePostAndBlock(true);
        setOpenDelete(true);
      },
      visible: !(
        type === 'stream_group' &&
        meInfo?.id !== post.account?.id &&
        checkAdminGroup() &&
        !blockedUserList.includes(post.account.id)
      )
    },
    {
      key: 'deletePost',
      icon: 'fal fa-trash',
      label: 'Xóa bài viết',
      action: () => {
        if (type === 'rating') {
          setRatingSelected(itemRating);
        }
        handleCloseMenu();
        setOpenDelete(true);
      },
      visible:
        [
          'account_avatar',
          'account_banner',
          'page_avatar',
          'page_banner',
          'groupAvatar',
          'group_banner',
          'project_banner',
          'event_shared'
        ].includes(post.post_type) ||
        type === 'feed' ||
        type === 'all_group' ||
        type === 'watch-search' ||
        (type === 'page'
          ? rolePage.role === ''
          : meInfo?.id !== post.account?.id &&
            meInfo?.id !== post.target_account?.id)
    },
    {
      key: 'hiddenPost',
      icon: 'fal fa-trash',
      label: 'Ẩn bài viết',
      // description: post?.bookmarked
      // ? 'Xóa bài viết khỏi danh sách mục đã lưu'
      // : 'Thêm vào danh sách mục đã lưu',
      action: () => {
        handleHiddenPost();
        handleCloseMenu();
        // setOpenDelete(true);
      },
      visible: !(
        [
          'account_avatar',
          'account_banner',
          'page_avatar',
          'page_banner',
          'groupAvatar',
          'group_banner'
        ].includes(post.post_type) &&
        type !== 'stream_home' &&
        (checkAdminGroup() ||
          rolePage.role === 'admin' ||
          ((type === 'event' || type === 'stream_profile') &&
            meInfo?.id === post.account?.id))
      )
    }
  ];

  const optionUpdate: any = [
    {
      id: 'public',
      field_type: 'button',
      startIcon: 'fa-solid fa-earth-asia',
      title: 'Mọi người',
      description: 'Tất cả mọi người có thể bình luận.',
      icon:
        roleComment === 'public' ? (
          <i
            className="fa-solid fa-circle-check"
            style={{
              fontSize: 19,
              marginRight: '0px',
              color: buttonColor.backgroundColor
            }}
          ></i>
        ) : (
          <i
            className="fa-light fa-circle"
            style={{ fontSize: 19, marginRight: '0px' }}
          ></i>
        ),
      action: (value: any) => {
        setRoleComment('public');
        handleCheckRoleComment('public');
      }
    },
    {
      id: 'friend',
      field_type: 'button',
      startIcon: 'fa-light fa-user-group',
      title: 'Bạn bè',
      description: 'Chỉ bạn bè của bạn mới có thể bình luận.',
      icon:
        roleComment === 'friend' ? (
          <i
            className="fa-solid fa-circle-check"
            style={{
              fontSize: 19,
              marginRight: '0px',
              color: buttonColor.backgroundColor
            }}
          ></i>
        ) : (
          <i
            className="fa-light fa-circle"
            style={{ fontSize: 19, marginRight: '0px' }}
          ></i>
        ),
      action: (value: any) => {
        setRoleComment('friend');
        handleCheckRoleComment('friend');
      },
      visible: 'user'
    },
    {
      id: 'tag',
      field_type: 'button',
      startIcon: 'fa-light fa-tag',
      title: 'Trang cá nhân và Trang bạn nhắc đến',
      description:
        'Chỉ những Trang cá nhân và Trang bạn nhắc đến mới có thể bình luận.',
      icon:
        roleComment === 'tag' ? (
          <i
            className="fa-solid fa-circle-check"
            style={{
              fontSize: 19,
              marginRight: '0px',
              color: buttonColor.backgroundColor
            }}
          ></i>
        ) : (
          <i
            className="fa-light fa-circle"
            style={{ fontSize: 19, marginRight: '0px' }}
          ></i>
        ),
      action: (value: any) => {
        setRoleComment('tag');
        handleCheckRoleComment('tag');
      }
    },
    {
      id: 'follow',
      field_type: 'button',
      startIcon: 'fa-light fa-check-to-slot',
      title: 'Chỉ những người theo dõi Trang.',
      description:
        'Chỉ những người theo dõi Trang trước 24 giờ mới có thể bình luận.',
      icon:
        roleComment === 'follow' ? (
          <i
            className="fa-solid fa-circle-check"
            style={{
              fontSize: 19,
              marginRight: '0px',
              color: buttonColor.backgroundColor
            }}
          ></i>
        ) : (
          <i
            className="fa-light fa-circle"
            style={{ fontSize: 19, marginRight: '0px' }}
          ></i>
        ),
      action: (value: any) => {
        setRoleComment('follow');
        handleCheckRoleComment('follow');
      },
      visible: 'page'
    }
  ];

  const renderListMoreAction = item => {
    return (
      <MenuItem key={item?.key} onClick={() => item.action()}>
        <ListItemIcon sx={{ color: 'text.secondary' }}>
          <i className={`${item?.icon} fa-lg`}></i>
        </ListItemIcon>
        <Typography variant="body1" sx={{ fontSize: '15px' }} fontWeight="500">
          {item?.label} <br />
          <Typography
            style={{ fontSize: '12px', color: '#65676b' }}
            variant="body2"
          >
            {item?.description}
          </Typography>
        </Typography>
      </MenuItem>
    );
  };
  const removeTagUser = async () => {
    let res = await hideTagUser(post.id);
    if (res.status === 200) {
      if (
        match.path === PATHS.GROUP_DETAIL &&
        match.params.key === 'schedule_post'
      ) {
        dispatch(updateSchedulePost(res.data));
      }
      if (match.path === PATHS.POST_DETAIL || match.path === PATHS.USER) {
        setPostDetail(res.data);
      } else {
        dispatch(updatePostSuccessAction(res.data, { hiddenTag: true }));
      }
    }
  };
  const handleHiddenPost = async () => {
    let res = await hiddenPostDetailApi(post.id, {
      hidden: true
    });
    if (res.status === 200) {
      setPostData(null);
      dispatch(updatePostSuccessAction(post, true));
    }
  };
  const handleUpdateVisibility = async key => {
    let res = await createUpdatePostApi(
      {
        visibility: key
      },
      post?.id
    );
    if (res.status === 200) {
      setPostData &&
        setPostData((prev: any) => ({
          ...prev,
          visibility: key
        }));
    }
  };
  const handleCompleteTarget = async () => {
    let res = await completeTargetUser(post.id);
    if (res.status === 200) {
      setPostData &&
        setPostData(prev => {
          return {
            ...prev,
            status_target: {
              ...prev.status_target,
              target_status: 'completed'
            }
          };
        });
    }
  };
  // const handleHiddenPost = async () => {
  //   let res = await createUpdatePostApi(
  //     { ...post, visibility: 'private' },
  //     post.id
  //   );
  //   if (res.status === 200) {
  //     setPostData(prev => {
  //       return {
  //         ...prev,
  //         status_target: {
  //           ...prev.status_target,
  //           target_status: 'completed'
  //         }
  //       };
  //     });
  //   }
  // };

  const handleDelete = async () => {
    try {
      let checkCallApi;
      if (type === 'rating') {
        checkCallApi = delRatingCourse(
          ratingSelected?.course_id,
          ratingSelected?.id
        );
      } else {
        checkCallApi = deletePostApi(post.id);
      }
      let res = await checkCallApi;
      if (res.status === 200) {
        let filterActivitiesCache = activitiesCache.filter(
          item => item.id !== post.id
        );
        if (typeAction === 'scheduled_action') {
          dispatch(schedulePostSuccess(post.id));
        } else {
          dispatch(deleteOldPostSuccess(post.id));
          dispatch(updateCachePage(filterActivitiesCache));
        }

        if (setMediaData) {
          setMediaData(prev =>
            prev.filter(item => item.id !== post?.media_attachments[0]?.id)
          );
          history.push(window.location.pathname);
        }

        setListPostRating &&
          setListPostRating(prev =>
            prev?.filter(item => item.id !== ratingSelected?.id)
          );

        setLoading(false);
        setIsRemovePost(false);
        setPostData(null);
        dispatch(
          notiSharePost({
            code: 200,
            msg: 'Bài viết của bạn đã bị xóa.'
          })
        );
        if (match.path === PATHS.POST_DETAIL) {
          history.push('/');
        } else if (
          match.path === PATHS.USER &&
          match.params.key === 'file_albums'
        ) {
          history.push(match);
        }
      }
    } catch (error: any) {
      setNoti({
        code: 400,
        message:
          error.response?.status === 429
            ? 'Chúng tôi nhận thấy có quá nhiều hành động bất thường từ tài khoản của bạn, vui lòng truy cập lại sau 5 phút.'
            : error.response?.data?.error ||
              'Đã có lỗi xảy ra, vui lòng thử lại sau!'
      });
      setOpenSnackbar(true);
    } finally {
      setOpenDelete(false);
      setLoading(false);
    }
  };
  return (
    <>
      {openPopper && (
        <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
          <Popper
            id="simple_menu"
            anchorEl={anchorEl}
            open={openPopper}
            placement="bottom-end"
            disablePortal
            style={{
              padding: '5px 0px',
              zIndex: 1001
            }}
          >
            <Paper>
              {listMoreActionTop
                ?.filter((el: any) => !el?.visible)
                ?.map((item: any) => renderListMoreAction(item))}
              {listMoreActionMid
                ?.filter((el: any) => !el?.visible)
                ?.map((item: any) => renderListMoreAction(item))}
              {listMoreActionBot
                ?.filter((el: any) => !el?.visible)
                ?.map((item: any) => (
                  <>{renderListMoreAction(item)}</>
                ))}
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorPosition={{
          top: 267,
          left: 680
        }}
        sx={{ width: '500px', position: 'absolute', zIndex: 100 }}
      >
        {listMoreActionTop
          ?.filter((el: any) => !el.visible)
          ?.map((item: any) => renderListMoreAction(item))}
        {listMoreActionMid
          ?.filter((el: any) => !el.visible)
          ?.map((item: any) => renderListMoreAction(item))}
        {listMoreActionBot
          ?.filter((el: any) => !el.visible)
          ?.map((item: any) => (
            <>{renderListMoreAction(item)}</>
          ))}
      </Menu> */}
      {openDialogReport && (
        <DialogReport
          open={openDialogReport}
          handleClose={() => setOpenDialogReport(false)}
          title={'Báo cáo'}
          headerText={'Tìm hỗ trợ hoặc báo cáo bình luận'}
          headerSubText={'Bạn có thể báo cáo bình luận sau khi chọn vấn đề.'}
          listCategoriesReport={listReport}
          postReport={post}
          // typeReport={typeReport}
        />
      )}
      {openDialogAdminReport && (
        <DialogReportAdminGroup
          open={openDialogAdminReport}
          handleClose={() => setOpenDialogAdminReport(false)}
          postReport={post}
          // typeReport={typeReport}
        />
      )}
      {openDialogVisibility && (
        <DialogVisibility
          value={post.visibility}
          handleAction={handleUpdateVisibility}
          open={openDialogVisibility}
          setOpen={setOpenDialogVisibility}
        />
      )}

      {openDialogEmbed && (
        <DialogEmbed
          open={openDialogEmbed}
          setOpen={setOpenDialogEmbed}
          dataEmbed={dataEmbed}
          post={post}
          type={type}
          setDataEmbed={setDataEmbed}
        />
      )}

      {openDelete && (
        <DialogConfirmDelete
          open={openDelete}
          handleClose={() => {
            setIsRemovePost(false);
            setOpenDelete(false);
          }}
          title={
            isRemovePost
              ? 'Gỡ bài viết'
              : isRemovePostAndBlock
              ? 'Gỡ bài viết và chặn tác giả'
              : 'Xóa bài viết '
          }
          hiddenButton={isRemovePostAndBlock}
          alignText="center"
          text={
            isRemovePostAndBlock
              ? null
              : `Bạn chắc chắn muốn ${
                  isRemovePost ? 'gỡ' : 'xóa'
                } bài viết này? Bài viết sau khi ${
                  isRemovePost ? 'gỡ' : 'xóa'
                } sẽ không thể hoàn tác`
          }
          action={() => handleDelete()}
          styleDialogContent={
            !isRemovePostAndBlock ? null : { padding: '5px 0px' }
          }
          contentDialog={
            isRemovePostAndBlock ? (
              <>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: 10
                  }}
                >
                  <AvatarSocial
                    type="feed"
                    avatarObj={post?.account?.avatar_media}
                    style={{ width: 60, height: 60, marginRight: '15px' }}
                  ></AvatarSocial>
                  <Typography style={{ fontSize: 20, fontWeight: 700 }}>
                    {displayName}
                  </Typography>
                  <Typography style={{ fontSize: 17 }}>
                    Bạn đang xóa bài viết của {displayName} và chặn họ khỏi
                    nhóm.
                  </Typography>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',

                    flexDirection: 'column',

                    margin: '20px 0px 10px 0px',
                    paddingLeft: '15px',
                    paddingRight: '10px'
                  }}
                >
                  <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                    Lựa chọn khác
                  </Typography>
                  <Typography>
                    Bạn có muốn thực hiện thêm hành động không?
                  </Typography>

                  <div>
                    <ListItem className={classes.list}>
                      <i
                        className="fa-regular fa-trash-can"
                        style={{
                          fontSize: 20,
                          backgroundColor: 'button.primary.background',
                          padding: 10,
                          borderRadius: '50%'
                        }}
                      ></i>

                      <ListItemText
                        className={classes.firstListItem}
                        primary={
                          <>
                            <Typography
                              style={{
                                fontSize: 15,

                                fontWeight: 600
                              }}
                            >
                              Xóa hoạt động gần đây
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 13,
                                color: '#808080',
                                fontWeight: 400
                              }}
                            >
                              Xóa bài viết, bình luận, tin, cuộc thăm dò ý kiến
                              và lời mời làm thành viên đang chờ phê duyệt của{' '}
                              {displayName} trong 7 ngày qua trong nhóm.
                            </Typography>
                          </>
                        }
                      />
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </ListItem>
                  </div>

                  <div>
                    <ListItem className={classes.list}>
                      <i
                        className="fa-regular fa-user-slash"
                        style={{
                          fontSize: 20,
                          backgroundColor: 'button.primary.background',
                          padding: 5,
                          borderRadius: '50%'
                        }}
                      ></i>

                      <ListItemText
                        className={classes.firstListItem}
                        primary={
                          <>
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 600
                              }}
                            >
                              Cấm {displayName}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 13,
                                color: '#808080',
                                fontWeight: 400
                              }}
                            >
                              {displayName} sẽ không thể tìm, xem tham gia lại
                              nhóm của bạn.
                            </Typography>
                          </>
                        }
                      />
                      <Checkbox
                        checked={checkedBlockAccount}
                        onChange={handleChangeCheckedBlock}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </ListItem>
                  </div>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '10px'
                    }}
                  >
                    <ButtonInherit
                      style={{
                        backgroundColor: buttonColor.backgroundColor,
                        color: '#fff'
                      }}
                      disabled={!checked && !checkedBlockAccount}
                      label="Đồng ý"
                      action={() => {
                        handleBlockAndRemovePost();
                      }}
                    />
                    <ButtonInherit
                      label="Hủy"
                      action={() => {
                        setIsRemovePostAndBlock(false);
                        setOpenDelete(false);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null
          }
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {openDialogCreateCollection && (
        <DialogCreateCollectionSaved
          open={openDialogCreateCollection}
          handleClose={() => {
            setOpenDialogCreateCollection(null);
          }}
          bookmarkId={postId}
          entityType="Status"
          setOpenSnackbar={setOpenSnackbar}
          setNoti={setNoti}
          setPostData={setPostData}
        />
      )}
      {/* {openDialogUpdateTime && (
        <DialogSchedulePost
          maxTime={post?.created_at}
          maxDay={post?.created_at}
          open={openDialogUpdateTime}
          handleClose={() => setOpenDialogUpdateTime(false)}
          postSelected={post}
          handleActionUpdate={handleUpdateTime}
          typeTimeUpdate={'post_update'}
          title="Chỉnh sửa ngày"
          description="Nội dung này sẽ xuất hiện ở đâu trên dòng thời gian của bạn?"
          buttonClose={'x'}
          labelButton="Xong"
          maxTimeCurrent={post?.backdated_time}
        />
      )} */}
      {openDialogRoleComment && (
        <DialogUpdatePage
          open={openDialogRoleComment}
          handleClose={() => {
            setOpenDialogRoleComment(false);
          }}
          title="Ai có thể bình luận về bài viết của bạn"
          text="Chọn hành động mà bạn muốn khách truy cập Trang thực hiện."
          optionUpdate={optionUpdate.filter((el: any) =>
            type === 'page' ? el.visible !== 'user' : el.visible !== 'page'
          )}
          indexSelected={roleComment}
          loading={isLoadingRole}
        />
      )}
      <CUPost
        type={post?.share_product_or_course ? 'update_product' : typeEdit}
        post={post}
        openCUPost={openCUPost}
        setOpenCUPost={setOpenCUPost}
        updateTags={updateTags}
        setUpdateTags={setUpdateTags}
        pageInfo={pageInfo}
        eventInfo={eventInfo}
        courseInfo={courseInfo}
        projectInfo={projectInfo}
      />
    </>
  );
});

export default MenuAction;
