import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ResponseApi } from 'src/@types/api';
import {
  createUpdateAlbum,
  createUpdateMusicApi,
  getCategoriesMusic,
  getListMusicUploadted,
  searchFriendUserMusic
} from 'src/apis/SocialMusic.api';
import { getListPagesApi } from 'src/apis/socialPages.api';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import { buttonColor } from 'src/constants/styles';
import { updateAlbumMusic } from 'src/store/action/socialMusicAction';
import { notiSharePost } from 'src/store/action/socialPostAction';
import { scrollSocial } from 'src/store/action/socialScrollAction';
import { uploadMediaSaga } from 'src/store/saga/socialPostSaga';
import IconFileDark from 'src/assets/images/Icon_file_dark.png';
import IconFileLight from 'src/assets/images/Icon_file_light.png';
import * as Yup from 'yup';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTab-iconWrapper': {
      margin: '0px 5px 0px 0px !important'
    }
  },
  dropzone: {
    position: 'relative',
    height: '200px',
    border: '2px dashed #ccc',
    textAlign: 'center',
    cursor: 'pointer'
  },
  icon: {
    '&:hover': {
      backgroundColor: '#eaeaea !important'
    }
  },
  image: {
    width: '100%',
    height: '17vh',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center'
  },
  mp3: {
    background: '#F2F2F2',
    width: '100%',
    height: '17vh',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }
});

interface Props {
  openDialogCreate: boolean;
  setOpenDialogCreate: Dispatch<SetStateAction<boolean>>;
  setListMusicAlbum?: Dispatch<SetStateAction<any>>;
  setOpenSnackbar?: any;
  setNoti?: any;
  type?: string;
  valueTab?: string;
  dataAlbum?: any;
}

export default function CreateAlbum(props: Props) {
  const {
    setOpenDialogCreate,
    setListMusicAlbum,
    openDialogCreate,
    setOpenSnackbar,
    setNoti,
    type,
    valueTab,
    dataAlbum
  } = props;
  const classes = useStyles();
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const [hostMusicAdd, setHostMusicAdd] = React.useState([] as any);
  const [pagesAdmin, setPagesAdmin] = React.useState([]);
  const [value, setValue] = React.useState(valueTab ? valueTab : 'music');
  const [verify, setVerify] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [isLoadingCreateMusic, setIsLoadingCreateMusic] =
    React.useState<any>(false);
  const [disableBtn, setDisableBtn] = React.useState<boolean>(false);
  const [disableBtnAlbum, setDisableBtnAlbum] = React.useState<boolean>(false);
  const [listMusicUploadted, setListMusicUploadted] = React.useState<any>([]);
  const [errorImage, setErrorImage] = React.useState<boolean>(false);
  const [textWarning, setTextWarning] = React.useState<any>(null);
  const [listCategory, setListCategory] = React.useState<any>([]);
  const [openSnackbarDialog, setOpenSnackbarDialog] =
    React.useState<any>(false);
  const [notiDialog, setNotiDialog] = React.useState<any>({
    code: 200,
    message: ''
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  let updateAlbum: any = query.get('update_album');
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .transform(value => {
        if (typeof value === 'string' && value !== null) {
          return value.trim();
        }
        return value;
      })
      .test(
        'doesNotStartWithSpecialCharacter',
        'Ký tự đầu không được là ký tự đặc biệt',
        (value: any) => {
          const firstCharacter = value?.charAt(0);
          return !/[!@#$%^&*(),.?":{}|<> ]/.test(firstCharacter);
        }
      )
      .min(3, 'Không được để trống, tối thiểu có 3 kí tự')
      .max(200, 'Không được nhập quá 200 ký tự, vui lòng kiểm tra lại!')
      .nullable(),
    description_music: Yup.string()
      .transform(value => {
        if (typeof value === 'string' && value !== null) {
          return value.trim();
        }
        return value;
      })
      .min(3, 'Không được để trống, tối thiểu có 3 kí tự')
      .max(5000, 'Mô tả không vượt quá 5000 ký tự, vui lòng kiểm tra lại!')
      .nullable(),
    banner: Yup.mixed().test(
      'imageDimensions',
      'Kích thước ảnh không hợp lệ',
      value => {
        if (!value) {
          return true;
        }

        if (value?.file?.file && !value?.file?.file?.id) {
          const image: any = new Image();
          image.src = value?.file?.file
            ? URL.createObjectURL(value.file.file)
            : null;

          return new Promise(resolve => {
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              if (width >= 450 && height >= 150) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }

        return true;
      }
    ),
    file_mp3: Yup.mixed()
      .test('fileSize', 'File quá lớn, vui lòng chọn tệp dưới 60MB', value => {
        if (!value) {
          return true; // Cho phép không có tệp
        }
        return value.file.file.size <= 60 * 1024 * 1024; // Kích thước tệp không quá 60MB
      })
      .test(
        'fileType',
        'Định dạng tệp không hợp lệ, vui lòng chọn tệp MP3',
        value => {
          if (!value) {
            return true; // Cho phép không có tệp
          }

          return value.file.file.type === 'audio/mpeg'; // Kiểm tra định dạng tệp có phải là MP3 không
        }
      ),
    description_song: Yup.string()
      .transform(value => {
        if (typeof value === 'string' && value !== null) {
          return value.trim();
        }
        return value;
      })
      .min(3, 'Không được để trống, tối thiểu có 3 kí tự')
      .max(5000, 'Mô tả không vượt quá 5000 ký tự, vui lòng kiểm tra lại!')
      .nullable()
  });

  const getCategoriesMusicSpace = async () => {
    try {
      let res = await getCategoriesMusic();
      if (res.status === 200) {
        setListCategory(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getCategoriesMusicSpace();
  }, []);

  const fetchPageAdmin = async () => {
    const res: ResponseApi = await getListPagesApi(null);
    if (res.status === 200) {
      setPagesAdmin(res.data);
    }
  };

  const handleSearchPage = React.useCallback(
    _.debounce(async keyword => {
      try {
        if (keyword !== '') {
          const res: ResponseApi = await getListPagesApi({
            keyword: keyword
          });
          if (res.status === 200) {
            setPagesAdmin(res.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 500),
    []
  );

  const handleCreateUpdateAlbum = async (type, data) => {
    setIsLoadingCreateMusic(true);
    try {
      let res = await createUpdateAlbum(
        {
          name: data.title,
          banner_id: data?.banner?.file
            ? data.banner.file.id
            : data?.banner?.id,
          page_owner_id: data.pageId.id,
          music_category_id: data.category_music_id.id,
          playlist_music_ids:
            data.list_music_id.map((el: any) => el?.id) || null,
          description: data.description_music
        },
        type,
        dataAlbum?.id
      );
      if (res.status === 200) {
        dispatch(updateAlbumMusic(res.data));
        setListMusicAlbum &&
          setListMusicAlbum(prev => [...prev, ...data.list_music_id]);
        setIsLoadingCreateMusic(false);
        setOpenSnackbar(true);
        setOpenDialogCreate(false);
        setNoti({
          code: 200,
          message:
            type === 'update'
              ? 'Cập nhập album thành công'
              : 'Thêm mới Album thành công. Chúng tôi sẽ xem xét Album và sớm gửi thông báo đến bạn!'
        });
        formik.resetForm();
      }
    } catch (err) {
      setIsLoadingCreateMusic(false);
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message:
          type === 'update'
            ? 'Cập nhập album thất bại'
            : 'Thêm mới album thất bại.'
      });
    }
  };

  const handleCreateUpdateMusic = async data => {
    try {
      let listIdMedia = await uploadMediaSaga(
        formik.values.copyright_id,
        'file'
      );
      let res = await createUpdateMusicApi({
        title: data.title,
        banner: data.banner.file,
        description: data.description_song,
        fileMp3: data.file_mp3.file,
        page_owner_id: data.page_owner_id,
        category_music_id: data.category_music_id.id,
        copyright_id: listIdMedia[0],
        music_host_added: data.music_host_added.map((el: any) => el?.id) || null
      });
      if (res.status === 200) {
        setOpenSnackbar(true);
        setOpenDialogCreate(false);
        setNoti({
          code: 200,
          message: 'Thêm mới bài hát thành công.'
        });

        formik.resetForm();
      }
    } catch (err: any) {
      dispatch(
        notiSharePost({
          code: 400,
          msg:
            err.response?.data?.error ||
            'Đã có lỗi xảy ra. Vui lòng thử lại sau!'
        })
      );
    } finally {
      setIsLoadingCreateMusic(false);
    }
  };

  const handleClose = () => {
    setOpenDialogCreate(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const fetchHostMusic = async (keyword: any) => {
    let response: ResponseApi = await searchFriendUserMusic({
      keyword: keyword === '' ? ' ' : keyword
    });
    if (response.status === 200) {
      setHostMusicAdd(
        response.data?.data.map((el: any) => ({
          id: el.id,
          display_name: el.display_name,
          avatar:
            el.avatar_media?.show_url ||
            el.avatar_media?.url ||
            el.avatar_media?.show_url ||
            avatarDefault,
          status: 'pending',
          description: 'Đang chờ'
        }))
      );
    }
  };

  const fetchListMusicUploadted = async data => {
    let res;
    res = await getListMusicUploadted({
      limit: 50,
      page_id: data.id,
      exclude_playlist_id: dataAlbum?.id
    });
    if (res.status === 200) {
      let data = res.data;
      if (!data.length) {
        setTextWarning(
          'Trang của bạn không có bài hát nào.Vui lòng thêm bài hát!'
        );
      } else {
        setTextWarning(null);
      }
      setListMusicUploadted(data);
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      title: null as any,
      banner: null as any,
      description_song: '',
      file_mp3: null as any,
      page_owner_id: null as any,
      category_music_id: null as any,
      music_host_added: [] as any,
      list_music_id: [] as any,
      description_music: null as any,
      copyright_id: null as any,
      pageId: null as any
    } as any,
    onSubmit: async (values: any) => {
      try {
        if (value === 'music') {
          handleCreateUpdateMusic(values);
        } else {
          if (dataAlbum?.id) {
            handleCreateUpdateAlbum('update', values);
          } else {
            handleCreateUpdateAlbum('create', values);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  } as any);
  React.useEffect(() => {
    if (type === 'updateAlbum' && dataAlbum) {
      let tempData: any = {
        title: dataAlbum.name,
        banner: dataAlbum.banner,
        description_music: dataAlbum.description,
        category_music_id: dataAlbum.music_category,
        pageId: dataAlbum.page_owner,
        list_music_id: dataAlbum?.playlist_music_ids ?? []
      };
      formik.setValues(tempData);
    }
  }, [type, dataAlbum]);

  React.useEffect(() => {
    if (
      formik.values.title &&
      formik.values.banner &&
      formik.values.file_mp3 &&
      formik.values.description_song.length &&
      formik.values.category_music_id &&
      formik.values.page_owner_id &&
      formik.values.copyright_id &&
      !Object.keys(formik.errors).length &&
      !errorImage
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);
  React.useEffect(() => {
    if (
      updateAlbum ||
      (formik.values.title &&
        formik.values.banner &&
        formik.values.category_music_id &&
        formik.values.pageId &&
        formik.values.description_music &&
        formik.values.list_music_id.length &&
        !Object.keys(formik.errors).length &&
        !errorImage)
    ) {
      setDisableBtnAlbum(false);
    } else {
      setDisableBtnAlbum(true);
    }
  }, [formik.values, formik.errors]);

  React.useEffect(() => {
    dispatch(scrollSocial('fixed'));

    return () => {
      dispatch(scrollSocial('static'));
    };
  }, [openDialogCreate]);

  React.useEffect(() => {
    if (!pagesAdmin.length) {
      fetchPageAdmin();
    }
  }, []);

  let createMusic: any = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder: 'Viết tên tác phẩm',
      label: 'Tên tác phẩm',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3
    },
    {
      field_type: 'textField',
      name: 'description_song',
      placeholder: 'Nhập mô tả của bạn',
      label: 'Mô tả bài hát',
      descriptionLabel: 'Bắt buộc*',
      descriptionTitle:
        'Vui lòng điền đầy đủ thông tin nghệ sĩ sáng tác, ca sĩ biểu diễn, nhà sản xuất, công ty phát hành, thông tin về bản quyền sáng tác để được duyệt nhanh hơn',
      rows: 4,
      onChange: true,
      styleDescriptionTitle: {
        whiteSpace: 'wrap',
        fontSize: 12
      }
    },
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'file_media',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      default_media: formik?.values?.banner,
      span: 6
    },
    {
      field_type: 'upload_media',
      name: 'file_mp3',
      label: 'File Mp3',
      type: 'file_mp3',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      default_media: formik?.values?.file_mp3,
      span: 6
    },
    {
      field_type: 'autocomplete',
      name: 'category_music_id',
      options: listCategory,
      multiple: false,
      setOptions: getCategoriesMusicSpace,
      placeholder: 'Chọn thể loại',
      descriptionLabel: 'Bắt buộc*',
      label: 'Thể loại',
      renderListTag: true,
      span: 6
    },
    {
      field_type: 'autocomplete',
      name: 'page_owner_id',
      options: pagesAdmin,
      fetchData: handleSearchPage,
      search: true,
      multiple: false,
      placeholder: 'Chọn trang của bạn',
      descriptionLabel: 'Bắt buộc*',
      label: 'Nghệ sĩ đăng tải',
      renderListTag: true,
      span: 6,
      avatar_media: true
    },
    {
      field_type: 'autocomplete',
      name: 'music_host_added',
      options: hostMusicAdd,
      multiple: true,
      fetchData: fetchHostMusic,
      search: true,
      placeholder: 'Chọn tác giả',
      label: 'Tác giả',
      span: 6,
      renderListTag: true
    },
    {
      field_type: 'upload_file',
      label: 'Chứng nhận bản quyền',
      name: 'copyright_id',
      placeholder: 'Click để tải file lên',
      descriptionLabel: 'Bắt buộc*',
      span: 6,
      button: {
        label: 'File mẫu',
        icon: {
          src: theme?.palette?.mode === 'light' ? IconFileDark : IconFileLight,
          style: {
            marginRight: '8px'
          }
        },
        style: {
          width: '100px',
          fontWeight: '600 !important',
          height: '38px'
        }
      }
    }
  ];
  let createAlbum: any = [
    {
      field_type: 'textField',
      name: 'title',
      placeholder: 'Viết tên album',
      label: 'Tên album',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 3,
      defaultValue: formik.values.title
    },
    {
      field_type: 'textField',
      name: 'description_music',
      label: 'Mô tả ',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Mô tả nội dung chương trình phát',
      rows: 4,
      onChange: true,
      defaultValue: formik.values.description_music
    },
    {
      field_type: 'upload_media',
      name: 'banner',
      label: 'Ảnh bìa',
      type: 'file_media',
      height: 200,
      descriptionLabel: 'Bắt buộc*',
      default_media: formik?.values?.banner
    },
    {
      field_type: 'autocomplete',
      name: 'category_music_id',
      options: listCategory,
      multiple: false,
      setOptions: getCategoriesMusicSpace,
      placeholder: 'Chọn thể loại',
      descriptionLabel: 'Bắt buộc*',
      label: 'Thể loại',
      renderListTag: true
    },
    ...(type !== 'updateAlbum'
      ? [
          {
            field_type: 'autocomplete',
            name: 'pageId',
            options: pagesAdmin,
            fetchData: handleSearchPage,
            multiple: false,
            search: true,
            placeholder: 'Chọn trang',
            descriptionLabel: 'Bắt buộc*',
            label: 'Chọn trang của bạn',
            renderListTag: true,
            disabled: type === 'updateAlbum' ? true : false,
            avatar_media: true
          },
          {
            field_type: formik.values.pageId?.id ? 'autocomplete' : null,
            name: 'list_music_id',
            options: listMusicUploadted,
            multiple: true,
            search: true,
            placeholder: 'Thêm bài hát',
            label: 'Thêm bài hát',
            descriptionLabel: 'Bắt buộc*',
            renderListTag: true,
            status_item: 'active',
            helpText: textWarning
          }
        ]
      : [])
  ];
  React.useEffect(() => {
    if (value === 'album' && formik.values.pageId?.id) {
      fetchListMusicUploadted(formik.values.pageId);
      formik.setFieldValue('list_music_id', []);
    }
  }, [formik.values.pageId?.id]);

  const renderSocialField = (setData, options) => {
    return (
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={setData}
        fieldUpdate={options}
        spacing={2}
        setErrorItem={setErrorImage}
        setOpenSnackbar={setOpenSnackbarDialog}
        setNoti={setNotiDialog}
      />
    );
  };

  return (
    <Box>
      <Dialog
        open={openDialogCreate}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
            zIndex: 100
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none'
            }
          }}
        >
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <Tabs sx={{ borderRadius: '16px' }} value={value}>
              <TabContext value={value}>
                {updateAlbum ? null : (
                  <Box sx={{ borderColor: 'divider' }}>
                    <TabList
                      orientation="vertical"
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="Tạo mới Tác phẩm "
                        value="music"
                        icon={<i className="fa-sharp fa-solid fa-plus"></i>}
                        className={classes.tabs}
                        sx={{
                          paddingLeft: '0px',
                          whiteSpace: 'nowrap',
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                          alignItems: 'center'
                        }}
                      />
                      <Tab
                        label={
                          type === 'updateAlbum'
                            ? 'Chỉnh sửa thông tin Album'
                            : 'Tạo mới Album'
                        }
                        value="album"
                        icon={<i className="fa-sharp fa-solid fa-plus"></i>}
                        className={classes.tabs}
                        sx={{
                          paddingLeft: '0px',
                          whiteSpace: 'nowrap',
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                          alignItems: 'center'
                        }}
                      />
                    </TabList>
                  </Box>
                )}
                <Box>
                  <TabPanel
                    value="music"
                    sx={{ padding: '24px 0px 24px 24px' }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Tạo mới Tác phẩm
                    </Typography>
                    {renderSocialField(formik, createMusic)}

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '20px'
                      }}
                    >
                      <ButtonInherit
                        label={'Huỷ'}
                        style={{
                          marginTop: '12px',
                          padding: '0px 40px',
                          height: '40px'
                        }}
                        action={() => {
                          handleClose();
                        }}
                      />
                      <ButtonInherit
                        loading={isLoadingCreateMusic}
                        label="Gửi phê duyệt"
                        color={buttonColor.backgroundColor}
                        textColor="#fff"
                        style={{ marginTop: '12px', height: '40px' }}
                        action={() => {
                          formik.handleSubmit();
                        }}
                        disabled={disableBtn}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel
                    value="album"
                    sx={{ padding: '24px 0px 24px 24px' }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {type === 'updateAlbum'
                        ? 'Chỉnh sửa Album'
                        : 'Tạo mới Album'}
                    </Typography>
                    {renderSocialField(formik, createAlbum)}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        width: '100%',
                        marginTop: '20px'
                      }}
                    >
                      <ButtonInherit
                        loading={isLoadingCreateMusic}
                        label={'Huỷ'}
                        style={{ marginTop: '12px', padding: '0px 40px' }}
                        action={() => {
                          handleClose();
                        }}
                      />
                      <ButtonInherit
                        loading={isLoadingCreateMusic}
                        label={
                          type === 'updateAlbum' ? 'Chỉnh sửa' : 'Gửi phê duyệt'
                        }
                        color={buttonColor.backgroundColor}
                        textColor="#fff"
                        style={{ marginTop: '12px', padding: '0px 40px' }}
                        action={() => {
                          formik.handleSubmit();
                        }}
                        disabled={disableBtnAlbum}
                      />
                    </Box>
                  </TabPanel>
                </Box>
              </TabContext>
            </Tabs>
          </Box>
        </DialogContent>
        {openSnackbarDialog && (
          <SnackbarNotification
            open={openSnackbarDialog}
            setOpen={setOpenSnackbarDialog}
            code={notiDialog.code}
            message={notiDialog.message}
          />
        )}
      </Dialog>
    </Box>
  );
}
