import axios, { AxiosRequestConfig } from 'axios';
import { USER_TOKEN } from 'src/util/config';
import { BASE_ROOT, BASE_ROOT_MARKET } from 'src/util/config';
import { apiDelete, apiGet, apiPatch, apiPost } from './apis';
import { apiDeleteMarket, apiGetMarket, apiPatchMarket, apiPostMarket } from './apisMarket';

const fetchApiRequest = async (
  endPoint,
  method,
  params,
  _data,
  typeEndpoint = ''
) => {
  let configs: AxiosRequestConfig = {
    url: `${
      typeEndpoint === 'course' ? BASE_ROOT_MARKET : BASE_ROOT
    }/api/v1/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getBalance = async () => {
  return apiGet(`transactions`, null, null);
};

export const getMerchantPayment = async params => {
  return apiGetMarket('merchant_payment_requests', params, null);
};

export const createMerchantPaymentApi = async (data: any) => {
  return apiPostMarket('merchant_payment_requests', null, data);
};

export const getRefundPayment = async params => {
  return apiGetMarket('refund_requests', params, null);
};

export const cancelRefundPayment = async (id, params) => {
  return apiPostMarket(`refund_requests/${id}/buyer_cancel`, null, params);
};

export const createRefundPaymentApi = async (data: any) => {
  return apiPostMarket('refund_requests', null, data);
};

export const getHistoryPaymentCoin = async (params: any) => {
  return apiGet(`transaction_history`, params, null);
};

export const getHistoryPaymentMoney = async (params: any) => {
  return apiGetMarket(`debt_logs`, params, null);
};

export const getAccountBalance = async () => {
  return apiGetMarket(`account_balances`, null, null);
};

export const rechargePayment = async (
  params: any,
  type,
  typeEndpoint?: string
) => {
  return fetchApiRequest(
    `${type === 'vtcpay' ? 'create_vtcpay_url' : 'create_payment_url'}`,
    'GET',
    params,
    null,
    typeEndpoint
  );
};

export const getPaymentCoin = async (params: any) => {
  return apiGet(`vnpay_return`, params, null);
};

export const postDonateAccountUser = async data => {
  const formData = new FormData();
  if (data.amount) {
    formData.append('ecoin_amount', data.amount);
  }
  if (data.target_account_id) {
    formData.append('target_account_id', data.target_account_id);
  }
  if (data.entity_id) {
    formData.append('entity_id', data.entity_id);
  }
  if (data.entity_type) {
    formData.append('entity_type', data.entity_type);
  }
  return apiPost('account_donate', null, formData);
};

export const postDonatePage = async (data: any) => {
  const formData = new FormData();
  if (data.amount) {
    formData.append('ecoin_amount', data.amount);
  }
  if (data.page_id) {
    formData.append('page_id', data.page_id);
  }
  if (data.entity_id) {
    formData.append('entity_id', data.entity_id);
  }
  if (data.entity_type) {
    formData.append('entity_type', data.entity_type);
  }
  return apiPost('page_donate', null, formData);
};

export const getListBranchesBank = async params => {
  return apiGet('bank_branches_lists', params, null);
};
export const createUpdatAddBank = async (data: any, type, bankId) => {
  const formData = new FormData();
  if (data.title) {
    formData.append('title', data.title);
  }
  if (data.identitycard_number) {
    formData.append('identity_verification_number', data.identitycard_number);
  }
  if (data.bank_id) {
    formData.append('bank_id', data.bank_id);
  }
  if (data.bank_branch_id) {
    formData.append('bank_branch_id', data.bank_branch_id);
  }
  if (data.bank_account_number) {
    formData.append('bank_account_number', data.bank_account_number);
  }
  if (data.full_name_in_bank) {
    formData.append('full_name_in_bank', data.full_name_in_bank);
  }
  formData.append('default', data.default);
  formData.append('bank_type', 'bank_account');

  if (type === 'update') {
    return apiPatchMarket(`bank_accounts/${bankId}`, null, formData);
  }

  return apiPostMarket('bank_accounts', null, formData);
};
export const getBankAccount = async () => {
  return apiGetMarket('bank_accounts', null, null);
};
export const deleteBankAccount = async bankId => {
  return apiDeleteMarket(`bank_accounts/${bankId}`, null, null);
};
