import { ClickAwayListener } from '@mui/base';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  Popper,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import noti from 'src/assets/images/Noti.svg';

import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';

import {
  addMemberAdminGroupChat,
  blockPage,
  blockRoom,
  getPinMessage,
  getRoomRoles,
  getUser,
  hideConversation,
  removeMemberAdminGroupChat,
  saveInfoGroupChat,
  unBlockPage,
  unBlockRoom,
  unPinMessage
} from 'src/apis/socialChat.apis';
import { getDetailPageApi } from 'src/apis/socialPages.api';

import {
  actionTyping,
  subStatusUser,
  unSubRoom
} from 'src/socket/RocketChatSubscriptions';
import {
  createGroupChatWith,
  hiddenUserBoxChat
} from 'src/store/action/socialChatAction';
import { getFriendOneSuccess } from 'src/store/action/socialFriendsAction';

import { collapseString } from 'src/common/string';
import { urlRocketChat } from 'src/util/config';

import ButtonComponent from 'src/components/Button/Button';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import CustomizedSnackBars from 'src/components/Snackbar/SnackbarSocial';
import { buttonColor, buttonColorHover, colorBlue } from 'src/constants/styles';
import DialogAddMemberGroupChat from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogAddMemberGroup';
import DialogBlockMember from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogBlockUser';
import DialogChangedEmoticon from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogChangedEmoticon';
import DialogChangedTopic from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogChangedTopic';
import DialogChangeNameGroupChat from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogChangeNameGroup';
import DialogDeleteConversation from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogDeleteConversation';
import DialogLeaveGroup from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogLeaveGroup';
import DialogMemberGroupChat from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogMemberChatGroup';
import DialogSkipConversation from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogSkipConversation';
import DialogViewPinMessage from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogViewPinMessage';
import {
  fetchListAdmin,
  handleSettingNotification,
  removeBoxChat,
  renderAllAvatar,
  renderNameChat
} from 'src/pages/Chat/functionChat';
const styleIconButtonHeaderClose = {
  width: '30px',
  height: '30px',
  borderRadius: '15px',
  alignItems: 'center',
  backgroundColor: 'button.default.background',
  '&:hover': {
    backgroundColor: 'button.default.hover'
  },
  right: '5px',
  margin: 0
};

const styleIconButtonHeaderHidden = {
  width: '30px',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  borderRadius: '15px',
  backgroundColor: 'button.default.background',
  '&:hover': {
    backgroundColor: 'button.default.hover'
  }
};

const styleIconButtonHeaderInfo = {
  width: 'fit-content',
  height: '30px',
  borderRadius: '15px',
  alignItems: 'center',
  display: 'flex',
  backgroundColor: 'button.default.background',
  '&:hover': {
    backgroundColor: 'button.default.hover'
  },
  right: '5px',
  margin: 0
};
const PinMessageComponent = ({
  listPinnedMessage,
  setAnchorElPinMessage,
  handleClickShowPinMessage,
  copyTextToClipboard,
  handleClickShowDetailPinMessage,
  theme,
  openPinMessage,
  id,
  anchorElPinMessage,
  checkShowData,
  dataRender,
  indexKey,
  handleUnPinMessage
}) => {
  return (
    <ListItem
      key={indexKey}
      sx={{
        padding: 0,
        '&:hover': {
          '& .iconMore': {
            opacity: 1
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          boxShadow: !checkShowData
            ? '0px 1px 1px rgba(0, 0, 0, 0.2)'
            : 'unset',
          padding: '0px 20px',
          height: '48px'
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{}}>
            <i
              className="fa-thin fa-comment-lines fa-xl"
              style={{ color: '#0084FF' }}
            ></i>
          </Box>
          <Box sx={{ marginLeft: '12px' }}>
            <Typography
              sx={{
                fontWeight: 500,
                lineHeight: 1.5,
                fontSize: '0.8125rem'
              }}
            >
              Tin nhắn
            </Typography>
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? '#fff' : '#7589a3',
                fontSize: '0.8125rem',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {checkShowData
                ? `${dataRender.u.name} : ${dataRender.msg}`
                : `${listPinnedMessage[0].u.name} : ${listPinnedMessage[0].msg}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'center'
          }}
        >
          {checkShowData ? (
            <IconButton
              onClick={handleClickShowPinMessage}
              aria-label="option"
              sx={{
                marginRight: '20px',
                opacity: 0
              }}
              id={id}
              className="iconMore"
            >
              <i
                className="fa-solid fa-ellipsis "
                style={{ fontSize: '18px' }}
              ></i>
            </IconButton>
          ) : (
            <ClickAwayListener
              onClickAway={e => {
                setAnchorElPinMessage(null);
              }}
            >
              <IconButton
                onClick={handleClickShowPinMessage}
                aria-label="option"
                sx={{
                  marginRight: '20px',
                  opacity: 0
                }}
                id={id}
                className="iconMore"
              >
                <i
                  className="fa-solid fa-ellipsis "
                  style={{ fontSize: '18px' }}
                ></i>
              </IconButton>
            </ClickAwayListener>
          )}

          <Popper
            id={id}
            open={openPinMessage}
            anchorEl={anchorElPinMessage}
            style={{ zIndex: 100 }}
          >
            <Box
              sx={{
                background: theme.palette.mode === 'dark' ? '#3A3B3C' : '#fff',
                boxShadow: ' 0 0 10px 0 rgba(0,0,0,0.2)',
                width: '160px',

                padding: '8px 0px',
                border:
                  theme.palette.mode === 'dark'
                    ? '1px solid #3A3B3C'
                    : '1px solid #d6dbe1',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
              }}
            >
              <Typography
                onClick={() => {
                  if (checkShowData) {
                    const indexPopper = anchorElPinMessage.id.split('-');

                    switch (indexPopper[2]) {
                      case '0':
                        copyTextToClipboard(listPinnedMessage[0].msg);

                        break;
                      case '1':
                        copyTextToClipboard(listPinnedMessage[1].msg);

                        break;
                      case '2':
                        copyTextToClipboard(listPinnedMessage[2].msg);

                        break;
                      default:
                    }
                  } else {
                    copyTextToClipboard(listPinnedMessage[0].msg);
                  }
                }}
                sx={{
                  padding: '6px 8px',
                  '&:hover': {
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgba(255,255,255,0.2)'
                        : '#f3f5f6'
                  },
                  cursor: 'pointer'
                }}
              >
                Coppy
              </Typography>
              <Divider
                sx={{
                  borderColor:
                    theme.palette.mode === 'dark' ? '#8A8C90' : '#CED0D4',
                  margin: '4px'
                }}
              />
              <Typography
                onClick={() => {
                  if (checkShowData) {
                    const indexPopper = anchorElPinMessage.id.split('-');

                    switch (indexPopper[2]) {
                      case '0':
                        handleUnPinMessage(listPinnedMessage[0]);

                        break;
                      case '1':
                        handleUnPinMessage(listPinnedMessage[1]);

                        break;
                      case '2':
                        handleUnPinMessage(listPinnedMessage[2]);

                        break;
                      default:
                    }
                  } else {
                    handleUnPinMessage(listPinnedMessage[0]);
                  }
                }}
                sx={{
                  color: '#D34D4D',
                  padding: '6px 8px',
                  '&:hover': {
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgba(255,255,255,0.2)'
                        : '#f3f5f6'
                  },
                  cursor: 'pointer'
                }}
              >
                Bỏ ghim
              </Typography>
            </Box>
          </Popper>

          <Box>
            {!checkShowData && listPinnedMessage.length > 1 && (
              <Button
                variant="outlined"
                sx={{
                  padding: '0px 10px !important',
                  height: '25px',
                  textTransform: 'lowercase'
                }}
                onClick={handleClickShowDetailPinMessage}
              >
                {listPinnedMessage.length} ghim khác
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

interface Props {
  conversation?: any;
  colorIcon?: boolean;
  idSubRocket?: string;
  type?: string;
  handleRenderInfoUserChat?: any;
  listMemberGrChat?: any;
  listMessageCheckPin?: any;
  setOpenSnackbar?: any;
  setNoti?: any;
  isCertified?: boolean;
}

const Header: React.FC<Props> = React.memo(
  ({
    conversation,
    colorIcon,
    listMemberGrChat,
    type,
    handleRenderInfoUserChat,
    idSubRocket,
    isCertified,
    listMessageCheckPin,
    setOpenSnackbar,
    setNoti
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const match: any = useRouteMatch();

    // const mainTopic = conversation?.RoomsList?.[0]?.topic;
    const mainTopic = conversation?.topic
      ? conversation?.topic?.colors
      : conversation?.RoomsList?.[0]?.topic?.colors || [
          colorBlue.color,
          '#179df7'
        ];
    const [userSelected, setUserSelected] = React.useState<any>({});
    const [status, setStatus] = React.useState('');
    const [openDialogChangeNameGroup, setOpenDialogChangeNameGroup] =
      React.useState<boolean>(false);
    const [openDialogChangedTopic, setOpenDialogChangedTopic] =
      React.useState<boolean>(false);
    const [openDialogChangedEmoticon, setOpenDialogChangedEmoticon] =
      React.useState<boolean>(false);
    const [openDialogSkipMessage, setOpenDialogSkipMessage] =
      React.useState<boolean>(false);
    const [openDialogDeleteConversation, setOpenDialogDeleteConversation] =
      React.useState<boolean>(false);
    const [currentRole, setCurrentRole] = React.useState<'member' | 'admin'>(
      'member'
    );
    const [openDialogViewPinMessage, setOpenDialogViewPinMessage] =
      React.useState<boolean>(false);
    const [isToastMessage, setIsToastMessage] = React.useState<boolean>(false);
    const [titleToastMessage, setTitleToastMessage] =
      React.useState<string>('');
    const [anchorElMenuShowPinMessage, setAnchorElMenuShowPinMessage] =
      React.useState<null | HTMLElement>(null);
    const [listAdmin, setListAdmin] = React.useState<any>([]);
    const meInfo = useSelector((state: any) => state.meReducer.info) || {};
    const meStatus = useSelector((state: any) => state.socialChatReducer.me);
    const [listPinnedMessage, setListPinnedMessage] = React.useState<any>([]);

    const [openDialogMember, setOpenDialogMember] = React.useState<any>(false);
    const [openDialogAddMember, setOpenDialogAddMember] =
      React.useState<any>(false);
    const [openDialogBlockUser, setOpenDialogBlockUser] =
      React.useState<boolean>(false);
    const [openDialogLeaveGroup, setOpenDialogLeaveGroup] =
      React.useState<boolean>(false);
    const [dataPage, setDataPage] = React.useState<any>({});
    const [userStatus, setUserStatus] = React.useState<any>({});
    const isMounted = React.useRef<boolean>(true);
    const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
    const [anchorElPinMessage, setAnchorElPinMessage] =
      React.useState<null | HTMLElement>(null);
    const copyTextToClipboard = text => {
      navigator.clipboard.writeText(text).then(
        () => {
          setAnchorElPinMessage(null);
        },
        err => {
          setAnchorElPinMessage(null);
        }
      );
    };

    const { getRootProps, getInputProps, open } = useDropzone({
      accept: 'image/*',
      maxFiles: 1,
      noClick: true,
      onDrop: (acceptedFiles: any) => {
        // handle update avatar
        handleSaveInfoGroupChat(acceptedFiles);
      }
    });
    React.useEffect(() => {
      if (conversation && conversation.prid && !conversation.user_chat_page) {
        getDataPage();
      }
      return () => {
        isMounted.current = false;
      };
    }, []);
    const handleDirectUserPage = React.useCallback(() => {
      history.replace(`/user/${conversation?.username}`);
    }, []);

    const handleOpenMemberGroup = React.useCallback(() => {
      setOpenDialogMember(true);
    }, []);
    const handleClickShowPinMessage = (
      event: React.MouseEvent<HTMLElement>
    ) => {
      event.stopPropagation();
      setAnchorElPinMessage(anchorElPinMessage ? null : event.currentTarget);
    };
    const openPinMessage = Boolean(anchorElPinMessage);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const fetchUserStatus = async () => {
      try {
        const response = await getUser(conversation?.userId);
        if (response.status === 200) {
          if (isMounted.current) {
            setUserStatus(response.data.user);
          }
        }
      } catch (error) {
        // console.log('[userStatus]', error);
      }
    };
    const handleClickShowDetailPinMessage = (
      event: React.MouseEvent<HTMLElement>
    ) => {
      setAnchorElMenuShowPinMessage(
        anchorElMenuShowPinMessage ? null : event.currentTarget
      );
    };
    React.useEffect(() => {
      if (conversation?.type === 'user') {
        fetchUserStatus();
      }
      return () => {
        isMounted.current = false;
      };
    }, []);
    const handleGetPinnedMessages = async () => {
      try {
        const roomId = await conversation.rid;

        const response = await getPinMessage(3, roomId);
        if (response.status === 200) {
          if (isMounted.current) {
            setListPinnedMessage(response.data.messages);
          }
        }
      } catch (error) {
        console.log('[getPinnedMessage]', error);
      }
    };

    React.useEffect(() => {
      if (
        conversation.rid &&
        (listMessageCheckPin[0]?.t === 'message_pinned' ||
          listMessageCheckPin[0]?.t === 'message_unpinned')
      ) {
        handleGetPinnedMessages();
      }
      return () => {
        isMounted.current = false;
      };
    }, [conversation.rid, listMessageCheckPin]);
    const handleRemoveAdmin = async (conversation, userSelectedDelete) => {
      const roomId = conversation.rid;
      const memberId = userSelectedDelete._id;

      if (roomId && memberId) {
        try {
          const response = await removeMemberAdminGroupChat(roomId, memberId);
          if (response.status === 200) {
            setAnchorEl(null);
            fetchListAdmin(
              conversation,
              setCurrentRole,
              setListAdmin,
              rocketId
            );
          }
        } catch (error) {}
      }
    };

    const hiddenBoxChat = React.useCallback(() => {
      unSubRoom(conversation?.idSubRocket);
      dispatch(
        hiddenUserBoxChat({
          ...conversation,
          lastMessage: conversation.lastMessage
        })
      );
    }, [conversation, dispatch, hiddenUserBoxChat]);

    const handleAddAdmin = async (conversationUser, userSelected) => {
      const roomId = conversationUser.rid;

      const memberId = userSelected._id;

      if (roomId && memberId) {
        try {
          const response = await addMemberAdminGroupChat(roomId, memberId);

          if (response.status === 200) {
            setAnchorEl(null);

            fetchListAdmin(
              conversation,
              setCurrentRole,
              setListAdmin,
              rocketId
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const handleHideConversation = async () => {
      const roomId = conversation.rid;

      try {
        const response = await hideConversation(roomId);
        if (response.status === 200) {
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleBlockRoom = async () => {
      const roomId = conversation.rid;
      const userId =
        conversation?.type === 'group' ||
        conversation.t === 'p' ||
        conversation.t === 'c'
          ? userSelected._id
          : conversation.userId;
      let response;

      if (conversation.t === 'cp') {
        if (conversation?.userBlock || conversation?.pageBlock) {
          await unBlockPage(
            roomId,
            conversation?.user_chat_page &&
              conversation?.user_chat_page?._id !== userId
              ? true
              : false
          );
        } else {
          await blockPage(
            roomId,
            conversation?.user_chat_page &&
              conversation?.user_chat_page?._id !== userId
              ? true
              : false
          );
        }
      } else {
        if (conversation?.blocker) {
          response = await unBlockRoom(roomId, userId);
        } else {
          response = await blockRoom(roomId, userId);
        }
      }

      try {
        if (response.status === 200) {
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleUnPinMessage = async (message: any) => {
      try {
        const response = await unPinMessage(message?._id, conversation?.rid);
        if (response.status === 200) {
          setAnchorElPinMessage(null);
        }
      } catch (error) {
        setIsToastMessage(true);
      }
    };
    // list friend follow status, right tab

    const friends =
      useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
    React.useEffect(() => {
      if (
        Object.keys(friends).length !== 0 &&
        conversation?.t === 'd' &&
        status === ''
      ) {
        const status = friends.find(
          el => el.acct === conversation.username
        )?.status;

        if (status) {
          setStatus(status);
        } else {
          subRocketStatusActiveUser(conversation.userId);
        }
      }
    }, [JSON.stringify(friends)]);

    const iconOptionStyle = {
      fontWeight: '300',
      fontSize: '20px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#050505'
    };

    const getListAdminGroup = async () => {
      const response = await getRoomRoles(conversation?.rid);

      if (response.status === 200) {
        const listAdminGroup = JSON.parse(response.data.message).result;
        if (isMounted.current) {
          if (
            listAdminGroup &&
            listAdminGroup.map(el => el.u._id).includes(rocketId)
          ) {
            setCurrentRole('admin');
          }
          setListAdmin(listAdminGroup);
        }
      }
    };
    React.useEffect(() => {
      if (
        conversation?.type === 'group' ||
        conversation.t === 'p' ||
        conversation.t === 'c'
      ) {
        getListAdminGroup();
      }
      return () => {
        isMounted.current = false;
      };
    }, [isMounted.current, conversation?.type, conversation?.t]);

    // const isAdmin = listAdmin.find((el: any) => el.u._id === meInfo.id);
    const isAdmin = React.useMemo(() => {
      return listAdmin && listAdmin.find(el => el.u._id === meInfo.id);
    }, [listAdmin, meInfo.id]);
    const listActionsConversationChatPage = [
      [
        {
          label: 'Xem trang cá nhân',
          id: 'profile',
          icon: 'fa-light fa-id-badge',
          styleIcon: iconOptionStyle,
          action: () => {
            if (
              conversation &&
              (conversation.type === 'user' || conversation.t === 'd')
            ) {
              history.push(`/user/${conversation?.usernames[0]}`);
            }
            if (
              conversation &&
              (conversation.type === 'chat_page' || conversation.t === 'cp')
            ) {
              if (match.params.key === 'chat') {
                history.push(`/user/${conversation?.u?.username}`);
              } else {
                history.push(`/page/${conversation?.prid}`);
              }
            }
          }
        },
        {
          label: conversation?.disableNotifications
            ? 'Bật thông báo'
            : 'Tắt thông báo',
          id: 'noti',
          icon: conversation?.disableNotifications
            ? 'fa-light fa-bell-slash'
            : 'fa-light fa-bell',
          styleIcon: iconOptionStyle,
          action: () => {
            handleSettingNotification(
              conversation,
              setIsToastMessage,
              rocketId
            );
          }
        },
        // {
        //   label: 'Bỏ qua cuộc trò chuyện',
        //   id: 'unread',
        //   icon: 'fa-light fa-check',
        //   styleIcon: iconOptionStyle,
        //   action: () => {
        //     setOpenDialogSkipMessage(true);
        //   }
        // },
        {
          label: 'Xóa cuộc trò chuyện',
          id: 'delete',
          icon: 'fa-light fa-trash-can',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogDeleteConversation(true);
          }
        },
        {
          label: conversation?.blocker ? 'Bỏ chặn' : 'Chặn',
          id: 'block',
          icon: conversation?.blocker
            ? 'fa-light fa-user-slash'
            : 'fa-light fa-user',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogBlockUser(true);
          }
        }
      ]
    ];

    const listActionsConversationGroup = [
      [
        {
          label: 'Mở trong EmsoChat',
          id: 'openWeb',
          avatar_icon: noti,
          styleIconMessage: {
            transform: `translate(${0}px , ${
              theme.palette.mode === 'dark' ? -526 : -150
            }px)`,
            height: '649px',
            borderWidth: 'inherit',
            width: '33px',
            borderRadius: 'inherit',
            objectFit: 'contain'
          },
          styleBoxMessage: {
            height: '30px',
            width: '25px',
            overflow: 'hidden',
            marginTop: theme.palette.mode === 'dark' ? '6px' : 0
          },
          action: () => {
            history.replace(`/messages/${conversation?.rid}`);
          }
        }
      ],
      [
        {
          label: 'Đổi chủ đề',
          id: 'theme',
          icon: 'fa-light fa-palette',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogChangedTopic(true);
          }
        },
        {
          label: 'Biểu tượng cảm xúc',
          id: 'icon',
          icon: 'fa-light fa-face-smile',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogChangedEmoticon(true);
          }
        },

        {
          label: 'Tên cuộc trò chuyện',
          id: 'title',
          icon: 'fa-light fa-pen',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogChangeNameGroup(true);
          },
          render: conversation?.t === 'd' ? 'no' : 'yes'
        },
        {
          label: 'Thay đổi ảnh',
          id: 'avatar',
          icon: 'fa-light fa-image',
          styleIcon: iconOptionStyle,
          action: () => {
            open();
          }
        },
        {
          label: 'Xem tin nhắn đã ghim',
          id: 'pinMessage',
          icon: 'fa-light fa-thumbtack',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogViewPinMessage(true);
          }
        }
      ],
      [
        (conversation && conversation.t === 'c') || isAdmin
          ? {
              label: 'Thêm người',
              id: 'add',
              icon: 'fa-light fa-user-plus',
              styleIcon: iconOptionStyle,
              action: () => {
                setOpenDialogAddMember(true);
              }
            }
          : {
              style: { display: 'none' }
            },

        {
          label: 'Rời khỏi nhóm',
          id: 'out',
          icon: 'fa-light fa-arrow-right-from-bracket',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogLeaveGroup(true);
          }
        },
        {
          label: conversation?.disableNotifications
            ? 'Bật thông báo'
            : 'Tắt thông báo',
          id: 'noti',
          icon: conversation?.disableNotifications
            ? 'fa-light fa-bell-slash'
            : 'fa-light fa-bell',
          styleIcon: iconOptionStyle,
          action: () => {
            handleSettingNotification(
              conversation,
              setIsToastMessage,
              rocketId
            );
          }
        }
        // {
        //   label: 'Xóa đoạn chat',
        //   id: 'delete',
        //   icon: 'fa-light fa-trash-can',
        //   styleIcon: iconOptionStyle,
        //   action: () => {
        //     setOpenDialogDeleteConversation(true);
        //   }
        //   // show:
        //   //   conversation?.roles && conversation?.roles[0] === 'owner'
        //   //     ? 'yes'
        //   //     : 'no'
        // }
      ]
    ];
    const listActionsConversationUser = [
      [
        {
          label: 'Mở trong EmsoChat',
          id: 'open',
          avatar_icon: noti,
          styleIconMessage: {
            transform: `translate(${0}px , ${
              theme.palette.mode === 'dark' ? -526 : -150
            }px)`,
            height: '649px',
            borderWidth: 'inherit',
            width: '33px',
            borderRadius: 'inherit',
            objectFit: 'contain'
          },
          styleBoxMessage: {
            height: '30px',
            width: '25px',
            overflow: 'hidden',
            marginTop: theme.palette.mode === 'dark' ? '6px' : 0
          },
          action: () => {
            history.push(`/messages/${conversation?.rid}`);
          }
        },
        // {
        //   label: 'Tải ứng dụng Chat',
        //   id: 'download',
        //   icon: 'fa-light fa-desktop',
        //   styleIcon: iconOptionStyle,
        //   action: () => {
        //     // console.log('download chat');
        //   }
        // },
        {
          label: 'Xem trang cá nhân',
          id: 'profile',
          icon: 'fa-light fa-id-badge',
          styleIcon: iconOptionStyle,
          action: () => {
            if (userStatus._id) {
              conversation?.type === 'user'
                ? history.push(`/user/${conversation?.userId}`)
                : handleDirectToPage(conversation);
            }
          }
        }
      ],
      [
        !conversation?.blocker &&
          !conversation?.blocked && {
            label: 'Đổi chủ đề',
            id: 'theme',
            icon: 'fa-light fa-palette',
            styleIcon: iconOptionStyle,
            action: () => {
              setOpenDialogChangedTopic(true);
            }
          },

        !conversation?.blocker &&
          !conversation?.blocked && {
            label: 'Biểu tượng cảm xúc',
            id: 'icon',
            icon: 'fa-light fa-face-smile',
            styleIcon: iconOptionStyle,
            action: () => {
              setOpenDialogChangedEmoticon(true);
            }
          },

        // {
        //   label: 'Biệt danh',
        //   id: 'nickname',
        //   icon: 'fa-light fa-pen',
        //   styleIcon: iconOptionStyle,
        //   action: () => {
        //     setOpenDialogChangedNickName(true);
        //   }
        // }

        !conversation?.blocker &&
          !conversation?.blocked && {
            label: 'Tạo nhóm',
            id: 'group',
            icon: 'fa-light fa-users',
            styleIcon: iconOptionStyle,
            action: () => {
              handleOpenBoxCreateWith();
            }
          },
        {
          label: 'Xem tin nhắn đã ghim',
          id: 'pinMessage',
          icon: 'fa-light fa-thumbtack',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogViewPinMessage(true);
          }
        }
      ],

      [
        {
          label: conversation?.disableNotifications
            ? 'Bật thông báo'
            : 'Tắt thông báo',
          id: 'noti',
          icon: conversation?.disableNotifications
            ? 'fa-light fa-bell-slash'
            : 'fa-light fa-bell',
          styleIcon: iconOptionStyle,
          action: () => {
            handleSettingNotification(
              conversation,
              setIsToastMessage,
              rocketId
            );
          }
        },
        // {
        //   label: 'Bỏ qua cuộc trò chuyện',
        //   id: 'unread',
        //   icon: 'fa-light fa-check',
        //   styleIcon: iconOptionStyle,
        //   action: () => {
        //     setOpenDialogSkipMessage(true);
        //   }
        // },
        {
          label: 'Xóa cuộc trò chuyện',
          id: 'delete',
          icon: 'fa-light fa-trash-can',
          styleIcon: iconOptionStyle,
          action: () => {
            setOpenDialogDeleteConversation(true);
          }
        },
        conversation?.t === 'd' &&
          conversation?.usersCount === 2 && {
            label: conversation?.blocker ? 'Bỏ chặn' : 'Chặn',
            id: 'block',
            icon: conversation?.blocker
              ? 'fa-light fa-user-slash'
              : 'fa-light fa-user',
            styleIcon: iconOptionStyle,
            action: () => {
              setOpenDialogBlockUser(true);
            }
          }
      ]
    ];

    const statusMemberGroupChat = listMemberGrChat.map(el => el.status);
    const updateAtMemberGroupChat = listMemberGrChat
      .map(el => el._updatedAt)
      .sort((a, b) => b - a);
    function checkOnlineMemberGroupChat(memberStatus: string): boolean {
      return memberStatus === 'online';
    }
    const eventTime = moment(updateAtMemberGroupChat[0]);
    let durationTime = eventTime.fromNow();
    const durationUser = moment(userStatus?._updatedAt).fromNow();
    const handleOpenBoxCreateWith = () => {
      dispatch(createGroupChatWith(conversation));
    };

    const getDataPage = async () => {
      try {
        const pageId = conversation.rid.split('_')[0];
        if (conversation && conversation.prid) {
          const response = await getDetailPageApi(conversation.prid || pageId);
          if (response.status === 200) {
            if (isMounted.current) {
              setDataPage(response.data);
            }
          }
        }
      } catch (error) {
        console.log('[getDataPage] Error: ', error);
      }
    };

    const convertFileToBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); //path of the file
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

    const handleSaveInfoGroupChat = async files => {
      const base64 = await convertFileToBase64(files[0]);
      const form = {
        roomAvatar: base64
      };
      const response = await saveInfoGroupChat(conversation.rid, form);
      try {
        if (response.status === 200) {
        }
      } catch (error) {
        console.log('[changedAvatar]', error);
      }
    };

    const subRocketStatusActiveUser = async (userId: string) => {
      try {
        if (!userId || !Object.keys(userStatus).length) return;
        subStatusUser([userId]);
        const user = {
          avatarETag: userStatus?.avatarEtag,
          name: userStatus?.name,
          display_name: userStatus?.name,
          acct: userStatus?.username,
          status: userStatus?.status,
          username: userStatus?.username,
          avatar_media: {
            show_url: `${urlRocketChat}/avatar/${userStatus?.username}`
          }
        };
        dispatch(getFriendOneSuccess(user));
      } catch (error) {
        console.log(error);
      }
    };

    const BoxNameAndCertified = props => {
      const { label } = props;
      return label || '';
    };

    const renderIconOption = (
      nameIcon: any,
      styleIcon: any,
      action: any,
      titleIcon?: any
    ) => {
      return (
        <IconButtonOptions
          icon={
            <i
              className={nameIcon}
              style={{
                color: colorIcon
                  ? mainTopic
                    ? mainTopic?.[0]
                    : colorBlue.color
                  : '#bcc0c4',
                fontSize: 20,
                margin: '0px 4px 0px 4px'
              }}
            ></i>
          }
          style={styleIcon}
          action={() => {
            action();
          }}
        >
          {titleIcon ? (
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '1.5',
                padding: '8px 4px',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1
              }}
            >
              {titleIcon}
            </Typography>
          ) : null}
        </IconButtonOptions>
      );
    };

    const isRenderAvatarGroupChat = conversation?.avatarETag
      ? `${urlRocketChat}/avatar/room/${conversation?.rid}?etag=${conversation?.avatarETag}`
      : `${urlRocketChat}/avatar/room/${conversation?.rid}?etag=${conversation?.RoomsList?.[0]?.avatarETag}`;

    const handleDirectToPage = (conversation: any) => {
      if (dataPage && dataPage.username) {
        history.replace(`/page/${dataPage.username}`);
      } else {
        history.replace(`/page/${conversation.prid}`);
      }
    };

    const actionClickNameHeader = (conversation: any) => {
      let result: any;

      if (conversation && conversation.type) {
        switch (conversation.type) {
          case 'user': {
            result = () => handleDirectUserPage();
            break;
          }
          case 'group': {
            result = () => handleOpenMemberGroup();
            break;
          }
          case 'chat_page': {
            result = () => handleDirectToPage(conversation);
            break;
          }
        }
      }
      return result;
    };

    return (
      <>
        <ListItem
          id="basic-button"
          key={conversation?.id}
          sx={{
            paddingRight: 0,
            paddingLeft: 0,
            height: 48,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          {type !== 'fullScreen' ? (
            <IconButtonOptions
              openPopup={true}
              options={
                type !== 'chatPage'
                  ? conversation?.type === 'group' ||
                    conversation.t === 'p' ||
                    conversation.t === 'c'
                    ? listActionsConversationGroup.map((item: any) =>
                        item.filter((el: any) => el?.show !== 'no')
                      )
                    : conversation?.type === 'user'
                    ? listActionsConversationUser
                    : listActionsConversationChatPage
                  : listActionsConversationChatPage
              }
              horizontalAnchor="left"
              horizontalTransform="right"
              style={{
                backgroundColor: 'none',
                width: 'auto',
                display: 'flex',
                margin: '7px 7px 7px 0px',
                zIndex: 10000,
                position: 'relative'
              }}
              stylePopup={{
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                maxHeight: '385px',
                maxWidth: '344px',
                padding: 0,
                zIndex: 100000,
                position: 'relative'
              }}
              styleListMenu={{
                width: '344px',
                zIndex: 10000,
                position: 'relative'
              }}
              styleTitle={{
                fontSize: '14px !important'
              }}
              styleIconOption={{ display: 'flex', justifyContent: 'center' }}
              popperPlacement="left-start"
              disablePortal={true}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <ListItemAvatar sx={{ minWidth: 40, marginLeft: 0 }}>
                    {conversation?.type === 'user' ||
                    conversation?.type === 'chat_page' ||
                    conversation?.t === 'cp' ||
                    conversation?.t === 'd' ? (
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        variant="dot"
                        sx={{
                          '& .MuiBadge-badge':
                            statusMemberGroupChat.some(
                              checkOnlineMemberGroupChat
                            ) || userStatus?.status === 'online'
                              ? meStatus &&
                                meStatus.status === 'online' && {
                                  backgroundColor: '#44b700',
                                  color: '#44b700',
                                  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                                  '&::after': {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    animation:
                                      'ripple 1.2s infinite ease-in-out',
                                    border: '1px solid currentColor',
                                    content: '""'
                                  }
                                }
                              : null,
                          '@keyframes ripple': {
                            '0%': {
                              transform: 'scale(.8)',
                              opacity: 1
                            },
                            '100%': {
                              transform: 'scale(2.4)',
                              opacity: 0
                            }
                          }
                        }}
                      >
                        <AvatarSocial
                          type="feed"
                          style={{ width: 36, height: 36, marginLeft: 0 }}
                          src={renderAllAvatar(conversation, match)}
                        />
                      </Badge>
                    ) : conversation?.t === 'p' ||
                      conversation?.t === 'c' ||
                      conversation?.type === 'group' ? (
                      <div style={{ position: 'relative' }}>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          variant="dot"
                          sx={{
                            '& .MuiBadge-badge':
                              statusMemberGroupChat.some(
                                checkOnlineMemberGroupChat
                              ) || userStatus?.user?.status === 'online'
                                ? meStatus &&
                                  meStatus.status === 'online' && {
                                    backgroundColor: '#44b700',
                                    color: '#44b700',
                                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                                    '&::after': {
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: '50%',
                                      animation:
                                        'ripple 1.2s infinite ease-in-out',
                                      border: '1px solid currentColor',
                                      content: '""'
                                    }
                                  }
                                : null,
                            '@keyframes ripple': {
                              '0%': {
                                transform: 'scale(.8)',
                                opacity: 1
                              },
                              '100%': {
                                transform: 'scale(2.4)',
                                opacity: 0
                              }
                            }
                          }}
                        >
                          {conversation?.RoomsList?.[0]?.avatarETag ||
                          conversation?.avatarETag ? (
                            <AvatarSocial
                              type="feed"
                              style={{ width: 36, height: 36, marginLeft: 0 }}
                              avatarObj={isRenderAvatarGroupChat}
                            />
                          ) : (conversation &&
                              conversation?.room?.uids?.length === 1) ||
                            (conversation?.members &&
                              conversation?.members.length === 1) ? (
                            <AvatarSocial
                              type="feed"
                              style={{ width: 36, height: 36, marginLeft: 0 }}
                              avatarObj={conversation?.avatar_media?.avatar_top}
                            />
                          ) : (
                            <Box sx={{ position: 'relative' }}>
                              <AvatarSocial
                                avatarObj={
                                  conversation.avatar_media?.avatar_top
                                }
                                style={{
                                  width: 21,
                                  height: 21,
                                  zIndex: 1
                                }}
                                isAvatarGroupChat={'yes'}
                              />
                              <AvatarSocial
                                type="feed"
                                avatarObj={
                                  conversation.avatar_media?.avatar_bottom
                                }
                                style={{
                                  width: 21,
                                  height: 21,
                                  position: 'absolute',
                                  right: '1px',
                                  bottom: '0px',
                                  top: '-8px',
                                  zIndex: 0
                                }}
                              />
                            </Box>
                          )}
                        </Badge>
                      </div>
                    ) : null}
                  </ListItemAvatar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 0.5,
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 15,
                          marginLeft: 4,
                          color:
                            theme.palette.mode === 'dark' ? '#fff' : '#050505',
                          marginRight: 6
                        }}
                      >
                        {collapseString(
                          renderNameChat(conversation, match),
                          match.path === PATHS.CHAT ? 40 : 20
                        )}
                      </Typography>
                      {/* {(conversation.t === 'cp' &&
                        conversation?.identity_verification) ||
                      isCertified ? (
                        <VerifiedIcon
                          titleAccess="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng8"
                          fontSize="small"
                          viewBox="0 -2 24 24"
                          sx={{
                            color: buttonColor.backgroundColor,
                            fontSize: '14px',
                            '&:hover': {
                              color: buttonColorHover.backgroundColor
                            },
                            marginRight: '8px'
                          }}
                        />
                      ) : null} */}
                    </Box>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        marginLeft: 4,
                        color:
                          theme.palette.mode === 'dark' ? '#fff' : '#050505',
                        marginRight: 8
                      }}
                    >
                      {conversation?.type === 'group' ||
                        conversation.t === 'p' ||
                        (conversation.t === 'c' &&
                          (statusMemberGroupChat.some(
                            checkOnlineMemberGroupChat
                          ) &&
                          meStatus &&
                          meStatus.status === 'online' ? (
                            <BoxNameAndCertified label={'Đang hoạt động'} />
                          ) : meStatus?.status === 'invisible' ? (
                            <BoxNameAndCertified />
                          ) : (
                            <BoxNameAndCertified
                              label={`Hoạt động ${durationTime}`}
                            />
                          ))) ||
                        (conversation?.type === 'user' &&
                          (userStatus?.status === 'online' &&
                          meStatus &&
                          meStatus.status === 'online' ? (
                            <BoxNameAndCertified label={'Đang hoạt động'} />
                          ) : meStatus?.status === 'invisible' ||
                            userStatus?.status === 'invisible' ? (
                            <BoxNameAndCertified />
                          ) : (
                            <BoxNameAndCertified
                              label={`Hoạt động ${durationUser}`}
                            />
                          )))}
                    </Typography>
                  </Box>
                </Box>
                {type !== 'fullScreen' && (
                  <i
                    style={{
                      fontSize: '15px',
                      color: colorIcon
                        ? mainTopic
                          ? mainTopic?.[0]
                          : colorBlue.color
                        : '#bcc0c4'
                    }}
                    className="fa-regular fa-angle-down"
                  ></i>
                )}
              </Box>
            </IconButtonOptions>
          ) : (
            <ButtonComponent
              action={actionClickNameHeader(conversation)}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <ListItemAvatar sx={{ minWidth: 40, marginLeft: 0 }}>
                      {conversation?.type === 'user' ||
                      conversation?.type === 'chat_page' ||
                      conversation?.t === 'cp' ||
                      conversation?.t === 'd' ? (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          variant="dot"
                          sx={{
                            '& .MuiBadge-badge':
                              statusMemberGroupChat.some(
                                checkOnlineMemberGroupChat
                              ) || userStatus?.user?.status === 'online'
                                ? meStatus &&
                                  meStatus.status === 'online' && {
                                    backgroundColor: '#44b700',
                                    color: '#44b700',
                                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                                    '&::after': {
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: '50%',
                                      animation:
                                        'ripple 1.2s infinite ease-in-out',
                                      border: '1px solid currentColor',
                                      content: '""'
                                    }
                                  }
                                : null,
                            '@keyframes ripple': {
                              '0%': {
                                transform: 'scale(.8)',
                                opacity: 1
                              },
                              '100%': {
                                transform: 'scale(2.4)',
                                opacity: 0
                              }
                            }
                          }}
                        >
                          <AvatarSocial
                            type="feed"
                            style={{ width: 36, height: 36, marginLeft: 0 }}
                            avatarObj={
                              conversation?.avatar_media?.show_url ||
                              conversation?.avatar_media
                            }
                          />
                        </Badge>
                      ) : conversation?.t === 'p' ||
                        conversation?.t === 'c' ||
                        conversation?.type === 'group' ? (
                        <div style={{ position: 'relative' }}>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            variant="dot"
                            sx={{
                              '& .MuiBadge-badge':
                                statusMemberGroupChat.some(
                                  checkOnlineMemberGroupChat
                                ) || userStatus?.user?.status === 'online'
                                  ? meStatus &&
                                    meStatus.status === 'online' && {
                                      backgroundColor: '#44b700',
                                      color: '#44b700',
                                      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                                      '&::after': {
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                        animation:
                                          'ripple 1.2s infinite ease-in-out',
                                        border: '1px solid currentColor',
                                        content: '""'
                                      }
                                    }
                                  : null,
                              '@keyframes ripple': {
                                '0%': {
                                  transform: 'scale(.8)',
                                  opacity: 1
                                },
                                '100%': {
                                  transform: 'scale(2.4)',
                                  opacity: 0
                                }
                              }
                            }}
                          >
                            {conversation?.RoomsList?.[0]?.avatarETag ? (
                              <AvatarSocial
                                type="feed"
                                style={{ width: 36, height: 36, marginLeft: 0 }}
                                avatarObj={isRenderAvatarGroupChat}
                              />
                            ) : (conversation &&
                                conversation?.uids?.length === 1) ||
                              (conversation?.members &&
                                conversation?.members.length === 1) ? (
                              <AvatarSocial
                                type="feed"
                                style={{ width: 36, height: 36, marginLeft: 0 }}
                                avatarObj={
                                  conversation?.avatar_media?.avatar_top
                                }
                              />
                            ) : (
                              <Box sx={{ position: 'relative' }}>
                                <AvatarSocial
                                  avatarObj={
                                    conversation.avatar_media?.avatar_top
                                  }
                                  style={{
                                    width: 21,
                                    height: 21,
                                    zIndex: 1
                                  }}
                                  isAvatarGroupChat={'yes'}
                                />
                                <AvatarSocial
                                  type="feed"
                                  avatarObj={
                                    conversation.avatar_media?.avatar_bottom
                                  }
                                  style={{
                                    width: 21,
                                    height: 21,
                                    position: 'absolute',
                                    right: '1px',
                                    bottom: '0px',
                                    top: '-8px',
                                    zIndex: 0
                                  }}
                                />
                              </Box>
                            )}
                          </Badge>
                        </div>
                      ) : null}
                    </ListItemAvatar>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 0.5,
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: 15,
                            marginLeft: 4,
                            color:
                              theme.palette.mode === 'dark'
                                ? '#fff'
                                : '#050505',
                            marginRight: 8
                          }}
                        >
                          {collapseString(
                            renderNameChat(conversation, match),
                            match.path === PATHS.CHAT ? 40 : 20
                          )}
                        </Typography>
                        {/* {(conversation.t === 'cp' &&
                          conversation?.identity_verification) ||
                        isCertified ? (
                          <VerifiedIcon
                            titleAccess="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                            fontSize="small"
                            viewBox="0 -2 24 24"
                            sx={{
                              color: buttonColor.backgroundColor,
                              fontSize: '14px',
                              '&:hover': {
                                color: buttonColorHover.backgroundColor
                              },
                              marginRight: '8px'
                            }}
                          />
                        ) : null} */}
                      </Box>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          marginLeft: 4,
                          color:
                            theme.palette.mode === 'dark' ? '#fff' : '#050505',
                          marginRight: 8
                        }}
                      >
                        {conversation?.type === 'group' ||
                          conversation.t === 'p' ||
                          (conversation.t === 'c' &&
                            (statusMemberGroupChat.some(
                              checkOnlineMemberGroupChat
                            ) &&
                            meStatus &&
                            meStatus.status === 'online' ? (
                              <BoxNameAndCertified label={'Đang hoạt động'} />
                            ) : meStatus?.status === 'invisible' ? (
                              <BoxNameAndCertified />
                            ) : (
                              <BoxNameAndCertified
                                label={`Hoạt động ${durationTime}`}
                              />
                            ))) ||
                          (conversation?.type === 'user' &&
                            (userStatus?.status === 'online' &&
                            meStatus &&
                            meStatus.status === 'online' ? (
                              <BoxNameAndCertified label={'Đang hoạt động'} />
                            ) : meStatus?.status === 'invisible' ||
                              userStatus?.status === 'invisible' ? (
                              <BoxNameAndCertified />
                            ) : (
                              <BoxNameAndCertified
                                label={`Hoạt động ${durationUser}`}
                              />
                            )))}
                      </Typography>
                    </Box>
                  </Box>
                  {type !== 'fullScreen' && (
                    <i
                      style={{
                        fontSize: '15px',
                        color: colorIcon
                          ? mainTopic
                            ? mainTopic?.[0]
                            : colorBlue.color
                          : '#bcc0c4'
                      }}
                      className="fa-regular fa-angle-down"
                    ></i>
                  )}
                </Box>
              }
              style={{
                width: 'fit-content',
                marginLeft: '10px',
                backgroundColor: 'unset',
                padding: '15px 5px'
              }}
              styleBtnHover={{
                padding: '24px 5px'
              }}
            ></ButtonComponent>
          )}

          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 0
            }}
            {...getRootProps({ className: 'dropzone' })}
          >
            <input {...getInputProps()} id="files" name="files" />
            {type !== 'chatPage' &&
              renderIconOption(
                type === 'fullScreen'
                  ? 'fa-solid fa-phone'
                  : 'fa-solid fa-minus',
                styleIconButtonHeaderHidden,
                type === 'fullScreen' ? () => {} : hiddenBoxChat
              )}
            {renderIconOption(
              type === 'fullScreen'
                ? 'fa-solid fa-video'
                : type === 'chatPage'
                ? ''
                : 'fa-solid fa-xmark',
              styleIconButtonHeaderClose,
              type === 'fullScreen'
                ? () => {}
                : () => {
                    removeBoxChat(idSubRocket, conversation, dispatch);
                    // Comment do khi gọi sẽ làm gọi thêm sự kiện sub change dẫn đến k tắt được popup chat
                    // actionTyping(
                    //   conversation?.rid,
                    //   meInfo?.display_name,
                    //   null,
                    //   meInfo?.username
                    // );
                  }
            )}

            {(type === 'chatPage' || type === 'fullScreen') &&
              renderIconOption(
                'fa-solid fa-circle-info',
                styleIconButtonHeaderInfo,
                handleRenderInfoUserChat,
                type === 'chatPage' ? 'Xem danh bạ ' : ''
              )}
          </div>
        </ListItem>

        {type === 'fullScreen' && listPinnedMessage.length > 0 && (
          <>
            <hr
              style={{
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#3E4042' : '#CED0D4',
                height: '1px'
              }}
            />
            {!anchorElMenuShowPinMessage && (
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorElPinMessage(null);
                }}
              >
                <Box>
                  <PinMessageComponent
                    listPinnedMessage={listPinnedMessage}
                    setAnchorElPinMessage={setAnchorElPinMessage}
                    handleClickShowPinMessage={handleClickShowPinMessage}
                    copyTextToClipboard={copyTextToClipboard}
                    handleClickShowDetailPinMessage={
                      handleClickShowDetailPinMessage
                    }
                    theme={theme}
                    openPinMessage={openPinMessage}
                    id={openPinMessage ? `simple-popper` : undefined}
                    anchorElPinMessage={anchorElPinMessage}
                    checkShowData={false}
                    dataRender=""
                    indexKey=""
                    handleUnPinMessage={handleUnPinMessage}
                  />
                </Box>
              </ClickAwayListener>
            )}
            {anchorElMenuShowPinMessage && (
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorElMenuShowPinMessage(null);
                  setAnchorElPinMessage(null);
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    zIndex: 100,
                    position: 'relative',
                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)'
                  }}
                  onClick={e => {
                    setAnchorElPinMessage(null);
                  }}
                >
                  {listPinnedMessage.map((el: any, index: any) => {
                    const id = `simple-popper-${index}`;

                    return (
                      <PinMessageComponent
                        listPinnedMessage={listPinnedMessage}
                        setAnchorElPinMessage={setAnchorElPinMessage}
                        handleClickShowPinMessage={handleClickShowPinMessage}
                        copyTextToClipboard={copyTextToClipboard}
                        handleClickShowDetailPinMessage={
                          handleClickShowDetailPinMessage
                        }
                        theme={theme}
                        openPinMessage={openPinMessage}
                        id={id}
                        anchorElPinMessage={anchorElPinMessage}
                        checkShowData={true}
                        dataRender={el}
                        indexKey={index}
                        handleUnPinMessage={handleUnPinMessage}
                      />
                    );
                  })}
                </Box>
              </ClickAwayListener>
            )}
          </>
        )}

        {openDialogChangeNameGroup ? (
          <DialogChangeNameGroupChat
            open={openDialogChangeNameGroup}
            setOpen={setOpenDialogChangeNameGroup}
            conversation={conversation}
            name={conversation.fname}
            setOpenSnackbar={setOpenSnackbar}
            setNoti={setNoti}
          />
        ) : null}

        {openDialogAddMember ? (
          <DialogAddMemberGroupChat
            open={openDialogAddMember}
            setOpen={setOpenDialogAddMember}
            conversation={conversation}
            members={conversation.members}
          />
        ) : null}
        {/* {openDialogChangedNickName ? (
          <DialogChangedNickNameMemberGr
            open={openDialogChangedNickName}
            setOpen={setOpenDialogChangedNickName}
            conversation={conversation}
            listMemberGrChat={listMemberGrChat}
          />
        ) : null} */}
        {openDialogSkipMessage ? (
          <DialogSkipConversation
            open={openDialogSkipMessage}
            setOpen={setOpenDialogSkipMessage}
            handleHideConversation={handleHideConversation}
            conversation={conversation}
            setIsToastMessage={setIsToastMessage}
          />
        ) : null}
        {openDialogDeleteConversation ? (
          <DialogDeleteConversation
            open={openDialogDeleteConversation}
            setOpen={setOpenDialogDeleteConversation}
            // handleActionCleanHistory={handleActionCleanHistory}
            conversation={conversation}
          />
        ) : null}
        {openDialogChangedTopic ? (
          <DialogChangedTopic
            open={openDialogChangedTopic}
            setOpen={setOpenDialogChangedTopic}
            conversation={conversation}
          />
        ) : null}
        {openDialogChangedEmoticon ? (
          <DialogChangedEmoticon
            open={openDialogChangedEmoticon}
            setOpen={setOpenDialogChangedEmoticon}
            conversation={conversation}
          />
        ) : null}
        {openDialogLeaveGroup ? (
          <DialogLeaveGroup
            open={openDialogLeaveGroup}
            setOpen={setOpenDialogLeaveGroup}
            conversation={conversation}
            setIsToastMessage={setIsToastMessage}
            setTitleToastMessage={setTitleToastMessage}
            listAdmin={listAdmin}
          />
        ) : null}

        {openDialogBlockUser ? (
          <DialogBlockMember
            open={openDialogBlockUser}
            setOpen={setOpenDialogBlockUser}
            conversation={conversation}
            userSelected={userSelected}
            handleBlockRoom={handleBlockRoom}
          />
        ) : null}
        {openDialogMember ? (
          <DialogMemberGroupChat
            open={openDialogMember}
            setOpen={setOpenDialogMember}
            conversation={conversation}
            listMemberGrChat={listMemberGrChat}
            handleAddAdmin={handleAddAdmin}
            handleRemoveAdmin={handleRemoveAdmin}
            setCurrentRole={setCurrentRole}
            listAdmin={listAdmin}
            setListAdmin={setListAdmin}
            currentRole={currentRole}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
          />
        ) : null}
        {openDialogViewPinMessage ? (
          <DialogViewPinMessage
            open={openDialogViewPinMessage}
            setOpen={setOpenDialogViewPinMessage}
            conversation={conversation}
          />
        ) : null}
        {isToastMessage ? (
          <CustomizedSnackBars
            open={isToastMessage}
            setOpen={setIsToastMessage}
            message={titleToastMessage}
            code={0}
          />
        ) : null}
      </>
    );
  }
);

export default Header;
