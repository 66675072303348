import React from 'react';
import { sendMessageApi, createDirectMessage } from 'src/apis/socialChat.apis';
import { urlRocketChat } from 'src/util/config';
// import { selectedUserBoxChat } from 'src/store/action/socialChatAction';
import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Theme
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { collapseString } from 'src/common/string';
import { buttonColor } from 'src/constants/styles';
const ForwardMessageComponent = (props: any) => {
  const { message, item, statusForwardMessage, setStatusForwardMessage } =
    props;

  const [isForwardMessage, setIsForwardMessage] = React.useState<boolean>(true);
  const [isToggleButton, setIsToggleButton] = React.useState<boolean>(true);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      buttonGroup: {
        '& .css-1daoff1-MuiButtonBase-root-MuiButton-root:hover': {
          backgroundColor: '#cccccc36 !important'
        },
        width: '100%',
        justifyContent: 'flex-start !important',
        color: '#333 !important',
        marginTop: '20px !important',
        padding: '12px 15px !important',
        borderRadius: '10px !important',
        fontSize: '17px !important'
      },
      sendGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      },
      sendFriend: {
        minWidth: '30px !important',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? `${buttonColor.backgroundColor} !important`
            : '#E7F3FF !important',
        color:
          theme.palette.mode === 'dark'
            ? '#fff !important'
            : `${buttonColor.backgroundColor} !important`,
        fontWeight: '500 !important',
        boxShadow: 'none !important'
      },
      textBold: {
        fontWeight: '500 !important'
      },
      textFriend: {
        fontSize: '17px !important',
        fontWeight: '500 !important',
        marginLeft: '15px !important',
        marginBottom: '5px !important'
      },
      title: {
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        textAlign: 'center'
      }
    })
  );
  const classes = useStyles();
  const handleChatNow = async (el: any) => {
    try {
      let roomId;
      let type = 'forward';
      let typeForwards = '';
      let messageForward =
        // message?.attachments[0]?.image_url ??
        // message?.attachments[0]?.video_url ??
        message?.msg ?? message?.md[0]?.value?.[0]?.value;
      if (message?.attachments) {
        if (message?.attachments[0]?.video_type) {
          messageForward = `${urlRocketChat}${message?.attachments[0]?.video_url}`;
          typeForwards = 'video';
          // messageForward = message?.attachments[0];
        } else if (message?.attachments[0]?.image_type) {
          messageForward = `${urlRocketChat}${message?.attachments[0]?.image_url}`;
          typeForwards = 'image';
        } else if (message?.attachments[0]?.audio_type) {
          messageForward = `${urlRocketChat}${message?.attachments[0]?.audio_url}`;
          typeForwards = 'audio';
        } else if (message?.msg.startsWith(`[ ](${urlRocketChat}/`)) {
          messageForward = message?.msg.split(') ')[1];
        } else {
          messageForward = message?.attachments[0].title_link;
        }
      } else if (message?.attachments?.[0]?.message_link) {
        messageForward = message?.msg ?? message?.md[0]?.value?.[0]?.value;
      }

      if (el.t === 'p' || el.t === 'c') {
        roomId = el.rid ?? el._id;
      } else {
        const response = await createDirectMessage(el?.username);
        if (response.status === 200) {
          roomId = JSON.parse(response.data.message).result.rid;
        }
      }

      const res = await sendMessageApi(
        messageForward,
        roomId,
        type,
        typeForwards
      );
      setStatusForwardMessage(prev => [...prev, res.data.message._id]);
      // let conversationSelected;

      if (res.status === 200) {
      }
    } catch (error) {
      console.log('[ForwardMessage]', error);
    }
  };

  const handleForwardMessage = el => {
    setIsToggleButton(false);
    if (isForwardMessage) {
      handleChatNow(el);
    }
  };

  // const handleReturnForwardMessage = () => {
  //   setIsForwardMessage(false);
  //   setIsToggleButton(true);
  // };

  let avatar_top = {
    show_url: item?.members?.[0]?.username
      ? `${urlRocketChat}/avatar/${item?.members?.[0]?.username}`
      : `${urlRocketChat}/avatar/${item?.members?.[0]}`
  };

  let avatar_bottom = {
    show_url: item?.members?.[0]?.username
      ? `${urlRocketChat}/avatar/${item?.members?.[1]?.username}`
      : `${urlRocketChat}/avatar/${item?.members?.[1]}`
  };

  return (
    <ListItem>
      <>
        <ListItemAvatar style={{ position: 'relative' }}>
          {item.t === 'p' || item.t === 'c' ? (
            item?.RoomsList?.[0]?.avatarETag ? (
              <Avatar
                alt="avatar"
                sx={{
                  height: 40,
                  width: 40
                }}
                src={
                  item?.avatarETag
                    ? `${urlRocketChat}/avatar/room/${item?.rid}?etag=${item?.avatarETag}`
                    : `${urlRocketChat}/avatar/room/${item?.rid}?etag=${item?.RoomsList?.[0]?.avatarETag}`
                }
              />
            ) : (
              <>
                <AvatarSocial
                  avatarObj={avatar_top}
                  style={{
                    width: 30,
                    height: 30,
                    zIndex: 1
                  }}
                  isAvatarGroupChat={'yes'}
                />
                <AvatarSocial
                  type="feed"
                  avatarObj={avatar_bottom}
                  style={{
                    width: 30,
                    height: 30,
                    position: 'absolute',
                    right: '6px',
                    top: '-8px',
                    zIndex: 0
                  }}
                />
              </>
            )
          ) : (
            <Avatar
              alt="avatar"
              sx={{
                height: 40,
                width: 40
              }}
              src={item?.avatar_media?.show_url}
            />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.textBold}>
              {collapseString(item.fname, 24)}
            </Typography>
          }
        />
      </>
      {isToggleButton &&
      !statusForwardMessage.includes(item?.lastMessage?._id) ? (
        <Button
          id={item._id}
          variant="contained"
          className={classes.sendFriend}
          onClick={() => handleForwardMessage(item)}
        >
          Gửi
        </Button>
      ) : (
        <Button
          id={item._id}
          variant="contained"
          className={classes.sendFriend}
          // onClick={handleReturnForwardMessage}
          disabled={true}
          sx={{
            textTransform: 'inherit',
            // opacity: statusForwardMessage?.success === true ? 0.5 : 1
            opacity: 0.75
          }}
        >
          {statusForwardMessage.includes(item?.lastMessage?._id)
            ? 'Đã gửi'
            : 'Đang gửi'}
        </Button>
      )}
    </ListItem>
  );
};

export default ForwardMessageComponent;
