import { Box, Divider, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { likeFollowPage } from 'src/apis/socialPages.api';
import {
  createUpdatePostApi,
  crudBookmarkApi,
  deletePostApi,
  getListMomentApi,
  getListPostTagApi,
  postWatchHistoriesApi,
  reactionPostApi,
  unPinPostApi,
  unReactionPostApi
} from 'src/apis/socialPost.api';
import { followUser, unFollowUser } from 'src/apis/socialUser';
import { shortenLargeNumber } from 'src/common/number';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import CUPost from 'src/components/CUPost';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import DialogVisibility from 'src/components/Dialog/DialogPostVisibility';
import DialogPreviewMedia from 'src/components/Dialog/DialogPreviewMedia';
import DialogShareToGroup from 'src/components/Dialog/DialogShare/DialogShareToGroup';
import DialogShareToMessenger from 'src/components/Dialog/DialogShare/DialogShareToMessenger';
import MenuAction from 'src/components/Menu';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import NameSocial from 'src/components/ProfileCardImage/NameSocial';
import TextCollapse from 'src/components/TextCollapse/Index';
import ImageRender from 'src/components/ImageGrid/components/ImageRender';
import { listVisibility } from 'src/constants/common';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';
import { PATHS } from 'src/constants/paths';
import { handleTimeShow } from 'src/helpers/string';
import { updateCachePage } from 'src/store/action/saveCacheAction';
import {
  addPostToView,
  deleteOldPostSuccess,
  getPostRequestAction,
  notiSharePost,
  reactionPost,
  removePostToView,
  unbookmarkPostSuccess,
  unpinPostSuccess,
  updatePostSuccessAction,
  updateReblogsCount
} from 'src/store/action/socialPostAction';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import DialogReport from 'src/components/Dialog/DialogReport';
import DialogCreateCollectionSaved from 'src/components/Dialog/DialogCreateCollectionSaved';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

interface Props {
  moment?: any;
  type?: string;
  typePost?: string;
  typeShare?: string;
  setPostData?: any;
  listData?: any;
  setListData?: any;
  notShowData?: boolean; //void duplicate information author when share moment
}

const FeedMoment = React.memo((props: Props) => {
  const {
    moment,
    type,
    typePost, //for function unPin post and css moment in pinned
    typeShare,
    setPostData,
    listData,
    setListData,
    notShowData
  } = props;
  // const match: any = useRouteMatch();
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      padding: '20px 12px'
    },
    wrapAvatar: {
      width: '56px'
    },
    wrapMedia: {
      width: 'calc(100% - 56px)'
    },
    wrapInformation: {
      marginLeft: 15,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    wrapBody: {
      fontSize: '15px !important',
      wordBreak: 'break-word',
      '& a.hashtag': {
        textDecoration: 'none',
        color: '#fff',
        fontWeight: '500 !important'
      }
    },
    wrapContent: {
      display: 'inline-flex',
      alignItems: 'flex-end',
      position: 'relative'
    },
    wrapReaction: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        cursor: 'pointer'
      },
      '&> i': {
        fontSize: 22,
        color: '#fff'
      }
    },
    wrapReactionLove: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        cursor: 'pointer'
      },
      '&> i': {
        fontSize: 22,
        color: 'rgb(254, 44, 85) !important'
      }
    },
    wrapListReaction: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20
    },

    wrapReactionInfor: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10
    },
    wrapButtonMore: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    colorTag: {
      color: '#fff',
      fontWeight: 700
    },
    boxHeaderMoment: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
    headerMomentLeft: {},
    headerMomentRight: {},
    textFollow: {
      fontSize: '16px',
      paddingLeft: '20px',
      color: 'rgb(113, 101, 224)',
      fontWeight: '400',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  // let params: any = match.params;
  const [postMoment, setPostMoment] = React.useState<any>(moment);
  // const [listPostMoment, setListPostMoment] = React.useState<any>([]);
  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );
  const listReport = useSelector(
    (state: any) => state.socialReportReducer.listReport
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDialogVisibility, setOpenDialogVisibility] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState<any>(false);
  const [indexMedia, setIndexMedia] = React.useState(-1);
  const [isVolume, setIsVolume] = React.useState(true);
  const [isLoadMore, setIsLoadMore] = React.useState(false);
  const [momentRenderDialog, setMomentRenderDialog] =
    React.useState<any>(moment);
  const [openCUPost, setOpenCUPost] = React.useState(false);
  const [sharePostNow, setSharePostNow] = React.useState(false);
  const [openDialogShareToGroup, setOpenDialogShareToGroup] =
    React.useState(false);
  const [openDialogShareToMessenger, setOpenDialogShareToMessenger] =
    React.useState(false);
  const [shareTo, setShareTo] = React.useState('');
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [isReload, setIsReload] = React.useState(false);
  const [isFollowMoment, setIsFollowMoment] = React.useState(false);

  const [openDialogCreateCollection, setOpenDialogCreateCollection] =
    React.useState<any>(null);
  const [openDialogReport, setOpenDialogReport] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  let status_tags = postMoment?.status_tags;

  //Get data reaction moment from activities redux
  React.useEffect(() => {
    if (postMoment?.viewer_reaction !== moment?.viewer_reaction) {
      setPostMoment(prev => ({
        ...prev,
        reactions: moment?.reactions,
        viewer_reaction: moment?.viewer_reaction || null
      }));
    }
  }, [moment.viewer_reaction]);

  React.useEffect(() => {
    //Synchronization reblog count data between post in list post and post in dialog
    if (postMoment?.reblogs_count !== momentRenderDialog?.reblogs_count) {
      if (postMoment?.reblogs_count > momentRenderDialog?.reblogs_count) {
        const newState = {
          ...momentRenderDialog,
          reblogs_count: postMoment?.reblogs_count
        };
        setMomentRenderDialog(newState);
      } else {
        const newState = {
          ...postMoment,
          reblogs_count: momentRenderDialog?.reblogs_count
        };
        setPostMoment(newState);
      }
    }
  }, [postMoment, momentRenderDialog]);

  const increment_reblog_count = () => {
    dispatch(updateReblogsCount(postMoment));
    const newData = { ...postMoment };
    ++newData.reblogs_count;
    setPostMoment(newData);
  };

  let optionsShare: any = [
    [
      {
        id: 0,
        key: 'share_now',
        label: 'Chia sẻ ngay',
        icon: 'fal fa-share',
        styleIcon: { fontSize: '20px' },
        action: () => {
          console.log(1234);
          setSharePostNow(true);
        }
      },
      {
        id: 1,
        key: 'share',
        label: 'Chia sẻ lên bảng tin',
        icon: 'fal fa-edit',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setOpenCUPost(true);
        }
      },
      {
        id: 2,
        key: 'sendMessenger',
        label: 'Gửi bằng Emso chat',
        icon: 'fal fa-comment',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setOpenDialogShareToMessenger(true);
        }
      },
      {
        id: 3,
        key: 'shareToGroup',
        label: 'Chia sẻ lên cộng đồng',
        icon: 'fal fa-users',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setShareTo('group');
          setOpenDialogShareToGroup(true);
        }
      },
      {
        id: 4,
        key: 'shareToPage',
        label: 'Chia sẻ lên Trang',
        icon: 'fa-light fa-flag',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setShareTo('page');
          setOpenDialogShareToGroup(true);
        }
      },
      {
        id: 5,
        key: 'shareToFriend',
        label: 'Chia sẻ lên trang cá nhân của bạn bè',
        icon: 'fal fa-user-friends',
        styleIcon: { fontSize: '20px' },
        action: () => {
          setShareTo('friend');
          setOpenDialogShareToGroup(true);
        }
      }
    ]
  ];

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      let res = await deletePostApi(postMoment.id);
      if (res.status === 200) {
        // let filterActivitiesCache = activitiesCache.filter(
        //   item => item.id !== postMoment.id
        // );
        setPostMoment(null);
        dispatch(deleteOldPostSuccess(postMoment.id));
        dispatch(
          notiSharePost({
            code: 200,
            msg: 'Bài viết khoảnh khắc của bạn đã bị xóa.'
          })
        );
        // setTimeout(() => {
        //   dispatch(updateCachePage(filterActivitiesCache));
        // }, 1000);
        dispatch(deleteOldPostSuccess(postMoment.id));
      }
    } catch (err: any) {
      dispatch(
        notiSharePost({
          code: 400,
          msg: 'Có lỗi xảy ra vui lòng thử lại.'
        })
      );
    }
  };

  const handleUpdateVisibility = async key => {
    let res = await createUpdatePostApi(
      {
        visibility: key
      },
      postMoment.id
    );
    if (res.status === 200) {
      setPostMoment((prev: any) => ({
        ...prev,
        visibility: key
      }));
      setMomentRenderDialog((prev: any) => ({
        ...prev,
        visibility: key
      }));
      dispatch(updatePostSuccessAction(res.data));
    }
  };

  // Update reaction post for data activities redux
  const handleCallAPIClickVote = async (
    idReaction: number,
    idPage: string | null
  ) => {
    let response;
    let data = {
      custom_vote_type: EnumEmojiReactType[idReaction],
      page_id: rolePage?.role === 'admin' ? idPage : null
    };
    if (idReaction < 0) {
      response = await unReactionPostApi(postMoment.id, {
        page_id: rolePage?.role === 'admin' ? idPage : null
      });
      if (response.status === 200) {
        dispatch(
          reactionPost(
            postMoment.id,
            response.data.favourites,
            null,
            response.data.reactions
          )
        );
      }
    } else {
      response = await reactionPostApi(postMoment.id, data);
      if (response.status === 200) {
        dispatch(
          reactionPost(
            postMoment.id,
            response.data.favourites,
            response.data.viewer_reaction,
            response.data.reactions
          )
        );
      }
    }
  };

  const debounceClickPost = useCallback(
    _.debounce(idReaction => {
      handleCallAPIClickVote(idReaction, rolePage?.page_owner_id);
    }, 500),
    [rolePage?.page_owner_id]
  );

  const handleReactPost = async (idReaction: number) => {
    setMomentRenderDialog(prev => ({
      ...prev,
      reactions: [
        ...postMoment.reactions.slice(0, 3),

        {
          type: 'love',
          loves_count: postMoment?.viewer_reaction
            ? postMoment?.reactions?.[3]?.loves_count - 1
            : postMoment?.reactions?.[3]?.loves_count + 1
        },
        ...postMoment.reactions.slice(4)
      ],
      viewer_reaction: postMoment?.viewer_reaction ? null : 'love'
    }));
    setPostMoment(prev => ({
      ...prev,
      reactions: [
        ...postMoment.reactions.slice(0, 3),

        {
          type: 'love',
          loves_count: postMoment?.viewer_reaction
            ? postMoment?.reactions?.[3]?.loves_count - 1
            : postMoment?.reactions?.[3]?.loves_count + 1
        },
        ...postMoment.reactions.slice(4)
      ],
      viewer_reaction: postMoment?.viewer_reaction ? null : 'love'
    }));
    setPostData &&
      setPostData(prev => ({
        ...prev,
        reactions: [
          ...postMoment.reactions.slice(0, 3),

          {
            type: 'love',
            loves_count: postMoment?.viewer_reaction
              ? postMoment?.reactions?.[3]?.loves_count - 1
              : postMoment?.reactions?.[3]?.loves_count + 1
          },
          ...postMoment.reactions.slice(4)
        ],
        viewer_reaction: postMoment?.viewer_reaction ? null : 'love'
      }));
    debounceClickPost(idReaction);
  };

  const handleAction = type => {
    if (type === 'love') {
      if (postMoment?.viewer_reaction) {
        handleReactPost(EnumEmojiReactType.default);
      } else {
        handleReactPost(EnumEmojiReactType.love);
      }
    } else if (type === 'comment') {
      setOpenPreview(true);
      setIsLoadMore(true);
    }
  };

  const handleUnbookmark = async (data: any) => {
    let res: any = await crudBookmarkApi('DELETE', null, data, '1', null);
    if (res.status === 200) {
      dispatch(unbookmarkPostSuccess(data.bookmark_id, res.data));
    }
  };

  const handleUnPinPost = async id => {
    const res: any = await unPinPostApi(id);
    if (res.status === 200) {
      dispatch(unpinPostSuccess(id, res.data));
      dispatch(
        notiSharePost({
          code: 200,
          msg: 'Đã bỏ ghim bài viết của bạn'
        })
      );
    }
  };

  let listMenu = [
    //for video owner
    //post in url moment
    {
      key: 'edit-visibility',
      icon: 'fa-light fa-pen',
      label: 'Chỉnh sửa quyền riêng tư',
      action: () => {
        setOpenDialogVisibility(true);
        handleCloseMenu();
      },
      visible:
        postMoment?.course ||
        postMoment?.project ||
        postMoment?.event ||
        (postMoment?.page && postMoment?.post_type === 'moment') ||
        postMoment?.account?.id !== meInfo?.id ||
        postMoment?.group
    },
    {
      key: 'delete',
      icon: 'fa-light fa-trash',
      label: 'Xóa video',
      action: () => {
        handleCloseMenu();
        setOpenDelete(true);
      },
      visible:
        postMoment?.course ||
        postMoment?.project ||
        postMoment?.event ||
        (postMoment?.page && postMoment?.post_type === 'moment') ||
        postMoment?.account?.id !== meInfo?.id
    },
    //post in pinned
    {
      key: 'pinPost',
      icon: 'fal fa-thumbtack',
      label: 'Bỏ ghim bài viết',
      action: () => {
        handleUnPinPost(moment?.id);
      },
      visible:
        !(typePost === 'pinned') || postMoment?.account?.id !== meInfo?.id
    },

    //for watcher video
    {
      key: 'save-moment',
      //action trong dialog cho dữ liệu trong redux. Nên dùng dữ liệu hiển thị icon của biến moment chứ không dùng postMoment
      icon: moment?.bookmarked
        ? 'fa-light fa-bookmark-slash'
        : 'fal fa-bookmark',
      label: moment?.bookmarked ? 'Bỏ lưu khoảnh khắc' : 'Lưu khoảnh khắc',
      action: () => {
        if (!moment?.bookmarked) {
          setOpenDialogCreateCollection(2);
        } else {
          handleUnbookmark({ bookmark_id: postMoment?.id });
        }
        handleCloseMenu();
      },
      visible: postMoment?.account?.id === meInfo?.id
    },
    {
      key: 'copy-moment',
      icon: 'fal fa-link',
      label: 'Sao chép liên kết',
      action: () => {
        handleCloseMenu();
        let originLocation = window.location.origin;
        navigator.clipboard.writeText(
          `${originLocation}/moment?mediaId=${postMoment?.media_attachments[0]?.id}`
        );
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Đã sao chép'
        });
      },
      visible: postMoment?.account?.id === meInfo?.id
    },
    {
      key: 'report-moment',
      icon: 'fa-light fa-flag',
      label: 'Báo cáo bài viết',
      action: () => {
        handleCloseMenu();
        setOpenDialogReport(true);
      },
      visible: postMoment?.account?.id === meInfo?.id
    }
  ];

  const getBody = () => {
    let content = postMoment?.content ? postMoment.content : '';
    return content;
  };

  const renderReaction = (icon, count, type) => {
    return (
      <div className={classes.wrapReactionInfor}>
        <div
          onClick={() => handleAction(type)}
          className={
            type === 'love' && postMoment?.viewer_reaction
              ? classes.wrapReactionLove
              : classes.wrapReaction
          }
          style={{
            backgroundColor: 'rgba(255,255,255,0.1)'
          }}
        >
          {icon}
        </div>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 500,
            color: '#fff'
          }}
        >
          {shortenLargeNumber(count)}
        </Typography>
      </div>
    );
  };

  const renderVisibilityIcon = (color = null) => {
    let icons = listVisibility?.find(
      (el: any) => el.key === postMoment?.visibility
    )?.icon;

    return <i className={icons} style={{ color: color as any }}></i>;
  };

  const updateVideoHistories = React.useCallback(
    _.debounce(async data => {
      await postWatchHistoriesApi(data);
    }, 800),
    []
  );

  const inViewPosts =
    useSelector((state: any) => {
      return state.socialPostReducer.inViewPosts;
    }) || [];

  const toggleMute = () => {
    setIsVolume(!isVolume);
  };

  // useEffect(() => {
  //   if (postMoment) {
  //     if (isVisible && !typePreview) {
  //       dispatch(addPostToView({ id: postMoment?.id, autoPlay: true }));
  //     } else {
  //       dispatch(removePostToView({ id: postMoment?.id, autoPlay: false }));
  //     }
  //   }
  // }, [isVisible, JSON.stringify(postMoment)]);

  const renderActionReaction = () => {
    return (
      <div className={classes.wrapListReaction}>
        {renderReaction(
          <i
            className={`fa-solid fa-heart ${
              postMoment?.viewer_reaction ? 'fa-beat' : ''
            }`}
          ></i>,
          postMoment?.favourites_count,
          'love'
        )}

        {renderReaction(
          <i className="fa-solid fa-comment-dots"></i>,
          postMoment?.replies_total,
          'comment'
        )}

        {renderReaction(
          <IconButtonOptions
            startIcon="fa-solid fa-share"
            startIconStyle={{
              fontSize: '22px'
            }}
            styleListMenu={{ minWidth: '300px' }}
            style={{
              color: '#fff',
              borderRadius: '50%',
              height: '48px',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            options={optionsShare}
            openPopup={true}
          />,
          postMoment?.reblogs_count,
          'share'
        )}
      </div>
    );
  };

  const renderAvatar = style => {
    return (
      <div
        className={classes.wrapAvatar}
        id={`moment-${postMoment?.media_attachments?.[0]?.id}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {!notShowData && (
          <AvatarSocial
            type="feed"
            style={{ width: 56, height: 56, cursor: 'pointer', ...style }}
            avatarObj={
              postMoment?.page_owner?.avatar_media ??
              postMoment?.account?.avatar_media
            }
            isPopup
            object={postMoment?.page_owner ?? postMoment?.account}
          />
        )}
      </div>
    );
  };

  const renderTimeVisible = (color = null) => {
    return (
      <>
        <Link
          sx={{ color: color ? color : 'text.secondary', fontSize: 14 }}
          href={`/${postMoment?.account?.username}/posts/${postMoment.id}`}
          underline="hover"
        >
          {handleTimeShow(postMoment?.created_at)}&nbsp;
        </Link>
        <span style={{ textAlign: 'center' }}>
          &nbsp;
          {postMoment?.group ? (
            postMoment?.group?.is_private ? (
              <i className="fas fa-users"></i>
            ) : (
              <i className="fas fa-globe-asia"></i>
            )
          ) : (
            renderVisibilityIcon(color)
          )}
        </span>
      </>
    );
  };

  const renderContent = (pageMoment = null) => {
    return (
      <div
        className={classes.wrapContent}
        style={{
          marginTop: 0,
          marginLeft: typePost === 'pinned' ? 0 : '15px'
        }}
      >
        <Box position="relative">
          <ImageRender
            type={type}
            typeShare={typeShare}
            styleImage={{
              borderRadius: 0,
              overflow: 'hidden',
              width: '280px'
            }}
            post={postMoment}
            typePost={typePost}
            setOpenPreview={setOpenPreview}
            images={postMoment?.media_attachments?.map((el: any) =>
              el.type !== 'video' ? el.url : el.remote_url ?? el.url
            )}
            medias={postMoment?.media_attachments}
            muted={!isVolume}
            // openDialogVideo={openPreview}
          />
        </Box>
      </div>
    );
  };

  const renderActionPost = () => {
    return (
      <div
        className={classes.wrapButtonMore}
        onClick={handleClick}
        style={{
          height: '30px',
          width: '30px',
          borderRadius: '50%',
          // backgroundColor: 'rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <i
          className="fa-solid fa-ellipsis-vertical"
          style={{ color: '#fff' }}
        ></i>
      </div>
    );
  };

  useEffect(() => {
    if (postMoment?.account_relationships?.following && !postMoment.page) {
      setIsFollowMoment(true);
    } else if (
      postMoment?.page?.page_relationship?.following &&
      postMoment.page
    ) {
      setIsFollowMoment(true);
    } else {
      setIsFollowMoment(false);
    }
  }, []);

  return (
    postMoment && (
      <>
        <div
          className={classes.root}
          style={{
            backgroundColor:
              postMoment?.media_attachments?.[0]?.meta?.small?.average_color,
            borderRadius: 10,
            padding: '0 20px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: typeShare === 'postShareMoment' ? 0 : 20,
            position: 'relative',
            width: '100%'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              left: 0,
              zIndex: 10,
              backgroundImage:
                'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0))',
              padding: 15,
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: '10px 10px 0 0'
            }}
          >
            <div style={{ display: 'flex' }}>
              {renderAvatar({ width: 38, height: 38 })}
              {!notShowData && (
                <div>
                  <div style={{ display: 'block' }}>
                    <NameSocial
                      post={postMoment}
                      style={{
                        color: '#fff',
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                      name={
                        postMoment?.page_owner?.title ??
                        postMoment.account?.display_name
                      }
                      isPopup
                      object={postMoment.page_owner ?? postMoment?.account}
                      styleTextHeader={
                        { color: '#fff' }
                        // typeShare === 'postShareMoment' ||
                        // match.path !== PATHS.MOMENT
                        //   ? { color: '#fff' }
                        //   : null
                      }
                    />
                  </div>

                  {renderTimeVisible('#fff' as any)}
                </div>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <div className={classes.wrapButtonMore}>
                {isVolume ? (
                  <div
                    onClick={() => {
                      toggleMute();
                    }}
                  >
                    <i
                      className="fa-solid fa-volume-high"
                      style={{ color: '#fff' }}
                    ></i>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      toggleMute();
                    }}
                  >
                    <i
                      className="fa-solid fa-volume-xmark"
                      style={{ color: '#fff' }}
                    ></i>
                  </div>
                )}
              </div>
              <div style={{ width: 8 }}></div>
              {renderActionPost()}
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 10,
              display: 'flex',
              padding: 15,
              paddingRight: 100,
              borderRadius: '0px 0px 10px 10px',
              backgroundImage:
                'linear-gradient( rgba(0,0,0,0),rgba(0,0,0,0.5))',
              width: '100%'
            }}
          >
            <TextCollapse
              type={true}
              data={getBody()}
              status_tags={status_tags}
              typeShare={typeShare}
              info={postMoment}
              style={{
                fontSize: '18px',
                color: '#fff',
                // display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              styleHastag={{
                color: '#fff'
              }}
            />
          </div>
          {renderContent('momentInFeed' as any)}
          <div
            style={{
              position: 'absolute',
              bottom: typePost === 'pinned' ? 75 : 15,
              right: 15,
              zIndex: 15
            }}
          >
            {typeShare !== 'postShareMoment' && renderActionReaction()}
          </div>
        </div>

        <div style={{ padding: '0px 12px' }}>
          <Divider />
        </div>
        <CUPost
          post={moment}
          openCUPost={openCUPost}
          setOpenCUPost={setOpenCUPost}
          shareNow={sharePostNow}
          setSharePostNow={setSharePostNow}
          increment_reblog_count={increment_reblog_count}
        />
        {openDialogShareToGroup && (
          <DialogShareToGroup
            shareTo={shareTo}
            post={moment}
            open={openDialogShareToGroup}
            handleClose={() => {
              setOpenDialogShareToGroup(false);
            }}
            isSharePost={true}
            increment_reblog_count={increment_reblog_count}
          />
        )}

        {openDialogShareToMessenger && (
          <DialogShareToMessenger
            open={openDialogShareToMessenger}
            handleClose={() => setOpenDialogShareToMessenger(false)}
            post={moment}
            meInfo={meInfo}
            type={type}
          />
        )}
        {anchorEl && (
          <MenuAction
            anchorEl={anchorEl}
            openPopper={openPopper}
            setOpenPopper={setOpenPopper}
            handleCloseMenu={handleCloseMenu}
            listMenu={listMenu.filter(item => !item.visible)}
          />
        )}

        {openDelete && (
          <DialogConfirmDelete
            title="Xóa video"
            text="Bạn chắc chắn muốn xóa video này? Video sau khi xóa sẽ không thể hoàn tác"
            labelButton="Xóa"
            handleClose={() => setOpenDelete(false)}
            open={openDelete}
            action={handleDelete}
          />
        )}

        {openDialogVisibility && (
          <DialogVisibility
            value={postMoment.visibility}
            handleAction={key => {
              handleUpdateVisibility(key);
            }}
            open={openDialogVisibility}
            setOpen={setOpenDialogVisibility}
          />
        )}

        {openPreview && postMoment && (
          <DialogPreviewMedia
            open={openPreview}
            setOpen={setOpenPreview}
            indexMedia={indexMedia}
            post={{
              media_attachments: postMoment.media_attachments
            }}
            setIndexMedia={setIndexMedia}
            setMediaSelected={index => {}}
            postMedia={momentRenderDialog}
            setPostMedia={setMomentRenderDialog}
            // listMoments={
            //   listData?.length && type === 'page_hashtag'
            //     ? listData
            //     : activities
            // }
            // listPostMoment={listPostMoment}
            // isAddMoment={isAddMoment}
            // setIsLoadMore={setIsLoadMore}
          />
        )}

        {openDialogCreateCollection && (
          <DialogCreateCollectionSaved
            open={openDialogCreateCollection}
            handleClose={() => {
              setOpenDialogCreateCollection(null);
            }}
            bookmarkId={postMoment.id}
            entityType="Status"
            setOpenSnackbar={setOpenSnackbar}
            setNoti={setNoti}
          />
        )}

        {openDialogReport && (
          <DialogReport
            open={openDialogReport}
            handleClose={() => setOpenDialogReport(false)}
            title={'Báo cáo'}
            headerText={'Tìm hỗ trợ hoặc báo cáo bình luận'}
            headerSubText={'Bạn có thể báo cáo bình luận sau khi chọn vấn đề.'}
            listCategoriesReport={listReport}
            postReport={postMoment}
            // typeReport={typeReport}
          />
        )}

        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      </>
    )
  );
});

export default FeedMoment;
