// @ts
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import './style.css';
import {
  Theme,
  List,
  Box,
  Typography,
  AvatarGroup,
  Fab,
  Tooltip,
  ClickAwayListener
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import PopupState from 'material-ui-popup-state';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import VerifiedIcon from '@mui/icons-material/Verified';

import { ListItemAvatar } from '@mui/material';
import { boxShadow, buttonColor, buttonColorHover } from 'src/constants/styles';
import { collapseString } from 'src/common/string';
import {
  sendMessageApi,
  getListMessage,
  sendMessageUploadFile,
  getSubscriptionRoom,
  updateMessage,
  getListMemberGroupChat,
  getMessageReadReceipts,
  seenMessage,
  searchInConversation,
  loadSurroundingMessage,
  getMembersOfSocialConversation,
  getTeamInfoById
} from 'src/apis/socialChat.apis';

import { getAboutUser, getInforUser } from 'src/apis/socialUser';

import {
  hiddenUserBoxChat,
  loadMoreMessageSuccess,
  handleReadUnreadMessageSuccess,
  receiveMessage,
  focusConversation
} from 'src/store/action/socialChatAction';

import { unSubRoom, subRoom } from 'src/socket/RocketChatSubscriptions';
import { urlRocketChat } from 'src/util/config';

import Header from 'src/pages/Chat/BoxChat/Header';
import Footer from 'src/pages/Chat/BoxChat/Footer';
import DetailMessage from 'src/pages/Chat/BoxChat/DetailMessage/index';
import ThumbListFile from 'src/pages/Chat/BoxChat/ThumbListFile';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import ButtonScrollMess from 'src/pages/Chat/BoxChat/ButtonScrollMess';
import DialogViewReadMessage from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogViewReadMessage';
import DialogConfirm from 'src/components/Dialog/DialogConfirmDelete';
import CheckConnectDevice from './CheckConnectDevice';
import CustomizedSnackBars from 'src/components/Snackbar/SnackbarSocial';
import { useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import RenderStatusSendingMessage from './RenderStatusSendingMessage';
import DialogSearchInConversation from './DialogSearchInConversation';
import { renderAllAvatar, renderNameChat } from '../functionChat';
// import DialogSearchInConversation from './DialogSearchInConversation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '338px',
    height: '445px',
    marginLeft: 5,
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: '8px 8px 0 0'
  },
  typing: {
    position: 'relative',
    padding: '2px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  typingDot: {
    float: 'left',
    width: '4px',
    height: '4px',
    margin: '0 2px',
    background: theme.palette.mode === 'light' ? '#8d8c91' : '#FAFAFA',
    borderRadius: '50%',
    opacity: '0'
  },
  rootHeader: {
    width: '100%',
    height: '48px'
  },
  rootBody: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    overflowAnchor: 'none',
    display: 'flex',
    flexDirection: 'column-reverse',
    '&:hover': {
      // overflowY: 'auto'
    },
    '&:focus-visible': {
      outline: 'none !important'
    },
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundImage: 'linear-gradient(to bottom,#ffa400,#7165E0)'
    }
  },
  rootFooter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: 0,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
  },
  messageContainer: {
    width: '90%',
    marginRight: '10px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'inherit',
    position: 'relative'
  },
  messagePreview: {
    // backgroundColor: buttonColor.backgroundColor,
    float: 'right',
    justifyContent: 'flex-end',
    display: 'flex',
    width: 'fit-content !100%',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    fontSize: '15px',
    color: 'white',
    padding: '7px 12px 8px 12px',
    border: 'none',
    borderRadius: '15px 5px 15px 15px'
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: '2px',
    alignItems: 'flex-end',
    width: '100%'
  },
  textUserTyping: {
    fontSize: '10px',
    fontWeight: '600',
    opacity: '0.75',
    letterSpacing: '0.5px',
    color: theme.palette.mode === 'light' ? '#373536' : '#fafafa'
  },
  statusSendingMessage: {
    fontSize: '10px',
    fontWeight: '400',
    opacity: '0.75',
    letterSpacing: '0.5px',
    padding: '0 10px'
  },
  styleRenderingStatusMessage: {
    position: 'absolute',
    bottom: '6px',
    right: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  styleUserTypingRoot: {
    position: 'absolute',
    bottom: '6px',
    left: '10px',
    display: 'flex',
    justifyContent: 'center'
  }
}));
const StartConversation = props => {
  const {
    conversation,
    isAvatarETagGroup,
    objectChatInfor,
    messageAutomatic,
    handleAutomaticReply,
    isCertified,
    meInfo,
    match
  } = props;

  return (
    <div
      style={{
        width: '100%',
        height:
          conversation && conversation.conversation.type === 'chat_page'
            ? '254px'
            : '204px',
        float: 'inline-start',
        alignItems: 'center',
        display: 'flex',
        marginBottom: conversation.listMessage?.length
          ? '0px'
          : conversation.conversation?.type === 'chat_page'
          ? '30px'
          : '60px',
        flexDirection: 'column'
        // marginTop: '20px'
      }}
    >
      <div
        style={{
          width: '100%',
          padding: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <ListItemAvatar
            sx={{ minWidth: 60, marginLeft: 0, paddingTop: '20px' }}
          >
            {(conversation &&
              conversation.conversation &&
              conversation.conversation.type === 'user') ||
            conversation.conversation.type === 'chat_page' ||
            conversation.conversation.t === 'cp' ||
            conversation.conversation.t === 'd' ? (
              <AvatarSocial
                type="feed"
                style={{ width: 60, height: 60, marginLeft: 0 }}
                src={renderAllAvatar(conversation?.conversation, match)}
              />
            ) : (conversation &&
                conversation.conversation &&
                conversation.conversation.type === 'group') ||
              conversation.conversation.t === 'p' ||
              conversation.conversation.t === 'c' ? (
              conversation.conversation.RoomsList?.[0]?.avatarETag ? (
                <AvatarSocial
                  type="feed"
                  style={{ width: 60, height: 60, marginLeft: 0 }}
                  avatarObj={isAvatarETagGroup}
                />
              ) : conversation.conversation?.uids?.length === 1 ||
                (conversation.conversation?.members &&
                  conversation.conversation?.members.length === 1) ? (
                <AvatarSocial
                  type="feed"
                  style={{ width: 60, height: 60, marginLeft: 0 }}
                  avatarObj={
                    conversation.conversation?.avatar_media?.avatar_top
                  }
                />
              ) : (
                <div style={{ position: 'relative' }}>
                  <AvatarSocial
                    avatarObj={
                      conversation.conversation &&
                      conversation?.conversation.avatar_media?.avatar_top
                    }
                    style={{
                      width: 50,
                      height: 50,
                      zIndex: 1
                    }}
                    isAvatarGroupChat={'yes'}
                  />
                  <AvatarSocial
                    type="feed"
                    avatarObj={
                      conversation.conversation &&
                      conversation?.conversation.avatar_media?.avatar_bottom
                    }
                    style={{
                      width: 50,
                      height: 50,
                      position: 'absolute',
                      right: '1px',
                      bottom: '0px',
                      top: '-8px',
                      zIndex: 0
                    }}
                  />
                </div>
              )
            ) : null}
          </ListItemAvatar>
        </div>
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '500',
              textAlign: 'center'
            }}
          >
            {collapseString(
              renderNameChat(conversation?.conversation, match),
              24
            )}
          </Typography>
          {/* {(conversation.conversation.t === 'cp' &&
            conversation?.conversation?.identity_verification) ||
          isCertified ? (
            <VerifiedIcon
              titleAccess="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
              fontSize="small"
              viewBox="0 -2 24 24"
              sx={{
                color: buttonColor.backgroundColor,
                fontSize: '14px',
                '&:hover': {
                  color: buttonColorHover.backgroundColor
                },
                marginRight: '8px'
              }}
            />
          ) : null} */}
        </Box>

        <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
          {objectChatInfor?.data?.relationships?.mutual_friend_count
            ? `${
                objectChatInfor.data.relationships.friendship_status ===
                'ARE_FRIENDS'
                  ? 'Bạn bè - '
                  : 'Các bạn không phải là bạn bè trên Emso - '
              } ${
                objectChatInfor.data.relationships.mutual_friend_count
              } bạn chung`
            : null}
        </Typography>
        {conversation && conversation.conversation?.type === 'user' && (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              maxWidth: '80%',
              wordWrap: 'break-word',
              display: 'inline'
            }}
          >
            {objectChatInfor?.about?.general_information
              ? objectChatInfor.about.general_information.address
                ? `Sống tại: ${collapseString(
                    objectChatInfor.about.general_information.address,
                    50
                  )}`
                : // : collapseString(
                  //     objectChatInfor.about.general_information.description,
                  //     50
                  //   )
                  // ? collapseString(
                  //     objectChatInfor.about.general_information.description,
                  //     50
                  //   )
                  null
              : null}
          </Typography>
        )}
      </div>
      {conversation &&
      conversation.conversation?.type === 'chat_page' &&
      conversation.listMessage.length === 0 &&
      messageAutomatic.length > 0 &&
      !conversation.conversation.user_chat_page ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            padding: '12px 0'
          }}
        >
          {messageAutomatic.map((option: any, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  handleAutomaticReply(option);
                }}
                sx={{
                  backgroundColor: 'button.custom.background',
                  '&:hover': {
                    cursor: 'pointer'
                    // backgroundColor: buttonColorHover.backgroundColor
                  },
                  padding: '4px 12px',
                  borderRadius: '4px',
                  fontSize: ' 14px',
                  border: 'none',
                  textAlign: 'center',
                  color: buttonColor.backgroundColor,
                  textDecoration: 'none',
                  width: '80%',
                  textTransform: 'none',
                  overflow: 'hidden',
                  whiteSpace: 'normal',
                  height: '100%',
                  lineHeight: '1.5'
                }}
              >
                {option.question}
              </Box>
            );
          })}
        </Box>
      ) : null}
    </div>
  );
};
const mentionTagAll = {
  _id: '111111111111111111',
  username: 'all',
  status: 'online',
  name: 'Tất cả',
  avatarETag: '',
  avatar_media: {
    show_url: null
  },
  displayName: 'Tất cả'
};

interface Props {
  conversation?: any;
  index?: number;
  type?: string;
  styleRoot?: any;
  styleRootHeader?: any;
  setOpenRLeftConversation?: React.Dispatch<any>;
  openLeftConversation?: boolean;
  styleBodyChat?: any;
  isChatPage?: string | undefined;
  openDialogSearchConversation?: boolean;
  setOpenDialogSearchConversation?: React.Dispatch<any>;
  setOpenSnackbar?: any;
  setNoti?: any;
  keyRender?: any;
}

const BoxChat: React.FC<Props> = React.memo(
  ({
    conversation,
    index,
    type,
    styleRoot,
    styleRootHeader,
    setOpenRLeftConversation,
    openLeftConversation,
    styleBodyChat,
    openDialogSearchConversation,
    setOpenDialogSearchConversation,
    setOpenSnackbar,
    setNoti,
    keyRender
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const listRef: any = React.useRef();
    const inputRef: any = React.useRef();
    let popupId = JSON.stringify(Math.random());
    const meInfo = useSelector((state: any) => state.meReducer.info) || {};
    const [gifSelected, setGifSelected] = React.useState<any>(null);
    const [stickySelected, setStickySelected] = React.useState<any>(null);
    const [repliedMessage, setRepliedMessage] = React.useState<any>(null);
    // console.log({ repliedMessage });
    const [updatedMessage, setUpdatedMessage] = React.useState<any>(null);
    const [listMemberGrChat, setListMemberGrChat] = React.useState([]);
    const [objectChatInfor, setObjectChatInfor] = React.useState({}) as any;
    const [sending, setSending] = React.useState<any>(null);
    // const [value, setValue] = React.useState<any>(null);
    // const [file, setFile] = React.useState<any>(null);
    const [page, setPage] = React.useState<number>(0); // chua co infinityscroll, hien lay 25 members dau tien
    // const [userInfo, setUserInfo] = React.useState<any>({});
    const [lastMessage, setLastMessage] = React.useState<any>({});
    const [listReadReceiptsLastMessage, setListReadReceiptsLastMessage] =
      React.useState([]);
    const [objectMentions, setObjectMentions] = React.useState<any[]>([]);

    const [openDialogValidateFiles, setOpenDialogValidateFiles] =
      React.useState<any>(false);

    const [listMessageSearch, setListMessageSearch] = React.useState<any>([]);
    const [keyword, setKeyword] = React.useState<string>('');
    const [listLoadSurroundingMessage, setListLoadSurroundingMessage] =
      React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [count, setCount] = React.useState<number>(0);
    const [isShowResultSearched, setIsShowResultSearched] =
      React.useState<boolean>(true);
    const [isToastMessage, setIsToastMessage] = React.useState<boolean>(false);
    const [titleToastMessage, setTitleToastMessage] =
      React.useState<string>('');
    const [codeNoti, setCodeNoti] = React.useState<number>(200);
    const [optionAutomaticReply, setOptionAutomaticReply] = React.useState<any>(
      []
    );
    const match: any = useRouteMatch();
    const isMounted = React.useRef<boolean>(true);
    const messageAutomatic = useSelector(
      (state: any) => state.socialChatReducer.messageAutomatic
    );
    const [isShowButtonRef, setIsShowButtonRef] =
      React.useState<boolean>(false);
    const buttonRef = React.useRef<any>(null);
    const lastMessageRef = React.useRef<any>(null);

    const scrollToLassMessage = () => {
      lastMessageRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
      setIsShowButtonRef(false);
    };
    React.useEffect(() => {
      if (
        conversation?.conversation?.type === 'group' ||
        conversation?.conversation?.t === 'p' ||
        conversation?.conversation?.t === 'c'
      ) {
        fetchListMember();
      }
      return () => {
        isMounted.current = false;
      };
    }, [
      conversation.conversation?.t,
      conversation.conversation?.type,
      conversation.conversation?.members?.length
    ]);

    const getSubscription = async () => {
      const roomId = conversation.conversation?.rid;
      if (roomId) {
        const response = await getSubscriptionRoom(roomId);
        if (response.status === 200) {
          // let newConversation = _.cloneDeep(conversation.conversation);
          // newConversation.disableNotifications =
          //   response?.data?.subscription?.disableNotifications || false;
          // newConversation.blocker =
          //   response?.data?.subscription?.blocker || false;
          // newConversation.blocked =
          //   response?.data?.subscription?.blocked || false;

          //  dispatch(selectedUserBoxChat(newConversation));
        }
      }
    };

    const fetchInformationPageWithTeam = async () => {
      try {
        const teamId =
          (conversation && conversation.conversation?.prid) ??
          (conversation && conversation.conversation.rid.split('_')[0]);
        const response = await getTeamInfoById(teamId);
        if (response.status === 200) {
          response.data.teamInfo.automatic_reply &&
            isMounted.current &&
            setOptionAutomaticReply(response.data.teamInfo.automatic_reply);
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    React.useEffect(() => {
      if (
        conversation &&
        conversation.conversation.t === 'cp' &&
        match.params.key !== 'chat'
      ) {
        fetchInformationPageWithTeam();
      }
      return () => {
        isMounted.current = false;
      };
    }, []);
    const handleSearchInConversation = React.useCallback(async () => {
      try {
        setIsLoading(true);
        const response = await searchInConversation(
          keyword,
          conversation.conversation.rid
        );
        if (response.status === 200) {
          const data = response.data.messages;

          setListMessageSearch(data);
          setIsLoading(false);
          setIsShowResultSearched(false);
          if (data && data.length > 0) {
            await handleLoadSurroundingMessages(data[0]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, [keyword]);

    React.useEffect(() => {
      subRoom(conversation.conversation?.rid);
      if (conversation.conversation?.t === 'd') {
        fetchDataObject();
        fetchAboutUser();
      }
      if (inputRef?.current) {
        // console.log('focus 66');
        // inputRef?.current?.focus();
      }
      return () => {
        isMounted.current = false;
      };
    }, [
      conversation.conversation?.rid,
      conversation.conversation?.type,
      inputRef
    ]);

    React.useEffect(() => {
      if (listMessageSearch && listMessageSearch.length > 0) {
        handleLoadSurroundingMessages(listMessageSearch[count]);
      }
    }, [count]);
    const handleAutomaticReply = React.useCallback(
      async (option: any) => {
        try {
          const roomId = conversation && conversation.conversation?.rid;
          await sendMessageApi(
            option.question,
            roomId,
            undefined,
            undefined,
            undefined,
            undefined,
            option.option
          );
          // await handleReplyAutoMessage(option.answer, roomId, type);
        } catch (error) {
          console.log(error);
        }
      },
      [conversation]
    );
    React.useEffect(() => {
      if (
        conversation &&
        conversation.listMessage &&
        conversation.listMessage.length &&
        conversation.listMessage?.[0]?.status === 'success'
      ) {
        getLastMessageReadReceipts(conversation.listMessage?.[0]?._id);
      }
      return () => {
        isMounted.current = false;
      };
    }, [conversation?.listMessage?.[0]?._id]);

    const handleSeenMessage = React.useCallback(async () => {
      try {
        console.log("seen 11111")
        if (conversation && conversation.conversation && conversation.focus) {
          console.log("seen 1")
          const response = await seenMessage(conversation.conversation.rid);
          if (response.status === 200) {
            // handles
            dispatch(
              handleReadUnreadMessageSuccess(
                conversation.conversation.rid,
                'read'
              )
            );
          }
        }
      } catch (error) {
        // console.log(error)
      }
    }, []);

    const handleSendMessageApi = async (message, roomId, id) => {
      try {
        if (!updatedMessage) {
          if (
            conversation?.listMessage?.length > 0 &&
            !message.includes(`[ ](${urlRocketChat}/messages`)
          ) {
            dispatch(
              receiveMessage({
                ...conversation.listMessage[0],
                msg: message,
                status: 'sending',
                _id: id,
                u: {
                  _id: meInfo.id,
                  username: meInfo.username,
                  name: meInfo.display_name
                },
                isPage: match.params?.key === 'chat' ? true : false,
                urls:
                  message.startsWith(`https://sdk.mojitok.im/`) ||
                  message.startsWith('https://media')
                    ? [
                        {
                          url: message
                        }
                      ]
                    : [],
                file: undefined,
                files: undefined,
                reactions: undefined,
                t: undefined,
                mentions: objectMentions.length > 0 ? objectMentions : undefined
              })
            );
          }

          const response = await sendMessageApi(
            message,
            roomId,
            type,
            undefined,
            id,
            objectMentions
          );

          if (response.status === 200) {
            // handle
            if (objectMentions.length > 0) {
              setObjectMentions([]);
            }
          }
        } else {
          if (updatedMessage.msg.startsWith('[ ](https')) {
            const _message = `[ ](${updatedMessage.attachments[0].message_link}) ${message}`;
            const response = await updateMessage(
              updatedMessage._id,
              updatedMessage.rid,
              _message
            );

            if (response.status === 200) {
              // handle ...
            }
          } else {
            const response = await updateMessage(
              updatedMessage._id,
              updatedMessage.rid,
              message
            );

            if (response.status === 200) {
              // handle ...
            }
          }
        }
      } catch (error) {
        setSending('error');

        console.log('Error in API response:', error);
        if (conversation?.listMessage?.length > 0) {
          dispatch(
            receiveMessage({
              ...conversation.listMessage[0],
              msg: message,
              status: 'error',
              _id: id,
              u: {
                _id: meInfo.id,
                username: meInfo.username,
                name: meInfo.display_name
              },
              urls:
                message.startsWith(`https://sdk.mojitok.im/`) ||
                message.startsWith('https://media')
                  ? [
                      {
                        url: message
                      }
                    ]
                  : [],
              file: undefined,
              files: undefined,
              reactions: undefined,
              t: undefined,
              mentions: objectMentions.length > 0 ? objectMentions : undefined
            })
          );
        }
      } finally {
        setRepliedMessage(null);
        setUpdatedMessage(null);
        if (inputRef?.current) {
          console.log('focus 77');
          inputRef?.current?.focus();
        }
      }
    };

    const handleUploadFileApi = async (receiveId, file, id) => {
      try {
        let duration: any = 0;
        const getDurationPromise = new Promise((resolve, reject) => {
          let media;
          if (file.type.startsWith('video') || file.type.startsWith('audio')) {
            media = document.createElement(
              file.type.startsWith('video') ? 'video' : 'audio'
            );
            media.onloadedmetadata = function (e) {
              duration = media.duration;
              resolve(duration);
            };
            media.src = file.preview;
          } else {
            resolve(0);
          }
        });

        duration = await getDurationPromise;

        if (repliedMessage) {
          const response = await sendMessageUploadFile(
            `[ ](${urlRocketChat}/messages/${conversation.rid}?msg=${repliedMessage._id}) `,
            file,
            conversation.conversation.rid,
            duration,
            id,
            type
          );

          if (response.status === 200) {
            // handle
            setSending('success');
            if (inputRef?.current) {
              console.log('focus 88');
              inputRef?.current?.focus();
            }
          }
        } else {
          dispatch(
            receiveMessage({
              ...conversation.listMessage[0],
              attachments: [
                {
                  title: file.name,
                  preview: file.preview,
                  video_size: file.size,
                  video_type: file.type
                }
              ],
              isPage: match.params?.key === 'chat' ? true : false,
              msg: '',
              status: 'sending',
              _id: id,
              u: {
                _id: meInfo.id,
                username: meInfo.username,
                name: meInfo.display_name
              },
              urls: [],
              file: {
                name: file.name,
                time: duration,
                type: file.type
              },
              files: [
                {
                  name: file.name,
                  time: duration,
                  type: file.type
                }
              ],
              reactions: undefined,
              t: undefined,
              mentions: objectMentions.length > 0 ? objectMentions : undefined
            })
          );

          const response = await sendMessageUploadFile(
            receiveId,
            file,
            conversation.conversation.rid,
            duration,
            id,
            type
          );

          if (response.status === 200) {
            // handle
            setSending('success');
            if (inputRef?.current) {
              console.log('focus 99');
              inputRef?.current?.focus();
            }
          }
        }
      } catch (error) {
        console.log('error upload file message', error);
      } finally {
        setRepliedMessage(null);
        if (inputRef?.current) {
          console.log('focus 1010');
          inputRef?.current?.focus();
        }
      }
    };

    const formik = useFormik({
      initialValues: {
        files: []
      },
      onSubmit: (values, contentMessage) => {
        if (values.files.length > 20) {
          setOpenDialogValidateFiles(true);
          return;
        }

        if (values.files.length > 0) {
          values.files.map((el: any) => {
            const id = uuidv4();
            handleUploadFileApi(conversation.conversation.rid, el, id);
          });
        }
        formik.resetForm();
      }
    });
    React.useEffect(() => {
      if (
        // conversation?.conversation?.type === 'group' &&
        conversation?.listMessage?.length
      ) {
        if (
          !lastMessage ||
          JSON.stringify(lastMessage) !==
            JSON.stringify(conversation.listMessage[0])
        ) {
          setLastMessage(conversation.listMessage[0]);
        }
      }
    }, [JSON.stringify(conversation.listMessage)]);

    // const handleCallMess = () => {};
    // const handleCallVideoMess = () => {};
    const handleRenderInforChater = React.useCallback(() => {
      if (!!setOpenRLeftConversation) {
        setOpenRLeftConversation(prev => !prev);
      }
    }, [openLeftConversation]);
    const { getRootProps, getInputProps, open } = useDropzone({
      noClick: true,
      maxFiles: 20,
      onDrop: (acceptedFiles: any) => {
        if (acceptedFiles.length <= 0) {
          setOpenDialogValidateFiles(true);
        }

        setGifSelected(null);
        setStickySelected(null);
        let fileSelected: any = acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        let preFiles = formik.values.files;
        formik.setFieldValue('files', [...preFiles, ...fileSelected]);
        if (inputRef?.current) {
          console.log('focus 1111');
          inputRef?.current?.focus();
        }
      }
    });

    const handleSendMessage = async contentMessage => {
      if (contentMessage) {
        if (!updatedMessage) {
          // setValue(contentMessage);
          setSending('sending');
        }
        const id = uuidv4();

        handleSendMessageApi(contentMessage, conversation.conversation.rid, id);
      }
      formik.submitForm();
    };

    const handleRemoveFile = (file: any) => {
      const newFiles: any = formik.values.files;
      newFiles.splice(newFiles.indexOf(file), 1);
      formik.setFieldValue('files', newFiles);
    };

    let old_unique_fromId = '';
    const setShowTimeSend = (currentDate, prevDate) => {
      if (currentDate && prevDate) {
        let currentDay = `0 + ${new Date(currentDate).getDate()}`.slice(-2);
        let currentMonth = `0 ${new Date(currentDate).getMonth() + 1}`.slice(
          -2
        );
        let currentYear = new Date(currentDate).getFullYear();
        let currentDateString: string = `${currentDay} / ${currentMonth} / ${currentYear}`;

        let prevDay = `0 + ${new Date(prevDate).getDate()}`.slice(-2);
        let prevMonth = `0 ${new Date(prevDate).getMonth() + 1}`.slice(-2);
        let prevYear = new Date(prevDate).getFullYear();
        let prevDateString: string = `${prevDay} / ${prevMonth} / ${prevYear}`;

        if (currentDateString === prevDateString) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    };

    const setShowAvatar = userId => {
      if (old_unique_fromId === userId) {
        return false;
      } else {
        old_unique_fromId = userId;
        return true;
      }
    };

    const setShowNameMemberGrChat = (userId, nextMessageAuthorId = null) => {
      if (nextMessageAuthorId) {
        if (
          old_unique_fromId === userId &&
          old_unique_fromId !== nextMessageAuthorId
        ) {
          return true;
        } else {
          old_unique_fromId = userId;
          return false;
        }
      } else {
        return true;
      }
    };

    const functionLoadMoreMessage = async () => {
      let time: any;
      if (conversation?.listMessage.length === 0 && match.path === PATHS.CHAT) {
        time = Date.now();
      } else if (conversation?.listMessage?.length === 0) {
        time = Date.now();
      } else if (conversation?.listMessage?.length > 0) {
        time =
          conversation.listMessage[conversation.listMessage.length - 1]?.ts
            ?.$date;
      } else {
        time = conversation?.lastMoment ?? Date.now();
      }

      try {
        if (conversation?.conversation?.rid) {
          const roomId = conversation?.conversation?.rid;

          const response = await getListMessage(roomId, 20, time);

          if (
            response.status === 200 &&
            response.data &&
            response.data.message
          ) {
            const listMessages = JSON.parse(response.data?.message)?.result
              ?.messages;
            if (roomId && listMessages) {
              dispatch(loadMoreMessageSuccess(listMessages, roomId));
            }
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    const funcLoad = () => {
      functionLoadMoreMessage();
    };

    React.useEffect(() => {
      funcLoad();
    }, [match?.params?.id, conversation.conversation?.rid]);

    const handleReplyMessage = React.useCallback(message => {
      setRepliedMessage(message);
      if (inputRef?.current) {
        console.log('focus 1212');
        inputRef?.current?.focus();
      }
    }, []);

    const handleCancelReplied = React.useCallback(() => {
      setRepliedMessage(null);
      if (inputRef?.current) {
        console.log('focus 1313');
        inputRef?.current?.focus();
      }
    }, []);

    const handleSendQuicklyIcon = React.useCallback(
      async (time: any) => {
        try {
          const roomId = conversation.conversation.rid;
          if (roomId) {
            let response;
            if (!repliedMessage) {
              response = await sendMessageApi(
                time === 0
                  ? `:like_small: ${
                      conversation.conversation?.customFields &&
                      Object.keys(conversation.conversation.customFields)
                        .length > 0
                        ? conversation.conversation.customFields.icon
                        : ''
                    }`
                  : time > 0 && time < 3
                  ? `:like_big: ${
                      conversation.conversation?.customFields &&
                      Object.keys(conversation.conversation.customFields)
                        .length > 0
                        ? conversation.conversation.customFields.icon
                        : ''
                    }`
                  : `:like_largest: ${
                      conversation.conversation?.customFields &&
                      Object.keys(conversation.conversation.customFields)
                        .length > 0
                        ? conversation.conversation.customFields.icon
                        : ''
                    }`,
                roomId,
                type
              );
            } else {
              response = await sendMessageApi(
                `[ ](${urlRocketChat}/messages/${conversation.rid}?msg=${
                  repliedMessage._id
                }) ${
                  conversation && conversation.conversation?.customFields
                    ? conversation.conversation.customFields?.icon
                    : time === 0
                    ? ':like_small:'
                    : time > 0 && time < 3
                    ? ':like_big:'
                    : ':like_largest:'
                }`,
                roomId,
                type
              );
            }

            if (response.status === 200) {
              // handle
            }
          }
        } catch (error) {
          console.log('error sendMessage', error);
        } finally {
          setRepliedMessage(null);
        }
      },
      [conversation?.conversation?.customFields?.icon, repliedMessage]
    );

    React.useEffect(() => {
      const roomId = conversation?.conversation?.rid || conversation?.rid;
      const id = uuidv4();
      if (gifSelected) {
        handleSendMessageApi(
          repliedMessage
            ? `[ ](${urlRocketChat}/messages/${roomId}?msg=${repliedMessage._id}) ${gifSelected}`
            : gifSelected,
          roomId,
          id
        );
        setGifSelected(null);
      } else if (stickySelected) {
        handleSendMessageApi(
          repliedMessage
            ? `[ ](${urlRocketChat}/messages/${roomId}?msg=${repliedMessage._id}) ${stickySelected.url}`
            : stickySelected.url,
          roomId,
          id
        );
        setStickySelected(null);
      }
    }, [JSON.stringify(gifSelected), JSON.stringify(stickySelected)]);

    const mainTopic = conversation?.conversation?.topic
      ? conversation?.conversation?.topic?.colors
      : conversation?.conversation?.RoomsList?.[0]?.topic?.colors || [
          buttonColor.backgroundColor,
          buttonColor.backgroundColor
        ];

    const fetchListMember = async () => {
      try {
        const count: number = 30;
        const offset: number = page * 25;
        const roomId: string = conversation.conversation.rid;
        let response;
        if (conversation && conversation.conversation?.type === 'group') {
          response = await getListMemberGroupChat(count, offset, roomId);
        }

        if (
          conversation &&
          conversation.conversation?.t === 'c' &&
          conversation.conversation?.RoomsList &&
          conversation.conversation?.RoomsList[0].description === 'socialChat'
        ) {
          const roomId: string = conversation._id || conversation._id;
          response = await getMembersOfSocialConversation(
            count,
            offset,
            roomId
          );
        }

        if (response?.status === 200) {
          setListMemberGrChat(
            response.data.members
              .filter(member => member._id !== meInfo.id)
              .map(el => {
                el.avatar_media = {
                  show_url: `${urlRocketChat}/avatar/${el.username}`
                };
                el.displayName = el.name;
                return el;
              })
          );
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    const fetchDataObject = async () => {
      if (conversation.conversation?.userId) {
        let response = await getInforUser(
          conversation.conversation?.userId,
          null
        );

        if (response.status === 200) {
          if (isMounted.current) {
            setObjectChatInfor(prev => {
              const newState = _.cloneDeep(prev);
              newState.data = response.data;
              return newState;
            });
          }
        }
      }
    };
    const fetchAboutUser = async () => {
      if (conversation.conversation?.userId) {
        let response = await getAboutUser(
          conversation.conversation?.userId,
          null
        );

        if (response.status === 200) {
          if (isMounted.current) {
            setObjectChatInfor(prev => {
              const newState = _.cloneDeep(prev);
              newState.about = response.data;
              return newState;
            });
          }
        }
      }
    };

    // handle scroll newest message

    const handleScrollShowBtn = _.debounce(event => {
      const currentScrollPos = event.target.scrollTop;
      if (currentScrollPos <= -1000) {
        setIsShowButtonRef(true);
      } else {
        setIsShowButtonRef(false);
      }
    }, 200);

    const handleLoadSurroundingMessages = async message => {
      try {
        const response = await loadSurroundingMessage(message);
        if (response.status === 200) {
          const data = JSON.parse(
            response.data.message
          ).result.messages.reverse();
          setListLoadSurroundingMessage(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    let MAX_COUNT;
    if (listMessageSearch && listMessageSearch.length > 0) {
      MAX_COUNT = listMessageSearch.length;
    } // Lấy độ dài của hàm validateCount
    const validateCount = value => {
      // Hàm validateCount trả về true nếu giá trị value hợp lệ, ngược lại trả về false
      return value >= 0 && value < MAX_COUNT;
    };
    const handleIncrement = () => {
      if (validateCount(count + 1)) {
        setCount(count + 1);
      }
    };

    const handleDecrement = () => {
      if (validateCount(count - 1)) {
        setCount(count - 1);
      }
    };

    const handleCloseDialogSearch = () => {
      setKeyword('');
      if (!!setOpenDialogSearchConversation) {
        setOpenDialogSearchConversation(false);
      }
      setListMessageSearch([]);
      setListLoadSurroundingMessage([]);
      setIsShowResultSearched(true);
    };
    // console.log({ listMessageSearch });
    // console.log({ listLoadSurroundingMessage });

    // const handleReplyAutoMessage = async (answer, roomId, type) => {
    //   console.log('🚀 ~ handleReplyAutoMessage ~ type:', type);
    //   try {
    //     await sendMessageApi(answer, roomId, type);
    //   } catch (error) {
    //     console.log({ error });
    //   }
    // };

    const isAvatarETagGroup = conversation?.conversation?.avatarETag
      ? `${urlRocketChat}/avatar/room/${conversation?.conversation?.rid}?etag=${conversation?.conversation?.avatarETag}`
      : `${urlRocketChat}/avatar/room/${conversation?.conversation?.rid}?etag=${conversation?.conversation?.RoomsList?.[0]?.avatarETag}`;

    const getLastMessageReadReceipts = async (messageId: string) => {
      try {
        const response = await getMessageReadReceipts(messageId);
        if (response.status === 200) {
          if (isMounted.current) {
            setListReadReceiptsLastMessage(
              response.data.receipts
                .filter(el => el.user._id !== meInfo.id)
                .map(el => {
                  el.avatar_media = {
                    show_url: `${urlRocketChat}/avatar/${el.user.username}`
                  };
                  return el;
                })
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    const usersTyping = conversation.action.find(
      el => el.action === 'user-typing' && el?.username !== meInfo.username
    );

    const countUsersTyping = conversation?.action.length;

    return conversation ? (
      <ClickAwayListener
        onClickAway={() => {
          if (conversation.focus) {
            dispatch(focusConversation(''));
          }
        }}
      >
        
        <Box
          key={keyRender}
          className={classes.root}
          sx={{
            backgroundColor: 'background.primary',
            ...boxShadow,
            ...styleRoot
          }}
          onClick={() => {
            if (!conversation.focus) {
              dispatch(focusConversation(conversation.id));
              console.log('focus 1414');
              inputRef.current.focus();
            }
          }}
        >
          {/* <input {...getInputProps()} /> */}
          <div
            className={classes.rootHeader}
            style={{
              ...styleRootHeader,
              boxShadow:
                theme.palette.mode === 'dark'
                  ? '0px 2px #3d3d43'
                  : '0px 2px #e5e5ec'
            }}
          >
            123123123
            <Header
              type={type}
              conversation={conversation.conversation}
              listMessageCheckPin={conversation.listMessage}
              handleRenderInfoUserChat={handleRenderInforChater}
              colorIcon={conversation?.focus}
              listMemberGrChat={listMemberGrChat}
              idSubRocket={conversation.idSubRocket}
              isCertified={objectChatInfor?.data?.certified}
              setOpenSnackbar={setOpenSnackbar}
              setNoti={setNoti}
            />
          </div>
          {openDialogSearchConversation ? (
            <DialogSearchInConversation
              keyword={keyword}
              setKeyword={setKeyword}
              handleSearchInConversation={handleSearchInConversation}
              isShowResultSearched={isShowResultSearched}
              isLoading={isLoading}
              listMessageSearch={listMessageSearch}
              handleIncrement={handleIncrement}
              MAX_COUNT={MAX_COUNT}
              count={count}
              handleDecrement={handleDecrement}
              handleCloseDialogSearch={handleCloseDialogSearch}
            />
          ) : null}

          <Box
            sx={{
              width: '100%',
              padding: '2px',
              bgcolor: theme.palette.mode === 'light' ? '#fafafa' : '#373536',
              textAlign: 'center'
            }}
          >
            {' '}
            <CheckConnectDevice />
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',

              flexGrow: 1,
              flexShrink: 1
            }}
          >
            <Box
              {...getRootProps({ className: 'dropzone' })}
              className={classes.rootBody}
              ref={listRef}
              id={`scrollableBoxChat ${
                conversation?.conversation?.rid
                  ? conversation?.conversation?.rid
                  : conversation.id
              }`}
              key={keyRender}
              style={{ ...styleBodyChat }}
              // onClick={handleSeenMessage}
            >
              <input {...getInputProps()} />
              <List
                ref={lastMessageRef}
                style={{
                  paddingBottom: '26px'
                }}
              >
                <InfiniteScroll
                  onScroll={handleScrollShowBtn}
                  inverse={true}
                  dataLength={conversation?.listMessage?.length}
                  next={funcLoad}
                  hasMore={conversation.hasMore}
                  loader={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        marginTop: '14px'
                      }}
                    >
                      <CircularProgress
                        sx={{
                          width: '24px !important',
                          height: '24px !important',
                          overflow: 'hidden'
                        }}
                      />
                    </div>
                  }
                  scrollableTarget={`scrollableBoxChat ${
                    conversation?.conversation?.rid
                      ? conversation?.conversation?.rid
                      : conversation.id
                  }`}
                  scrollThreshold={0.9}
                  style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    overflow: 'hidden'
                  }}
                >
                  <Box
                    ref={buttonRef}
                    id="back-to-new-message-anchor"
                    style={{ display: 'block' }}
                  ></Box>

                  {listLoadSurroundingMessage &&
                  listLoadSurroundingMessage.length
                    ? listLoadSurroundingMessage.map((el: any, index: any) => {
                        const showAvatar: Boolean = el?.t
                          ? true
                          : setShowAvatar(el?.u?._id);
                        const showNameMemberGrChat = setShowNameMemberGrChat(
                          el?.u?._id,
                          listLoadSurroundingMessage?.[index + 1]?.u?._id
                        );
                        const borderBottomMessage = showAvatar;
                        const borderTopMessage = showNameMemberGrChat;
                        const showTimeSend = setShowTimeSend(
                          listLoadSurroundingMessage?.[index]?.ts?.$date,
                          listLoadSurroundingMessage?.[index + 1]?.ts?.$date
                        );

                        return (
                          // detailMessage
                          <PopupState
                            variant="popover"
                            popupId={popupId}
                            key={'popover' + el?._id}
                          >
                            {PopupState => (
                              <Box
                                sx={{
                                  width: '100%',
                                  height: 'auto'
                                }}
                              >
                                <DetailMessage
                                  message={el}
                                  key={el?._id}
                                  // sending={sending}
                                  messageNext={
                                    listLoadSurroundingMessage[index + 1]
                                  }
                                  searchMessage={listMessageSearch[count]}
                                  showAvatar={showAvatar}
                                  showNameMemberGrChat={showNameMemberGrChat}
                                  conversation={conversation.conversation}
                                  isFirstIndex={index === 0}
                                  handleReplyMessage={handleReplyMessage}
                                  setUpdatedMessage={setUpdatedMessage}
                                  borderTopMessage={borderTopMessage}
                                  borderBottomMessage={borderBottomMessage}
                                  listMemberGroupChat={JSON.stringify([
                                    mentionTagAll,
                                    ...listMemberGrChat
                                  ])}
                                  typeBoxChat={type}
                                  showTimeSend={showTimeSend}
                                  nextMessageShowTimeSend={
                                    listLoadSurroundingMessage?.[index - 1]
                                      ?.isShowTimeSend
                                  }
                                  repliedMessage={repliedMessage}
                                  setIsToastMessage={setIsToastMessage}
                                  setTitleToastMessage={setTitleToastMessage}
                                  setCodeNoti={setCodeNoti}
                                />
                              </Box>
                            )}
                          </PopupState>
                        );
                      })
                    : conversation.listMessage
                    ? conversation.listMessage.map((el: any, index: any) => {
                        // console.log('check show ', setShowAvatar(el?.u?._id));

                        const showAvatar: Boolean = el?.t
                          ? true
                          : setShowAvatar(el?.u?._id);
                        const showNameMemberGrChat = setShowNameMemberGrChat(
                          el?.u?._id,
                          conversation.listMessage?.[index + 1]?.u?._id
                        );
                        const borderBottomMessage = showAvatar;
                        const borderTopMessage = showNameMemberGrChat;
                        const showTimeSend = setShowTimeSend(
                          conversation.listMessage?.[index]?.ts?.$date,
                          conversation.listMessage?.[index + 1]?.ts?.$date
                        );

                        return (
                          // detailMessage
                          <PopupState
                            variant="popover"
                            popupId={popupId}
                            key={'popover' + el?._id}
                          >
                            {PopupState => (
                              <Box sx={{ width: '100%', height: 'auto' }}>
                                <DetailMessage
                                  message={el}
                                  key={el?._id}
                                  // sending={sending}
                                  messageNext={
                                    conversation?.listMessage[index + 1]
                                  }
                                  showAvatar={showAvatar}
                                  showNameMemberGrChat={showNameMemberGrChat}
                                  conversation={conversation.conversation}
                                  isFirstIndex={index === 0}
                                  handleReplyMessage={handleReplyMessage}
                                  setUpdatedMessage={setUpdatedMessage}
                                  borderTopMessage={borderTopMessage}
                                  borderBottomMessage={borderBottomMessage}
                                  listMemberGroupChat={JSON.stringify([
                                    mentionTagAll,
                                    ...listMemberGrChat
                                  ])}
                                  typeBoxChat={type}
                                  showTimeSend={showTimeSend}
                                  nextMessageShowTimeSend={
                                    conversation.listMessage?.[index - 1]
                                      ?.isShowTimeSend
                                  }
                                  repliedMessage={repliedMessage}
                                  setIsToastMessage={setIsToastMessage}
                                  setTitleToastMessage={setTitleToastMessage}
                                  setCodeNoti={setCodeNoti}
                                />
                              </Box>
                            )}
                          </PopupState>
                        );
                      })
                    : null}
                  {!conversation.hasMore && (
                    <StartConversation
                      conversation={conversation}
                      isAvatarETagGroup={isAvatarETagGroup}
                      objectChatInfor={objectChatInfor}
                      messageAutomatic={messageAutomatic}
                      handleAutomaticReply={handleAutomaticReply}
                      isCertified={objectChatInfor?.data?.certified}
                      meInfo={meInfo}
                      match={match}
                    />
                  )}
                </InfiniteScroll>

                {conversation.conversation?.type === 'group' ? (
                  listReadReceiptsLastMessage &&
                  listReadReceiptsLastMessage.length ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: '6px',
                        right: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <AvatarGroup max={3}>
                        {listReadReceiptsLastMessage
                          .slice(0, 3)
                          .map((el: any, index: number) => {
                            return (
                              <Tooltip
                                key={el._id}
                                title={el?.user?.name}
                                arrow
                                style={{
                                  maxWidth: '120px',
                                  fontSize: '12px'
                                }}
                              >
                                <Box>
                                  <AvatarSocial
                                    type="feed"
                                    avatarObj={el.avatar_media}
                                    style={{
                                      height: 14,
                                      width: 14,
                                      marginRight: '2px'
                                    }}
                                    object={el}
                                  />
                                </Box>
                              </Tooltip>
                            );
                          })}
                      </AvatarGroup>
                      {listReadReceiptsLastMessage &&
                      listReadReceiptsLastMessage.length > 3 ? (
                        <DialogViewReadMessage
                          listConversation={listReadReceiptsLastMessage}
                        />
                      ) : null}
                    </Box>
                  ) : (
                    <Box className={classes.styleRenderingStatusMessage}>
                      <RenderStatusSendingMessage
                        type={conversation?.listMessage?.[0]?.status}
                        classes={classes}
                        lastMessage={lastMessage}
                        meInfo={meInfo}
                      />
                    </Box>
                  )
                ) : (conversation &&
                    conversation.listMessage &&
                    conversation.listMessage.length > 0 &&
                    conversation?.listMessage.hasOwnProperty('unread') &&
                    !conversation?.listMessage?.[0]?.unread) ||
                  (conversation?.listMessage?.length > 0 &&
                    conversation.conversation?.type === 'chat_page' &&
                    conversation?.conversation?.countUnreadPage === 0) ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '6px',
                      right: '10px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Tooltip // icon đã xem
                      arrow
                      placement="bottom"
                      title={conversation?.conversation?.fname}
                    >
                      <Box>
                        <AvatarSocial
                          type="feed"
                          avatarObj={conversation?.conversation?.avatar_media}
                          style={{
                            height: 14,
                            width: 14,
                            marginRight: '2px'
                          }}
                          object={conversation?.conversation}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                ) : (
                  meInfo.id === lastMessage?.u?._id &&
                  conversation.listMessage &&
                  conversation.listMessage[0] &&
                  !conversation.listMessage[0]?.t && (
                    <Box className={classes.styleRenderingStatusMessage}>
                      <RenderStatusSendingMessage
                        type={conversation?.listMessage?.[0]?.status}
                        classes={classes}
                        lastMessage={lastMessage}
                        meInfo={meInfo}
                      />
                    </Box>
                  )
                )}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {isShowButtonRef && (
                    <ButtonScrollMess handleScroll={scrollToLassMessage}>
                      <Fab size="small" aria-label="scroll back to top">
                        <i
                          style={{
                            fontSize: '14px',
                            padding: '10px',
                            borderRadius: '50%',
                            color: mainTopic ? mainTopic[0] : '#7165E0'
                          }}
                          className="fa-solid fa-arrow-down"
                        ></i>
                      </Fab>
                    </ButtonScrollMess>
                  )}
                </Box>
              </List>
            </Box>
          </Box>

          {usersTyping ? (
            <Box sx={{ position: 'relative' }}>
              <Box className={classes.styleUserTypingRoot}>
                {countUsersTyping === 1 ? (
                  <span className={classes.textUserTyping}>
                    {conversation &&
                    conversation.conversation.t === 'cp' &&
                    !conversation.conversation.is_user_chat
                      ? conversation.conversation?.fname?.split(' ')[0]
                      : conversation?.action?.[0]?.fname
                          ?.split(' ')
                          .slice(-1)}
                    &nbsp;
                  </span>
                ) : countUsersTyping > 1 && countUsersTyping <= 3 ? (
                  conversation?.action.map((el: any) => (
                    <span className={classes.textUserTyping}>
                      {el.fname.split(' ')[0]},&nbsp;
                    </span>
                  ))
                ) : (
                  <span className={classes.textUserTyping}>
                    Mọi người&nbsp;
                  </span>
                )}
                <span className={classes.textUserTyping}>đang nhập</span>
                <div id="typing" className={classes.typing}>
                  <div className={`typing__dot ${classes.typingDot}`}></div>
                  <div className={`typing__dot ${classes.typingDot}`}></div>
                  <div className={`typing__dot ${classes.typingDot}`}></div>
                </div>
              </Box>
            </Box>
          ) : null}

          {formik.values.files.length > 0 && (
            <ThumbListFile
              files={formik.values.files}
              handleRemoveFile={file => handleRemoveFile(file)}
            />
          )}

          <Box
            key={keyRender}
            className={classes.rootFooter}
            // onClick={handleSeenMessage}
            sx={{
              maxHeight: conversation.conversation?.blocker
                ? '184px'
                : conversation.conversation?.blocked
                ? '164px'
                : repliedMessage
                ? '204px'
                : '164px'
            }}
          >
         
            <Footer
              keyRender={keyRender}
              type={type}
              inputRef={inputRef}
              conversation={conversation.conversation}
              repliedMessage={repliedMessage}
              setRepliedMessage={setRepliedMessage}
              handleCancelReplied={handleCancelReplied}
              handleSendMessage={handleSendMessage}
              formik={formik}
              getRootProps={getRootProps}
              getSubscription={getSubscription}
              getInputProps={getInputProps}
              open={open}
              setGifSelected={setGifSelected}
              setStickySelected={setStickySelected}
              gifSelected={gifSelected}
              stickySelected={stickySelected}
              handleQuickSendIcon={handleSendQuicklyIcon}
              updatedMessage={updatedMessage}
              listMemberGrChat={listMemberGrChat}
              colorIcon={conversation?.focus}
              optionsAutomaticReply={optionAutomaticReply}
              handleSeenMessage={handleSeenMessage}
              setObjectMentions={setObjectMentions}
              setUpdatedMessage={setUpdatedMessage}
            />
          </Box>

          {openDialogValidateFiles && (
            <DialogConfirm
              title="Không tải lên tệp được"
              text="File bạn đã chọn quá lớn. Bạn chỉ có thể tải lên tối đa 20 file một lần."
              open={openDialogValidateFiles}
              handleClose={() => setOpenDialogValidateFiles(false)}
              labelButton="Đóng"
              action={() => {
                setOpenDialogValidateFiles(false);
              }}
              notCancel
            />
          )}

          {/* </ClickOutHandler> */}
          {isToastMessage && (
            <CustomizedSnackBars
              open={isToastMessage}
              setOpen={setIsToastMessage}
              message={titleToastMessage}
              style={{
                left: match.params.key === 'chat' ? '120px !important' : '0px'
              }}
              code={codeNoti}
            />
          )}
        </Box>
      </ClickAwayListener>
    ) : null;
  }
);

export default BoxChat;
