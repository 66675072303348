import { Avatar, Box, Divider, Rating, Theme, Typography } from '@mui/material';
import NoSsr from '@mui/material/NoSsr';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import parse from 'html-react-parser';
import React from 'react';
import { voteChangeApi, votePostApi } from 'src/apis/socialPost.api';
import target_default from 'src/assets/images/target.svg';
import succesfull from 'src/assets/images/win.svg';
import { formatDateTimeToStringDateTime } from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import LocationPost from 'src/components/Dialog/DialogCUPost/CUMenuUi/CULocationPost';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import TextCollapse from 'src/components/TextCollapse/Index';
import { buttonColor } from 'src/constants/styles';
import CardPost from './Card';
import DialogShowPost from './DialogShowPost';
import GroupPageShared from './GroupPageShared';
import Media from './Media';
import NewEventCreated from './NewEventCreated';
import Poll from './Poll';
import Share from './Share';
import ShareProductOrCourse from './ShareProductOrCourse';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import { useLocation, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  typography: { wordBreak: 'break-word' },
  typographyMoment: {
    '&> a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      fontWeight: '500 !important'
    }
  },
  statusBackground: {
    minHeight: '472px',
    maxHeight: '600px',
    overflow: 'auto',
    width: '100%',
    backgroundRepeat: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    padding: '0px 22px'
  },
  statusBackgroundQuestion: {
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: '0 2px 12px var(rgba(0, 0, 0, 0.2)) !important',
    marginBottom: 20,
    position: 'relative'
  },
  wrapBg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20
  },
  wrapAvatar: {
    height: 168,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20
  },
  wrapText: {
    whiteSpace: 'pre-wrap',
    'line-height': '20px',
    fontSize: '15px',

    '& > a': {
      color: `${buttonColor.backgroundColor} !important`,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  titleBackground: {
    width: '100%',
    wordBreak: 'break-word',
    fontSize: '24px'
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  contentTitle: {
    fontWeight: 700,
    textAlign: 'center',
    color: '#fff',
    wordBreak: 'break-word',
    padding: '0 30px'
  },
  ratingStart: {
    fontSize: '20px !important',
    padding: '10px 12px !important'
  },
  imageShare: {
    width: '100px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '10px'
  },
  watchSearch: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px'
  },
  lifeEvent: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '10px'
  },
  boxWatchPage: {
    width: '100%',
    marginTop: '10px'
  }
}));

interface RouteParams {
  id?: string;
  key?: string;
  tab?: string;
  userId?: string;
}

const Index = React.memo((props: any) => {
  const classes = useStyles();
  const theme: any = useTheme();
  const { id, key, tab } = useParams<RouteParams>();
  const location = useLocation();
  const isFeedPage = id && !key && !tab && location.pathname === `/page/${id}`;

  const {
    post,
    type,
    typePost,
    valueRating,
    typeShare,
    scrollToTop,
    setPostData,
    postShare,
    offset
  } = props;

  let status_tags = post?.status_tags;

  let tags = post?.tags;

  const [dataVote, setDataVote] = React.useState<any>({});
  const [openDialogShowPost, setOpenDialogShowPost] = React.useState(false);

  const getBody = () => {
    if (post?.album && post?.media_attachments?.[0]?.description) {
      return post?.media_attachments?.[0]?.description;
    }
    return post?.content ?? '';
  };

  const handleVote = async (id, data) => {
    try {
      let res = await votePostApi(id, data);
      if (res.status === 200) {
        setDataVote(res.data);
      }
    } catch (error: any) {}
  };

  const handleChangeVote = async (id, data) => {
    try {
      let res = await voteChangeApi(id, data);
      if (res.status === 200) {
        setDataVote(res.data);
      }
    } catch (error: any) {}
  };

  const renderMedia = () => {
    if (!post || !post?.media_attachments?.length) {
      return null;
    }

    if ((type && type === 'previewMedia') || (type && type === 'sharePost')) {
      return null;
    }

    return typeShare === 'share-messenger' ? (
      <div>
        <img
          src={
            post?.media_attachments[0].preview_url ||
            post?.media_attachments[0].url
          }
          alt="share-messenger"
          className={classes.imageShare}
        />
      </div>
    ) : (
      <>
        <Media
          key={`media-${post.id}`}
          post={post}
          typePost={typePost}
          type={type}
          scrollToTop={scrollToTop}
          setPostData={setPostData}
          offset={offset}
        />
      </>
    );
  };

  const check_hidden_post = post => {
    //for post delete or user block other user or block page or because visibility
    if (post?.source_deleted) return true; //post deleted

    if (post?.not_show_reblogs) return true; //for visibility post user

    if (
      post?.account_relationships?.blocking ||
      (post?.account_relationships?.blocked_by &&
        !post?.group?.group_relationship?.admin)
    )
      return true; //for block owner event

    if (post?.reblog?.page_owner?.page_relationship?.block_page) return true; //for block page owner

    return false;
  };

  let checkOnlyStatus: any =
    post?.media_attachments?.length ||
    post?.shared_group ||
    post?.shared_page ||
    post?.shared_event ||
    post?.shared_project ||
    post?.shared_recruit ||
    post?.shared_course ||
    post?.shared_music ||
    (post &&
      post?.place &&
      !post?.media_attachments?.length &&
      !post?.status_background &&
      !post?.card &&
      !post?.poll) ||
    (post && post?.reblog) ||
    (post &&
      !post?.media_attachments?.length &&
      !post?.card &&
      post?.card &&
      !post?.status_background) ||
    (post &&
      post?.card &&
      !post?.media_attachments?.length &&
      !post?.status_background) ||
    (post && post?.event?.id && post?.post_type === 'event_shared') ||
    (post && post?.project?.id && post?.post_type === 'project_shared') ||
    (post && post?.recruit?.id && post?.post_type === 'recruit_shared') ||
    (post && post?.course?.id && post?.post_type === 'course_shared') ||
    post?.life_event ||
    (post && post?.poll) ||
    post?.post_type === 'question';

  const renderRemoveReblog = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '95%',
          height: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '35px',

            width: '100%',
            height: 'auto',
            borderRadius: '10px',
            border: '1px solid rgba(0,0,0,0.2)',
            padding: '30px'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i style={{ fontSize: '25px' }} className="fa-solid fa-lock"></i>
            <Typography
              sx={{ fontWeight: 600, fontSize: '18px', marginLeft: '5px' }}
            >
              Nội dung này không hiển thị
            </Typography>
          </div>
          <Typography sx={{ marginLeft: '28px' }}>
            Lỗi này thường do chủ sở hữu chỉ chia sẻ nội dung với một nhóm nhỏ,
            thay đổi người được xem hoặc đã xoá nội dung.
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <>
      {type === 'rating' ? (
        <Rating
          name="read-only"
          value={valueRating}
          className={classes.ratingStart}
          readOnly
        />
      ) : null}
      {post?.status_background ? (
        typeShare !== 'share-messenger' ? (
          <div
            key={`status-${post.id}`}
            className={classes.statusBackground}
            style={{
              backgroundImage: `url(${post?.status_background.url})`,
              minHeight:
                post?.status_background.url !== null
                  ? typePost === 'pinned'
                    ? '100%'
                    : '472px'
                  : 'auto'
            }}
          >
            <span
              className={classes.titleBackground}
              style={{
                ...post?.status_background.style,
                ...(post?.status_background && {
                  fontSize: '30px'
                }),
                textAlign:
                  post?.status_background.url !== null ? 'center' : 'start',
                color: post?.status_background.style?.fontColor,
                '&> a': {
                  color: `${post?.status_background.style?.fontColor}`,
                  textDecoration: 'none'
                }
              }}
            >
              {status_tags?.length || tags?.length > 0 ? (
                <TextCollapse
                  type={true}
                  data={getBody()}
                  status_tags={status_tags}
                  typeShare={typeShare}
                  info={post}
                  style={{
                    ...(post?.status_background && {
                      fontSize: '30px'
                    })
                  }}
                />
              ) : (
                parse(getBody()?.text ?? getBody())
              )}
            </span>
          </div>
        ) : (
          <span style={{ padding: '10px 26px' }}>
            {status_tags?.length > 0 ? (
              <TextCollapse
                type={true}
                data={getBody()}
                status_tags={status_tags}
                typeShare={typeShare}
                info={post}
              />
            ) : (
              parse(getBody()?.text ?? getBody())
            )}
          </span>
        )
      ) : (
        <div
          style={{
            padding: typePost === 'pinned' ? '4px 16px 11px' : '4px 16px 16px'
            // minHeight:
            //   type === 'sharePost'
            //     ? '70px'
            //     : typePost === 'pinned'
            //     ? '35px'
            //     : ''
          }}
        >
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505'
            }}
            key={`text-${post?.id}`}
            className={
              checkOnlyStatus && typePost === 'pinned'
                ? classes.lineClamp
                : post?.post_type === 'moment'
                ? classes.typographyMoment
                : classes.typography
            }
            variant="subtitle1"
            component="div"
            style={
              typePost === 'pinned'
                ? { wordBreak: 'break-word', lineHeight: 1.333 }
                : { lineHeight: 1.333 }
            }
          >
            <TextCollapse
              type={true}
              data={getBody()}
              status_tags={status_tags}
              typeShare={typeShare}
              info={post}
              style={{
                fontSize: 'inherit'
              }}
            />
          </Typography>
        </div>
      )}

      {post?.post_type === 'question' ? (
        typeShare !== 'share-messenger' ? (
          <div className={classes.wrapBg}>
            <div
              key={`status-${post.id}`}
              className={classes.statusBackgroundQuestion}
              style={
                typePost === 'pinned'
                  ? {
                      width: 'calc(100% - 80px)',
                      height: '100%',
                      background: post.status_question?.color
                    }
                  : {
                      width: '432px',
                      height: '556px',
                      background: post.status_question?.color
                    }
              }
            >
              <div className={classes.wrapAvatar}>
                <Avatar
                  style={
                    typePost === 'pinned'
                      ? {
                          width: '50px',
                          height: '50px',
                          position: 'absolute',
                          top: '14px'
                        }
                      : { width: '168px', height: '168px' }
                  }
                  src={post.account?.avatar_media?.url}
                  alt={post.account?.display_name}
                />
              </div>
              <span
                style={
                  typePost === 'pinned'
                    ? {
                        fontSize: '15px',
                        position: 'absolute',
                        top: '73px',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }
                    : {
                        fontSize:
                          post.status_question?.content?.length <= 50
                            ? 30
                            : post.status_question?.content?.length <= 196
                            ? 24
                            : 20
                      }
                }
                className={classes.contentTitle}
              >
                {post.status_question?.content}
              </span>
            </div>
          </div>
        ) : (
          <span
            style={{
              fontSize: 15
            }}
          >
            {post.status_question?.content}
          </span>
        )
      ) : null}

      {post?.post_type === 'target' ? (
        typeShare !== 'share-messenger' ? (
          <div className={classes.wrapBg}>
            <div
              key={`status-${post.id}`}
              className={classes.statusBackgroundQuestion}
              style={
                typePost === 'pinned'
                  ? {
                      width: 'calc(100% - 80px)',
                      height: '100%',
                      background: post.status_target?.color
                    }
                  : {
                      width: '432px',
                      height: '556px',
                      background: post.status_target?.color
                    }
              }
            >
              <div className={classes.wrapAvatar}>
                <AvatarSocial
                  type="feed"
                  src={
                    post.status_target?.target_status === 'completed'
                      ? succesfull
                      : target_default
                  }
                  style={
                    typePost === 'pinned'
                      ? {
                          width: '57px',
                          height: '57px',
                          position: 'absolute',
                          top: '14px'
                        }
                      : { width: '168px', height: '168px' }
                  }
                ></AvatarSocial>
              </div>
              <span
                style={
                  typePost === 'pinned'
                    ? {
                        fontSize: '15px',
                        position: 'absolute',
                        top: '73px',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }
                    : {
                        fontSize:
                          post.status_target?.content?.length <= 50
                            ? 30
                            : post.status_target?.content?.length <= 196
                            ? 24
                            : 20
                      }
                }
                className={classes.contentTitle}
              >
                {post.status_target?.content}
              </span>
            </div>
          </div>
        ) : (
          <span
            style={{
              fontSize: 15
            }}
          >
            {post.status_target?.content}
          </span>
        )
      ) : null}

      {post && post.poll ? (
        <div style={{ marginBottom: 20 }}>
          <Poll
            key={`poll-${post.id}`}
            info={
              Object.keys(dataVote).length
                ? {
                    ...post,
                    poll: dataVote
                  }
                : post
            }
            handleVote={handleVote}
            handleChangeVote={handleChangeVote}
          />
        </div>
      ) : null}
      <NoSsr defer={false}>{renderMedia()}</NoSsr>
      {post?.life_event && (
        <>
          {post?.life_event?.default_media_url && (
            <video
              autoPlay={true}
              muted
              loop
              style={{
                width: '100%',
                height: typePost === 'pinned' ? '112px' : '100%'
              }}
              src={post?.life_event?.default_media_url}
            />
          )}
          <div
            className={classes.lifeEvent}
            style={
              typePost === 'pinned'
                ? {
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical'
                  }
                : {}
            }
          >
            <Typography style={{ fontSize: 17, fontWeight: 400 }}>
              {post?.life_event?.life_event_category?.id === 31 &&
              post?.life_event.company ? (
                <span>
                  {post?.life_event?.end_date && post?.life_event?.start_date
                    ? 'Đã làm việc tại '
                    : 'Bắt đầu làm việc tại '}{' '}
                </span>
              ) : post?.life_event?.school_type && !post?.life_event?.name ? (
                <span>
                  {post?.life_event?.currently
                    ? 'Đã tốt nghiệp tại '
                    : 'Bắt đầu học tại '}{' '}
                </span>
              ) : null}
              <div
                style={{
                  padding: '6px 12px',
                  wordBreak: 'break-word'
                }}
              >
                {post?.life_event.name
                  ? post?.life_event.name
                  : post?.life_event.company}
              </div>
              {post?.life_event?.place ? (
                <>
                  <span> tại </span>
                  <span style={{ fontWeight: 500 }}>
                    {post?.life_event.place?.title}
                  </span>
                </>
              ) : post?.life_event?.partner ? (
                <>
                  <span> với </span>
                  <span style={{ fontWeight: 500 }}>
                    {post?.life_event?.partner?.display_name}
                  </span>
                </>
              ) : null}
            </Typography>
            <Typography
              style={{
                fontSize: 15,
                color: '#65676b',
                padding: '0px 20px'
              }}
            >
              {formatDateTimeToStringDateTime(
                post?.life_event?.start_date,
                false
              )}{' '}
              <span
                style={{
                  wordBreak: 'break-word'
                }}
              >
                {`${
                  post?.life_event?.position
                    ? ' - ' + post?.life_event?.position
                    : ''
                }`}
              </span>
            </Typography>
            <Typography
              style={{
                fontSize: '18px',
                fontWeight: '600'
              }}
            >
              {post?.life_event?.city ? `${post?.life_event?.city}` : ''}
            </Typography>
            {/* {post.life_event.place || post.life_event.city ? (
              <Typography style={{ fontSize: 16, fontWeight: 500 }}>
                {post.life_event?.place
                  ? post.life_event.place.title
                  : post.life_event.city}
              </Typography>
            ) : null} */}
            {post?.life_event?.school_type ? (
              post?.life_event?.school_type === 'HIGH_SCHOOL' ? (
                <Typography>Trường trung học</Typography>
              ) : (
                <Typography
                  style={{
                    wordBreak: 'break-word',
                    padding: '0px 20px'
                  }}
                >
                  {post?.life_event?.attended_for === 'university'
                    ? 'Đại học'
                    : post?.life_event?.attended_for === 'colleges'
                    ? 'Cao học'
                    : ''}
                  {[
                    post?.life_event?.concentration1,
                    post?.life_event?.concentration2,
                    post?.life_event?.concentration3
                  ].filter(Boolean).length > 0
                    ? ' - ' +
                      [
                        post?.life_event?.concentration1,
                        post?.life_event?.concentration2,
                        post?.life_event?.concentration3
                      ]
                        .filter(Boolean)
                        .join(', ')
                    : ''}
                </Typography>
              )
            ) : null}
            <div
              style={{
                display: 'flex',
                padding: '0px 20px',
                wordBreak: 'break-word'
              }}
            >
              {post?.life_event?.description}
            </div>
          </div>
        </>
      )}

      {post &&
        ((post?.event?.id && post?.post_type === 'event_shared') ||
          (post?.project?.id && post?.post_type === 'project_shared') ||
          (post?.recruit?.id && post?.post_type === 'recruit_shared') ||
          (post?.course?.id && post?.post_type === 'course_shared')) && (
          <NewEventCreated post={post} />
        )}

      {post &&
      post?.card &&
      !post?.media_attachments?.length &&
      !post?.status_background ? (
        post?.card?.provider_name === 'GIPHY' ? (
          <div
            style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
          >
            <img
              style={{ maxHeight: 700, width: '100%' }}
              alt="gif"
              src={post?.card.url || post.card.link}
            />
          </div>
        ) : (
          <CardPost post={post} type={type} typeShare={typeShare} />
        )
      ) : null}

      {post &&
      !post?.media_attachments?.length &&
      !post?.card &&
      post?.card &&
      !post?.status_background ? (
        post?.card?.title ? (
          <CardPost
            post={{
              ...post,
              card: {
                ...post?.card,
                image: post?.card?.url,
                link: post?.card?.link
              }
            }}
            typeShare={typeShare}
          />
        ) : (
          <div
            style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
          >
            <img
              style={{ maxHeight: 700, width: '100%' }}
              alt="gif"
              src={post.card.url}
            />
          </div>
        )
      ) : null}

      {check_hidden_post(post) ? (
        renderRemoveReblog()
      ) : post && post?.reblog ? (
        <Share post={post} typePost={typePost} />
      ) : null}

      {post &&
      post?.place &&
      !post?.reblog &&
      !post?.media_attachments?.length &&
      !post?.status_background &&
      !post?.card &&
      !post?.poll &&
      !post?.shared_music &&
      !post?.shared_page &&
      !post?.shared_event &&
      !post?.shared_project &&
      !post?.shared_recruit &&
      !post?.life_event &&
      !post?.share_product_or_course &&
      !post?.shared_group ? (
        <div
          style={{
            border: '1px solid rgba(0,0,0,0.12)',
            borderLeft: 0,
            borderRight: 0
          }}
        >
          <LocationPost
            locationSelected={post.place}
            style={{
              zIndex: 1,
              width: '100%',
              height: typePost === 'pinned' ? 88 : 200
            }}
          />
          <Box
            sx={{
              backgroundColor:
                theme.palette.mode === 'dark' ? '#3e4042' : '#e4e6eb',
              padding: typePost === 'pinned' ? '3px 8px !important' : 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Avatar
              style={{ marginRight: 12 }}
              alt="location"
              src={
                post?.place?.avatar_media
                  ? post?.place?.avatar_media?.show_url ??
                    post?.place?.avatar_media?.preview_url
                  : avatarDefault
              }
            />

            <div>
              <Typography
                style={{ fontSize: 15 }}
                variant="body2"
                color="textSecondary"
                component="div"
              >
                {post?.place?.page_categories?.[0]?.text}
              </Typography>
              <Typography
                style={{ fontWeight: 500, fontSize: 17 }}
                gutterBottom
              >
                {post?.place?.title}
              </Typography>
            </div>
          </Box>
        </div>
      ) : null}

      {((post?.shared_group &&
        !post?.account_relationships?.blocking &&
        !post?.account_relationships?.blocked_by) ||
        (post?.shared_page &&
          !post?.account_relationships?.blocking &&
          !post?.account_relationships?.blocked_by) ||
        (post?.shared_event &&
          !post?.account_relationships?.blocking &&
          !post?.account_relationships?.blocked_by) ||
        (post?.shared_project &&
          !post?.account_relationships?.blocking &&
          !post?.account_relationships?.blocked_by) ||
        (post?.shared_recruit &&
          !post?.account_relationships?.blocking &&
          !post?.account_relationships?.blocked_by) ||
        (post?.shared_music &&
          !post?.account_relationships?.blocking &&
          !post?.account_relationships?.blocked_by) ||
        (post?.shared_course &&
          !post?.account_relationships?.blocking &&
          !post?.account_relationships?.blocked_by)) && (
        <GroupPageShared
          typePost={typePost}
          post={post}
          group={post?.shared_group}
          page={post?.shared_page}
          event={post?.shared_event}
          project={post?.shared_project}
          recruit={post?.shared_recruit}
          course={post?.shared_course}
          music={post?.shared_music}
          postShare={postShare}
        />
      )}
      {post?.share_product_or_course &&
        !post?.account_relationships?.blocking &&
        !post?.account_relationships?.blocked_by && (
          <ShareProductOrCourse
            typePost={typePost}
            postData={post?.share_product_or_course}
            postShare={postShare}
          />
        )}
      {(post?.page?.page_relationship?.role === 'admin' ||
        post?.page?.page_relationship?.role === 'moderator') &&
      post?.page &&
      isFeedPage ? (
        <Box className={classes.boxWatchPage}>
          <ButtonInherit
            label="Xem chi tiết"
            style={{
              backgroundColor: 'transparent',
              color: buttonColor.backgroundColor,
              padding: '12px 24px'
            }}
            action={() => {
              setOpenDialogShowPost(true);
            }}
          />
          <Divider variant="middle" sx={{ marginTop: '10px' }} />
        </Box>
      ) : null}
      {openDialogShowPost && (
        <DialogShowPost
          post={post}
          handleClose={() => {
            setOpenDialogShowPost(false);
          }}
          open={openDialogShowPost}
        />
      )}
    </>
  );
});

export default Index;
