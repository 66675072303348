import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Theme } from '@mui/material';
import { buttonColor } from 'src/constants/styles';
import { useTheme } from '@mui/material/styles';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 36,
    borderRadius: 8,
    fontWeight: '500 !important',
    cursor: 'pointer'
  },
  rootDisable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 36,
    borderRadius: 8,
    fontWeight: '500 !important',
    pointerEvents: 'none',
    backgroundColor: '#cccccc !important'
  }
}));

interface ButtonProps {
  icon?: any;
  label?: any;
  style?: any;
  disabled?: boolean;
  styleBtnHover?: any;
  solid?: any;
  action?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  iconStart?: any;
  iconEnd?: any;
  isLoading?: boolean;
}
const checkColor = (theme, type) => {
  if (theme.palette.mode === 'dark' && !type) {
    return '#424648';
  } else if (theme.palette.mode === 'light' && !type) {
    return '#E5F0F4';
  } else if (theme.palette.mode && type) {
    return '#7D72DF';
  }
};
const Button = (props: ButtonProps) => {
  const {
    icon,
    label,
    action,
    style,
    disabled,
    solid,
    styleBtnHover,
    iconEnd,
    iconStart,
    isLoading
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      onClick={e => {
        action && !disabled && action();
      }}
      className={disabled ? classes.rootDisable : classes.root}
      sx={{
        transition: 'all 0.3s ease',
        backgroundColor: solid
          ? buttonColor.backgroundColor
          : 'button.custom.background',
        '&:hover': {
          backgroundColor: checkColor(theme, solid),
          ...styleBtnHover
        },
        ...style
      }}
    >
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {iconStart && iconStart?.src ? (
          <img 
            src={iconStart?.src} 
            alt="Icon" 
            style={iconStart?.style}
          />
        ) : ''} 
          {icon ? <>{icon}&nbsp;</> : ''}
          {label} {iconEnd ? <>&nbsp;{iconEnd}</> : ''}
        </>
      )}
    </Box>
  );
};

Button.defaultProps = {
  isLoading: false
};

export default React.memo(Button);
